import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter, useParams } from "react-router-dom";

import { eventBookingConstants } from "../../../../constants";
import { eventBookingActions } from "../../../../redux/actions";
import Modal from "../../../../container/Views/Login/Modal";
import {
  convertEpochToFormattedDate,
  convertLongDateToShortDate,
} from "../../../../shared/utility";

const DraftEventCart = ({
  handleCheckout,
  handlePromoCodeSubmit,
  calculateSubtotal,
  setIsModalOpen,
  handleRemoveShutterB,
  handleRemoveReel,
  fee,
  tax,
  serviceFee,
  netTotal,
  discount,
  miscAmounts,
  setmodel,
  ...props
}) => {
  const { eventId } = useParams();
  const handleUserCheckout = () => {
    // draft event case
    const savedData = localStorage.getItem("dataToSend");
    const parsedData = JSON.parse(savedData);
    parsedData.Event = {
      ...parsedData.Event,
      status: "draft",
      id: eventId,
      // ...props.model,
    };
    if (typeof props.model.eventdate !== "string") {
      parsedData.Event.eventDate = props.model.eventdate;
    }
    if (typeof props.model.sbstartTime !== "string") {
      parsedData.Event.sbStartTime = props.model.sbstartTime;
    }
    if (typeof props.model.event_start_time !== "string") {
      parsedData.Event.EventStartTime = String(props.model.event_start_time);
    }

    localStorage.setItem("dataToSend", JSON.stringify(parsedData));
    handleCheckout();
  };

  useEffect(() => {
    if (
      !props.selectedShutterBz ||
      props.selectedShutterBz.length === 0 ||
      !props.model?.eventdate
    ) {
      props.getDraftEventSbList(setmodel, eventId);
    }

    return () => {
      localStorage.setItem("isDraftEventCart", true);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <section className="h-100 h-custom">
        <div className="container h-100 py-5">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="p-0 col outer-border">
              <div className="table-responsive border-0">
                <table className="table my-custom-table">
                  <thead className="d-none d-md-none d-lg-block d-xl-block header-border brdr-btm-hidden">
                    <tr className="row col-12 px-0 mx-0">
                      {/* This <th> will be displayed on medium and small screens */}
                      <th
                        className="d-table-cell d-md-none col-1 d-flex align-items-center bordr-top-hidden"
                        scope="col"
                      ></th>
                      {/* This <td> will be displayed on larger screens */}
                      <th className="d-none d-md-table-cell col-1 d-flex align-items-center bordr-top-hidden bordr-btm-hidden">
                        {/* Add your content here */}
                      </th>
                      <th
                        className="d-table-cell d-md-none col-3 d-flex align-items-center bordr-top-hidden"
                        scope="col"
                      ></th>
                      <th
                        className="d-none d-md-table-cell col-3 d-flex align-items-center bordr-top-hidden  bordr-btm-hidden"
                        scope="col"
                      ></th>
                      <th
                        className="d-table-cell d-md-none col-5 d-flex align-items-center px-0 bordr-top-hidden"
                        scope="col"
                      >
                        Details
                      </th>
                      <th
                        className="d-none d-md-table-cell col-5 d-flex align-items-center px-0 bordr-top-hidden bordr-btm-hidden"
                        scope="col"
                      >
                        Details
                      </th>

                      <th
                        className="d-table-cell d-md-none  col-1 d-flex align-items-center px-0 bordr-top-hidden"
                        scope="col"
                      >
                        Hourly Rate
                      </th>
                      <th
                        className="d-none d-md-table-cell col-1 d-flex align-items-center px-0 bordr-top-hidden bordr-btm-hidden"
                        scope="col"
                      >
                        Hourly Rate
                      </th>

                      <th
                        className="d-table-cell d-md-none  col-1 d-flex align-items-center bordr-top-hidden"
                        scope="col"
                      >
                        Qty
                      </th>
                      <th
                        className="d-none d-md-table-cell col-1 d-flex align-items-center bordr-top-hidden bordr-btm-hidden"
                        scope="col"
                      >
                        Qty
                      </th>
                      <th
                        className="d-table-cell d-md-none col-1 d-flex align-items-center px-0 bordr-top-hidden"
                        scope="col"
                      >
                        Total
                      </th>
                      <th
                        className="d-none d-md-table-cell col-1 d-flex align-items-center px-0 bordr-top-hidden bordr-btm-hidden"
                        scope="col"
                      >
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody className="brdr-btm">
                    {props.selectedShutterBz?.map(
                      (shutterB, index) =>
                        shutterB.orderItemTypeId !==
                          eventBookingConstants.ORDER_ITEM_TYPE_ID.REEL && (
                          <tr
                            className="row col-12 px-0 mx-xs-0 mx-sm-0 mx-md-0 mx-lg-0 mx-xl-0 mx-0"
                            key={index}
                          >
                            <td className="d-none d-lg-table-cell col-6 col-lg-1">
                              {/* This will be displayed on larger and extra large screens */}
                              <div className="flex-column mr-4 ms-4">
                                <div
                                  onClick={() => handleRemoveShutterB(index)}
                                >
                                  <i
                                    className="fas fa-trash-alt mx-4 mt-4 mb-0 clr-grey cursor-point"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </td>
                            <td className="d-table-cell d-lg-none col-6 col-lg-1 back-dull">
                              <div className="flex-column mr-4 ms-4">
                                <div
                                  onClick={() => handleRemoveShutterB(index)}
                                >
                                  <i
                                    className="fas fa-trash-alt mx-4 mt-4 mb-0 clr-grey cursor-point"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </td>
                            <td className="d-none d-lg-table-cell col-6 col-lg-3">
                              {/* This will be displayed on larger and extra large screens */}
                              <div className="d-flex align-items-center">
                                <img
                                  src={`${
                                    process.env.REACT_APP_S3_BASE_URL_PORTFOLIO
                                  }/${
                                    shutterB?.sbportfolioGalleries &&
                                    shutterB?.sbportfolioGalleries[0]
                                      ?.profileImageUrl
                                  }`}
                                  className="img-fluid rounded-circle cart-image min-width-100"
                                  alt="Book"
                                />
                              </div>
                            </td>
                            {/* Second <td> for medium and smaller screens */}
                            <td className="d-table-cell d-lg-none col-6 col-lg-3 back-dull">
                              <div className="d-flex align-items-center">
                                <img
                                  src={`${
                                    process.env.REACT_APP_S3_BASE_URL_PORTFOLIO
                                  }/${
                                    shutterB?.sbportfolioGalleries &&
                                    shutterB?.sbportfolioGalleries[0]
                                      ?.profileImageUrl
                                  }`}
                                  className="img-fluid rounded-circle cart-image min-width-100"
                                  alt="Book"
                                />
                              </div>
                            </td>
                            <td className="align-middle col-12 col-lg-5 px-4 px-md-0">
                              <div className="d-flex col-12 px-0 flex-column">
                                <div className="d-flex col-12 px-0 flex-row">
                                  <p className="mb-0 col-6 col-lg-4 col-xl-4 col-md-6 col-sm-6-col-xs-6 px-0 font-weight-bold">
                                    shutterb:
                                  </p>
                                  <p className="mb-0 col-6 col-lg-8 col-xl-8 col-md-6 col-sm-6-col-xs-6 px-0 ">
                                    {shutterB.firstName} {shutterB.lastName}
                                  </p>
                                </div>
                                <div className="d-flex col-12 px-0 flex-row">
                                  <p className="mb-0 col-6 col-lg-4 col-xl-4 col-md-6 col-sm-6-col-xs-6 px-0 font-weight-bold">
                                    Event:
                                  </p>
                                  <p className="mb-0 col-6 col-lg-8 col-xl-8 col-md-6 col-sm-6-col-xs-6 px-0 ">
                                    {props.model.eventname}
                                  </p>
                                </div>
                                <div className="d-flex col-12 px-0 flex-row">
                                  <p className="mb-0 col-6 col-lg-4 col-xl-4 col-md-6 col-sm-6-col-xs-6 px-0 font-weight-bold">
                                    Date:
                                  </p>
                                  <p className="mb-0 col-6 col-lg-8 col-xl-8 col-md-6 col-sm-6-col-xs-6 px-0 ">
                                    {convertLongDateToShortDate(
                                      convertEpochToFormattedDate(
                                        props.model.eventdate
                                      )
                                    )}
                                  </p>
                                </div>
                                <div className="d-flex col-12 px-0 flex-row">
                                  <p className="mb-0 font-weight-bold col-6 col-lg-4 col-xl-4 col-md-6 col-sm-6-col-xs-6 px-0">
                                    Start Time:
                                  </p>
                                  <p className="mb-0 col-6 col-lg-8 col-xl-8 col-md-6 col-sm-6-col-xs-6 px-0 ">
                                    {/*props.model.shutterb_start_time*/}
                                    {typeof props.model.event_start_time !==
                                    "string"
                                      ? new Date(
                                          props.model.event_start_time * 1000
                                        ).toLocaleString("en-US", {
                                          hour: "numeric",
                                          minute: "numeric",
                                          hour12: true,
                                        })
                                      : new Date(
                                          props.model.event_start_time
                                        ).toLocaleTimeString()}{" "}
                                  </p>
                                </div>
                                <div className="d-flex flex-row col-12 px-0">
                                  <p className="mb-0 font-weight-bold col-6 col-lg-4 col-xl-4 col-md-6 col-sm-6-col-xs-6 px-0">
                                    Duration:
                                  </p>
                                  <p className="mb-0 col-6 col-lg-8 col-xl-8 col-md-6 col-sm-6-col-xs-6 px-0 ">
                                    {props.model.duration} hours
                                  </p>
                                </div>
                              </div>
                            </td>

                            <td className="row align-middle mx-0 col-12 col-lg-1 px-4 px-md-0">
                              <p className="mb-0 d-xs-block d-sm-block d-md-block d-xl-none d-lg-none font-weight-bold col-6 px-0">
                                Price:
                              </p>
                              <p className="mb-0 px-0 col-6 col-xs-6 col-sm-6 col-md-6 col-lg-12 col-xl-12 ">
                                ${(shutterB?.hourlyRate ?? 0).toFixed(2)}
                              </p>
                            </td>
                            <td className="row align-middle mx-0 col-12 col-lg-1 px-4 px-md-0">
                              <div className="d-flex flex-row col-12 col-lg-1 px-lg-3 px-md-0 px-sm-0 px-xs-0 px-xl-3 px-0">
                                <p className="mb-0 d-xs-block d-sm-block d-md-block d-xl-none d-lg-none font-weight-bold col-6 px-0">
                                  Quantity:
                                </p>
                                <p className="mb-0 px-0 col-6 col-xs-6 col-sm-6 col-md-6 col-lg-12 col-xl-12 ">
                                  {props.model.duration}
                                </p>
                              </div>
                            </td>
                            <td className="align-middle col-12 col-lg-1 px-4 px-md-0">
                              <div className="d-flex flex-row col-12 col-lg-1 px-lg-0 px-md-0 px-sm-0 px-xs-0 px-xl-0 px-0">
                                <p className="mb-0 d-xs-block d-sm-block d-md-block d-xl-none d-lg-none font-weight-bold col-6 px-0">
                                  Total:
                                </p>
                                <p className="mb-0 px-0 col-6 col-xs-6 col-sm-6 col-md-6 col-lg-12 col-xl-12 ">
                                  $
                                  {(
                                    props.model.duration *
                                      shutterB?.hourlyRate ?? 0
                                  ).toFixed(2)}
                                </p>
                              </div>
                            </td>
                          </tr>
                        )
                    )}

                    {/* addReel order item (reel without logo) */}
                    {props.addReel?.isReelSubmitted &&
                      props.addReel?.isReelWithoutLogo && (
                        <tr
                          key={props.selectedShutterBz.length + 1}
                          className="add-reel-row row col-12 px-0 mx-xs-0 mx-sm-0 mx-md-0 mx-lg-0 mx-xl-0 mx-0"
                        >
                          <td className="d-table-cell d-lg-none col-6 col-lg-1 back-dull">
                            <div className="flex-column mr-4 ms-4 pt-2">
                              <div onClick={() => handleRemoveReel()}>
                                <i
                                  className="fas fa-trash-alt mx-4 clr-grey cursor-point"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                          </td>
                          <td className="d-none d-lg-table-cell col-6 col-lg-1 ">
                            <div className="flex-column mr-4 ms-4">
                              <div onClick={() => handleRemoveReel()}>
                                <i
                                  className="fas fa-trash-alt mx-4 clr-grey cursor-point"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                          </td>
                          <td className="d-table-cell d-lg-none col-6 col-lg-3 px-0 back-dull">
                            <div className="d-flex  align-items-center px-0">
                              <h5 className="p-0 pb-5 m-0">Reel</h5>
                            </div>
                          </td>
                          <td className="d-none d-lg-table-cell col-6 col-lg-3">
                            <div className="d-flex  align-items-center px-0">
                              <h5 className="p-0 pb-5 m-0">Reel</h5>
                            </div>
                          </td>
                          <td className="row align-middle mx-0 col-12 col-lg-5 px-4 px-md-0">
                            <p className="mb-0 d-xs-block d-sm-block d-md-block d-xl-none d-lg-none font-weight-bold col-6 px-0">
                              Details:
                            </p>
                            <p className="mb-0 px-0 col-6 col-xs-6 col-sm-6 col-md-6 col-lg-12 col-xl-12 ">
                              {"-"}
                            </p>
                          </td>

                          <td className="row align-middle mx-0 col-12 col-lg-1 px-4 px-md-0">
                            <p className="mb-0 d-xs-block d-sm-block d-md-block d-xl-none d-lg-none font-weight-bold col-6 px-0">
                              Hourly Rate:
                            </p>
                            <p className="mb-0 px-0 col-6 col-xs-6 col-sm-6 col-md-6 col-lg-12 col-xl-12 ">
                              {"-"}
                            </p>
                          </td>
                          <td className="row align-middle mx-0 col-12 col-lg-1 px-4 px-md-0">
                            <div className="d-flex flex-row col-12 col-lg-1 px-lg-3 px-md-0 px-sm-0 px-xs-0 px-xl-3 px-0">
                              <p className="mb-0 d-xs-block d-sm-block d-md-block d-xl-none d-lg-none font-weight-bold col-6 px-0">
                                Quantity:
                              </p>
                              <p className="mb-0 px-0 col-6 col-xs-6 col-sm-6 col-md-6 col-lg-12 col-xl-12 ">
                                {props.addReel.reelQtyWithoutLogo}
                              </p>
                            </div>
                          </td>
                          <td className="row align-middle mx-0 col-12 col-lg-1 px-4 px-md-0">
                            <div className="d-flex flex-row col-12 col-lg-1 px-lg-0 px-md-0 px-sm-0 px-xs-0 px-xl-0 px-0">
                              <p className="mb-0 d-xs-block d-sm-block d-md-block d-xl-none d-lg-none font-weight-bold col-6 px-0">
                                Total:
                              </p>
                              <p className="mb-0 px-0 col-6 col-xs-6 col-sm-6 col-md-6 col-lg-12 col-xl-12 ">
                                $
                                {props.addReel.reelQtyWithoutLogo *
                                  props.addReel.reelPriceWithoutLogo}
                              </p>
                            </div>
                          </td>
                        </tr>
                      )}
                    {/* addReel order item (reel with logo) */}
                    {props.addReel?.isReelSubmitted &&
                      props.addReel?.isReelWithLogo && (
                        <tr
                          key={props.selectedShutterBz.length + 1}
                          className="add-reel-row row col-12 px-0 mx-xs-0 mx-sm-0 mx-md-0 mx-lg-0 mx-xl-0 mx-0"
                        >
                          <td className="d-table-cell d-lg-none col-6 col-lg-1 back-dull">
                            <div className="flex-column mr-4 ms-4 pt-2">
                              <div onClick={() => handleRemoveReel()}>
                                <i
                                  className="fas fa-trash-alt mx-4 clr-grey cursor-point"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                          </td>
                          <td className="d-none d-lg-table-cell col-6 col-lg-1 ">
                            <div className="flex-column mr-4 ms-4">
                              <div onClick={() => handleRemoveReel()}>
                                <i
                                  className="fas fa-trash-alt mx-4 clr-grey cursor-point"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                          </td>
                          <td className="d-table-cell d-lg-none col-6 col-lg-3 px-0 back-dull">
                            <div className="d-flex  align-items-center px-0">
                              <h5 className="p-0 pb-5 m-0">Recap</h5>
                            </div>
                          </td>
                          <td className="d-none d-lg-table-cell col-6 col-lg-3">
                            <div className="d-flex  align-items-center px-0">
                              <h5 className="p-0 pb-5 m-0">Recap</h5>
                            </div>
                          </td>
                          <td className="row align-middle mx-0 col-12 col-lg-5 px-4 px-md-0">
                            <p className="mb-0 d-xs-block d-sm-block d-md-block d-xl-none d-lg-none font-weight-bold col-6 px-0">
                              Details:
                            </p>
                            <p className="mb-0 px-0 col-6 col-xs-6 col-sm-6 col-md-6 col-lg-12 col-xl-12 ">
                              {"-"}
                            </p>
                          </td>

                          <td className="row align-middle mx-0 col-12 col-lg-1 px-4 px-md-0">
                            <p className="mb-0 d-xs-block d-sm-block d-md-block d-xl-none d-lg-none font-weight-bold col-6 px-0">
                              Hourly Rate:
                            </p>
                            <p className="mb-0 px-0 col-6 col-xs-6 col-sm-6 col-md-6 col-lg-12 col-xl-12 ">
                              {"-"}
                            </p>
                          </td>
                          <td className="row align-middle mx-0 col-12 col-lg-1 px-4 px-md-0">
                            <div className="d-flex flex-row col-12 col-lg-1 px-lg-3 px-md-0 px-sm-0 px-xs-0 px-xl-3 px-0">
                              <p className="mb-0 d-xs-block d-sm-block d-md-block d-xl-none d-lg-none font-weight-bold col-6 px-0">
                                Quantity:
                              </p>
                              <p className="mb-0 px-0 col-6 col-xs-6 col-sm-6 col-md-6 col-lg-12 col-xl-12 ">
                                {props.addReel.reelQtyWithLogo}
                              </p>
                            </div>
                          </td>
                          <td className="row align-middle mx-0 col-12 col-lg-1 px-4 px-md-0">
                            <div className="d-flex flex-row col-12 col-lg-1 px-lg-0 px-md-0 px-sm-0 px-xs-0 px-xl-0 px-0">
                              <p className="mb-0 d-xs-block d-sm-block d-md-block d-xl-none d-lg-none font-weight-bold col-6 px-0">
                                Total:
                              </p>
                              <p className="mb-0 px-0 col-6 col-xs-6 col-sm-6 col-md-6 col-lg-12 col-xl-12 ">
                                $
                                {props.addReel.reelQtyWithLogo *
                                  props.addReel.reelPriceWithLogo}
                              </p>
                            </div>
                          </td>
                        </tr>
                      )}
                    {/* apply coupon */}
                    <tr>
                      <td
                        className="align-middle border-bottom-0 col-12 px-4 px-md-0"
                        colSpan={6}
                      >
                        <form
                          onSubmit={(e) => {
                            setIsModalOpen(true);
                            handlePromoCodeSubmit(e);
                          }}
                        >
                          <div className="d-flex flex-row justify-content-start">
                            <input
                              id="form1"
                              name="promoCode"
                              required
                              placeholder="Promo Code"
                              type="text"
                              maxLength={35}
                              className="form-control form-control-sm m-0 mx-md-4 w-50"
                            />
                            <button
                              className="apply-coupon-btn px-2"
                              disabled={props.selectedShutterBz?.length === 0}
                            >
                              Coupon
                            </button>
                          </div>
                        </form>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* subtotal and total section */}
              <div className="cartt shadow-2-strong outer-border mx-0 mx-md-4 px-4 px-md-1">
                <div className="cartt-body p-0">
                  <table className="table">
                    <tbody className="brdr-btm-hidden">
                      {discount.current > 0 &&
                        props.selectedShutterBz?.length !== 0 && (
                          <tr className="brdr-top-hidden">
                            <th
                              scope="row"
                              className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50"
                            >
                              <div className="d-flex flex-row">
                                <p className="font-weight-bold m-0">Discount</p>
                              </div>
                            </th>
                            <td className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50">
                              <div className="d-flex flex-row justify-content-start justify-content-md-end">
                                <p className="m-0">${discount.current ?? 0}</p>
                              </div>
                            </td>
                          </tr>
                        )}

                      <tr
                        className={
                          (discount.current === 0 ||
                            props.selectedShutterBz?.length === 0) &&
                          "brdr-top-hidden"
                        }
                      >
                        <th
                          scope="row"
                          className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50"
                        >
                          <div className="d-flex flex-row">
                            <p className="font-weight-bold m-0">Fee</p>
                          </div>
                        </th>
                        <td className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50">
                          <div className="d-flex flex-row justify-content-start justify-content-md-end">
                            <p className="m-0">${fee.current.toFixed(2)}</p>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50"
                        >
                          <div className="d-flex flex-row">
                            <p className="font-weight-bold m-0">
                              Service Fee
                              {` (${miscAmounts?.serviceFee}%)` || ""}
                            </p>
                          </div>
                        </th>
                        <td className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50">
                          <div className="d-flex flex-row justify-content-start justify-content-md-end">
                            <p className="m-0">
                              ${serviceFee.current.toFixed(2)}
                            </p>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50"
                        >
                          <div className="d-flex flex-row">
                            <p className="font-weight-bold m-0">Subtotal</p>
                          </div>
                        </th>
                        <td className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50">
                          <div className="d-flex flex-row justify-content-start justify-content-md-end">
                            <p className="m-0">
                              ${calculateSubtotal().toFixed(2)}
                            </p>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50"
                        >
                          <div className="d-flex flex-row">
                            <p className="font-weight-bold m-0">
                              Tax{` (${miscAmounts?.tax}%)` || ""}
                            </p>
                          </div>
                        </th>
                        <td className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50">
                          <div className="d-flex flex-row justify-content-start justify-content-md-end">
                            <p className="m-0">${tax.current.toFixed(2)}</p>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50"
                        >
                          <div className="d-flex flex-row">
                            <p className="font-weight-bold m-0">Total</p>
                          </div>
                        </th>
                        <td className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50">
                          <div className="d-flex flex-row justify-content-start justify-content-md-end">
                            <p className="m-0">
                              ${netTotal.current.toFixed(2)}
                            </p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {/* back button or add reel to your event button */}
              <div className="container">
                <div className="row py-3">
                  <div className="col-12 d-flex justify-content-between align-items-center flex-wrap">
                    <button
                      type="button"
                      className="btn mb-1 mb-sm-0 mr-1 px-4"
                      onClick={() => {
                        props.onBack();
                        props.setUndoCartItem({});
                      }}
                    >
                      Add Recap{" "}
                      <span className="d-none d-sm-inline">to your event</span>
                    </button>
                    <button
                      type="button"
                      className={`btn mb-1 mb-sm-0 px-3 ${
                        !props.selectedShutterBz?.length
                          ? "disable-checkout"
                          : ""
                      }`}
                      disabled={!props.selectedShutterBz?.length}
                      onClick={() => {
                        setIsModalOpen(true);
                        handleUserCheckout();
                      }}
                    >
                      Check out
                    </button>
                  </div>
                </div>
              </div>
              <p className="text-center cart-footer">
                Shutter Hive Inc. HST # 73876 3739 RT0001
              </p>
            </div>
          </div>
        </div>
      </section>
      {!props.isAuthenticated && <Modal isAllowModal isPage />}
    </>
  );
};

const mapStateToProps = (state) => ({
  userId: state.authentication.userId,
  isAuthenticated: state.authentication.isAuthenticated,
  selectedShutterBz: state.eventBooking.selectedShutterBz,
  addReel: state.eventBooking.addReel,
});

const mapDispatchToProps = (dispatch) => ({
  getDraftEventSbList: (setmodel, eventId) =>
    dispatch(eventBookingActions.getDraftEventSbList(setmodel, eventId)),
  setReelValues: (dataObj) =>
    dispatch({ type: eventBookingConstants.SET_REEL_VALUES, data: dataObj }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DraftEventCart));
