import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { withRouter } from "react-router-dom";

import { shutterbDashboardActions } from "../../../../redux/actions";
import { configurationsConstants } from "../../../../constants";

const EventRequestTab = (props) => {
  const { t } = useTranslation("common");

  const [...eventRequets] = props.eventRequests;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [eventIdToDecline, setEventIdToDecline] = useState(null);
  const [shutterbIdToDecline, setShutterbIdToDecline] = useState(null);
  const cardBodyRef = useRef([]);

  useEffect(() => {
    // call action to getThe evenRequests from server and update everything
    props.getEventRequests(props.sbUserId);

    // call action that will call serice to get the user data
    //  eslint-disable-next-line
  }, []);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleReasonSubmit = (event, values, errors) => {
    handleUpdateEventRequest(
      eventIdToDecline,
      shutterbIdToDecline,
      false,
      values.reason
    );
  };

  const handleUpdateEventRequest = (eventId, userName, isActive, reason) => {
    const dataObj = {
      Id: props.sbUserId,
      EventId: eventId,
      UserName: userName,
      isActive: isActive,
      reason: reason,
    };
    props.updateEventRequest(props.sbUserId, dataObj);
    closeModal();
  };
  const convertDateToUTC = (date) => {
    const epochTimestamp = date;
    const getDate = new Date(epochTimestamp * 1000);
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = months[getDate.getUTCMonth()];
    const day = getDate.getUTCDate();
    const year = getDate.getUTCFullYear();

    return `${month} ${day}, ${year}`;
  };

  const handleCloseAllCards = (index) => {
    cardBodyRef.current[index].className = cardBodyRef.current[
      index
    ].className.replace("show", " ");
  };
  return (
    <>
      <div id="accordion" className="container ">
        {eventRequets.length ? (
          [...eventRequets].reverse().map((eventDetails, index) => {
            return (
              <>
                <div className="event-request-con px-0 py-0">
                  <div id="headingOne" className="event-request-header">
                    <div
                      className="row"
                      data-toggle="collapse"
                      data-target={"#collapseOne" + eventDetails?.id}
                      aria-expanded="true"
                      aria-controls={"collapseOne" + eventDetails?.id}
                    >
                      <div className="col-md-8">
                        <h3>
                          {eventDetails?.eventName +
                            " - " +
                            convertDateToUTC(eventDetails?.eventDate) +
                            " - " +
                            eventDetails?.user?.firstName}
                        </h3>
                      </div>
                      <div className="col-md-4">
                        <h3 className="event-header-text text-md-right">
                          Event ID: {eventDetails?.id}
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div
                    id={"collapseOne" + eventDetails?.id}
                    className="collapse event-request-body"
                    aria-labelledby="headingOne"
                    data-parent="#accordion"
                    ref={(element) => cardBodyRef.current.push(element)}
                  >
                    <div>
                      <div className="container">
                        <div className="row">
                          <div className="col-4">
                            <h5>Event Type: </h5>
                          </div>
                          <div className="col-8">
                            <h5>{eventDetails?.eventType?.name}</h5>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-4">
                            <h5>Event Date: </h5>
                          </div>
                          <div className="col-8">
                            <h5>{convertDateToUTC(eventDetails?.eventDate)}</h5>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-4">
                            <h5>Event Duration: </h5>
                          </div>
                          <div className="col-8">
                            <h5>
                              {eventDetails?.duration}
                              {eventDetails?.duration <= 1 ? " hour" : " hours"}
                            </h5>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-4">
                            <h5>Event Start Time: </h5>
                          </div>
                          <div className="col-8">
                            <h5>
                              {new Date(
                                eventDetails?.eventStartTime * 1000
                              ).toLocaleTimeString()}
                            </h5>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-4">
                            <h5>shutterb Start Time: </h5>
                          </div>
                          <div className="col-8">
                            <h5>
                              {new Date(
                                eventDetails?.sbstartTime * 1000
                              ).toLocaleTimeString()}
                            </h5>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-4">
                            <h5>Address: </h5>
                          </div>
                          <div className="col-8">
                            <h5>
                              {eventDetails?.eventLocation[0]?.currentLocation}
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className="container">
                        <div className="row">
                          <div className="col-12">
                            <button
                              onClick={() => {
                                handleUpdateEventRequest(
                                  eventDetails?.id,
                                  eventDetails?.user?.userName,
                                  "true"
                                );
                                handleCloseAllCards(index);
                              }}
                            >
                              ACCEPT
                            </button>
                            <button
                              onClick={() => {
                                /*handleUpdateEventRequest(
                                eventDetails?.id,
                                eventDetails?.user?.userName,
                                false
                              )*/
                                setEventIdToDecline(eventDetails?.id);
                                setShutterbIdToDecline(
                                  eventDetails?.user?.userName
                                );
                                openModal();
                                handleCloseAllCards(index);
                              }}
                              className="mt-2 mt-md-0 dec-button"
                            >
                              DECLINE
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })
        ) : (
          <p>You have not received any event requests yet.</p>
        )}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Set Reason Modal"
          className="reason-modal"
          style={{
            content: {
              width: "auto",
              height: "auto",
              margin: "auto",
            },
          }}
        >
          <div className="modal-content p-4">
            <>
              <h2>{t("Reason")}</h2>
              <AvForm onValidSubmit={handleReasonSubmit}>
                <AvField
                  name="reason"
                  type="textarea"
                  maxLength={configurationsConstants.TEXTAREA_INPUT_LENGTH}
                  rows="4"
                  cols="30"
                  className={"h-50"}
                  label={t("Reason to Decline")}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: t("reason_required"),
                    },
                  }}
                />
                <button type="submit" className="btn">
                  {t("Submit")}
                </button>
              </AvForm>
              <button onClick={closeModal} className="modal-close">
                <img
                  src="/images/simpleCrossWhite.svg"
                  alt="crossIcon"
                  className="modal-close-img"
                />
              </button>
            </>
          </div>
        </Modal>
      </div>
    </>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  sbUserId: state.shutterbAuthentication.sbUserId,
  eventRequests: state.shutterbDashboard.events,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getEventRequests: (id) =>
    dispatch(shutterbDashboardActions.eventRequests(id)),
  updateEventRequest: (id, dataObj) =>
    dispatch(shutterbDashboardActions.updateEventRequest(id, dataObj)),
});

// mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EventRequestTab));
