// eslint-disable-next-line import/no-cycle
import responseHandler from "../system/server-response-handler";
import { getRequest } from "../system/server-call";

// get payments list
const getSmartPhoneDevices = () => {
  return getRequest(`/api/v1/admin/ShutterB/GetDevices`, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

// get postal codes list
const getPostalCodes = () => {
  return getRequest(`/api/v1/Admin/PostalCode`, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

// exporting functions for other files
export const configurationsServices = {
  getSmartPhoneDevices,
  getPostalCodes
};
