import React from "react";
import Modal from "react-modal";
import { messageBoardActions } from "../../../../redux/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import MessageBoardView from "../MessageBoardView";
const MessageBoardModal = (props) => {
  return (
    <Modal
      isOpen={props.isMessageModalOpen}
      className={`${props.className} ${props.isMessageModalOpen ? "show" : ""}`}
    >
      <>
        <span className="close" onClick={props.handleCloseModal}>
          x
        </span>
        <MessageBoardView
          currentUser={props.currentUser}
          userId={props.formState.userId}
          sbUserId={props.formState.sbUserId}
          eventId={props.formState.eventId}
          currentActiveId={props.formState.sbUserId}
          currentUserId={props.currentUserId}
          // currentActiveTab={
          //   props.formState.userId +
          //   props.formState.sbUserId +
          //   props.formState.eventId
          // }
        />
        {/* </div>
        </div> */}
      </>
    </Modal>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  data: state.messageBoard.data,
  formState: state.messageBoard.formState,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getshutterbzByEventId: (eventId) =>
    dispatch(messageBoardActions.getshutterbByEventId(eventId)),
  setFormState: (data) => dispatch(messageBoardActions.setFormState(data)),
  getMessages: (eventId, sbUserId) =>
    dispatch(messageBoardActions.getMessages(eventId, sbUserId)),
});

// mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MessageBoardModal));
