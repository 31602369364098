import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";

const Spinner = (props) => {
  return props.isSpinnerActivate ? (
    <div
      className={`spinner d-flex justify-content-center align-items-center ${
        props.showDownloadProgress ? "flex-column" : ""
      }`}
    >
      <TailSpin
        color="#3e8993"
        ariaLabel="tail-spin-loading"
        radius="2"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
      {props.showDownloadProgress && (
        <div className="text-center mt-2">Preparing Download</div>
      )}
    </div>
  ) : null;
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  isSpinnerActivate: state.system.isSpinnerActivate,
  showDownloadProgress: state.system.showDownloadProgress,
});

// mapping action and store the function via props
export default connect(mapStateToProps)(withRouter(Spinner));
