import { updateObject } from "../../shared/utility";
import { messageBoardConstants } from "../../constants";

// getting the default actions name from defined constants via decomposition
const {
  GET_SHUTTERB_BY_EVENT_ID,
  FORM_STATE_OF_MESSAGE_BOARD,
  GET_MESSAGES,
  RECEIVE_MESSAGES,
  GET_SB_BY_ID,
  GET_NOTIFICATIONS,
  GET_NOTIFICATION_COUNT,
  RECEIVE_NOTIFICATION,
  CUSTOMER_CHAT_STARTED,
} = messageBoardConstants;

// default state
const initialState = {
  data: {},
  messages: [],
  notifications: [],
  notificationCount: 0,
  formState: {
    userId: 0,
    sbUserId: 0,
    senderId: 0,
    receiverId: 0,
    profileImageUrl: "",
    name: "",
    email: "",
    eventId: "",
    message: "",
    reaction: "",
    sender: "",
    isActive: true,
    sendedTime: "",
  },
  customerChatStarted: {
    currentTabId: 0,
  },
};

// manupilating the state based on actions and returning the state
export const messageBoard = (state = initialState, action) => {
  switch (action.type) {
    case GET_SHUTTERB_BY_EVENT_ID:
      return updateObject(state, {
        data: action.data.items,
      });
    case FORM_STATE_OF_MESSAGE_BOARD:
      return updateObject(state, { formState: action.data });
    case GET_MESSAGES:
      return updateObject(state, {
        messages: action.data.items,
      });
    case RECEIVE_MESSAGES:
      return {
        ...state,
        messages: [...state.messages, action.data],
      };
    case GET_SB_BY_ID:
      return updateObject(state, {
        data: action.data.items,
      });
    case GET_NOTIFICATIONS:
      return updateObject(state, {
        notifications: action.data.items,
      });
    case GET_NOTIFICATION_COUNT:
      return updateObject(state, {
        notificationCount: action.data.items,
      });
    case RECEIVE_NOTIFICATION:
      console.log("action.data", action.data);
      return updateObject(state, {
        notificationCount: state.notificationCount + action.data.unseenCount,
      });
    // case UPDATE_MESSAGE_STATUS:
    //   return updateObject(state, {
    //     notificationCount: state.notificationCount - 1,
    //   });
    // case BULK_UPDATE_MESSAGE_STATUS:
    //   return updateObject(state, {
    //     notificationCount: state.notificationCount - action.data.items,
    //   });
    case CUSTOMER_CHAT_STARTED:
      return updateObject(state, {
        customerChatStarted: action.data,
      });
    default:
      return state;
  }
};
