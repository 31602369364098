import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router";

import Modal from "../../../../../../../container/Views/Login/Modal";
import { NavigateToPathWithGo } from "../../../../../../../system";
import { customerDashboardActions } from "../../../../../../../redux/actions";
import { customerConstants } from "../../../../../../../constants";
import useGetQueryParams from "../../../../../../../shared/components/CustomHooks/GetQueryParams";
import ViewGallery from "../../../../../../../shared/components/ViewGallery/ViewGallery";

const SharedGallery = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const queryParams = useGetQueryParams();
  const history = useHistory();
  // navigate to screen after login
  useEffect(() => {
    // if (props.isAuthenticated) {
      NavigateToPathWithGo(history, "/SharedGallery", "SharedGallery");
      props.getMyEventsAccessGallery(
        queryParams.get("eventid"),
        files,
        setFiles
      );
    // }

    // eslint-disable-next-line
  },[]); 
  // [props.isAuthenticated]);

  /*const handleLock = () => {
      const filesToLock = selectedFiles.filter((file) => file.id);

    if (filesToLock.length > 0) {
      const galleryFiles = filesToLock.map((file) => ({
        Id: file.id,
        IsLocked: file.isLocked ? false : true,
      }));

      props.lockFromGallery(
        { GalleryFiles: galleryFiles },
        queryParams.get("eventid"),
        files,
        setFiles
      );
    }

      setSelectedFiles([]);
    };*/
  return (
    <div className="shared-gallery container my-3 py-1 custom-min-height">
      {props.isAuthenticated ? (
        <p>
          Welcome to the {props.myEventsAccessGallery.eventName} gallery. We
          hope you enjoy the content below.
        </p>
      ) : (
        <div>
          {!isModalOpen ? ( <p>
            Welcome to the {props.myEventsAccessGallery.eventName} gallery. We
            hope you enjoy the content below. Please create a{" "}
            <strong className="login-btn" onClick={() => setIsModalOpen(true)}>
              login here
            </strong>{" "}
            to download multiple images and videos at once, and to save this
            gallery to your attended events within your shutterb account.
          </p>):""}
         
        </div>
      )}
      {!props.isAuthenticated && isModalOpen && (
        <Modal setIsModalOpen={setIsModalOpen} />
      )}
      {!isModalOpen ? (
        <div>
          {files?.length > 0 ? (
            <ViewGallery
              files={files}
              setFiles={setFiles}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              isModalAllowed
              isAllowDownload
              hideDeleteIcon
              isShareAllowed
              isTabsDisplay
            />
          ) : (
            <p>
              Sorry the event content is not ready yet, please check back within
              24 hours of the event ending.
            </p>
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );};

const mapStateToProps = (state) => ({
  isAuthenticated: state.authentication.isAuthenticated,
  myEventsAccessGallerUrl:
    state.customerDashboard.myEventsAccessGallery.sharedGalleryUrl,
  myEventsAccessGallery: state.customerDashboard.myEventsAccessGallery,
});

const mapDispatchToProps = (dispatch) => ({
  getMyEventsAccessGallery: (eventId, files, setFiles) =>
    dispatch(
      customerDashboardActions.myEventsAccessGallery(
        eventId,
        files,
        setFiles,
        customerConstants.SHARED_GALLERY_CALL
      )
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SharedGallery));
