import { updateObject, formatTime } from "../../shared/utility";
import { eventBookingConstants, globalConstants } from "../../constants";
// getting the default actions name from defined constants via decomposition
const {
  GET_SHOT_LIST,
  GET_AVAILABLE_SHUTTER_BS,
  GET_EVENT_TYPES,
  SET_FORM_STATE,
  SET_CART_TAB,
  SELECTED_SHUTTER_BS,
  CHECK_OUT,
  REQUEST_BOOKING,
  SET_REEL_VALUES,
  GET_REEL_PRICE,
  GET_MISC_AMOUNTS,
  REEL_STORED,
  SET_UNDO_CART_ITEM,
  SET_IS_DRAFT_EVENT_ALLOWED,
  SET_BULK_REEL_VALUES,
  SET_EVENT_DETAIL_VALUES,
  EVENT_DETAIL_STORED,
  GET_REEL_TYPES,
  SET_BULK_EVENT_DETAIL_VALUES,
  SET_REEL_VALUES_ON_DELETE,
} = eventBookingConstants;

const retrievedData = localStorage.getItem("dataToSend");
const parsedData = JSON.parse(retrievedData);
const formData = parsedData?.Event;
// getting reels from local storage if stored
const reelStored = JSON.parse(localStorage.getItem(REEL_STORED));
const eventDetailStored = JSON.parse(localStorage.getItem(EVENT_DETAIL_STORED));

// default state
const initialState = {
  eventTypes: [],
  shotList: [],
  reelTypes: [],
  shutterBCount: 0,
  formState: {
    postalCode: formData?.postalCode,
    eventname: formData?.eventName,
    eventdate: formData?.eventDate
      ? new Date(formData.eventDate * 1000).toISOString().split("T")[0]
      : null,
    event_start_time: formData?.EventStartTime
      ? formatTime(formData.EventStartTime)
      : null,
    eventlocation:
      typeof formData?.eventLocation === "object"
        ? formData?.eventLocation?.currentLocation
        : formData?.eventLocation,

    apartment: formData?.apartment,
    eventtype: formData?.EventTypeId,
    additionalNotes: formData?.additionalNotes,
    notes: formData?.notes,
    shutterb_start_time: formData?.sbStartTime
      ? formatTime(formData.sbStartTime)
      : null,
    duration: formData?.duration,
    termsAndConditions: formData?.termsAndConditions,
    privacyPolicy: formData?.privacyPolicy,
    isGallerySharedPermission: formData?.isGallerySharedPermission ?? true,
    isConsentForMarketing: formData?.isConsentForMarketing ?? true,
    latLng:
      typeof formData?.eventLocation === "object"
        ? {
            lat: formData?.eventLocation?.latitude,
            lng: formData?.eventLocation?.longitude,
          }
        : formData?.latLng
        ? {
            lat: formData?.latLng?.lat,
            lng: formData?.latLng?.lng,
          }
        : null,
    contactname: formData?.contactname,
    phoneNumber: formData?.phoneNumber,
  },
  availableShutterBs: [],
  cartTab: false,
  selectedShutterBz:
    formData?.Order?.orderItems?.length !== 0
      ? formData?.Order?.orderItems
      : [],
  url: null,
  eventRequested: "",
  transactionDone: false,
  showPage: false,
  addReel: {
    isReelWithoutLogo: reelStored?.isReelWithoutLogo ?? false,
    reelPriceWithoutLogo: reelStored?.reelPriceWithoutLogo ?? 75,
    reelQtyWithoutLogo: reelStored?.reelQtyWithoutLogo ?? 0,
    isReelWithLogo: reelStored?.isReelWithLogo ?? true,
    reelPriceWithLogo: reelStored?.reelPriceWithLogo ?? 125,
    reelQtyWithLogo: reelStored?.reelQtyWithLogo ?? 0,
    logo: reelStored?.logo ?? {
      preview: "",
      raw: "",
    },
    musicFile: reelStored?.musicFile ?? {
      preview: "",
      filePath: null,
    },
    reelQuestion1: reelStored?.reelQuestion1 ?? "",
    music: reelStored?.music ?? "",
    orientation:
      reelStored?.orientation ??
      eventBookingConstants.REEL_ORIENTATION_OPTIONS[0],
    terms: reelStored?.terms ?? false,
    reelTotal: reelStored?.reelTotal ?? 0,
    isReelSubmitted: reelStored?.isReelSubmitted ?? false,
    reelType: reelStored?.reelType ?? "",
  },

  addEventDetail: {
    eventQuestion1: eventDetailStored?.eventQuestion1 ?? "",
    eventQuestion2: eventDetailStored?.eventQuestion2 ?? "",
    eventQuestion3: eventDetailStored?.eventQuestion3 ?? "",
    eventQuestion4: eventDetailStored?.eventQuestion4 ?? "",
  },
  miscAmounts: {},
  undoCartItem: {},
  isDraftEventAllowed: false,
};

// manupilating the state based on actions and returning the state
export const eventBooking = (state = initialState, action) => {
  switch (action.type) {
    case GET_SHOT_LIST:
      return updateObject(state, {
        shotList: action.data.items,
      });
    case GET_EVENT_TYPES:
      return updateObject(state, {
        eventTypes: action.data.items,
      });
    case GET_AVAILABLE_SHUTTER_BS + globalConstants.SUCCESS:
      const newArray = action.data.items.map((item) => {
        const images =
          item.sbportfolioGalleries[0]?.gallery
            .filter((image) => image.imageUrl !== null)
            .map((image) => image.imageUrl) || [];

        return {
          ...item,
          images,
        };
      });
      return updateObject(state, {
        availableShutterBs: newArray,
        shutterBCount: action.data.items.length,
      });
    case GET_AVAILABLE_SHUTTER_BS + globalConstants.FAILURE:
      return updateObject(state, {
        availableShutterBs: [],
        shutterBCount: 0,
      });
    case SET_FORM_STATE:
      return updateObject(state, { formState: action.data });
    case SET_CART_TAB:
      return updateObject(state, { cartTab: action.data });
    case SELECTED_SHUTTER_BS:
      return updateObject(state, { selectedShutterBz: action.data });
    case CHECK_OUT:
      return updateObject(state, { url: action.data.message, showPage: true });
    case REQUEST_BOOKING:
      return updateObject(state, { eventRequested: true });
    case REQUEST_BOOKING + globalConstants.FAILURE:
      return updateObject(state, { eventRequested: false });
    case GET_REEL_PRICE + globalConstants.SUCCESS:
      let updatedAddReelPrice = {
        addReel: {
          ...state.addReel,
          reelPriceWithoutLogo: action?.data?.items.priceOnPerReel,
          reelPriceWithLogo: action?.data?.items.priceOnPerReelWithLogo,
        },
      };
      localStorage.setItem(
        REEL_STORED,
        JSON.stringify(updatedAddReelPrice.addReel)
      );
      return updateObject(state, updatedAddReelPrice);
    case SET_REEL_VALUES:
      let updatedAddReel = {
        addReel: {
          ...state.addReel,
          [action.data.name]: action.data.value,
          // reelQty: Number(action?.data?.reelQty) ?? state.addReel.reelQty,
          // reelInstruction:
          //   action?.data?.reelInstruction ?? state.addReel.reelInstruction,
        },
      };
      localStorage.setItem(REEL_STORED, JSON.stringify(updatedAddReel.addReel));
      return updateObject(state, updatedAddReel);

    case SET_REEL_VALUES_ON_DELETE:
      let reel = {
        addReel: action.data.value,
      };
      localStorage.setItem(REEL_STORED, JSON.stringify(reel.addReel));
      return updateObject(state, reel);

    case SET_BULK_REEL_VALUES:
      let updatedBulkAddReel = {
        addReel: Object.keys(initialState.addReel).reduce(
          (accumulator, current) => {
            accumulator[current] = null;
            if (current === "orientation") {
              accumulator[current] =
                eventBookingConstants.REEL_ORIENTATION_OPTIONS[0];
            } else if (current === "logo") {
              accumulator[current] = {
                preview: "",
                raw: "",
              };
            }
            return accumulator;
          },
          {}
        ),
      };
      localStorage.setItem(
        REEL_STORED,
        JSON.stringify(updatedBulkAddReel.addReel)
      );
      return updateObject(state, updatedBulkAddReel);
    case GET_MISC_AMOUNTS:
      return updateObject(state, { miscAmounts: action?.data?.items });
    case SET_UNDO_CART_ITEM:
      return updateObject(state, { undoCartItem: action?.data });
    case SET_IS_DRAFT_EVENT_ALLOWED:
      return updateObject(state, { isDraftEventAllowed: action?.data });
    case SET_EVENT_DETAIL_VALUES:
      let updatedEventDetail = {
        addEventDetail: {
          ...state.addEventDetail,
          [action.data.name]: action.data.value,
          // reelQty: Number(action?.data?.reelQty) ?? state.addReel.reelQty,
          // reelInstruction:
          //   action?.data?.reelInstruction ?? state.addReel.reelInstruction,
        },
      };
      localStorage.setItem(
        EVENT_DETAIL_STORED,
        JSON.stringify(updatedEventDetail.addEventDetail)
      );
      return updateObject(state, updatedEventDetail);
    case GET_REEL_TYPES:
      return updateObject(state, { reelTypes: action.data?.items });
    case SET_BULK_EVENT_DETAIL_VALUES:
      let updatedBulkEventDetail = {
        addEventDetail: Object.keys(initialState.addEventDetail).reduce(
          (accumulator, current) => {
            accumulator[current] = null;
            return accumulator;
          },
          {}
        ),
      };
      localStorage.setItem(
        EVENT_DETAIL_STORED,
        JSON.stringify(updatedBulkEventDetail.addEventDetail)
      );
      return updateObject(state, updatedBulkEventDetail);
    default:
      return state;
  }
};
