import React, { useEffect } from "react";
import { Balancer } from "react-wrap-balancer";
import { connect } from "react-redux";
import { withRouter, Link, useParams, useHistory } from "react-router-dom";
import { Card, CardBody, Col, ButtonToolbar } from "reactstrap";
import { eventBookingActions } from "../../../../redux/actions/eventBooking.actions";
import {
  eventBookingConstants,
  customerConstants,
} from "../../../../constants";

const ThankYou = (props) => {
  const { id, orderItemGroupId, isNewBooking } = useParams();
  const history = useHistory();

  const handleNewBooking = () => {
    props.emptyEventBookingRed();

    const tempToken = localStorage.getItem(process.env.REACT_APP_PAYLOAD);
    localStorage.clear();
    localStorage.setItem(process.env.REACT_APP_PAYLOAD, tempToken);
    localStorage.setItem("activeTab", eventBookingConstants.TABS.EVENT_SETUP);
    window.location.replace("/event_booking");
  };

  useEffect(() => {
    window.dataLayer.push({
      event: "site_action",
      action: "eventbookrequested",
    });

    if (id && orderItemGroupId) {
      const savedData = localStorage.getItem("dataToSend");
      const parsedData = JSON.parse(savedData);
      if (parsedData) {
        parsedData.Event.Order.orderItems &&
          parsedData.Event.Order.orderItems.forEach((item) => {
            item.Price *= parsedData.Event.duration;
          });
      }
      props.requestBooking(id, orderItemGroupId);
      localStorage.removeItem("dataToSend");
      localStorage.removeItem("activeTab");
      props.setSelectedShutterBz([]);
      props.setFormState({
        eventname: null,
        eventdate: null,
        event_start_time: null,
        eventlocation: null,
        duration: null,
        termsAndConditions: false,
        privacyPolicy: false,
        latLng: null,
        shutterb_start_time: null,
      });
      props.setCartTab(false);
      props.setReelValues({
        reelQty: 0,
        reelPrice: 0,
        reelInstruction: "",
      });
      history.replace("/EventBookRequested");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Col md={12}>
      <Card className="border-0 d-flex justify-content-center my-5 py-5 flex-row">
        <CardBody>
          {isNewBooking === "2" ? (
            <>
              {" "}
              <h1 className="email-confirmation__title d-flex justify-content-center mb-4">
                Thank You
              </h1>
              <h3 className="email-confirmation__title d-flex justify-content-center mb-4 pb-4">
                <Balancer>
                  Your booking is complete and all the shutterbz are assigned to
                  the event.
                </Balancer>
              </h3>
            </>
          ) : props.eventRequested === "" ? (
            <h1 className="email-confirmation__title d-flex justify-content-center mb-4">
              processing....
            </h1>
          ) : props.eventRequested ? (
            <>
              <h1 className="email-confirmation__title d-flex justify-content-center mb-4">
                Thank You
              </h1>
              <h3 className="email-confirmation__title d-flex justify-content-center mb-4 pb-4">
                <Balancer>
                  Your payment has been processed. Check your email for further
                  details regarding your booking or other transactions made
                  through our shutterb website.
                </Balancer>
              </h3>
            </>
          ) : (
            <h1 className="email-confirmation__title d-flex justify-content-center mb-4">
              Something went wrong
            </h1>
          )}
          <ButtonToolbar className="email-confirmation__buttons d-flex justify-content-center">
            <Link
              className="btn mb-2 ml-2 d-none"
              to="/event_booking"
              onClick={() => handleNewBooking}
            >
              New Booking
            </Link>

            <a className="btn mb-2 ml-2" href="/CustomerDashboard">
              My Account
            </a>
          </ButtonToolbar>
        </CardBody>
      </Card>
    </Col>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  eventRequested: state.eventBooking.eventRequested,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  requestBooking: (data, orderItemGroupId) =>
    dispatch(eventBookingActions.requestBooking(data, orderItemGroupId)),
  setSelectedShutterBz: (data) =>
    dispatch(eventBookingActions.setSelectedShutterBz(data)),
  setFormState: (data) => dispatch(eventBookingActions.setFormState(data)),
  setCartTab: (isCartTab) =>
    dispatch(eventBookingActions.setCartTab(isCartTab)),
  setReelValues: (dataObj) =>
    dispatch({ type: eventBookingConstants.SET_REEL_VALUES, data: dataObj }),
  emptyEventBookingRed: () => {
    dispatch({ data: {}, type: eventBookingConstants.SET_FORM_STATE });
    dispatch({ data: [], type: eventBookingConstants.SELECTED_SHUTTER_BS });
    dispatch({ data: false, type: eventBookingConstants.SET_CART_TAB });
    dispatch({
      data: [],
      type: customerConstants.UPDATE_CUSTOMER_DASHBOARD_CART,
    });
  },
});

// mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ThankYou));
