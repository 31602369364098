import React from "react";
import { useGoogleLogin } from "@react-oauth/google";
const LoginWithGoogle = (props) => {
  /* eslint-disable */
  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      const accessToken = tokenResponse.access_token;
      props.onLoginWithGoogleClick({ accessToken: accessToken });
    },
  });
  return (
    <div className="custom-google-btn" onClick={() => login()}>
      Login with Google{" "}
    </div>
  );
};

export default LoginWithGoogle;
