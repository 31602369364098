import React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter, Link, useHistory } from "react-router-dom";
import { customerDashboardActions } from "../../../../../redux/actions";
import {
  customerConstants,
  eventBookingConstants,
} from "../../../../../constants";
import { ClearExtendOrTipStorage } from "../../../../../shared/utility";
import Modal from "react-modal";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import clock from "../../../../../shared/img/customer-icon-clock.png";
import tip from "../../../../../shared/img/customer-icon-tip.png";
import gallery from "../../../../../shared/img/customer-icon-gallery.png";
import invoice from "../../../../../shared/img/customer-icon-invoice.png";
import review from "../../../../../shared/img/customer-icon-review.png";
import reel from "../../../../../shared/img/customer-icon-reel.png";
import reelDisabled from "../../../../../shared/img/customer-icon-reel-disabled.png";
import cancelEventIcon from "../../../../../shared/img/cancel-event-icon.png";
import cancelEventDisabledIcon from "../../../../../shared/img/cancel-event-disabled-icon.png";
import messageEnabledIcon from "../../../../../shared/img/message-enabled-icon.png";
import messageDisabledIcon from "../../../../../shared/img/message-disabled-icon.png";

const MyEventsTab = (props) => {
  /* eslint-disable */
  const [...myEvents] = props.myEvents;
  const history = useHistory();
  const { TABS } = eventBookingConstants;
  const [activeTab, setActiveTab] = useState(customerConstants.UPCOMING_EVENTS);
  const [modal, setModal] = useState(false);
  const [openEventId, setOpenEventId] = useState(null);
  const [dataToSend, setDataToSend] = useState({
    eventId: null,
    orderId: 0,
    userId: 0,
  });

  const handleEvents = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setOpenEventId(null);
      props.getMyEvents(props.userId, tab);
    }
  };
  useEffect(() => {
    // call action to getThe My Events from server and update the list
    props.getMyEvents(props.userId, customerConstants.UPCOMING_EVENTS);
    // call action that will call serice to get the user data
    //  eslint-disable-next-line
  }, []);

  const convertDateToUTC = (date) => {
    const epochTimestamp = date;
    const getDate = new Date(epochTimestamp * 1000);
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = months[getDate.getUTCMonth()];
    const day = getDate.getUTCDate();
    const year = getDate.getUTCFullYear();

    return `${month} ${day}, ${year}`;
  };

  const addReel = (id, orderId) => {
    ClearExtendOrTipStorage();
    localStorage.setItem("activeTab", TABS.ADD_REEL);
    localStorage.setItem("isOnlyReel", true);
    localStorage.setItem("reelEventId", id);
    localStorage.setItem("reelOrderId", orderId);
    localStorage.setItem(customerConstants.NOT_NEW_EVENT, JSON.stringify(true));
    history.push(`/event_booking/`);
  };

  const handleEventCancelation = (eventId, orderId) => {
    if (!modal) {
      setDataToSend({ eventId, orderId, userId: props.userId });
      setModal(true);
    } else {
      props.cancelEvent(dataToSend);
      setModal(false);
    }
  };

  const handleCardClick = (eventId) => {
    setOpenEventId((prevEventId) => (prevEventId === eventId ? null : eventId));
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
          <Tabs
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            value={activeTab}
            aria-label="basic tabs example"
            className="member-my-events-tabs"
          >
            <Tab
              label="UPCOMING Events"
              onClick={() => {
                handleEvents(customerConstants.UPCOMING_EVENTS);
              }}
              aria-selected={
                activeTab == customerConstants.UPCOMING_EVENTS ? true : false
              }
            />
            <Tab
              label="Completed Events"
              onClick={() => {
                handleEvents(customerConstants.COMPLETED_EVENTS);
              }}
              aria-selected={
                activeTab == customerConstants.COMPLETED_EVENTS ? true : false
              }
            />

            <Tab
              label="Cancelled Events"
              onClick={() => {
                handleEvents(customerConstants.CANCELLED_EVENTS);
              }}
              aria-selected={
                activeTab == customerConstants.CANCELLED_EVENTS ? true : false
              }
            />
          </Tabs>
        </Box>
      </Box>

      <div id="accordion" className="container p-0 mt-5">
        {myEvents.length === 0 ? (
          <p className="d-flex justify-content-center">No events found</p>
        ) : (
          [...myEvents]
            .sort((nextEvent, event) => {
              return event.order[0].id - nextEvent.order[0].id;
            })
            .map((eventDetails) => {
              return (
                <>
                  <div className="my-events-con px-0 py-0">
                    <div
                      className="card-header px-2 py-1 px-md-4 py-md-3"
                      id="headingOne"
                    >
                      <div
                        className="row"
                        data-toggle="collapse"
                        data-target={"#collapseOne" + eventDetails.id}
                        aria-expanded={openEventId === eventDetails.id}
                        aria-controls={"collapseOne" + eventDetails.id}
                        onClick={() => handleCardClick(eventDetails.id)}
                      >
                        <div className="col-md-8">
                          <h3>
                            {eventDetails?.eventName +
                              " - " +
                              convertDateToUTC(eventDetails?.eventDate)}
                          </h3>
                        </div>
                        <div className="col-md-4">
                          <h3 className="event-header-text text-md-right">
                            Event ID: {eventDetails.id}
                          </h3>
                        </div>
                      </div>
                    </div>

                    <div
                      id={"collapseOne" + eventDetails.id}
                      className={`collapse ${
                        openEventId === eventDetails.id ? "show" : ""
                      }`}
                      aria-labelledby="headingOne"
                      data-parent="#accordion"
                    >
                      <div class="pt-3 px-0 px-md-4">
                        <div className="container">
                          <div className="row">
                            <div className="col-5 pr-0">
                              <h5 className="p-0">shutterb:</h5>
                            </div>
                            <div className="col-7">
                              <h5 className="p-0">
                                {/* {eventDetails.shutterbList.map((e, key) => {
                              return key < eventDetails.shutterbList.length - 1
                                ? e.name + ", "
                                : e.name;
                            })} */}
                                {eventDetails.sbList.slice(0, -2)}
                              </h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-5 pr-0">
                              <h5 className="p-0">Event Type: </h5>
                            </div>
                            <div className="col-7">
                              <h5 className="p-0">
                                {eventDetails?.eventType?.name}
                              </h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-5 pr-0">
                              <h5 className="p-0">Event Date: </h5>
                            </div>
                            <div className="col-7">
                              <h5 className="p-0">
                                {convertDateToUTC(eventDetails?.eventDate)}
                              </h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-5 pr-0">
                              <h5 className="p-0">Event Duration: </h5>
                            </div>
                            <div className="col-7">
                              <h5 className="p-0">
                                {eventDetails?.duration}
                                {eventDetails?.duration <= 1
                                  ? " hour"
                                  : " hours"}
                              </h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-5 pr-0">
                              <h5 className="p-0">Event start Time: </h5>
                            </div>
                            <div className="col-7">
                              <h5 className="p-0">
                                {new Date(
                                  eventDetails?.eventStartTime * 1000
                                ).toLocaleTimeString()}
                              </h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-5 pr-0">
                              <h5 className="p-0">shutterb start Time: </h5>
                            </div>
                            <div className="col-7">
                              <h5 className="p-0">
                                {new Date(
                                  eventDetails?.sbstartTime * 1000
                                ).toLocaleTimeString()}
                              </h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-5 pr-0">
                              <h5 className="p-0">Address: </h5>
                            </div>
                            <div className="col-7">
                              <h5 className="p-0">
                                {
                                  eventDetails?.eventLocation[0]
                                    ?.currentLocation
                                }
                              </h5>
                            </div>
                          </div>
                        </div>

                        <div className="container event-card-footer py-3">
                          <div className="row">
                            <div className="col-12 main-con">
                              <div className="col-md-4">
                                <figure>
                                  <Link
                                    to={`/CustomerDashboardTab/MyEvents/${eventDetails.id}/${customerConstants.EXTEND_YOUR_SHUTTERB}`}
                                  >
                                    <img
                                      src={clock}
                                      alt="shutterb-extension-icon"
                                    />
                                  </Link>
                                </figure>
                                <h6>EXTEND YOUR SHUTTERB</h6>
                                <p>
                                  Event Running over? No problem. Extend your
                                  shutterb here.
                                </p>
                              </div>
                              <div className="col-md-4">
                                <figure>
                                  <Link
                                    to={`/CustomerDashboardTab/MyEvents/${eventDetails.id}/${customerConstants.TIP_YOUR_SHUTTERB}`}
                                  >
                                    <img src={tip} alt="Tip Shutterb Icon" />
                                  </Link>
                                </figure>
                                <h6>TIP YOUR SHUTTERB</h6>
                                <p>
                                  Show some love to your shutterb by tipping
                                  them for a job well done.
                                </p>
                              </div>
                              <div className="col-md-4">
                                <figure>
                                  <Link
                                    to={`/CustomerDashboardTab/MyEvents/${eventDetails.id}/${customerConstants.ACCESS_GALLERY}`}
                                  >
                                    <img src={gallery} alt="Gallery Icon" />
                                  </Link>
                                </figure>
                                <h6>GALLERY</h6>
                                <p>
                                  Click here to access your images, share,
                                  download and print.
                                </p>
                              </div>
                              <div className="col-md-4">
                                <figure>
                                  <Link
                                    to={`/CustomerDashboardTab/MyEvents/${eventDetails.id}/${customerConstants.DOWNLOAD_INVOICE}`}
                                  >
                                    <img src={invoice} alt="Invoice Icon" />
                                  </Link>
                                </figure>
                                <h6>INVOICE</h6>
                                <p>
                                  Access your event invoice and billing
                                  information.
                                </p>
                              </div>
                              <div className="col-md-4">
                                <figure>
                                  <Link
                                    to={`/CustomerDashboardTab/MyEvents/${eventDetails.id}/${customerConstants.REVIEW_YOUR_SHUTTERB}`}
                                  >
                                    <img src={review} alt="Review Icon" />
                                  </Link>
                                </figure>
                                <h6>REVIEW YOUR SHUTTERB</h6>
                                <p>
                                  How did your shutterb do? We'd love to hear
                                  what you think!
                                </p>
                              </div>
                              <div className="col-md-4">
                                <figure>
                                  {eventDetails.isReelExist ? (
                                    <img src={reelDisabled} alt="Reel Icon" />
                                  ) : (
                                    <Link
                                      to="#"
                                      onClick={() =>
                                        addReel(
                                          eventDetails.id,
                                          eventDetails.order[0].id
                                        )
                                      }
                                    >
                                      <img src={reel} alt="Reel Icon" />
                                    </Link>
                                  )}
                                </figure>
                                <h6>ADD RECAP</h6>
                                <p>Include a RECAP for Your Event.</p>
                              </div>

                              <div className="col-md-4">
                                <figure>
                                  {activeTab ===
                                  customerConstants.CANCELLED_EVENTS ? (
                                    <img
                                      src={messageDisabledIcon}
                                      alt="Message Disabled Icon"
                                    />
                                  ) : (
                                    <Link
                                      to={`/CustomerDashboardTab/MyEvents/${eventDetails.id}/${customerConstants.MESSAGE_BOARD}`}
                                      // onClick={() =>
                                      //   handleEventCancelation(
                                      //     eventDetails.id,
                                      //     eventDetails.order[0].id
                                      //   )
                                      // }
                                    >
                                      <img
                                        src={messageEnabledIcon}
                                        alt="Message Icon"
                                      />
                                    </Link>
                                  )}
                                </figure>
                                <h6>Message Board</h6>
                                <p>
                                  Message Youre shutterb(z). Share your thoughs
                                  & discuss about your event.
                                </p>
                              </div>

                              <div className="col-md-4">
                                <figure>
                                  {activeTab !==
                                  customerConstants.UPCOMING_EVENTS ? (
                                    <img
                                      src={cancelEventDisabledIcon}
                                      alt="Cancel Event Disabled Icon"
                                    />
                                  ) : activeTab ===
                                      customerConstants.UPCOMING_EVENTS &&
                                    !eventDetails.isCancelEventAllowed ? (
                                    <>
                                      <img
                                        src={cancelEventDisabledIcon}
                                        alt="Cancel Event Disabled Icon"
                                      />
                                    </>
                                  ) : (
                                    <Link
                                      to="#"
                                      onClick={() =>
                                        handleEventCancelation(
                                          eventDetails.id,
                                          eventDetails.order[0].id
                                        )
                                      }
                                    >
                                      <img
                                        src={cancelEventIcon}
                                        alt="Cancel Event Icon"
                                      />
                                    </Link>
                                  )}
                                </figure>
                                <h6>CANCEL EVENT</h6>
                                <p>Cancel Your Event.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })
        )}
      </div>

      <Modal
        isOpen={modal}
        // onRequestClose={(e) => manageBlackoutDays(e)}
        contentLabel="Cancel Event Modal"
        className="cancel-event-modal modal-border-colors"
      >
        <div className="modal-content pt-2">
          <p className="text-center p-2">
            Please confirm if you wish to proceed with canceling your event.
          </p>
          <div className="con">
            <div class="set-save-btn">
              <button
                type="submit"
                class="btn-simple my-2"
                onClick={() => {
                  handleEventCancelation();
                }}
              >
                Confirm
              </button>
            </div>
            <button className="modal-close" onClick={() => setModal(false)}>
              <img
                src="/images/simpleCrossWhite.svg"
                alt="crossIcon"
                className="modal-close-img"
              />
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  userId: state.authentication.userId,
  myEvents: state.customerDashboard.myEvents,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getMyEvents: (id, status) =>
    dispatch(customerDashboardActions.myEvents(id, status)),
  cancelEvent: (dataObj) =>
    dispatch(customerDashboardActions.cancelEvent(dataObj)),
});

// mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MyEventsTab));
