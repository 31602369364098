import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AvForm, AvField } from "availity-reactstrap-validation";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import CloseIcon from "mdi-react/CloseIcon";
import MapMarker from "mdi-react/MapMarkerIcon";

import { shutterbActions, configurationsActions } from "../../redux/actions";
import { customToast } from "../../shared";
import { configurationsConstants } from "../../constants";

const ShutterbForm = (props) => {
  const { t } = useTranslation("common");

  const [address, setAddress] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [noFileSelected, setNoFileSelected] = useState(false);
  const [latLng, setLatLng] = useState(null);
  const [autocompleteSuggestions, setAutocompleteSuggestions] = useState([]);
  const [data, setData] = useState([]);
  const focusErrorFieldRef = useRef([]);
  const [isJobRoleAccepted, setIsJobRoleAccepted] = useState(false);
  const [isCheckboxError, setIsCheckboxError] = useState(false);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    if (files.length > 3) {
      customToast(t("max_3_photos_can_be_selected"), toast.TYPE.ERROR);
      return;
    }
    // checking invalid files
    const allowedExt = ["image/jpg", "image/jpeg", "image/png"];
    const invalidFiles = files.filter((f) => {
      return allowedExt.indexOf(f.type) === -1;
    });
    if (invalidFiles.length > 0) {
      customToast(t("invalid_file_type"), toast.TYPE.ERROR);
      return;
    }

    setSelectedFiles(files);
  };

  const removeImage = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  const checkImage = () => {
    if (!selectedFiles.length) {
      setNoFileSelected(true);
      return;
    }
  };

  const handleSelect = async (selectedAddress) => {
    setAddress(selectedAddress);
    try {
      const results = await geocodeByAddress(selectedAddress);
      const coordinates = await getLatLng(results[0]);
      setLatLng(coordinates);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (
      address &&
      autocompleteSuggestions &&
      autocompleteSuggestions.some(
        (suggestion) => suggestion.description === address
      )
    ) {
      handleSelect(address);
    } else {
      setLatLng(null);
    }

    // eslint-disable-next-line
  }, [address]);

  const handleSubmit = (event, values) => {
    let formdata = new FormData();
    if (!latLng) {
      customToast("Invalid Location", toast.TYPE.ERROR);
      return;
    }
    selectedFiles.forEach((file) => {
      formdata.append("file", file);
    });
    if (!selectedFiles.length) {
      customToast(t("please_select_image"), toast.TYPE.ERROR);
      return;
    }
    formdata.append("FirstName", values.firstName);
    formdata.append("LastName", values.lastName);
    formdata.append("Email", values.email);
    formdata.append(
      "Address",
      JSON.stringify({
        currentLocation: address,
        longitude: latLng.lng,
        latitude: latLng.lat,
      })
    );
    formdata.append("latLng", latLng);
    formdata.append(
      "Distance",
      JSON.stringify({
        minDistanceToTravel: values.distance.substr(0, 2).trim(),
        unit: values.distance.substr(-2),
      })
    );
    formdata.append("InstagramHandle", values.instagramHandle);
    formdata.append("deviceId", values.currentSmartphone);
    formdata.append("phoneNumber", values.phoneNumber);
    const questionDetails = [
      {
        type: 2,
        detail: values.qType1,
      },
      {
        type: 3,
        detail: values.qType2,
      },
      {
        type: 4,
        detail: values.qType3,
      },
      {
        type: 14,
        detail: values.qType4,
      },
    ];
    formdata.append("questionDetails", JSON.stringify(questionDetails));
    formdata.append("isActive", true);
    props.shutterbRegister(formdata);
  };

  const handleInValidSubmit = (event, errors, values) => {
    for (let toFocusElm of focusErrorFieldRef.current) {
      if (toFocusElm.htmlFor === errors[0]) {
        toFocusElm.focus();
        break;
      }
    }
  };

  useEffect(() => {
    props.getSmartPhoneDevices(setData);

    // eslint-disable-next-line
  }, []);

  return (
    <div className="event-setup-form-box shutterb-form px-2 px-md-5">
      <h4 className="text-center shutterb-form-header">APPLICATION FORM</h4>
      <hr className="my-4" />
      {props.isFormSubmitted ? (
        <h4 className="sb-form-success-msg">
          Thank you! Your application was successfully received. Join our
          <a
            href="https://www.facebook.com/groups/904907153888896"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            shutterb labs Facebook community{" "}
          </a>
          to stay in touch while we review your submission.
        </h4>
      ) : (
        <AvForm
          id="event-form"
          onValidSubmit={handleSubmit}
          onInvalidSubmit={handleInValidSubmit}
          noValidate
        >
          <div id="form-content">
            <div className="row">
              <div className="col-lg-6">
                <label
                  ref={(element) => focusErrorFieldRef.current.push(element)}
                  htmlFor="firstName"
                >
                  {t("first_name")}
                </label>
                <AvField
                  name="firstName"
                  type="text"
                  maxLength={30}
                  pattern="^[a-z,A-Z].{0,30}$"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: t("please_enter_first_name"),
                    },
                  }}
                />
              </div>
              <div className="col-lg-6">
                <label
                  ref={(element) => focusErrorFieldRef.current.push(element)}
                  htmlFor="lastName"
                >
                  {t("last_name")}
                </label>
                <AvField
                  name="lastName"
                  type="text"
                  maxLength={30}
                  pattern="^[a-z,A-Z].{0,30}$"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: t("please_enter_last_name"),
                    },
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 mb-3">
                <label
                  ref={(element) => focusErrorFieldRef.current.push(element)}
                  htmlFor="shutterblocation"
                >
                  {t("current_address")}
                </label>
                <div className="places-autocomplete-container">
                  <PlacesAutocomplete
                    value={address}
                    onChange={setAddress}
                    onSelect={handleSelect}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => {
                      if (suggestions.length > 0) {
                        setAutocompleteSuggestions(suggestions);
                      }
                      return (
                        <div>
                          <AvField
                            placeholder={t("enter_a_location")}
                            name="shutterblocation"
                            type="address"
                            validate={{
                              required: {
                                value: true,
                                errorMessage: t("this_field_is_required"),
                              },
                            }}
                            {...getInputProps()}
                          />
                          <div className="address-suggestions-con px-2">
                            {loading ? <div>{t("loading")}</div> : null}
                            {suggestions.map((suggestion, index) => {
                              const style = {
                                backgroundColor: suggestion.active
                                  ? "#eaeaea"
                                  : "#fff",
                              };
                              return (
                                <>
                                  <div
                                    className="address-suggestions"
                                    key={index}
                                    {...getSuggestionItemProps(suggestion, {
                                      style,
                                    })}
                                  >
                                    <MapMarker className="map-icon" />
                                    {suggestion.description}
                                  </div>
                                  <hr className="p-0 m-0" />
                                </>
                              );
                            })}
                            {suggestions.length > 0 && (
                              <div className="google-trademark p-0 m-0">
                                powered by
                                <div className="google-svg-con">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="250"
                                    width="400"
                                    viewBox="-40.446 -22.19 350.532 133.14"
                                  >
                                    <path
                                      d="M115.39 46.71c0 12.77-9.99 22.18-22.25 22.18s-22.25-9.41-22.25-22.18c0-12.86 9.99-22.18 22.25-22.18s22.25 9.32 22.25 22.18zm-9.74 0c0-7.98-5.79-13.44-12.51-13.44s-12.51 5.46-12.51 13.44c0 7.9 5.79 13.44 12.51 13.44s12.51-5.55 12.51-13.44z"
                                      fill="#EA4335"
                                    />
                                    <path
                                      d="M163.39 46.71c0 12.77-9.99 22.18-22.25 22.18s-22.25-9.41-22.25-22.18c0-12.85 9.99-22.18 22.25-22.18s22.25 9.32 22.25 22.18zm-9.74 0c0-7.98-5.79-13.44-12.51-13.44s-12.51 5.46-12.51 13.44c0 7.9 5.79 13.44 12.51 13.44s12.51-5.55 12.51-13.44z"
                                      fill="#FBBC05"
                                    />
                                    <path
                                      d="M209.39 25.87v39.82c0 16.38-9.66 23.07-21.08 23.07-10.75 0-17.22-7.19-19.66-13.07l8.48-3.53c1.51 3.61 5.21 7.87 11.17 7.87 7.31 0 11.84-4.51 11.84-13v-3.19h-.34c-2.18 2.69-6.38 5.04-11.68 5.04-11.09 0-21.25-9.66-21.25-22.09 0-12.52 10.16-22.26 21.25-22.26 5.29 0 9.49 2.35 11.68 4.96h.34v-3.61h9.25zm-8.56 20.92c0-7.81-5.21-13.52-11.84-13.52-6.72 0-12.35 5.71-12.35 13.52 0 7.73 5.63 13.36 12.35 13.36 6.63 0 11.84-5.63 11.84-13.36z"
                                      fill="#4285F4"
                                    />
                                    <path
                                      d="M224.64 2.53v65h-9.5v-65z"
                                      fill="#34A853"
                                    />
                                    <path
                                      d="M261.66 54.01l7.56 5.04c-2.44 3.61-8.32 9.83-18.48 9.83-12.6 0-22.01-9.74-22.01-22.18 0-13.19 9.49-22.18 20.92-22.18 11.51 0 17.14 9.16 18.98 14.11l1.01 2.52-29.65 12.28c2.27 4.45 5.8 6.72 10.75 6.72 4.96 0 8.4-2.44 10.92-6.14zm-23.27-7.98l19.82-8.23c-1.09-2.77-4.37-4.7-8.23-4.7-4.95 0-11.84 4.37-11.59 12.93z"
                                      fill="#EA4335"
                                    />
                                    <path
                                      d="M34.93 40.94v-9.41h31.71c.31 1.64.47 3.58.47 5.68 0 7.06-1.93 15.79-8.15 22.01-6.05 6.3-13.78 9.66-24.02 9.66C15.96 68.88 0 53.42 0 34.44 0 15.46 15.96 0 34.94 0c10.5 0 17.98 4.12 23.6 9.49l-6.64 6.64c-4.03-3.78-9.49-6.72-16.97-6.72-13.86 0-24.7 11.17-24.7 25.03 0 13.86 10.84 25.03 24.7 25.03 8.99 0 14.11-3.61 17.39-6.89 2.66-2.66 4.41-6.46 5.1-11.65z"
                                      fill="#4285F4"
                                    />
                                  </svg>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    }}
                  </PlacesAutocomplete>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 icon-field-con">
                <AvField
                  type="select"
                  name="distance"
                  label={t("distance_willing_to_travel")}
                  value="5 KM"
                >
                  <option>5 KM</option>
                  <option>10 KM</option>
                  <option>15 KM</option>
                  <option>20 KM</option>
                  <option>30 KM</option>
                  <option>40 KM</option>
                  <option>50 KM</option>
                  <option>60 KM</option>
                  <option>70 KM</option>
                  <option>80 KM</option>
                  <option>90 KM</option>
                  <option>100 KM</option>
                </AvField>
                <div className="drop-arrow-con">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="m12 15l-4.243-4.242l1.415-1.414L12 12.172l2.828-2.828l1.415 1.414L12 15.001Z"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <label
                  ref={(element) => focusErrorFieldRef.current.push(element)}
                  htmlFor="email"
                >
                  {t("email_address")}
                </label>
                <AvField
                  name="email"
                  type="email"
                  maxLength={configurationsConstants.EMAIL_INPUT_LENGTH}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: t("please_enter_email"),
                    },
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <label
                  ref={(element) => focusErrorFieldRef.current.push(element)}
                  htmlFor="instagramHandle"
                >
                  {t("instagram_handle")}
                </label>
                <AvField
                  name="instagramHandle"
                  type="text"
                  maxLength={240}
                  pattern="^[a-z,A-Z,0-9,!@#$%^&*_=+-].{2,240}$"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: t("please_enter_instagram"),
                    },
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <label
                  ref={(element) => focusErrorFieldRef.current.push(element)}
                  htmlFor="phoneNumber"
                >
                  {t("phone_number")}
                </label>
                <AvField
                  name="phoneNumber"
                  type="number"
                  pattern="^[0-9].{8,9}$"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: t("please_enter_phone_number"),
                    },
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 icon-field-con">
                <AvField
                  type="select"
                  name="currentSmartphone"
                  label={t("current_smartphone")}
                  value={data && data.length > 0 ? data[0].id : null}
                >
                  {data &&
                    data.map((device) => (
                      <option key={device.id} value={device.id}>
                        {device.deviceName}
                      </option>
                    ))}
                </AvField>
                <div className="drop-arrow-con">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width=""
                    height=""
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="m12 15l-4.243-4.242l1.415-1.414L12 12.172l2.828-2.828l1.415 1.414L12 15.001Z"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <label
                  ref={(element) => focusErrorFieldRef.current.push(element)}
                  htmlFor="qType1"
                >
                  {t("shutterb_question_1")}
                </label>
                <p className="p-0 m-0 question-des">
                  {t("shutterb_question_1_description")}
                </p>
                <AvField
                  name="qType1"
                  type="textarea"
                  maxLength={configurationsConstants.TEXTAREA_INPUT_LENGTH}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: t("this_field_is_required"),
                    },
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <label
                  ref={(element) => focusErrorFieldRef.current.push(element)}
                  htmlFor="qType2"
                >
                  {t("shutterb_question_2")}
                </label>
                <p className="p-0 m-0 question-des">
                  {t("shutterb_question_2_description")}
                </p>
                <AvField
                  name="qType2"
                  type="textarea"
                  maxLength={configurationsConstants.TEXTAREA_INPUT_LENGTH}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: t("this_field_is_required"),
                    },
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <label
                  ref={(element) => focusErrorFieldRef.current.push(element)}
                  htmlFor="qType3"
                >
                  {t("shutterb_question_3")}
                </label>
                <p className="p-0 m-0 question-des">
                  {t("shutterb_question_3_description")}
                </p>
                <AvField
                  name="qType3"
                  type="textarea"
                  required
                  maxLength={configurationsConstants.TEXTAREA_INPUT_LENGTH}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: t("this_field_is_required"),
                    },
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <label
                  htmlFor="qType4"
                  ref={(element) => focusErrorFieldRef.current.push(element)}
                >
                  {t("shutterb_question_4")}
                </label>
                <p className="p-0 m-0 question-des">
                  {t("shutterb_question_4_description")}
                </p>
                <AvField
                  name="qType4"
                  type="textarea"
                  required
                  maxLength={configurationsConstants.TEXTAREA_INPUT_LENGTH}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: t("this_field_is_required"),
                    },
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 files-btn">
                <AvField
                  name="files"
                  type="file"
                  accept=".jpeg, .png, .jpg"
                  multiple="multiple"
                  onChange={handleFileChange}
                  className="d-none"
                />
                <label className="block-label" htmlFor="files">
                  {t("upload_image_msg")}
                </label>
                <div className="upload-btn">
                  <label
                    ref={(element) => focusErrorFieldRef.current.push(element)}
                    className="btn-green"
                    htmlFor="files"
                  >
                    {t("select_multiple_files")}
                  </label>
                  <div className="clearfix"></div>
                </div>
                {!(selectedFiles.length > 0) && noFileSelected && (
                  <div className="text-danger">{t("please_select_image")}</div>
                )}
              </div>
              {selectedFiles.length > 0 && (
                <div className="row">
                  <div className="col-lg-12">
                    <div className="py-3 image-preview d-flex justify-content-start align-items-center flex-row flex-wrap">
                      {selectedFiles.map((file, index) => (
                        <div
                          key={index}
                          className="image-container mr-1 mb-1 mb-md-0"
                        >
                          <img
                            src={URL.createObjectURL(file)}
                            alt={file.name}
                            className="p-1 img-size"
                          />
                          <div
                            className="remove-icon"
                            onClick={() => removeImage(index)}
                          >
                            <CloseIcon size={16} className="w-75" />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="row">
              <div className="col-lg-12">
                <a
                  href="https://www.shutterb.co/shutterb-job-role"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="color-teal"
                >
                  shutterb job role
                </a>
              </div>
            </div>

            <div className="row pl-3 checkbox-custom-err">
              <label className="ml-md-0 ml-lg-0 mt-1">
                I have read the shutterb job role and agree this sounds like the
                perfect gig for me!
              </label>
              <AvField
                type="checkbox"
                name="readJobRole"
                style={{ minHeight: "18px" }}
                required
                onChange={(event, value) => {
                  setIsJobRoleAccepted(event.target.checked);
                  if (event.target.checked) {
                    setIsCheckboxError(false);
                  }
                }}
              />
              <label
                htmlFor="readJobRole"
                ref={(element) => focusErrorFieldRef.current.push(element)}
                className="pl-1 ml-1 ml-md-0 ml-lg-0"
              >
                Yes I'm in
              </label>
              <p className={!isCheckboxError ? "d-none" : ""}>
                {t("this_field_is_required")}
              </p>
            </div>

            <div className="row justify-content-center pt-4">
              <div className="event-booking-form-btn">
                <button
                  type="submit"
                  className="btn sb-register-submit-btn px-1 py-0 px-md-4"
                  onClick={(e) => {
                    if (!isJobRoleAccepted) {
                      setIsCheckboxError(true); // Display error if checkbox is unchecked
                    } else {
                      setIsCheckboxError(false); // Clear error if checkbox is checked
                    }
                    checkImage();
                  }}
                >
                  {t("submit_application")}
                </button>
              </div>
            </div>
          </div>
        </AvForm>
      )}
    </div>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  isFormSubmitted: state.shutterbAuthentication.isFormSubmitted,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  shutterbRegister: (userData) =>
    dispatch(shutterbActions.shutterbRegister(userData)),
  getSmartPhoneDevices: (setData) =>
    dispatch(configurationsActions.getSmartPhoneDevices(setData)),
});

// mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ShutterbForm));
