import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import { globalSystemConstants } from "../../../../constants";

const Support = (props) => {
  return (
    <div className="container supprt-tab">
      <div className="row">
        <div className="col px-0">
          <h5 className="p-0 m-0 mb-2">Support</h5>
          <p className="p-0 m-0 mb-4">
            If you have any concerns or require support please contact us and
            someone from our team will be in touch.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col px-0">
          <div className="d-flex justify-content-start">
            <button
              className="btn-simple search-btn mb-2"
              type="submit"
              onClick={() => {
                props.spinnerActivate();
                window.location.replace(
                  `${process.env.REACT_APP_MARKETING_SITE_BASE_URL}/contact-us/`
                );
              }}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  spinnerActivate: () => {
    dispatch({
      type: globalSystemConstants.SPINNER_ACTIVATE,
    });
  },
  spinnerDiactivate: () => {
    dispatch({
      type: globalSystemConstants.SPINNER_DIACTIVATE,
    });
  },
});

export default connect(null, mapDispatchToProps)(withRouter(Support));
