// eslint-disable-next-line import/no-cycle
import responseHandler from "../system/server-response-handler";
import { customerConstants } from "../constants";
import {
  getRequest,
  putRequest,
  deleteRequestWithPayload,
  postRequest,
} from "../system/server-call";

/**
 * The Customer's Dashboard Services
 *
 * This is used for getting data from server and handling the business logic on that data
 */

// get the updated Customer Events Requests from server
const getMyEvents = (id,status) => {
  return getRequest(`/api/v1/User/EventSetup/list/${id}/${status}`, true).then(
    (response) => {
      return responseHandler.handleServerResponse(response);
    },
    (error) => {
      return responseHandler.handleHTTPResponses(error.response);
    }
  );
};

// same service for both components extend sb and tip sb
const getExtendSb = (eventId, userId) => {
  return getRequest(
    `/api/v1/User/EventGallery/AttendedEvent/${eventId}/0`,
    true
  ).then(
    (response) => {
      return responseHandler.handleServerResponse(response);
    },
    (error) => {
      return responseHandler.handleHTTPResponses(error.response);
    }
  );
};

// get shared gallery images from server
const getAccessGallery = (eventId, componentName) => {
  // same response structure just end point is diffrent that's why it's same for both components AccessGAllery and SharedGallery
  const endPoint =
    componentName === customerConstants.ACCESS_GALLERY_CALL
      ? `/api/v1/User/EventGallery/${eventId}`
      : `/api/v1/User/EventGallery/SharedGalleryFiles/${eventId}`;

  return getRequest(endPoint, true).then(
    (response) => {
      return responseHandler.handleServerResponse(response);
    },
    (error) => {
      return responseHandler.handleHTTPResponses(error.response);
    }
  );
};

const deleteFromGallery = (dataObj) => {
  return deleteRequestWithPayload(
    `/api/v1/User/EventGallery`,
    dataObj,
    true
  ).then(
    (response) => {
      return responseHandler.handleServerResponse(response);
    },
    (error) => {
      return responseHandler.handleHTTPResponses(error.response);
    }
  );
};

const lockFromGallery = (dataObj) => {
  return putRequest(`/api/v1/User/EventGallery/LockFiles`, dataObj, true).then(
    (response) => {
      return responseHandler.handleServerResponse(response);
    },
    (error) => {
      return responseHandler.handleHTTPResponses(error.response);
    }
  );
};

// my account tab services
const getMyAccountInfo = (userId) => {
  return getRequest(`/api/v1/user/userinfo/${userId}`, true).then(
    (response) => {
      return responseHandler.handleServerResponse(response);
    },
    (error) => {
      return responseHandler.handleHTTPResponses(error.response);
    }
  );
};

const updateMyAccountInfo = (userId, dataObj) => {
  return putRequest(`/api/v1/user/userinfo/${userId}`, dataObj, true).then(
    (response) => {
      return responseHandler.handleServerResponse(response);
    },
    (error) => {
      return responseHandler.handleHTTPResponses(error.response);
    }
  );
};

const updateCustomerPassword = (userId, dataObj) => {
  return putRequest(
    `/api/v1/user/userinfo/updateuserpassword/${userId}`,
    dataObj,
    true
  ).then(
    (response) => {
      return responseHandler.handleServerResponseMessage(response);
    },
    (error) => {
      return responseHandler.handleHTTPResponses(error.response);
    }
  );
};

// Attended event tab services
const getAttendedEventSearchResult = (eventId, userId) => {
  return getRequest(
    `/api/v1/User/EventGallery/AttendedEvent/${eventId}/${userId}`,
    true
  ).then(
    (response) => {
      return responseHandler.handleServerResponse(response);
    },
    (error) => {
      return responseHandler.handleHTTPResponses(error.response);
    }
  );
};

const getAttendedEventRecentSearches = (userId) => {
  return getRequest(
    `/api/v1/User/EventGallery/RecentSearch/${userId}`,
    true
  ).then(
    (response) => {
      return responseHandler.handleServerResponse(response);
    },
    (error) => {
      return responseHandler.handleHTTPResponses(error.response);
    }
  );
};

const referAFriend = (dataObj) => {
  return postRequest(
    `/api/v1/User/EventSetup/ReferToFriend`,
    dataObj,
    true
  ).then(
    (response) => responseHandler.handleServerResponseMessage(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const support = (dataObj) => {
  return postRequest(`/api/v1/User/EventSetup/support`, dataObj, true).then(
    (response) => responseHandler.handleServerResponseMessage(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

// same service for both components extend sb and tip sb
const getReviewFormData = (eventId) => {
  return getRequest(`/api/v1/user/review/${eventId}`, true).then(
    (response) => {
      return responseHandler.handleServerResponse(response);
    },
    (error) => {
      return responseHandler.handleHTTPResponses(error.response);
    }
  );
};

const submitRatingAndFeedback = (data) => {
  return postRequest(`/api/v1/user/review`, data, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

// same service for both components extend sb and tip sb
const getInvoice = (eventId) => {
  return getRequest(`/api/v1/ShutterB/GenerateInvoice/${eventId}`, true).then(
    (response) => {
      return responseHandler.handleServerResponse(response);
    },
    (error) => {
      return responseHandler.handleHTTPResponses(error.response);
    }
  );
};

const downloadSelectedFiles = (eventIdsArr) => {
  return postRequest(
    `/api/v1/ShutterB/Event/DownloadEventFiles`,
    eventIdsArr,
    true
  ).then(
    (response) => responseHandler.handleServerResponseMessage(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const cancelEvent = (dataObj) => {
  return postRequest(`/api/Stripe/CreateRefund`, dataObj, true).then(
    (response) => responseHandler.handleServerResponseMessage(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

// exporting functions for other files
export const customerDashboardServices = {
  getMyEvents,
  getExtendSb,
  getAccessGallery,
  deleteFromGallery,
  lockFromGallery,
  getMyAccountInfo,
  updateMyAccountInfo,
  updateCustomerPassword,
  getAttendedEventSearchResult,
  getAttendedEventRecentSearches,
  referAFriend,
  support,
  getReviewFormData,
  submitRatingAndFeedback,
  getInvoice,
  downloadSelectedFiles,
  cancelEvent,
};
