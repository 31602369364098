import i18next from "i18next";
import { toast } from "react-toastify";
import { authService } from "../../services";
import {
  loginConstants,
  signupConstants,
  shutterbLoginConstants,
  LOGIN_FORM,
} from "../../constants";
import { customToast } from "../../shared/utility";
import { success, failure, custom } from "./utilities";
import { globalSystemConstants } from "../../constants";
import { navigateToLogin } from "../../system";

// this action is called when user click on login or sign up
function login(userData) {
  return (dispatch) => {
    dispatch(
      custom(loginConstants.USER_LOGIN, globalSystemConstants.SPINNER_ACTIVATE)
    );
    authService
      .login(userData)
      .then(
        (response) => {
          customToast(i18next.t("logged_in_successfully"), toast.TYPE.SUCCESS);
          dispatch(success(response, loginConstants.USER_LOGIN));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, signupConstants.USER_SIGNUP));
          dispatch(failure(error, loginConstants.USER_LOGIN));
        }
      )
      .finally(() => {
        dispatch(custom(null, globalSystemConstants.SPINNER_DIACTIVATE));
      });
  };
}

function logout() {
  return (dispatch) => {
    dispatch(custom(null, globalSystemConstants.SPINNER_ACTIVATE));
    dispatch(custom(null, loginConstants.LOGOUT));
    authService.logout().then(() => navigateToLogin());
  };
}

function signup(userData, setIsModalOpen, setFormSelect) {
  return (dispatch) => {
    dispatch(
      custom(loginConstants.USER_LOGIN, globalSystemConstants.SPINNER_ACTIVATE)
    );
    authService
      .signup(userData)
      .then(
        (response) => {
          customToast(i18next.t("registered_successfully"), toast.TYPE.SUCCESS);
          setIsModalOpen(false);
          setFormSelect(LOGIN_FORM);
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, signupConstants.USER_SIGNUP));
        }
      )
      .finally(() => {
        dispatch(
          custom(
            loginConstants.USER_LOGIN,
            globalSystemConstants.SPINNER_DIACTIVATE
          )
        );
      });
  };
}
// this action is called when user click on login with Facebook
function loginWithFacebook(accessTokenObj) {
  return (dispatch) => {
    dispatch(
      custom(loginConstants.USER_LOGIN, globalSystemConstants.SPINNER_ACTIVATE)
    );
    authService
      .loginWithFacebook(accessTokenObj)
      .then(
        (response) => {
          customToast(i18next.t("logged_in_successfully"), toast.TYPE.SUCCESS);
          dispatch(success(response, loginConstants.USER_LOGIN));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, loginConstants.USER_LOGIN));
        }
      )
      .finally(() => {
        dispatch(
          custom(
            loginConstants.USER_LOGIN,
            globalSystemConstants.SPINNER_DIACTIVATE
          )
        );
      });
  };
}

function logoutWithFacebook() {
  return (dispatch) => {
    dispatch(custom(null, loginConstants.LOGOUT));
    authService.logout().then(() => window.location.reload());
  };
}

// this action is called when user click on login with Google
function loginWithGoogle(accessTokenObj) {
  return (dispatch) => {
    dispatch(
      custom(loginConstants.USER_LOGIN, globalSystemConstants.SPINNER_ACTIVATE)
    );
    authService
      .loginWithGoogle(accessTokenObj)
      .then(
        (response) => {
          customToast(i18next.t("logged_in_successfully"), toast.TYPE.SUCCESS);
          dispatch(success(response, loginConstants.USER_LOGIN));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, loginConstants.USER_LOGIN));
        }
      )
      .finally(() => {
        dispatch(
          custom(
            loginConstants.USER_LOGIN,
            globalSystemConstants.SPINNER_DIACTIVATE
          )
        );
      });
  };
}

function logoutWithGoogle() {
  return (dispatch) => {
    dispatch(custom(null, loginConstants.LOGOUT));
    authService.logout().then(() => window.location.reload());
  };
}

function emailVerification(queryParam) {
  return (dispatch) => {
    dispatch(
      custom(loginConstants.USER_LOGIN, globalSystemConstants.SPINNER_ACTIVATE)
    );
    authService
      .emailVerification(queryParam)
      .then(
        (response) => {
          dispatch(success(response, shutterbLoginConstants.shutterbLogin));
        },
        (error) => {
          dispatch(failure(error, loginConstants.USER_LOGIN));
          dispatch({ type: "EMAIL_VERIFIED", data: false });
        }
      )
      .finally(() => {
        dispatch(custom(null, globalSystemConstants.SPINNER_DIACTIVATE));
      });
  };
}

function changePassword(Data) {
  return (dispatch) => {
    dispatch(
      custom(loginConstants.USER_LOGIN, globalSystemConstants.SPINNER_ACTIVATE)
    );
    authService
      .changePassword(Data)
      .then(
        () => {
          const message = i18next.t("password_has_been_set_successfully");
          customToast(message, toast.TYPE.SUCCESS);
          setTimeout(() => window.location.replace("/"), 300);
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, loginConstants.USER_LOGIN));
        }
      )
      .finally(() => {
        dispatch(
          custom(
            loginConstants.USER_LOGIN,
            globalSystemConstants.SPINNER_DIACTIVATE
          )
        );
      });
  };
}

const forgotPassword = (Data, setIsModalOpen) => {
  return (dispatch) => {
    dispatch(
      custom(loginConstants.USER_LOGIN, globalSystemConstants.SPINNER_ACTIVATE)
    );
    authService
      .forgotPassword(Data)
      .then(
        (response) => {
          // if message is email_not_found modal will not close and toast will be of error, other inverse of this
          !response?.message?.includes("not") && setIsModalOpen(false);
          customToast(
            response?.message,
            response?.message.includes("not")
              ? toast.TYPE.ERROR
              : toast.TYPE.SUCCESS
          );
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, loginConstants.USER_LOGIN));
        }
      )
      .finally(() => {
        dispatch(
          custom(
            loginConstants.USER_LOGIN,
            globalSystemConstants.SPINNER_DIACTIVATE
          )
        );
      });
  };
};

export const loginActions = {
  login,
  logout,
  loginWithFacebook,
  logoutWithFacebook,
  loginWithGoogle,
  logoutWithGoogle,
  signup,
  emailVerification,
  changePassword,
  forgotPassword,
};
