import React from "react";

const Footer = () => {
  return (
    <footer className="footer pb-0">
      <div style={{ backgroundImage: `url("/images/footer.png")` }}>
        <div className="container pt-35">
          <div className="row">
            <div className="col-md-12">
              <div className="footer-logo text-center">
                <figure>
                  <img
                    src="/images/logo.png"
                    className="w-100"
                    alt="Footer Logo"
                  />
                  {/* <img
                  src="/images/logo.png"
                  onClick={() => {
                    window.location.href = `${process.env.REACT_APP_MARKETING_SITE_BASE_URL}`;
                  }}
                  alt=""
                /> */}
                </figure>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="footer-links text-center">
                <ul className="list-unstyled d-flex flex-wrap justify-content-center">
                  <li className="pt-2">
                    <a
                      // className="border-right-responsive"
                      href={`${process.env.REACT_APP_MARKETING_SITE_BASE_URL}/contact-us/`}
                    >
                      Contact Us
                    </a>
                  </li>
                  <span className="vertical-divider pl-0 pr-0"></span>
                  <li className="pt-2">
                    <a
                      // className="border-right-responsive"
                      href={`${process.env.REACT_APP_MARKETING_SITE_BASE_URL}/our-story/`}
                    >
                      Our Story
                    </a>
                  </li>
                  <span className="vertical-divider pl-0 pr-0"></span>
                  <li className="pt-2">
                    <a
                      // className="border-right-responsive"
                      href={`mailto:info@shutterb.co`}
                    >
                      Careers
                    </a>
                  </li>
                  <span className="vertical-divider pl-0 pr-0"></span>
                  <li className="pt-2">
                    <a
                      // className="border-right-responsive"
                      href={`mailto:info@shutterb.co`}
                    >
                      Investors
                    </a>
                  </li>
                  <span className="vertical-divider pl-0 pr-0"></span>
                  <li className="pt-2">
                    <a
                      href={`${process.env.REACT_APP_MARKETING_SITE_BASE_URL}/faq/`}
                    >
                      FAQ
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="divider"></div>
          <div className="row">
            <div className="col-md-12">
              <div className="footer-social-links text-center pb-0 mb-0">
                <ul className="list-inline pb-0 mb-3">
                  <li>
                    <a
                      href="https://www.facebook.com/shutterb.co"
                      style={{ fontSize: "23px" }}
                    >
                      <i
                        className="fab fa-facebook"
                        style={{ height: "23px" }}
                      ></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/shutterb.co/"
                      style={{ fontSize: "23px" }}
                    >
                      <i
                        className="fab fa-instagram"
                        style={{ height: "23px" }}
                        aria-hidden="true"
                      ></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/@shutterb.co"
                      style={{ fontSize: "23px" }}
                    >
                      <i
                        className="fab fa-tiktok"
                        style={{ height: "23px" }}
                        aria-hidden="true"
                      ></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="footer-copyright text-center text-underline">
                <p
                  className="mb-0 pb-5"
                  style={{ fontSize: "12px", fontWeight: "400" }}
                >
                  all bookings are subject to a 5% Service Fee and 13% HST
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid pt-2">
        <div className="row w-100">
          <div className="col-md-3 footer-bottom">
            <a
              href={`${process.env.REACT_APP_MARKETING_SITE_BASE_URL}/privacy-policy/`}
              className="pr-3 text-grey policy-border"
            >
              Privacy Policy
            </a>
            <a
              href={`${process.env.REACT_APP_MARKETING_SITE_BASE_URL}/terms-and-conditions/`}
              className="pl-3 text-grey"
            >
              Terms and Conditions
            </a>
          </div>
          <div className="col-md-6"></div>
          <div className="col-md-3 footer-bottom">
            <p>© 2024 ShutterB. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
