import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

import { customerDashboardActions } from "../../../../../../../redux/actions";
import {
  customToast,
  convertDateToUTC,
} from "../../../../../../../shared/utility";
import { configurationsConstants } from "../../../../../../../constants";

const ReviewYourSb = (props) => {
  const { t } = useTranslation("common");
  const [rating, setRating] = useState([]);
  const [feedback, setFeedback] = useState([]);
  const { eventId } = useParams();

  useEffect(() => {
    props.getReviewFormData(eventId);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props?.reviewFormData && props?.reviewFormData[0]) {
      setRating(
        props?.reviewFormData?.map((sb) => sb.sbUser?.sbReviews[0]?.reviews)
      );
      setFeedback(
        props?.reviewFormData?.map((sb) => sb.sbUser?.sbReviews[0]?.feedback)
      );
    }
    // eslint-disable-next-line
  }, [props.reviewFormData]);

  const handleRatingChange = (value, index) => {
    const updatedRating = [...rating];
    updatedRating[index] = value;
    setRating(updatedRating);
  };

  const handleFeedbackChange = (value, index) => {
    const updatedFeedback = [...feedback];
    updatedFeedback[index] = value;
    setFeedback(updatedFeedback);
  };

  const handleSubmit = (event, sbId, reviewId) => {
    event.preventDefault();

    // Find the corresponding rating from the rating array
    const sbIndex = props.reviewFormData.findIndex(
      (sb) => sb.sbuserId === sbId
    );
    const sbRating = rating[sbIndex];

    if (sbRating) {
      const sbFeedbackIndex = props.reviewFormData.findIndex(
        (sb) => sb.sbuserId === sbId
      );
      const sbFeedback = feedback[sbFeedbackIndex];
      const dataToSend = {
        id: reviewId,
        userId: props.userId,
        sbuserId: sbId,
        eventId: eventId,
        reviews: sbRating,
        feedback: sbFeedback,
      };
      props.submitRatingAndFeedback(dataToSend);
    } else {
      customToast("Please give rating", toast.TYPE.ERROR);
    }
  };

  return (
    <div className="container extend-sb tip-sb">
      <div className="row">
        <div className="col d-flex justify-content-center align-items-center flex-column">
          <p className="p-0 m-0">
            {props.eventDetails?.eventName +
              " - " +
              convertDateToUTC(props.eventDetails?.eventDate)}
          </p>
          <p className="p-0 m-0">
            <strong> Event ID:</strong> {props.eventDetails?.id}
          </p>
        </div>
      </div>
      <div className="row my-3">
        <div className="col px-0"></div>
      </div>
      {/* list of all shutterbs of event */}
      <div className="row px-0">
        <form className="col px-0" onSubmit={handleSubmit}>
          {props.reviewFormData && props.reviewFormData.length ? (
            props.reviewFormData.map((sb, index) => (
              <div className={"py-md-4 "} key={sb.id}>
                <div className="py-md-4 sb-list-item row d-flex justify-content-start mb-3">
                  <div className="col-md-3 p-0 m-0">
                    <div className="profile-img-con">
                      <label
                        className="profile-img"
                        htmlFor={sb?.sbUser?.firstName + sb?.sbUser?.id}
                      >
                        <img
                          src={
                            process.env.REACT_APP_S3_BASE_URL_PORTFOLIO +
                            "/" +
                            sb?.sbUser?.sbPortfolios[0]?.profileImageUrl
                          }
                          alt=""
                        />
                      </label>
                    </div>
                    <h6>
                      {sb?.sbUser?.firstName + " " + sb?.sbUser?.lastName}
                    </h6>
                  </div>
                  <div className="col-md-9 p-0 m-0">
                    <div className="col-md-4 pl-0"></div>
                    <div className="rating-input">
                      <label htmlFor="rating">
                        <h6>
                          1. Give your shutterb a rating so we know how they
                          did!
                        </h6>
                      </label>

                      <div className="stars">
                        {/* Render star icons based on the selected rating */}
                        {[1, 2, 3, 4, 5].map((value) => (
                          <span
                            key={value}
                            className={`star ${
                              value <= rating[index] ? "active" : ""
                            }`}
                            onClick={() => handleRatingChange(value, index)}
                          >
                            &#9733;
                          </span>
                        ))}
                      </div>
                    </div>
                    <div className="feedback-input">
                      <label htmlFor="feedback">
                        <h6>
                          2. We’d love to hear a bit more! Feel free to leave
                          comments and feedback below from your shutterb
                          experience.
                        </h6>
                      </label>

                      <textarea
                        name="feedback"
                        id="feedback"
                        className="feedback-text-area p-2"
                        maxLength={
                          configurationsConstants.TEXTAREA_INPUT_LENGTH
                        }
                        value={feedback[index]}
                        onChange={(event) =>
                          handleFeedbackChange(event.target.value, index)
                        }
                        rows="3"
                      ></textarea>
                    </div>
                    <div className="checkout-btn col-md-4 px-0">
                      <button
                        type="submit"
                        className="btn-simple w-100 align-items-center d-flex justify-content-center"
                        onClick={(e) =>
                          handleSubmit(
                            e,
                            sb?.sbUser?.id,
                            sb?.sbUser?.sbReviews[0]?.id
                          )
                        }
                      >
                        {t("Submit")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>The event request has not been accepted by any shutterb yet.</p>
          )}
        </form>
      </div>
    </div>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  userId: state.authentication.userId,
  reviewFormData: state.customerDashboard.reviewFormData,
  eventDetails: state.customerDashboard.eventDetails,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getReviewFormData: (eventId) =>
    dispatch(customerDashboardActions.getReviewFormData(eventId)),
  submitRatingAndFeedback: (data) =>
    dispatch(customerDashboardActions.submitRatingAndFeedback(data)),
});

// mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ReviewYourSb));
