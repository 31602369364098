import i18next from "i18next";
import { toast } from "react-toastify";
import { customToast } from "../../shared";
import { custom, failure } from "./utilities";
import { configurationsServices } from "../../services";
import {
  globalSystemConstants,
  configurationsConstants,
} from "../../constants";

const { GET_SMARTPHONE_DEVICES, GET_POSTAL_CODES } = configurationsConstants;

const getSmartPhoneDevices = (setData) => {
  return (dispatch) => {
    dispatch(
      custom(GET_SMARTPHONE_DEVICES, globalSystemConstants.SPINNER_ACTIVATE)
    );
    configurationsServices
      .getSmartPhoneDevices()
      .then(
        (response) => {
          if (response) {
            setData(response?.items);
            dispatch(custom(response, GET_SMARTPHONE_DEVICES));
          }
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, GET_SMARTPHONE_DEVICES));
        }
      )
      .finally(() => {
        dispatch(
          custom(
            GET_SMARTPHONE_DEVICES,
            globalSystemConstants.SPINNER_DIACTIVATE
          )
        );
      });
  };
};

const getPostalCodes = () => {
  return (dispatch) => {
    dispatch(
      custom(GET_POSTAL_CODES, globalSystemConstants.SPINNER_ACTIVATE)
    );
    configurationsServices
      .getPostalCodes()
      .then(
        (response) => {
          if (response) {
            dispatch(custom(response, GET_POSTAL_CODES));
          }
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, GET_POSTAL_CODES));
        }
      )
      .finally(() => {
        dispatch(
          custom(
            GET_POSTAL_CODES,
            globalSystemConstants.SPINNER_DIACTIVATE
          )
        );
      });
  };
};

export const configurationsActions = {
  getSmartPhoneDevices,
  getPostalCodes
};
