import { updateObject } from "../../shared/utility";
import { customerConstants, globalConstants } from "../../constants";

const storedCustomerDashboardCart = JSON.parse(
  localStorage.getItem(customerConstants.STORED_CUSTOMER_DASHBOARD_CART)
);
const storedEventDetails = JSON.parse(
  localStorage.getItem(customerConstants.STORED_EVENT_DETAILS)
);

// default state
const initialState = {
  myEvents: [],
  // myEvents Tab State Slices
  myEventsExtendSb: storedEventDetails ? { ...storedEventDetails } : {},
  customerDashboardCart: storedCustomerDashboardCart
    ? [...storedCustomerDashboardCart]
    : [],
  myEventsAccessGallery: {},
  // ends
  myAccountInfo: {},
  attendedEvents: {
    searchResult: {},
    recentSearches: [],
  },
  reviewFormData: [],
  eventDetails: {},
  invoice: null,
};

// manupilating the state based on actions and returning the state
export const customerDashboard = (state = initialState, action) => {
  switch (action.type) {
    // My events tab reducer cases
    case customerConstants.GET_MY_EVENTS + globalConstants.SUCCESS:
      return updateObject(state, {
        myEvents: action.data?.items?.event,
      });

    case customerConstants.GET_MY_EVENTS + globalConstants.FAILURE:
      return state;

    case customerConstants.EXTEND_SHUTTERB + globalConstants.SUCCESS:
      localStorage.setItem(
        customerConstants.STORED_EVENT_DETAILS,
        JSON.stringify(action.data?.items)
      );
      return updateObject(state, {
        // empting cart on selecting another event
        myEventsExtendSb: action.data?.items,
        customerDashboardCart:
          state.myEventsExtendSb.id === action.data?.items?.id
            ? [...state.customerDashboardCart]
            : [],
      });

    case customerConstants.EXTEND_SHUTTERB_CART + globalConstants.SUCCESS:
      localStorage.setItem(
        customerConstants.STORED_CUSTOMER_DASHBOARD_CART,
        JSON.stringify(action?.data)
      );
      return updateObject(state, {
        customerDashboardCart: [...action?.data],
      });

    case customerConstants.ADD_TO_CUSTOMER_DASHBOARD_CART:
      localStorage.setItem(
        customerConstants.STORED_CUSTOMER_DASHBOARD_CART,
        JSON.stringify([...state.customerDashboardCart, ...action.data])
      );
      return updateObject(state, {
        customerDashboardCart: [...state.customerDashboardCart, ...action.data],
      });

    case customerConstants.UPDATE_CUSTOMER_DASHBOARD_CART:
      localStorage.setItem(
        customerConstants.STORED_CUSTOMER_DASHBOARD_CART,
        JSON.stringify(action?.data)
      );
      return updateObject(state, {
        customerDashboardCart: [...action.data],
      });

    case customerConstants.MY_EVENTS_GALLERY + globalConstants.SUCCESS:
      return updateObject(state, {
        myEventsAccessGallery: action.data?.items,
      });

    // Shared gallery reducer cases
    case customerConstants.SHARED_GALLERY_IMAGES + globalConstants.SUCCESS:
      return updateObject(state, {
        myEventsAccessGallery: action.data?.items,
      });

    case customerConstants.DELETE_FROM_GALLERY + globalConstants.SUCCESS:
      return updateObject(state, {
        myEventsAccessGallery: action.data?.items,
      });

    // my account tab reducer cases
    case customerConstants.GET_MY_ACCOUNT_INFO + globalConstants.SUCCESS:
      return updateObject(state, {
        myAccountInfo: { ...action.data?.items },
      });

    case customerConstants.UPDATE_MY_ACCOUNT_INFO + globalConstants.SUCCESS:
      return updateObject(state, {
        myAccountInfo: action.data?.items,
      });

    // Attended events tab reducer cases
    case customerConstants.GET_SEARCH_RESULT + globalConstants.SUCCESS:
      return updateObject(state, {
        attendedEvents: {
          ...state.attendedEvents,
          // empty object to handle null response
          searchResult: action.data?.items ?? {},
        },
      });

    case customerConstants.GET_RECENT_SEARCHES + globalConstants.SUCCESS:
      return updateObject(state, {
        attendedEvents: {
          ...state.attendedEvents,
          recentSearches: action.data?.items,
        },
      });

    case customerConstants.GET_REVIEW_FORM_DATA + globalConstants.SUCCESS:
      return updateObject(state, {
        eventDetails: action.data?.items,
        reviewFormData: action.data?.items?.sbUser.map((sb) => ({
          id: sb.id,
          sbuserId: sb.id,
          sbUser: {
            ...sb,
          },
        })),
      });
    case customerConstants.GET_INVOICE + globalConstants.SUCCESS:
      return updateObject(state, {
        invoice: action?.data?.items,
      });
    default:
      return state;
  }
};
