export const shutterbProfileConstants = {
  GET_SHUTTERB_PROFILE_DATA: "GET_SHUTTERB_PROFILE_DATA",
};

export const languageOptions = [
  "Afrikaans",
  "Albanian - shqip",
  "Amharic - አማርኛ",
  "Arabic - العربية",
  "Aragonese - aragonés",
  "Armenian - հայերեն",
  "Asturian - asturianu",
  "Azerbaijani - azərbaycan dili",
  "Basque - euskara",
  "Belarusian - беларуская",
  "Bengali - বাংলা",
  "Bosnian - bosanski",
  "Breton - brezhoneg",
  "Bulgarian - български",
  "Catalan - català",
  "Central Kurdish - کوردی (دەستنوسی عەرەبی)",
  "Chinese - 中文",
  "Chinese (Hong Kong) - 中文（香港）",
  "Chinese (Simplified) - 中文（简体）",
  "Chinese (Traditional) - 中文（繁體）",
  "Corsican",
  "Croatian - hrvatski",
  "Czech - čeština",
  "Danish - dansk",
  "Dutch - Nederlands",
  "Esperanto - esperanto",
  "Estonian - eesti",
  "Faroese - føroyskt",
  "Filipino",
  "Finnish - suomi",
  "French - français",
  "French (Canada) - français (Canada)",
  "French (France) - français (France)",
  "French (Switzerland) - français (Suisse)",
  "Galician - galego",
  "Georgian - ქართული",
  "German - Deutsch",
  "German (Austria) - Deutsch (Österreich)",
  "German (Germany) - Deutsch (Deutschland)",
  "German (Liechtenstein) - Deutsch (Liechtenstein)",
  "German (Switzerland) - Deutsch (Schweiz)",
  "Greek - Ελληνικά",
  "Guarani",
  "Gujarati - ગુજરાતી",
  "Hausa",
  "Hawaiian - ʻŌlelo Hawaiʻi",
  "Hebrew - עברית",
  "Hindi - हिन्दी",
  "Hungarian - magyar",
  "Icelandic - íslenska",
  "Indonesian - Indonesia",
  "Interlingua",
  "Irish - Gaeilge",
  "Italian - italiano",
  "Italian (Italy) - italiano (Italia)",
  "Italian (Switzerland) - italiano (Svizzera)",
  "Japanese - 日本語",
  "Kannada - ಕನ್ನಡ",
  "Kazakh - қазақ тілі",
  "Khmer - ខ្មែរ",
  "Korean - 한국어",
  "Kurdish - Kurdî",
  "Kyrgyz - кыргызча",
  "Lao - ລາວ",
  "Latin",
  "Latvian - latviešu",
  "Lingala - lingála",
  "Lithuanian - lietuvių",
  "Macedonian - македонски",
  "Malay - Bahasa Melayu",
  "Malayalam - മലയാളം",
  "Maltese - Malti",
  "Marathi - मराठी",
  "Mongolian - монгол",
  "Nepali - नेपाली",
  "Norwegian - norsk",
  "Norwegian Bokmål - norsk bokmål",
  "Norwegian Nynorsk - nynorsk",
  "Occitan",
  "Oriya - ଓଡ଼ିଆ",
  "Oromo - Oromoo",
  "Pashto - پښتو",
  "Persian - فارسی",
  "Polish - polski",
  "Portuguese - português",
  "Portuguese (Brazil) - português (Brasil)",
  "Portuguese (Portugal) - português (Portugal)",
  "Punjabi - ਪੰਜਾਬੀ",
  "Quechua",
  "Romanian - română",
  "Romanian (Moldova) - română (Moldova)",
  "Romansh - rumantsch",
  "Russian - русский",
  "Scottish Gaelic",
  "Serbian - српски",
  "Serbo-Croatian - Srpskohrvatski",
  "Shona - chiShona",
  "Sindhi",
  "Sinhala - සිංහල",
  "Slovak - slovenčina",
  "Slovenian - slovenščina",
  "Somali - Soomaali",
  "Southern Sotho",
  "Spanish - español",
  "Spanish (Argentina) - español (Argentina)",
  "Spanish (Latin America) - español (Latinoamérica)",
  "Spanish (Mexico) - español (México)",
  "Spanish (Spain) - español (España)",
  "Spanish (United States) - español (Estados Unidos)",
  "Sundanese",
  "Swahili - Kiswahili",
  "Swedish - svenska",
  "Tajik - тоҷикӣ",
  "Tamil - தமிழ்",
  "Tatar",
  "Telugu - తెలుగు",
  "Thai - ไทย",
  "Tigrinya - ትግርኛ",
  "Tongan - lea fakatonga",
  "Turkish - Türkçe",
  "Turkmen",
  "Twi",
  "Ukrainian - українська",
  "Urdu - اردو",
  "Uyghur",
  "Uzbek - o‘zbek",
  "Vietnamese - Tiếng Việt",
  "Walloon - wa",
  "Welsh - Cymraeg",
  "Western Frisian",
  "Xhosa",
  "Yiddish",
  "Yoruba - Èdè Yorùbá",
  "Zulu - isiZulu",
];
