import moment from "moment";
import i18next from "i18next";
import { toast, Slide } from "react-toastify";
import { SERVER_TRANSLATION_KEYS } from "../system";
import heic2any from "heic2any";
import { sharedConstants } from "../constants";

export const updateObject = (oldObject, updatedProperties) => ({
  ...oldObject,
  ...updatedProperties,
});

export const customToast = (
  AllErrorMessages,
  type = toast.TYPE.INFO,
  position = toast.POSITION.TOP_RIGHT,
  time = 4000
) => {
  const options = {
    autoClose: time,
    type,
    hideProgressBar: true,
    position,
    pauseOnHover: true,
    transition: Slide,
    className: "primary",
  };
  if (Array.isArray(AllErrorMessages)) {
    AllErrorMessages.forEach((message) => {
      if (message) {
        toast(message, options);
      }
    });
  } else {
    toast(i18next.t(AllErrorMessages), options);
  }
};

export const customToastWithTextMsg = (
  AllErrorMessages,
  type = toast.TYPE.INFO,
  position = toast.POSITION.TOP_RIGHT,
  time = 4000
) => {
  const options = {
    autoClose: time,
    type,
    hideProgressBar: true,
    position,
    pauseOnHover: true,
    transition: Slide,
    className: "primary",
  };
  if (Array.isArray(AllErrorMessages)) {
    AllErrorMessages.forEach((message) => {
      if (SERVER_TRANSLATION_KEYS.includes(message)) {
        toast(i18next.t(message), options);
      }
    });
  } else {
    toast(AllErrorMessages, options);
  }
};

export const standardDateTimeFormat = (date) => {
  return moment(date).format("YYYY/MM/DD HH:mm");
};

/*export const formatTime = (epochTime) => {
  const date = new Date(epochTime * 1000);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedTime = `${formattedHours}:${formattedMinutes}`;
  return formattedTime;
};*/

export const formatTime = (epochTimestamp) => {
  const date = new Date(epochTimestamp * 1000);
  const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const day = weekdays[date.getDay()];
  const month = months[date.getMonth()];
  const dayOfMonth = date.getDate();
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const timezoneOffset = -date.getTimezoneOffset() / 60;
  const timezoneSuffix =
    timezoneOffset >= 0 ? `+${timezoneOffset}` : timezoneOffset.toString();

  const formattedTime = `${day} ${month} ${dayOfMonth} ${year} ${hours}:${minutes}:${seconds} GMT${timezoneSuffix} (Pakistan Standard Time)`;
  return formattedTime;
};

// get minimum allowed date
export const minimumAllowedDate = (numberOfFutureDays = 0) => {
  let currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + numberOfFutureDays);
  return currentDate.toISOString().split("T")[0];
};

export const convertHeicToImage = async (file) => {
  try {
    const fileExtension = file.name.split(".").pop().toLowerCase();
    const toType = fileExtension === "heif" ? "image/jpeg" : "image/jpeg";

    const imageBlob = await heic2any({ blob: file, toType });
    const convertedFileName = file.name.replace(/\.(heif|heic)$/, ".jpeg");

    return new File([imageBlob], convertedFileName, {
      type: "image/jpeg",
    });
  } catch (error) {
    console.error("HEIC/HEIF to image conversion error:", error);
    return null;
  }
};

export const calculateTotalFileSizeInMbs = (filesArr) => {
  let tempArr = filesArr;
  if (!Array.isArray(filesArr)) {
    tempArr = [...Array.from(filesArr)];
  }
  return (
    tempArr.reduce((accomulator, currentValue) => {
      return accomulator + currentValue.size;
    }, 0) / sharedConstants.KB_TO_MB_BYTES
  );
};

export const convertBytesToMbs = (fileSize) => {
  let size = fileSize;
  if (fileSize instanceof File) {
    size = fileSize.size;
  }
  return size / sharedConstants.KB_TO_MB_BYTES;
};

export const convertDateToUTC = (date) => {
  const epochTimestamp = date;
  const getDate = new Date(epochTimestamp * 1000);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const month = months[getDate.getUTCMonth()];
  const day = getDate.getUTCDate();
  const year = getDate.getUTCFullYear();

  return `${month} ${day}, ${year}`;
};

export const convertEpochToFormattedDate = (epochTimestamp) => {
  const getDate = new Date(epochTimestamp * 1000);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const month = months[getDate.getUTCMonth()];
  const day = getDate.getUTCDate();
  const year = getDate.getUTCFullYear();

  return `${month} ${day}, ${year}`;
}

export const convertLongDateToShortDate = (longDate) => {
  const date = new Date(longDate);
  const month = date.getMonth() + 1; // Adding 1 since months are 0-indexed
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
}

export const convertHeicToJpeg = async (heicFile) => {
  const toType = "image/jpeg";
  const imageBlob = await heic2any({ blob: heicFile, toType });
  const convertedFileName = heicFile.name.replace(/\.(heif|heic)$/, ".jpeg");
  return new File([imageBlob], convertedFileName, {
    type: "image/jpeg",
  });
};

export const ClearReelStorage = () => {
  localStorage.removeItem("activeTab");
  localStorage.removeItem("isOnlyReel");
  localStorage.removeItem("reelEventId");
  localStorage.removeItem("reelOrderId");
  localStorage.removeItem("NOT_NEW_EVENT");
  localStorage.removeItem("dataToSend");
  localStorage.removeItem("REEL_STORED");
  localStorage.removeItem("EVENT_DETAIL_STORED");
}

export const ClearExtendOrTipStorage = () => {
  localStorage.removeItem("activeTab");
  localStorage.removeItem("STORED_EVENT_DETAILS");
  localStorage.removeItem("STORED_CUSTOMER_DASHBOARD_CART");
  localStorage.removeItem("CART_PREVIOUS_SCREEN");
  localStorage.removeItem("NOT_NEW_EVENT");
  localStorage.removeItem("dataToSend");
  localStorage.removeItem("EVENT_DETAIL_STORED");
};