import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import { useParams } from "react-router-dom";

import { customerDashboardActions } from "../../../../../../../redux/actions";

const DownloadInvoice = ({ invoice, ...props }) => {
  const { eventId } = useParams();
  const [pdfBlobUrl, setPdfBlobUrl] = useState(null);

  const handleDownloadInvoice = async () => {
    try {
      // Decode the base64 data to a Uint8Array
      const decodedData = atob(invoice);
      const dataArray = new Uint8Array(decodedData.length);
      for (let i = 0; i < decodedData.length; i++) {
        dataArray[i] = decodedData.charCodeAt(i);
      }

      // Create a Blob from the Uint8Array
      const blob = new Blob([dataArray], { type: "application/pdf" });

      // Create a Blob URL for the Blob
      const blobUrl = URL.createObjectURL(blob);

      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = "invoice.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Clean up the temporary URL
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  useEffect(() => {
    props.getInvoice(eventId);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Decode the base64 data to a Uint8Array
    const decodedData = atob(invoice);
    const dataArray = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; i++) {
      dataArray[i] = decodedData.charCodeAt(i);
    }

    // Create a Blob from the Uint8Array
    const blob = new Blob([dataArray], { type: "application/pdf" });

    // Create a Blob URL for the Blob
    const blobUrl = URL.createObjectURL(blob);
    setPdfBlobUrl(blobUrl);

    // Clean up the temporary URL when the component unmounts
    return () => URL.revokeObjectURL(blobUrl);
  }, [invoice]);

  return (
    <div className="container px-0 col-12 mx-0">
      <h3>Click here to download your invoice</h3>
      <button
        disabled={!invoice}
        className="gallery-btn mb-3"
        onClick={handleDownloadInvoice}
      >
        Download Invoice
      </button>
      {invoice && pdfBlobUrl && (
        <iframe
          style={{ height: "140vh", width: "100%", display: "flex" }}
          title="PDF Viewer"
          src={pdfBlobUrl}
          type="application/pdf"
          width="100%"
          height="100%"
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  invoice: state.customerDashboard.invoice,
});

const mapDispatchToProps = (dispatch) => ({
  getInvoice: (eventId) =>
    dispatch(customerDashboardActions.getInvoice(eventId)),
});

// mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DownloadInvoice));
