import { toast } from "react-toastify";
import { customToast } from "../../shared";
import { custom, failure, success } from "./utilities";
import i18next from "i18next";
import { eventBookingServices } from "../../services";
import { loginActions } from "./login.actions";
import CryptoJS from "crypto-js";
import {
  globalSystemConstants,
  eventBookingConstants,
  customerConstants,
} from "../../constants";
import { NavigateToPathWithGo, history } from "../../system";
const {
  GET_SHOT_LIST,
  GET_AVAILABLE_SHUTTER_BS,
  GET_EVENT_TYPES,
  SET_FORM_STATE,
  SET_CART_TAB,
  SELECTED_SHUTTER_BS,
  CHECK_OUT,
  REQUEST_BOOKING,
  GET_REEL_PRICE,
  GET_MISC_AMOUNTS,
  SET_IS_DRAFT_EVENT_ALLOWED,
  SET_BULK_REEL_VALUES,
  GET_REEL_TYPES,
  ADD_REEL_STATES,
  SET_REEL_VALUES,
  SET_BULK_EVENT_DETAIL_VALUES,
} = eventBookingConstants;

const getShotList = (id) => {
  return (dispatch) => {
    dispatch(custom(GET_SHOT_LIST, globalSystemConstants.SPINNER_ACTIVATE));
    eventBookingServices
      .getShotList(id)
      .then(
        (response) => {
          dispatch(custom(response, GET_SHOT_LIST));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, GET_SHOT_LIST));
        }
      )
      .finally(() => {
        dispatch(
          custom(GET_SHOT_LIST, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};

const getAvailableShutterBs = (data) => {
  return (dispatch) => {
    dispatch(
      custom(GET_AVAILABLE_SHUTTER_BS, globalSystemConstants.SPINNER_ACTIVATE)
    );
    eventBookingServices
      .getAvailableShutterBs(data)
      .then(
        (response) => {
          dispatch(success(response, GET_AVAILABLE_SHUTTER_BS));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, GET_AVAILABLE_SHUTTER_BS));
          if (error[0].includes("no_shutterB_available_in_this_location")) {
            dispatch(custom(true, SET_IS_DRAFT_EVENT_ALLOWED));
          } else {
            dispatch(custom(false, SET_IS_DRAFT_EVENT_ALLOWED));
          }
        }
      )
      .finally(() => {
        dispatch(
          custom(
            GET_AVAILABLE_SHUTTER_BS,
            globalSystemConstants.SPINNER_DIACTIVATE
          )
        );
      });
  };
};

const getEventTypes = (isAuthenticated) => {
  return (dispatch) => {
    dispatch(custom(GET_EVENT_TYPES, globalSystemConstants.SPINNER_ACTIVATE));
    eventBookingServices
      .getEventTypes()
      .then(
        (response) => {
          const url = window.location.search;
          const params = new URLSearchParams(url);
          let token = params.get("token");
          const decodedToken = decodeURIComponent(token);
          try {
            if (decodedToken !== "") {
              var bytes = CryptoJS.AES.decrypt(
                decodedToken,
                "my-secret-key@123"
              );
              var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
              if (!isAuthenticated) {
                dispatch(
                  loginActions.login({
                    userName: decryptedData.user.userName,
                    password: `${process.env.REACT_APP_ADMIN_PASSWORD}`,
                  })
                );
                localStorage.setItem(
                  process.env.REACT_APP_PAYLOAD,
                  decodedToken
                );
                const updatedPath = window.location.pathname.replace(
                  `/${token}`,
                  "/:token"
                );
                window.location.replace(updatedPath);
              }
            }
          } catch (e) {
            console.log("Error parsing decrypted data:", e);
          }
          dispatch(custom(response, GET_EVENT_TYPES));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, GET_EVENT_TYPES));
        }
      )
      .finally(() => {
        dispatch(
          custom(GET_EVENT_TYPES, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};

const setFormState = (data) => {
  return (dispatch) => {
    dispatch(custom(data, SET_FORM_STATE));
  };
};

const setCartTab = (isCartTab = true) => {
  return (dispatch) => {
    dispatch(custom(isCartTab, SET_CART_TAB));
  };
};

const setSelectedShutterBz = (data) => {
  return (dispatch) => {
    dispatch(custom(data, SELECTED_SHUTTER_BS));
  };
};

const checkOut = (data) => {
  return (dispatch) => {
    dispatch(custom(CHECK_OUT, globalSystemConstants.SPINNER_ACTIVATE));
    eventBookingServices
      .checkOut(data)
      .then(
        (response) => {
          if (response) {
            dispatch(custom(response, CHECK_OUT));
          }
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, CHECK_OUT));
        }
      )
      .finally(() => {
        dispatch(custom(CHECK_OUT, globalSystemConstants.SPINNER_DIACTIVATE));
      });
  };
};

const requestBooking = (data, orderItemGroupId) => {
  return (dispatch) => {
    dispatch(custom(REQUEST_BOOKING, globalSystemConstants.SPINNER_ACTIVATE));
    eventBookingServices
      .requestBooking(data, orderItemGroupId)
      .then(
        (response) => {
          localStorage.removeItem("dataToSend");
          localStorage.removeItem("activeTab");
          localStorage.removeItem(eventBookingConstants.EVENT_DETAIL_STORED);
          dispatch(custom(response, REQUEST_BOOKING));
        },
        (error) => {
          Array.isArray(error)
            ? error[0]
              ? customToast(i18next.t(error[0]), toast.TYPE.ERROR)
              : customToast("Something went wrong", toast.TYPE.ERROR)
            : customToast(i18next.t(error), toast.TYPE.ERROR);
          localStorage.removeItem("dataToSend");
          localStorage.removeItem("activeTab");
          localStorage.removeItem(eventBookingConstants.EVENT_DETAIL_STORED);

          dispatch(failure(error, REQUEST_BOOKING));
        }
      )
      .finally(() => {
        dispatch(custom({}, SET_FORM_STATE));
        dispatch(custom([], SELECTED_SHUTTER_BS));
        dispatch(custom(false, SET_CART_TAB));
        dispatch(custom([], customerConstants.UPDATE_CUSTOMER_DASHBOARD_CART));
        dispatch(custom({}, SET_BULK_REEL_VALUES));
        dispatch(custom({}, SET_BULK_EVENT_DETAIL_VALUES));
        const tempToken = localStorage.getItem(process.env.REACT_APP_PAYLOAD);
        localStorage.clear();
        localStorage.setItem(process.env.REACT_APP_PAYLOAD, tempToken);
        dispatch(
          custom(REQUEST_BOOKING, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};

const getReelPrice = () => {
  return (dispatch) => {
    dispatch(custom(null, globalSystemConstants.SPINNER_ACTIVATE));
    eventBookingServices
      .getReelPrice()
      .then(
        (response) => {
          dispatch(success(response, GET_REEL_PRICE));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
        }
      )
      .finally(() => {
        dispatch(custom(null, globalSystemConstants.SPINNER_DIACTIVATE));
      });
  };
};

const getMiscAmounts = () => {
  return (dispatch) => {
    dispatch(custom(GET_MISC_AMOUNTS, globalSystemConstants.SPINNER_ACTIVATE));
    eventBookingServices
      .getMiscAmounts()
      .then(
        (response) => {
          dispatch(custom(response, GET_MISC_AMOUNTS));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, GET_MISC_AMOUNTS));
        }
      )
      .finally(() => {
        dispatch(
          custom(GET_MISC_AMOUNTS, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};

const applyCoupon = (
  dataObj,
  promoCodeDiscount,
  setPromoCodeDiscount,
  subTotal,
  fee,
  total
) => {
  return (dispatch) => {
    dispatch(custom(GET_MISC_AMOUNTS, globalSystemConstants.SPINNER_ACTIVATE));
    eventBookingServices
      .applyCoupon(dataObj)
      .then(
        (response) => {
          setPromoCodeDiscount(response?.items);
          customToast(
            i18next.t("Discount successfully applied"),
            toast.TYPE.SUCCESS
          );
        },
        (error) => {
          setPromoCodeDiscount();
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
        }
      )
      .finally(() => {
        dispatch(
          custom(GET_MISC_AMOUNTS, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};
const draftEventDetails = (dataObj) => {
  return (dispatch) => {
    dispatch(custom(null, globalSystemConstants.SPINNER_ACTIVATE));
    eventBookingServices
      .draftEventDetails(dataObj)
      .then(
        (response) => {
          customToast("Submitted successfully.", toast.TYPE.SUCCESS);
          NavigateToPathWithGo(history, "/draft-event-thank-you");
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          // customToast("Submitted successfully.", toast.TYPE.SUCCESS);
        }
      )
      .finally(() => {
        localStorage.removeItem("dataToSend");
        localStorage.removeItem("activeTab");
        localStorage.removeItem(customerConstants.DATA_TO_SEND_EXTEND_SB);
        localStorage.removeItem(customerConstants.NOT_NEW_EVENT);
        localStorage.removeItem(customerConstants.CART_PREVIOUS_SCREEN);
        localStorage.removeItem(eventBookingConstants.REEL_STORED);
        localStorage.removeItem(eventBookingConstants.EVENT_DETAIL_STORED);
        localStorage.removeItem(
          customerConstants.STORED_CUSTOMER_DASHBOARD_CART
        );
        localStorage.removeItem(customerConstants.STORED_EVENT_DETAILS);
        dispatch({
          type: customerConstants.UPDATE_CUSTOMER_DASHBOARD_CART,
          data: [],
        });
        dispatch(custom({}, SET_FORM_STATE));
        // dispatch(custom(null, globalSystemConstants.SPINNER_DIACTIVATE));
      });
  };
};

const getDraftEventSbList = (setModel, eventId) => {
  return (dispatch) => {
    dispatch(custom(GET_MISC_AMOUNTS, globalSystemConstants.SPINNER_ACTIVATE));
    eventBookingServices
      .getDraftEventSbList(eventId)
      .then(
        (response) => {
          dispatch(custom(response.items.suggestedSBz, SELECTED_SHUTTER_BS));
          setModel({
            id: eventId,
            eventname: response.items.eventName,
            eventdate: response.items.eventDate,
            duration: response.items.duration,
            event_start_time: response.items.eventStartTime,
            shutterb_start_time: response.items.sbstartTime,
            ...response.items,
          });
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, GET_MISC_AMOUNTS));
        }
      )
      .finally(() => {
        dispatch(
          custom(GET_MISC_AMOUNTS, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};

const getReelTypes = () => {
  return (dispatch) => {
    dispatch(custom(GET_REEL_TYPES, globalSystemConstants.SPINNER_ACTIVATE));
    eventBookingServices
      .getReelTypes()
      .then(
        (response) => {
          dispatch(custom(response, GET_REEL_TYPES));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
        }
      )
      .finally(() => {
        dispatch(
          custom(GET_REEL_TYPES, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};

const uploadMusicFile = (formData, fileName) => {
  return (dispatch) => {
    dispatch(custom(null, globalSystemConstants.SPINNER_ACTIVATE));
    eventBookingServices
      .uploadMusicFile(formData)
      .then(
        (response) => {
          console.log("response", response);
          // dispatch(success(response, UPLOAD_MUSIC_FILE));
          dispatch({
            type: SET_REEL_VALUES,
            data: {
              name: ADD_REEL_STATES.MUSIC_FILE,
              value: {
                filePath: response.items,
                preview: fileName,
              },
            },
          });
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
        }
      )
      .finally(() => {
        dispatch(custom(null, globalSystemConstants.SPINNER_DIACTIVATE));
      });
  };
};

export const eventBookingActions = {
  checkOut,
  setCartTab,
  getShotList,
  setFormState,
  getEventTypes,
  requestBooking,
  setSelectedShutterBz,
  getAvailableShutterBs,
  getReelPrice,
  getMiscAmounts,
  applyCoupon,
  draftEventDetails,
  getDraftEventSbList,
  getReelTypes,
  uploadMusicFile,
};
