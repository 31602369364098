import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { withRouter, useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { loginActions } from "../../../redux/actions";
import { useTranslation } from "react-i18next";
import Cart from "./Tabs/Cart";
import EventSetup from "./Tabs/EventSetup";
import SelectShutterB from "./Tabs/SelectShutterB";
import AddReel from "./Tabs/AddReel";
import { eventBookingActions } from "../../../redux/actions/eventBooking.actions";
import { eventBookingConstants, customerConstants } from "../../../constants";
import UndoCart from "./Tabs/UndoCart";
import { customToast } from "../../../shared";
import { custom } from "../../../redux/actions/utilities";
import EventDetail from "./Tabs/EventDetail";

const EventBookingForm = (props) => {
  const { t } = useTranslation("common");
  const history = useHistory();
  const previousAffiliateCode = localStorage.getItem("affiliateCode");
  const { isDraftEvent } = useParams();
  const { TABS } = eventBookingConstants;
  const [activeTab, setActiveTab] = useState(
    props.isAuthenticated && props.cartTab
      ? TABS.CART
      : Number(isDraftEvent) === TABS.CART
      ? TABS.CART
      : JSON.parse(localStorage.getItem("activeTab"))
      ? JSON.parse(localStorage.getItem("activeTab"))
      : TABS.EVENT_SETUP
  );
  const [model, setmodel] = useState(props.formState);
  const affiliateCode = localStorage.getItem("affiliateCode");
  const partnerCodeUrlParam = new URLSearchParams(history.location.search).get(
    "partnerCode"
  );
  const { eventId } = useParams();
  const handleNext = () => {
    setActiveTab((activeTab) => activeTab + 1);
  };

  const handleBack = () => {
    setActiveTab((activeTab) => activeTab - 1);
  };

  const handleCart = () => {
    setActiveTab(TABS.CART);
  };

  useEffect(() => {
    if (eventId !== undefined && eventId !== null && affiliateCode !== null) {
      history.push(`?partnerCode=${affiliateCode}`);
    } else if (partnerCodeUrlParam !== null) {
      localStorage.setItem("affiliateCode", partnerCodeUrlParam);
      if (previousAffiliateCode !== partnerCodeUrlParam) {
        window.location.href = process.env.REACT_APP_MARKETING_SITE_BASE_URL;
      } else {
        history.push(
          `${process.env.REACT_APP_MARKETING_EVENT_BOOKING_URL}${partnerCodeUrlParam}`
        );
      }
    } else if (affiliateCode !== null) {
      // Use history.push() for redirection
      history.push(
        `${process.env.REACT_APP_MARKETING_EVENT_BOOKING_URL}${affiliateCode}`
      );
    }
    // eslint-disable-next-line
  }, [history.location.search]);

  useEffect(() => {
    const existingData = localStorage.getItem("dataToSend");
    const parsedData = JSON.parse(existingData);
    if (parsedData?.Event) {
      // Save the model state to the local storage
      parsedData.Event = {
        ...model,
        ...parsedData.Event,
      };
    }
  }, [model]);

  useEffect(() => {
    if (localStorage.hasOwnProperty("isDraftEventCart") && !isDraftEvent) {
      props.emptyCartCauseOfDraftCart();
      const tempToken = localStorage.getItem(process.env.REACT_APP_PAYLOAD);
      localStorage.clear();
      localStorage.setItem(process.env.REACT_APP_PAYLOAD, tempToken);
      setmodel({});
      window.location.reload();
    }
    const parsedData = JSON.parse(localStorage.getItem("dataToSend"));
    const dataToSend = {
      Event: {
        notes:
          parsedData?.Event.additionalNotes !== undefined
            ? parsedData?.Event.additionalNotes
            : null,
        affiliateCode:
          partnerCodeUrlParam !== null ? partnerCodeUrlParam : affiliateCode,
        Order: {
          orderItems: [],
        },
      },
    };
    localStorage.setItem("dataToSend", JSON.stringify(dataToSend));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const existingData = localStorage.getItem("dataToSend");
    const parsedData = JSON.parse(existingData);
    if (parsedData?.Event) {
      parsedData.Event.Order.orderItems = props.selectedShutterBz;
    }

    // Save the orderItems to localStorage
    localStorage.setItem("dataToSend", JSON.stringify(parsedData));
    // eslint-disable-next-line
  }, [props.selectedShutterBz]);

  useEffect(() => {
    const retrievedData = localStorage.getItem("dataToSend");
    if (!retrievedData) {
      const dataToSend = {
        Event: {
          Order: {
            orderItems: [],
          },
        },
      };
      localStorage.setItem("dataToSend", JSON.stringify(dataToSend));
    }
    const parsedData = JSON.parse(retrievedData);

    if (parsedData?.Event) {
      const parsedModel = parsedData.Event;
      parsedModel.eventDate = Date.parse(model.eventdate) / 1000;

      //const date = new Date(); // Current date
      if (model.event_start_time !== null) {
        const epochTime = Date.parse(model.event_start_time) / 1000;
        parsedModel.EventStartTime = epochTime;
      }

      if (model.shutterb_start_time !== null) {
        //const [hours, minutes] = model.shutterb_start_time.split(":");
        //date.setHours(hours, minutes, 0, 0);
        //const epochTime = date.getTime() / 1000;
        const epochTime = Date.parse(model.shutterb_start_time) / 1000;
        parsedModel.sbStartTime = epochTime;
      }
      parsedModel.IsActive = 1;
      parsedModel.EventTypeId = parseInt(model.eventtype);
      parsedModel.additionalNotes = model.additionalNotes;
      parsedModel.eventLocation = model.eventlocation;
      parsedModel.apartment = model.apartment;
      parsedModel.UserId = props.userId;
      parsedModel.eventName = model.eventname;
      parsedModel.duration = model.duration;
      parsedModel.privacyPolicy = model.privacyPolicy;
      parsedModel.termsAndConditions = model.termsAndConditions;
      parsedModel.latLng = model?.latLng ? model?.latLng : null;
      parsedModel.postalCode = model?.postalCode ? model?.postalCode : null;
      parsedModel.contactname = model.contactname;
      parsedModel.phoneNumber = model.phoneNumber;
      parsedModel.isConsentForMarketing = model.isConsentForMarketing;
      parsedModel.isGallerySharedPermission = model.isGallerySharedPermission;
      const {
        event_start_time,
        eventdate,
        eventlocation,
        eventname,
        eventtype,
        // latLng,
        shutterb_start_time,
        ...filteredModel
      } = parsedModel;

      const dataToSend = {
        Event: {
          ...filteredModel,
        },
      };
      localStorage.setItem("dataToSend", JSON.stringify(dataToSend));
    }
    // eslint-disable-next-line
  }, [model, props.selectedShutterBz]);

  // const toBase64 = (file) =>
  //   new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => resolve(reader.result);
  //     reader.onerror = reject;
  //   });

  const userCheckOut = async (isFromNewEvent = true, dataObj) => {
    if (isFromNewEvent) {
      const savedData = localStorage.getItem("dataToSend");
      const parsedData = JSON.parse(savedData);
      parsedData.Event.duration = parseInt(parsedData.Event.duration);
      parsedData.Event.SBStartTime = parsedData.Event.shutterbStartTime;
      parsedData.Event.EventQuestionAnswers = [
        {
          questionTypeId: 5,
          answer: props.addEventDetail.eventQuestion1,
        },
        {
          questionTypeId: 7,
          answer: props.addEventDetail.eventQuestion2,
        },
        {
          questionTypeId: 8,
          answer: props.addEventDetail.eventQuestion3,
        },
        {
          questionTypeId: 9,
          answer: props.addEventDetail.eventQuestion4,
        },
      ];
      if (typeof parsedData.Event.eventLocation === "string") {
        parsedData.Event.eventLocation = {
          currentLocation: parsedData.Event.eventLocation,
          longitude: parsedData.Event.latLng
            ? parsedData.Event.latLng.lng
            : 345475675,
          latitude: parsedData.Event.latLng
            ? parsedData.Event.latLng.lat
            : 3454645,
        };
      }
      if (parsedData.Event.latLng) {
        delete parsedData.Event.latLng; // Remove the "latLng" parameter
      }
      if (props?.selectedShutterBz?.length) {
        parsedData.Event.Order.orderItems = [
          ...props.selectedShutterBz.map((shutterB) => ({
            ...shutterB,
            quantity: parseInt(model.duration),
            OrderItemTypeId: 2,
            SbuserId: shutterB?.id,
            Price: shutterB?.hourlyRate ? parseFloat(shutterB.hourlyRate) : 0,
          })),
        ];
      } else {
        parsedData.Event = {
          ...parsedData.Event,
          id: localStorage.getItem("reelEventId"),
          eventDate: 0,
          duration: 0,
          status: "reelOnly",
        };
        parsedData.Event.Order = {
          ...parsedData.Event.Order,
          id: localStorage.getItem("reelOrderId"),
          orderItems: [],
        };
      }

      // storing reel item in payload if reel is selected--------reel
      parsedData.Event.Order =
        props.addReel.isReelWithoutLogo || props.addReel.isReelWithLogo
          ? {
              ...parsedData.Event.Order,
              reelQuestionAnswers: [
                {
                  questionTypeId: 6,
                  answer: props.addReel.reelQuestion1,
                },
              ],
            }
          : {
              ...parsedData.Event.Order,
              reelQuestionAnswers: null,
            };
      // props.addReel.isReelSubmitted &&
      //   props.addReel.isReelWithoutLogo &&
      //   parsedData.Event.Order.orderItems.push({
      //     orderItemTypeId: eventBookingConstants.ORDER_ITEM_TYPE_ID.REEL,
      //     price: props.addReel.reelPriceWithoutLogo,
      //     quantity: props.addReel.reelQtyWithoutLogo,
      //     reelDetail: {
      //       logoUrl: null,
      //       music: props.addReel.music,
      //       orientation: props.addReel.orientation,
      //     },
      //     tipPrice: 0,
      //     sbuserId: null,
      //     isActive: false,
      //     paymentStatus: false,
      //   });
      props.addReel.isReelSubmitted &&
        props.addReel.isReelWithLogo &&
        parsedData.Event.Order.orderItems.push({
          orderItemTypeId: eventBookingConstants.ORDER_ITEM_TYPE_ID.REEL,
          price: props.addReel.reelPriceWithLogo,
          quantity: props.addReel.reelQtyWithLogo,
          reelDetail: {
            logoUrl: props.addReel.logo.raw,
            musicFilePath: props.addReel.musicFile.filePath,
            music: props.addReel.music,
            orientation: props.addReel.orientation,
            reelType: props.addReel.reelType,
          },
          tipPrice: 0,
          sbuserId: null,
          isActive: false,
          paymentStatus: false,
        });
      // --------reel ends

      if (props.isAuthenticated) {
        // if (parsedData?.Event?.Order) {
        //   if (parsedData?.Event?.Order?.totalAmount < 2) {
        //     customToast(t("minimum_order_should_be"), toast.TYPE.ERROR);
        //     return;
        //   }
        // }
        props.checkOut(parsedData);
        // removing reel item to avoid storing it in the local storage--------reel
        // props.addReel.isReelWithoutLogo &&
        //   parsedData.Event.Order.orderItems.pop();
        props.addReel.isReelSubmitted &&
          props.addReel.isReelWithLogo &&
          parsedData.Event.Order.orderItems.pop();
        // --------reel ends
        localStorage.removeItem("dataToSend");
        localStorage.setItem("dataToSend", JSON.stringify(parsedData));
      }
    } else {
      if (props.isAuthenticated) {
        if (dataObj?.Event?.Order) {
          if (dataObj?.Event?.Order?.totalAmount < 2) {
            customToast(t("minimum_order_should_be"), toast.TYPE.ERROR);
            return;
          }
        }
        props.checkOut(dataObj);
      }
    }
  };

  useEffect(() => {
    if (props.url) {
      //window.location.replace(props.url);
      window.location.href = props.url;
    }
    // eslint-disable-next-line
  }, [props.url]);

  return (
    // {
    previousAffiliateCode === partnerCodeUrlParam ||
      (eventId !== undefined && eventId !== null) ? (
      <section className="pt-0">
        <UndoCart />
        <div
          className={`container pt-4 ${
            activeTab === TABS.EVENT_DETAIL
              ? "event-detail"
              : activeTab === TABS.SELECT_SHUTTERB
              ? "select-shutterb"
              : activeTab === TABS.ADD_REEL
              ? "add-reel"
              : activeTab === TABS.CART
              ? "cart"
              : ""
          }`}
        >
          <div className="booking text-center">
            <h1 className="d-flex justify-content-center pb-4">
              {activeTab === TABS.EVENT_DETAIL ||
              activeTab === TABS.SELECT_SHUTTERB ||
              activeTab === TABS.CART
                ? t("book_your_event")
                : t("event_booking_form")}
            </h1>
            <div>
              <ul className="mt-4">
                <li>{t("event_setup")}</li>
                <li>{t("event_detail")}</li>
                <li>{t("select_shutterb")}</li>
                <li>{t("add_reel")}</li>
                <li>{t("cart")}</li>
                <li>{t("payment")}</li>
              </ul>
            </div>
          </div>
          {activeTab === TABS.EVENT_DETAIL ? (
            <EventDetail onBack={handleBack} onNext={handleNext} />
          ) : activeTab === TABS.SELECT_SHUTTERB ? (
            <SelectShutterB
              model={model}
              onBack={handleBack}
              onNext={handleNext}
              handleCart
            />
          ) : activeTab === TABS.EVENT_SETUP ? (
            <EventSetup model={model} setmodel={setmodel} onNext={handleNext} />
          ) : activeTab === TABS.ADD_REEL ? (
            <AddReel onBack={handleBack} onNext={handleCart} />
          ) : activeTab === TABS.CART ? (
            <Cart
              model={model}
              setmodel={setmodel}
              onBack={handleBack}
              onCheckOut={userCheckOut}
            />
          ) : null}
        </div>
      </section>
    ) : (
      <div style={{ height: "70vh" }}>
        <div className="spinner d-flex justify-content-center align-items-center">
          <div
            class="spinner-grow"
            style={{ width: "3rem", height: "3rem" }}
          ></div>
        </div>
      </div>
    )
    // }
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  userId: state.authentication.userId,
  isAuthenticated: state.authentication.isAuthenticated,
  formState: state.eventBooking.formState,
  cartTab: state.eventBooking.cartTab,
  url: state.eventBooking.url,
  selectedShutterBz: state.eventBooking.selectedShutterBz,
  customerDashboardCart: state?.customerDashboard?.customerDashboardCart,
  addReel: state.eventBooking.addReel,
  addEventDetail: state.eventBooking.addEventDetail,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  checkOut: (data) => dispatch(eventBookingActions.checkOut(data)),
  login: (data) => dispatch(loginActions.login(data)),
  emptyCartCauseOfDraftCart: () => {
    dispatch(custom({}, eventBookingConstants.SET_FORM_STATE));
    dispatch(custom([], eventBookingConstants.SELECTED_SHUTTER_BS));
    dispatch(custom(false, eventBookingConstants.SET_CART_TAB));
    dispatch(custom([], customerConstants.UPDATE_CUSTOMER_DASHBOARD_CART));
    dispatch(custom({}, eventBookingConstants.SET_BULK_REEL_VALUES));
  },
});

// mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EventBookingForm));
