import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { connect } from "react-redux";
import MapMarker from "mdi-react/MapMarkerIcon";
import { withRouter } from "react-router";
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";

import { customerDashboardActions } from "../../../../../redux/actions";
import { customToast } from "../../../../../shared";
import { toast } from "react-toastify";
import {
  configurationsConstants,
  passwordRegexConstant,
} from "../../../../../constants";

const AccountInfo = (props) => {
  const { t } = useTranslation("common");
  const [, setAutocompleteSuggestions] = useState([]);
  const { ...myAccountInfo } = props?.myAccountInfo;
  const [isPasswordSame, setIsPasswordSame] = useState(true);
  const [fieldResetKey, setFieldResetKey] = useState(1);
  const [address, setAddress] = useState(null);
  const [city, setCity] = useState(null);
  const [postalCode, setPostalCode] = useState(null);
  const [province, setProvince] = useState(null);
  const [country, setCountry] = useState(null);
  let isSelected = useRef(false);
  useEffect(() => {
    props.getMyAccountInfo(props.userId);
    // eslint-disable-next-line
  }, []);

  const handleSelect = async (address, placeId) => {
    setAddress(address);
    isSelected.current = true;
    const [place] = await geocodeByPlaceId(placeId);
    const { long_name: postalCode = "" } =
      place.address_components.find((c) => c.types.includes("postal_code")) ||
      {};
    const { long_name: city = "" } =
      place.address_components.find((c) => c.types.includes("locality")) || {};
    const { long_name: province = "" } =
      place.address_components.find((c) =>
        c.types.includes("administrative_area_level_1")
      ) || {};
    const { long_name: country = "" } =
      place.address_components.find((c) => c.types.includes("country")) || {};

    setCity(city);
    setPostalCode(postalCode);
    setProvince(province);
    setCountry(country);
  };
  const handlePersonalInfoSubmit = async (event, values) => {
    if (address !== null) {
      if (!isSelected.current) {
        customToast("Please select diffrent address.", toast.TYPE.ERROR);
        return;
      }
    }
    geocodeByAddress(address ? address : values?.currentAddress)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        const dataObj = {
          // sbLocation = address ? address : values.sbLocation
          ...values,
          id: props.userId,
          currentAddress: address ? address : values?.currentAddress,
          longitude: lng,
          latitude: lat,
        };
        props.updateMyAccountInfo(props.userId, dataObj);
      });
  };

  const handlePasswordSubmit = (event, values) => {
    if (values.newPassword !== values.confirmNewPassword) {
      setIsPasswordSame(false);
      return;
    } else {
      setIsPasswordSame(true);
    }
    // it empty the input fields after successful submit
    setFieldResetKey((fieldResetKey) => fieldResetKey + 1);

    const dataObj = {
      id: props.userId,
      currentPassword: values.currentPassword,
      newPassword: values.newPassword,
    };
    props.updateCustomerPassword(props.userId, dataObj);
  };

  return (
    <div className="my-account-tab container-fluid">
      <div className="row">
        <div className="col px-0 my-account-heading">
          <h5 className="p-0 m-0 mb-2">Account Info</h5>
        </div>
      </div>
      <div className="row">
        {/* personal info form */}
        <AvForm
          id="my-info-form"
          className="col-sm-5 px-0 py-4"
          onValidSubmit={handlePersonalInfoSubmit}
          noValidate
        >
          <h5 className="p-0 m-0 mb-2">Personal Info</h5>
          <div id="form-content">
            <div className="row">
              <div className="col-lg-12">
                <label htmlFor="firstName">{t("first_name")}</label>
                <AvField
                  name="firstName"
                  value={myAccountInfo?.firstName}
                  type="text"
                  maxLength={35}
                  pattern="^[a-z,A-Z].{0,35}$"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: t("this_field_is_required"),
                    },
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <label htmlFor="lastName">{t("last_name")}</label>
                <AvField
                  name="lastName"
                  value={myAccountInfo?.lastName}
                  type="text"
                  maxLength={35}
                  pattern="^[a-z,A-Z].{0,35}$"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: t("this_field_is_required"),
                    },
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <label htmlFor="email">{t("email_address")}</label>
                <AvField
                  name="email"
                  value={myAccountInfo?.email}
                  type="email"
                  disabled
                  validate={{
                    required: {
                      value: true,
                      errorMessage: t("this_field_is_required"),
                    },
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <label htmlFor="phoneNumber">{t("phone_number")}</label>
                <AvField
                  name="phoneNumber"
                  type="number"
                  value={myAccountInfo?.phoneNumber}
                  pattern="^[0-9].{8,9}$"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: t("this_field_is_required"),
                    },
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <label htmlFor="currentAddress">{t("address")}</label>
                <div className="places-autocomplete-container">
                  <PlacesAutocomplete
                    value={
                      myAccountInfo?.currentAddress && address === null
                        ? myAccountInfo.currentAddress
                        : address
                    }
                    onChange={(e) => {
                      setAddress(e);
                      isSelected.current = false;
                    }}
                    onSelect={handleSelect}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => {
                      if (suggestions.length > 0) {
                        setAutocompleteSuggestions(suggestions);
                      }
                      return (
                        <div>
                          <AvField
                            placeholder={t("enter_a_location")}
                            name="currentAddress"
                            type="address"
                            value={address}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: t("this_field_is_required"),
                              },
                            }}
                            {...getInputProps()}
                          />
                          <div className="address-suggestions-con px-2">
                            {loading ? <div>{t("loading")}</div> : null}
                            {suggestions.map((suggestion, index) => {
                              const style = {
                                backgroundColor: suggestion.active
                                  ? "#eaeaea"
                                  : "#fff",
                              };
                              return (
                                <>
                                  <div
                                    key={index}
                                    className="address-suggestions"
                                    {...getSuggestionItemProps(suggestion, {
                                      style,
                                    })}
                                  >
                                    <MapMarker className="map-icon" />
                                    {suggestion.description}
                                  </div>
                                  <hr className="p-0 m-0" />
                                </>
                              );
                            })}
                            {suggestions.length > 0 && (
                              <div className="google-trademark p-0 m-0">
                                powered by
                                <div className="google-svg-con">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="250"
                                    width="400"
                                    viewBox="-40.446 -22.19 350.532 133.14"
                                  >
                                    <path
                                      d="M115.39 46.71c0 12.77-9.99 22.18-22.25 22.18s-22.25-9.41-22.25-22.18c0-12.86 9.99-22.18 22.25-22.18s22.25 9.32 22.25 22.18zm-9.74 0c0-7.98-5.79-13.44-12.51-13.44s-12.51 5.46-12.51 13.44c0 7.9 5.79 13.44 12.51 13.44s12.51-5.55 12.51-13.44z"
                                      fill="#EA4335"
                                    />
                                    <path
                                      d="M163.39 46.71c0 12.77-9.99 22.18-22.25 22.18s-22.25-9.41-22.25-22.18c0-12.85 9.99-22.18 22.25-22.18s22.25 9.32 22.25 22.18zm-9.74 0c0-7.98-5.79-13.44-12.51-13.44s-12.51 5.46-12.51 13.44c0 7.9 5.79 13.44 12.51 13.44s12.51-5.55 12.51-13.44z"
                                      fill="#FBBC05"
                                    />
                                    <path
                                      d="M209.39 25.87v39.82c0 16.38-9.66 23.07-21.08 23.07-10.75 0-17.22-7.19-19.66-13.07l8.48-3.53c1.51 3.61 5.21 7.87 11.17 7.87 7.31 0 11.84-4.51 11.84-13v-3.19h-.34c-2.18 2.69-6.38 5.04-11.68 5.04-11.09 0-21.25-9.66-21.25-22.09 0-12.52 10.16-22.26 21.25-22.26 5.29 0 9.49 2.35 11.68 4.96h.34v-3.61h9.25zm-8.56 20.92c0-7.81-5.21-13.52-11.84-13.52-6.72 0-12.35 5.71-12.35 13.52 0 7.73 5.63 13.36 12.35 13.36 6.63 0 11.84-5.63 11.84-13.36z"
                                      fill="#4285F4"
                                    />
                                    <path
                                      d="M224.64 2.53v65h-9.5v-65z"
                                      fill="#34A853"
                                    />
                                    <path
                                      d="M261.66 54.01l7.56 5.04c-2.44 3.61-8.32 9.83-18.48 9.83-12.6 0-22.01-9.74-22.01-22.18 0-13.19 9.49-22.18 20.92-22.18 11.51 0 17.14 9.16 18.98 14.11l1.01 2.52-29.65 12.28c2.27 4.45 5.8 6.72 10.75 6.72 4.96 0 8.4-2.44 10.92-6.14zm-23.27-7.98l19.82-8.23c-1.09-2.77-4.37-4.7-8.23-4.7-4.95 0-11.84 4.37-11.59 12.93z"
                                      fill="#EA4335"
                                    />
                                    <path
                                      d="M34.93 40.94v-9.41h31.71c.31 1.64.47 3.58.47 5.68 0 7.06-1.93 15.79-8.15 22.01-6.05 6.3-13.78 9.66-24.02 9.66C15.96 68.88 0 53.42 0 34.44 0 15.46 15.96 0 34.94 0c10.5 0 17.98 4.12 23.6 9.49l-6.64 6.64c-4.03-3.78-9.49-6.72-16.97-6.72-13.86 0-24.7 11.17-24.7 25.03 0 13.86 10.84 25.03 24.7 25.03 8.99 0 14.11-3.61 17.39-6.89 2.66-2.66 4.41-6.46 5.1-11.65z"
                                      fill="#4285F4"
                                    />
                                  </svg>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    }}
                  </PlacesAutocomplete>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-xl-6">
                <label htmlFor="city">{t("city")}</label>
                <AvField
                  name="city"
                  value={
                    myAccountInfo?.city && city === null
                      ? myAccountInfo.city
                      : city
                  }
                  type="text"
                  maxLength={70}
                  pattern="^[a-z,A-Z].{1,70}$"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: t("this_field_is_required"),
                    },
                  }}
                />
              </div>
              <div className="col-xl-6">
                <label htmlFor="postalCode">{t("postal_code")}</label>
                <AvField
                  name="postalCode"
                  value={
                    myAccountInfo?.postalCode && postalCode === null
                      ? myAccountInfo.postalCode
                      : postalCode
                  }
                  type="text"
                  maxLength={20}
                  pattern="^[a-z,A-Z,0-9].{1,20}$"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: t("this_field_is_required"),
                    },
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <label htmlFor="province">{t("province")}</label>
                <AvField
                  name="province"
                  value={
                    myAccountInfo?.province && province === null
                      ? myAccountInfo.province
                      : province
                  }
                  type="text"
                  maxLength={90}
                  pattern="^[a-z,A-Z].{1,90}$"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: t("this_field_is_required"),
                    },
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <label htmlFor="country">{t("country")}</label>
                <AvField
                  name="country"
                  value={
                    myAccountInfo?.country && country === null
                      ? myAccountInfo.country
                      : country
                  }
                  type="text"
                  maxLength={100}
                  pattern="^[a-z,A-Z].{1,100}$"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: t("this_field_is_required"),
                    },
                  }}
                />
              </div>
            </div>

            <div className="container">
              <div className="row pt-4">
                <div className="shutterb-portfolio-btn">
                  <button type="submit" className="btn-simple">
                    {t("update")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </AvForm>

        <div className="col-1 mx-auto d-none d-sm-flex justify-content-center align-items-center py-5 px-0">
          <hr className="account-form-devider" />
        </div>

        {/* change password form */}
        <AvForm
          id="my-info-form"
          className="col-sm-5 px-0 py-4"
          onValidSubmit={handlePasswordSubmit}
          noValidate
          key={String(fieldResetKey)}
        >
          <h5 className="p-0 m-0 mb-2">Change Password</h5>
          <div id="form-content">
            <div className="row">
              <div className="col-lg-12">
                <label htmlFor="currentPassword">{t("current_password")}</label>
                <AvField
                  name="currentPassword"
                  type="password"
                  maxLength={configurationsConstants.PASSWORD_INPUT_LENGTH}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: t("This Field is Required"),
                    },
                    pattern: {
                      value: `${passwordRegexConstant.PASSWORD_REGEX}`,
                      errorMessage: t("password_instruction_error"),
                    },
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <label htmlFor="newPassword">{t("new_password")}</label>
                <AvField
                  name="newPassword"
                  type="password"
                  maxLength={configurationsConstants.PASSWORD_INPUT_LENGTH}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: t("This Field is Required"),
                    },
                    pattern: {
                      value: `${passwordRegexConstant.PASSWORD_REGEX}`,
                      errorMessage: t("password_instruction_error"),
                    },
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <label htmlFor="confirmNewPassword">
                  {t("confirm_new_password")}
                </label>
                <AvField
                  name="confirmNewPassword"
                  type="password"
                  maxLength={configurationsConstants.PASSWORD_INPUT_LENGTH}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: t("This Field is Required"),
                    },
                    pattern: {
                      value: `${passwordRegexConstant.PASSWORD_REGEX}`,
                      errorMessage: t("password_instruction_error"),
                    },
                  }}
                  onChange={() => {
                    !isPasswordSame && setIsPasswordSame(true);
                  }}
                />
                {!isPasswordSame && (
                  <p className="custom-error p-0 m-0">
                    Password & Confirm Password does not match.
                  </p>
                )}
              </div>
            </div>

            <div className="container">
              <div className="row pt-4">
                <div className="shutterb-portfolio-btn">
                  <button type="submit" className="btn-simple">
                    {t("update")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </AvForm>
      </div>
    </div>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  userId: state.authentication.userId,
  myAccountInfo: state.customerDashboard.myAccountInfo,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  updateCustomerPassword: (userId, dataObj) =>
    dispatch(customerDashboardActions.updateCustomerPassword(userId, dataObj)),
  getMyAccountInfo: (userId) =>
    dispatch(customerDashboardActions.myAccountInfo(userId)),
  updateMyAccountInfo: (userId, dataObj) =>
    dispatch(customerDashboardActions.updateMyAccountInfo(userId, dataObj)),
});

// mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AccountInfo));
