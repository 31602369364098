import React, { useState, useEffect } from "react";
import i18next from "i18next";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import ImageUploading from "react-images-uploading";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import { shutterbDashboardActions } from "../../../../../redux/actions";
import { customToast, convertHeicToJpeg } from "../../../../../shared/utility";
import {
  shutterbDashboardConstants,
  globalSystemConstants,
  ALLOWED_PORTFOLIO_FORMATS,
} from "../../../../../constants";

const SortableImage = sortableElement(({ image, onRemove }) => (
  <div className="file-item col-4 col-sm-3 px-0">
    {/* eslint-disable-next-line */}
    <a
      href="#"
      className="cross-icon"
      onClick={() => {
        onRemove(image);
      }}
    >
      x
    </a>
    <div className="aspect-ratio-container">
      <img src={image.data_url} alt="" className="file-content" style={{cursor: "grab"}} />
    </div>
  </div>
));

const SortableImageList = sortableContainer(
  ({ images, onRemove, ...props }) => {
    return (
      <div className="file-preview col-12 px-0" style={{overflow:"hidden"}}>
        {images.map((image, index) => (
          <SortableImage
            key={index}
            index={index}
            image={image}
            onRemove={onRemove}
          />
        ))}
        <div
          onClick={props.onImageUpload}
          {...props.dragProps}
          className="file-item col-4 col-sm-3 px-0"
        >
          <div className="aspect-ratio-container">
            <img
              src={shutterbDashboardConstants.UPLOAD_IMAGE_URL}
              alt=""
              className="file-content cursor-point"
            />
          </div>
        </div>
      </div>
    );
  }
);

function MyPortfolioGallery({ images, setImages, ...props }) {
  const maxNumber = 30;
  const [reOrderedArray, setReOrderedArray] = useState([]);
  const [isReordered, setIsReordered] = useState(false);

  function arraymove(arr, fromIndex, toIndex) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
    return arr;
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    let reOrderedArrayTemp = arraymove(images, oldIndex, newIndex);
    setReOrderedArray(reOrderedArrayTemp);
    setIsReordered(!isReordered);
  };

  useEffect(() => {
    setImages(reOrderedArray);
    //eslint-disable-next-line
  }, [reOrderedArray, isReordered]);

  const onChange = async (imageList, addUpdateIndex) => {
    if (
      imageList.length <=
      shutterbDashboardConstants.SB_PORTFOLIO_MAX_IMAGE_COUNT
    ) {
      const convertedImages = await Promise.all(
        imageList.map(async (image) => {
          const fileExtension = image?.file?.name
            ?.split(".")
            .pop()
            .toLowerCase();
          if (fileExtension === "heic" || fileExtension === "heif") {
            props.activateSpinner();
            const convertedFile = await convertHeicToJpeg(image.file);
            return {
              ...image,
              file: convertedFile,
              data_url: URL.createObjectURL(convertedFile),
            };
          } else {
            return image;
          }
        })
      );
      setImages(convertedImages);
      props.diActivateSpinner();
    } else {
      customToast(i18next.t("max_img_error"), toast.TYPE.ERROR);
    }
  };

  const handleImgDelete = (e) => {
    //delete image from server
    if (e.id) {
      props.setDeleteImageIds((prevIds) => [...prevIds, e.id]);
      setImages((prevImages) => prevImages.filter((image) => image !== e));
    }
    //Handle newly uploaded image that is not uploaded to server yet
    else {
      setImages((prevImages) => prevImages.filter((image) => image !== e));
    }
  };

  const handleErrorUploading = (e) => {
    customToast(i18next.t("invalid_image_type"), toast.TYPE.ERROR);
  };

  return (
    <div className="my-portfolio-gallery-App">
      <ImageUploading
        multiple
        allowNonImageType
        value={images}
        onError={handleErrorUploading}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
        acceptType={ALLOWED_PORTFOLIO_FORMATS}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          <div className="upload__image-wrapper">
            <div className="file-preview col-12 px-0">
              <SortableImageList
                dragProps={dragProps}
                images={images}
                setImages={setImages}
                onSortEnd={onSortEnd}
                onImageUpload={onImageUpload}
                axis="xy" // Allow both vertical and horizontal sorting
                distance={5} // Set the distance before sorting starts
                onRemove={handleImgDelete}
              />
            </div>
          </div>
        )}
      </ImageUploading>
    </div>
  );
}

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  sbUserId: state.shutterbAuthentication.sbUserId,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  deleteImg: (dataObj) => dispatch(shutterbDashboardActions.deleteImg(dataObj)),
  activateSpinner: () =>
    dispatch({ type: globalSystemConstants.SPINNER_ACTIVATE, data: null }),
  diActivateSpinner: () =>
    dispatch({ type: globalSystemConstants.SPINNER_DIACTIVATE, data: null }),
});

// mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MyPortfolioGallery));
