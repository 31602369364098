import React from "react";
import FacebookLogin from "react-facebook-login";
// import axios from "axios";

// import googleIcon from "../../../../shared/img/google.svg";

const LoginWithFacebook = (props) => {
  const responseFacebook = (response) => {
    const accessToken = response.accessToken;
    accessToken && props.onLoginWithFacebookClick({ accessToken: accessToken });
  };
  return (
    <FacebookLogin
      appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}
      callback={responseFacebook}
      cssClass="facebook-button-class"
    />
  );
};

export default LoginWithFacebook;
