import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter, useParams } from "react-router-dom";
import { shutterbProfileActions } from "../../../redux/actions/shutterbProfile.actions";

const ViewShutterBProfile = ({ profileData, ...props }) => {
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      props.getShutterBProfile(id);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <section className="custom-min-height">
      <div className="container">
        <div
          className="event-setup-form-box view-profile-color"
        >
          <div className="card-body">
            <div className="d-flex flex-wrap justify-content-center align-items-center">
              <div className="pr-2 w-8">
                {profileData?.sbportfolios &&
                  profileData?.sbportfolios[0]?.profileImageUrl && (
                    <figure>
                      <img
                        className="rounded-circle w-100 obj-fit-cover h-8"
                        alt=""
                        src={`${process.env.REACT_APP_S3_BASE_URL_PORTFOLIO}/${profileData.sbportfolios[0].profileImageUrl}`}
                      />
                    </figure>
                  )}
              </div>
              <div className="d-flex col-12 col-md-10 flex-column">
                <div className="d-flex align-items-center justify-content-between flex-wrap">
                  <div className="col-12 col-md-6 mb-2">
                    {/* Profile Name and Star Rating */}
                    {profileData?.sbportfolios && (
                      <p
                        className="font-weight-bold mb-0 clr-black"
                      >
                        {profileData?.sbportfolios[0]?.profileName}
                      </p>
                    )}
                    <p className="mb-0">
                      <i
                        className="fa fa-star color-custom mb-0"
                        aria-hidden="true"
                      ></i>{" "}
                      {profileData?.averageReview
                        ? profileData?.averageReview.toFixed(2)
                        : "N/A"}
                    </p>
                  </div>
                  <div className="col-12 col-md-6 mb-2">
                    {/* Phone Information */}
                    <div className="d-flex align-items-center">
                      <p
                        className="font-weight-bold mb-0 clr-black"
                      >
                        PHONE
                      </p>
                    </div>
                    <div className="d-flex align-items-center">
                      {/* <i className="fab fa-android" aria-hidden="true"></i>*/}
                      {profileData?.sbportfolios && (
                        <p className="mb-0 ml-0">
                          {profileData?.device?.deviceName ?? "N/A"}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {profileData?.sbportfolios && (
              <p className="text-center clr-black">
                {profileData?.sbportfolios[0]?.bio}
              </p>
            )}
            {profileData?.sbportfolios && profileData.sbportfolios[0] && (
              <div className="row">
                {profileData.sbportfolios[0].gallery.map((image, index) => (
                  <div
                    className="col-sm-3 col-md-4 col-lg-3 col-xl-3"
                    key={index}
                  >
                    <img
                      className="img-fluid images-style"
                      src={`${process.env.REACT_APP_S3_BASE_URL_PORTFOLIO}/${image?.imageUrl}`}
                      alt={`${index + 1}`}
                      onClick={() =>
                        (window.location.href = `${process.env.REACT_APP_S3_BASE_URL_PORTFOLIO}/${image?.imageUrl}`)
                      }
                    />
                  </div>
                ))}
              </div>
            )}
            <div className="mt-4">
              <div className="row">
                {props.sbReviews && (
                  <div className="col text-center">
                    <p
                      className="font-weight-bold mb-0 fs-16 clr-black"
                    >
                      REVIEWS
                    </p>
                    <p>
                      {props.sbReviews.length ? props.sbReviews.length : 0}{" "}
                      hosts have provided reviews
                    </p>
                  </div>
                )}
              </div>

              <div className="row">
                <div className="col">
                  {props.sbReviews &&
                    props.sbReviews.length > 0 &&
                    props.sbReviews.map((user, index) => (
                      <div>
                        <p
                          className="font-weight-bold mb-0 fs-16 clr-black"
                        >
                          {user.user.firstName + " " + user.user.lastName}
                        </p>
                        <div
                          className="stars pointer-events-none"
                        >
                          {/* Render star icons based on the selected rating */}
                          {[...Array(user.reviews)].map((_, index) => (
                            <span key={index} className="star active">
                              &#9733;
                            </span>
                          ))}
                        </div>
                        <p>{user.feedback}</p>
                      </div>
                    ))}
                </div>
              </div>

              <div className="row">
                {profileData?.sbportfolios &&
                  profileData?.sbportfolios[0] &&
                  profileData?.sbportfolios[0]?.additionalEquipment && (
                    <div className="col text-center">
                      <p
                        className="font-weight-bold mb-0 fs-16 clr-black"
                      >
                        ADDITIONAL EQUIPMENT
                      </p>
                      <p>{profileData?.sbportfolios[0]?.additionalEquipment}</p>
                    </div>
                  )}
              </div>
              <div className="row">
                {profileData?.sbportfolios && (
                  <div className="col text-center">
                    <p
                      className="font-weight-bold mb-0 fs-16 clr-black"
                    >
                      LANGUAGES SPOKEN
                    </p>
                    <p className="mb-0">English</p>
                    {profileData?.sbportfolios[0]?.language &&
                      profileData?.sbportfolios[0]?.language !== "null" && (
                        <p>{profileData?.sbportfolios[0]?.language}</p>
                      )}
                  </div>
                )}
              </div>
            </div>

            <div className="mt-4">
              <div className="row">
                {props.allowBooking && (
                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn addToCart"
                      onClick={() => props.setSelectedShutterBId(null)}
                    >
                      Back
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {props.allowBooking && (
        <div className="text-center show_continue_booking_shutterb h-auto px-4">
          <div className="row">
            <div className="col">
              <div className="d-flex justify-content-start">
                <div>
                  <p
                    className="font-weight-bold mb-0 clr-black"
                  >
                    {profileData.firstName} {profileData.lastName}
                  </p>
                  <p className="mb-0">
                    <i
                      className="fa fa-star color-custom mb-0"
                      aria-hidden="true"
                    ></i>{" "}
                    {profileData?.averageReview
                      ? profileData?.averageReview
                      : "N/A"}
                  </p>
                </div>
              </div>
            </div>
            <div className="col d-flex justify-content-end align-items-center">
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="btn addToCart"
                  onClick={() => {
                    props.handleCardClick(null, profileData.id);
                    props.setSelectedShutterBId(null);
                  }}
                >
                  Reserve {profileData.firstName}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  profileData: state.shutterbProfile.profileData,
  sbReviews: state.shutterbProfile.sbReviews,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getShutterBProfile: (id) =>
    dispatch(shutterbProfileActions.getShutterBProfile(id)),
});

// mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ViewShutterBProfile));
