import { updateObject } from "../../shared/utility";
import { shutterbProfileConstants } from "../../constants";

// getting the default actions name from defined constants via decomposition
const { GET_SHUTTERB_PROFILE_DATA } = shutterbProfileConstants;

// default state
const initialState = {
  profileData: {},
  sbReviews: {},
};

// manupilating the state based on actions and returning the state
export const shutterbProfile = (state = initialState, action) => {
  switch (action.type) {
    case GET_SHUTTERB_PROFILE_DATA:
      return updateObject(state, {
        profileData: action.data.items,
        sbReviews: action?.data?.items?.sbReviews,
      });
    default:
      return state;
  }
};
