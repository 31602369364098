import React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ShutterBGallery from "./MyEventsTab/ShutterBGallery";
import {
  shutterbDashboardActions,
  messageBoardActions,
} from "../../../../redux/actions";
import ReelDetailsModal from "./ReelDetailsModal";
import MessageBoardModal from "../../../../shared/components/MessageBoard/Modal/MessageBoardModal";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import {
  shutterbDashboardConstants,
  customerConstants,
} from "../../../../constants";
import Modal from "react-modal";

const MyEventsTab = (props) => {
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [...sbMyEvents] = props.sbMyEvents;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isRequestMessageModalOpen, setIsRequestMessageModalOpen] =
    useState(false);
  const [reelModalEventId, setReelModalEventId] = useState(null);
  const [isEventDetailModalOpen, setIsEventDetailModalOpen] = useState(false);
  const [eventDetailId, setEventDetailId] = useState(0);
  const [openEventId, setOpenEventId] = useState(null);
  const [activeTab, setActiveTab] = useState(customerConstants.UPCOMING_EVENTS);

  const handleGalleryClick = (eventId) => {
    setSelectedEventId(eventId);
    const dataToSend = {
      eventId: eventId,
      sbUserId: props.sbUserId,
    };
    props.getGalleryData(dataToSend);
  };

  const handleCheckStatus = (btnName, eventId, status, goToPreviousPage) => {
    let decision = true;
    if (status === shutterbDashboardConstants.CHECK_OUT) {
      decision = window.confirm(
        "Have you finished uploading all content to the gallery? Please hit OK to continue to checkout or cancel to go back and finish uploading to the gallery."
      );
    }
    if (decision) {
      let dataObj = {};
      dataObj = {
        id: eventId,
        sbUserId: props.sbUserId,
        checkedStatus: status,
      };
      props.updateSbMyEventsStatus(
        eventId,
        dataObj,
        props.sbUserId,
        goToPreviousPage
      );
    }
  };
  useEffect(() => {
    // call action to getThe evenRequests from server and update everything
    props.getSbMyEvents(props.sbUserId, customerConstants.UPCOMING_EVENTS);

    // call action that will call serice to get the user data
    //  eslint-disable-next-line
  }, []);
  const convertDateToUTC = (date) => {
    const epochTimestamp = date;
    const getDate = new Date(epochTimestamp * 1000);
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = months[getDate.getUTCMonth()];
    const day = getDate.getUTCDate();
    const year = getDate.getUTCFullYear();

    return `${month} ${day}, ${year}`;
  };

  const handleEvents = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setOpenEventId(null);
      setSelectedEventId(null);
      props.getSbMyEvents(props.sbUserId, tab);
    }
  };

  const handleCardClick = (eventId) => {
    setOpenEventId((prevEventId) => (prevEventId === eventId ? null : eventId));
  };

  const handleCloseModal = () => {
    setIsMessageModalOpen(false);
    setIsRequestMessageModalOpen(false);
  };

  const handleMessageBoard = (eventId, hostName) => {
    setIsRequestMessageModalOpen(true);
    props.getMessages(eventId, props.sbUserId);
    props.getSBById(eventId, props.sbUserId);
    props.bulkUpdateMessageStatus(
      eventId,
      props.sbUserId,
      props.authentication.userId,
      props.authentication.userType
    );
    props.setFormState({
      ...props.formState,
      eventId: eventId,
      sbUserId: props.sbUserId,
      senderId: props.sbUserId,
      name: hostName,
      sender: shutterbDashboardConstants.SHUTTERB,
    });
  };

  useEffect(() => {
    props.setFormState({
      ...props.formState,
      userId: props.data?.userId,
      receiverId: props.data?.userId,
      email: props.data?.sbUser?.email,
    });
    if (Object.keys(props.data).length > 0 && isRequestMessageModalOpen) {
      setIsMessageModalOpen(true);
    }
    // eslint-disable-next-line
  }, [props.data]);

  return (
    <>
      <Box sx={{ width: "100%" }} className="mb-4">
        <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
          <Tabs
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            value={activeTab}
            aria-label="basic tabs example"
            className="member-my-events-tabs"
          >
            <Tab
              label="UPCOMING Events"
              onClick={() => {
                handleEvents(customerConstants.UPCOMING_EVENTS);
              }}
              aria-selected={
                activeTab === customerConstants.UPCOMING_EVENTS ? true : false
              }
            />
            <Tab
              label="Completed Events"
              onClick={() => {
                handleEvents(customerConstants.COMPLETED_EVENTS);
              }}
              aria-selected={
                activeTab === customerConstants.COMPLETED_EVENTS ? true : false
              }
            />
          </Tabs>
        </Box>
      </Box>
      {/* Render Gallery component when selectedEventId is not null */}
      {selectedEventId ? (
        <ShutterBGallery
          eventId={selectedEventId}
          eventDetails={[...sbMyEvents]}
          onCheckStatus={handleCheckStatus}
          setSelectedEventId
        />
      ) : isMessageModalOpen ? (
        <MessageBoardModal
          isMessageModalOpen={isMessageModalOpen}
          handleCloseModal={handleCloseModal}
          currentUser={shutterbDashboardConstants.SHUTTERB}
          currentUserId={props.sbUserId}
          className="message-board-modal"
        />
      ) : (
        <div id="accordion" className="container p-0">
          {sbMyEvents.length ? (
            [...sbMyEvents].reverse().map((eventDetails) => {
              return (
                <>
                  <div className="event-request-con px-0 py-0">
                    <div className="event-request-header" id="headingOne">
                      <div
                        className="row"
                        data-toggle="collapse"
                        data-target={"#collapseOne" + eventDetails?.id}
                        aria-expanded={openEventId === eventDetails.id}
                        aria-controls={"collapseOne" + eventDetails?.id}
                        onClick={() => handleCardClick(eventDetails.id)}
                      >
                        <div className="col-md-8">
                          <h3>
                            {eventDetails?.eventName +
                              " - " +
                              convertDateToUTC(eventDetails?.eventDate)}
                          </h3>
                        </div>
                        <div className="col-md-4">
                          <h3 className="event-header-text text-md-right">
                            Event ID: {eventDetails?.id}
                          </h3>
                        </div>
                      </div>
                    </div>

                    <div
                      id={"collapseOne" + eventDetails?.id}
                      // className="collapse event-request-body"
                      className={`collapse ${
                        openEventId === eventDetails.id ? "show" : ""
                      }`}
                      aria-labelledby="headingOne"
                      data-parent="#accordion"
                    >
                      <div>
                        <div className="container">
                          <div className="row">
                            <div className="col-4">
                              <h5>Event Type: </h5>
                            </div>
                            <div className="col-8">
                              <h5>{eventDetails?.eventType?.name}</h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <h5>Event Host: </h5>
                            </div>
                            <div className="col-8">
                              <h5>
                                {eventDetails?.user.firstName +
                                  " " +
                                  eventDetails?.user.lastName}
                              </h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <h5>Event Date: </h5>
                            </div>
                            <div className="col-8">
                              <h5>
                                {convertDateToUTC(eventDetails?.eventDate)}
                              </h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <h5>Event Duration: </h5>
                            </div>
                            <div className="col-8">
                              <h5>
                                {eventDetails?.duration}
                                {eventDetails?.duration <= 1
                                  ? " hour"
                                  : " hours"}
                              </h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <h5>Event Start Time: </h5>
                            </div>
                            <div className="col-8">
                              <h5>
                                {new Date(
                                  eventDetails?.eventStartTime * 1000
                                ).toLocaleTimeString()}
                              </h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <h5>shutterb Start Time: </h5>
                            </div>
                            <div className="col-8">
                              <h5>
                                {new Date(
                                  eventDetails?.sbstartTime * 1000
                                ).toLocaleTimeString()}
                              </h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <h5>Shot List: </h5>
                            </div>
                            <div className="col-8">
                              <h5>
                                {eventDetails?.shortList
                                  ? eventDetails.shortList
                                      .split(",")
                                      .map((item) => item.trim())
                                      .join(", ")
                                  : null}
                              </h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <h5>Address: </h5>
                            </div>
                            <div className="col-8">
                              <h5>
                                {
                                  eventDetails?.eventLocation[0]
                                    ?.currentLocation
                                }
                              </h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <h5>shutterb can share gallery with guests: </h5>
                            </div>
                            <div className="col-8">
                              <h5>
                                {eventDetails?.isGallerySharedPermission
                                  ? "True"
                                  : "False"}
                              </h5>
                            </div>
                          </div>
                          {eventDetails?.contactName &&
                            eventDetails?.contactName !== "" && (
                              <div className="row">
                                <div className="col-4">
                                  <h5>On-Site Contact Name: </h5>
                                </div>
                                <div className="col-8">
                                  <h5>{eventDetails?.contactName}</h5>
                                </div>
                              </div>
                            )}
                          {eventDetails?.phoneNumber &&
                            eventDetails?.phoneNumber !== "" && (
                              <div className="row">
                                <div className="col-4">
                                  <h5>On-Site Contact Phone Number: </h5>
                                </div>
                                <div className="col-8">
                                  <h5>{eventDetails?.phoneNumber}</h5>
                                </div>
                              </div>
                            )}
                        </div>
                        <div className="container">
                          <div className="row pb-3">
                            <div className="col-12 col-md-12 col-sm-6">
                              {eventDetails.isReelExist && (
                                <button
                                  className={"mt-2 mt-md-0"}
                                  onClick={() => {
                                    setReelModalEventId(eventDetails?.id);
                                    setIsModalOpen(!isModalOpen);
                                  }}
                                >
                                  VIEW RECAP DETAILS
                                </button>
                              )}

                              <button
                                type="submit"
                                className={"mt-2 mt-md-0"}
                                onClick={() => {
                                  props.getEventDetailByEventId(
                                    eventDetails?.id
                                  );
                                  setEventDetailId(eventDetails?.id);
                                  setIsEventDetailModalOpen(true);
                                }}
                              >
                                EVENT DETAILS
                              </button>

                              <button
                                className={"mt-2 mt-md-0"}
                                value="messageBoard"
                                onClick={() => {
                                  handleMessageBoard(
                                    eventDetails?.id,
                                    eventDetails?.user.firstName +
                                      " " +
                                      eventDetails?.user.lastName
                                  );
                                }}
                              >
                                MESSAGE BOARD
                              </button>
                              <button
                                className={
                                  eventDetails?.order.orderItems[0]
                                    .checkedStatus ===
                                  shutterbDashboardConstants.CREATED
                                    ? "mt-2 mt-md-0"
                                    : "mt-2 mt-md-0 dec-button"
                                }
                                value="checkIn"
                                disabled={
                                  eventDetails?.order.orderItems[0]
                                    .checkedStatus !==
                                  shutterbDashboardConstants.CREATED
                                }
                                onClick={(e) =>
                                  handleCheckStatus(
                                    e,
                                    eventDetails?.id,
                                    shutterbDashboardConstants.CHECK_IN
                                  )
                                }
                              >
                                CHECK IN
                              </button>

                              <button
                                className={
                                  eventDetails?.order.orderItems[0]
                                    .checkedStatus ===
                                  shutterbDashboardConstants.CREATED
                                    ? "mt-2 mt-md-0 dec-button"
                                    : "mt-2 mt-md-0"
                                }
                                disabled={
                                  eventDetails?.order.orderItems[0]
                                    .checkedStatus ===
                                  shutterbDashboardConstants.CREATED
                                }
                                onClick={() =>
                                  handleGalleryClick(eventDetails?.id)
                                }
                              >
                                GALLERY
                              </button>

                              <button
                                className={
                                  eventDetails?.order.orderItems[0]
                                    .checkedStatus ===
                                  shutterbDashboardConstants.CHECK_IN
                                    ? "mt-2 mt-md-0"
                                    : "mt-2 mt-md-0 dec-button"
                                }
                                value="checkOut"
                                disabled={
                                  eventDetails?.order.orderItems[0]
                                    .checkedStatus !==
                                  shutterbDashboardConstants.CHECK_IN
                                }
                                onClick={(e) =>
                                  handleCheckStatus(
                                    e,
                                    eventDetails?.id,
                                    shutterbDashboardConstants.CHECK_OUT
                                  )
                                }
                              >
                                CHECK OUT
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <p>You have no events at the moment.</p>
          )}
        </div>
      )}
      <ReelDetailsModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        reelModalEventId={reelModalEventId}
        className="reel-detail-con"
      />

      <Modal
        isOpen={isEventDetailModalOpen}
        onRequestClose={setIsEventDetailModalOpen}
        contentLabel="Event Details"
        className="reel-detail-con modal-border-color"
      >
        <div className="main-con">
          <div className="row w-100 pl-3">
            {props.eventDetailById.length <= 0 ? (
              <h4 className="d-flex justify-content-center align-items-center pl-4">
                No Detail Exists ...
              </h4>
            ) : (
              <table>
                <thead>
                  <tr>
                    <td colSpan="12">Event Details</td>
                  </tr>
                  <tr>
                    <td colSpan="12"> {eventDetailId}</td>
                  </tr>
                </thead>
                <tbody>
                  {props.eventDetailById?.map((item) => {
                    return (
                      <>
                        <tr>
                          <th>{item.questionType.description}:</th>
                          <td>{item.answer}</td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
          <button
            onClick={() => setIsEventDetailModalOpen(false)}
            className="modal-close"
          >
            <img
              src="/images/simpleCrossWhite.svg"
              alt="crossIcon"
              className="modal-close-img"
            />
          </button>
        </div>
      </Modal>
    </>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  sbUserId: state.shutterbAuthentication.sbUserId,
  sbMyEvents: state.shutterbDashboard.sbMyEvents,
  eventDetailById: state.shutterbDashboard.eventDetailById,
  formState: state.messageBoard.formState,
  data: state.messageBoard.data,
  authentication: state.authentication,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getSbMyEvents: (id, status) =>
    dispatch(shutterbDashboardActions.sbMyEvents(id, status)),
  getGalleryData: (data) =>
    dispatch(shutterbDashboardActions.getGalleryData(data)),
  updateSbMyEventsStatus: (eventId, dataObj, sbUserId, goToPreviousPage) =>
    dispatch(
      shutterbDashboardActions.updateSbMyEventsStatus(
        eventId,
        dataObj,
        sbUserId,
        goToPreviousPage
      )
    ),
  getEventDetailByEventId: (eventId) =>
    dispatch(shutterbDashboardActions.getEventDetailByEventId(eventId)),
  setFormState: (data) => dispatch(messageBoardActions.setFormState(data)),
  getSBById: (eventId, sbUserId) =>
    dispatch(messageBoardActions.getSBById(eventId, sbUserId)),
  getMessages: (eventId, sbUserId) =>
    dispatch(messageBoardActions.getMessages(eventId, sbUserId)),
  bulkUpdateMessageStatus: (eventId, sbUserId, userId, userType) =>
    dispatch(
      messageBoardActions.bulkUpdateMessageStatus(
        eventId,
        sbUserId,
        userId,
        userType
      )
    ),
});

// mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MyEventsTab));
