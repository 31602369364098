import thunkMiddleware from "redux-thunk";
import AllReducers from "../redux/reducers/index";
import { combineReducers, createStore, applyMiddleware, compose } from "redux";
const { ...AllOtherReducers } = AllReducers;

const reducer = combineReducers({
  ...AllOtherReducers,
});

const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25,
    })) ||
  compose;

const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(thunkMiddleware))
);

export default store;
