import i18next from "i18next";
import { toast } from "react-toastify";
import { customToast } from "../../shared";
import { custom, failure } from "./utilities";
import { messageBoardService } from "../../services";
import { globalSystemConstants, messageBoardConstants } from "../../constants";

const {
  GET_SHUTTERB_BY_EVENT_ID,
  GET_SB_BY_ID,
  FORM_STATE_OF_MESSAGE_BOARD,
  GET_MESSAGES,
  GET_NOTIFICATIONS,
  UPDATE_MESSAGE_STATUS,
  GET_NOTIFICATION_COUNT,
  BULK_UPDATE_MESSAGE_STATUS,
  CUSTOMER_CHAT_STARTED,
} = messageBoardConstants;

const getshutterbByEventId = (eventId) => {
  return (dispatch) => {
    dispatch(
      custom(GET_SHUTTERB_BY_EVENT_ID, globalSystemConstants.SPINNER_ACTIVATE)
    );
    messageBoardService
      .getshutterbByEventId(eventId)
      .then(
        (response) => {
          if (response) {
              dispatch(custom(response, GET_SHUTTERB_BY_EVENT_ID));
          }
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, GET_SHUTTERB_BY_EVENT_ID));
        }
      )
      .finally(() => {
        dispatch(
          custom(
            GET_SHUTTERB_BY_EVENT_ID,
            globalSystemConstants.SPINNER_DIACTIVATE
          )
        );
      });
  };
};

const getMessages = (eventId, sbUserId) => {
  return (dispatch) => {
    dispatch(
      custom(GET_MESSAGES, globalSystemConstants.SPINNER_ACTIVATE)
    );
    messageBoardService
      .getMessages(eventId, sbUserId)
      .then(
        (response) => {
          if (response) {
            dispatch(custom(response, GET_MESSAGES));
          }
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, GET_MESSAGES));
        }
      )
      .finally(() => {
        dispatch(
          custom(GET_MESSAGES, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};

const getSBById = (eventId, sbUserId) => {
  return (dispatch) => {
    dispatch(custom(GET_SB_BY_ID, globalSystemConstants.SPINNER_ACTIVATE));
    messageBoardService
      .getSBById(eventId, sbUserId)
      .then(
        (response) => {
          if (response) {
            dispatch(custom(response, GET_SB_BY_ID));
          }
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, GET_SB_BY_ID));
        }
      )
      .finally(() => {
        dispatch(
          custom(GET_SB_BY_ID, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};

const getNotifications = (id, type) => {
  return (dispatch) => {
    dispatch(custom(GET_NOTIFICATIONS, globalSystemConstants.SPINNER_ACTIVATE));
    messageBoardService
      .getNotifications(id, type)
      .then(
        (response) => {
          if (response) {
            dispatch(custom(response, GET_NOTIFICATIONS));
          }
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, GET_NOTIFICATIONS));
        }
      )
      .finally(() => {
        dispatch(
          custom(GET_NOTIFICATIONS, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};

const getUserNotificationCount = (id, type) => {
  return (dispatch) => {
    // dispatch(
    //   custom(GET_NOTIFICATION_COUNT, globalSystemConstants.SPINNER_ACTIVATE)
    // );
    messageBoardService
      .getUserNotificationCount(id, type)
      .then(
        (response) => {
          if (response) {
            console.log("count",response)
            dispatch(custom(response, GET_NOTIFICATION_COUNT));
          }
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, GET_NOTIFICATION_COUNT));
        }
      )
      .finally(() => {
        dispatch(
          custom(
            GET_NOTIFICATION_COUNT,
            globalSystemConstants.SPINNER_DIACTIVATE
          )
        );
      });
  };
};

const bulkUpdateMessageStatus = (eventId, sbUserId, userId, userType) => {
  return (dispatch) => {
    messageBoardService.bulkUpdateMessageStatus(eventId, sbUserId).then(
      (response) => {
        if (response) {
          // dispatch(custom(response, BULK_UPDATE_MESSAGE_STATUS));
          dispatch(getUserNotificationCount(userId, userType));
        }
      },
      (error) => {
        Array.isArray(error) &&
          customToast(
            i18next.t(error[0] === undefined ? "undefined" : error[0]),
            toast.TYPE.ERROR
          );
        dispatch(failure(error, BULK_UPDATE_MESSAGE_STATUS));
      }
    );
  };
};

const updateMessageStatus = (messageId) => {
  return (dispatch) => {
    messageBoardService
      .updateMessageStatus(messageId)
      .then(
        (response) => {
          if (response) {
            // dispatch(custom(response, UPDATE_MESSAGE_STATUS));
          }
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, UPDATE_MESSAGE_STATUS));
        }
      )
  };
};

function setFormState(data) {
  return (dispatch) => {
    dispatch(custom(data, FORM_STATE_OF_MESSAGE_BOARD));
  };
}

function setCustomerChatStart(data) {
  return (dispatch) => {
    dispatch(custom(data, CUSTOMER_CHAT_STARTED));
  };
}


export const messageBoardActions = {
  getshutterbByEventId,
  setFormState,
  getMessages,
  getSBById,
  getNotifications,
  bulkUpdateMessageStatus,
  getUserNotificationCount,
  updateMessageStatus,
  setCustomerChatStart,
};
