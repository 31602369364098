import i18next from "i18next";
import { toast } from "react-toastify";
import { shutterbService } from "../../services";
import {
  shutterbRegisterConstants,
  shutterbLoginConstants,
  globalSystemConstants,
} from "../../constants";
import { customToast } from "../../shared/utility";
import { success, failure, custom } from "./utilities";

// shutterB register actions
const shutterbRegister = (userData) => {
  return (dispatch) => {
    dispatch(custom(null, globalSystemConstants.SPINNER_ACTIVATE));
    shutterbService
      .shutterbRegister(userData)
      .then(
        (response) => {
          customToast(
            i18next.t("form_submitted_successfully"),
            toast.TYPE.SUCCESS
          );
          dispatch(
            success(response, shutterbRegisterConstants.shutterbRegister)
          );
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, shutterbRegisterConstants.shutterbRegister));
        }
      )
      .finally(() => {
        dispatch(custom(null, globalSystemConstants.SPINNER_DIACTIVATE));
      });
  };
};

// Shutterb login actions.
const shutterbLogin = (userData, setIsModalOpen) => {
  return (dispatch) => {
    dispatch(custom(null, globalSystemConstants.SPINNER_ACTIVATE));
    shutterbService
      .shutterbLogin(userData)
      .then(
        (response) => {
          customToast(i18next.t("logged_in_successfully"), toast.TYPE.SUCCESS);
          dispatch(success(response, shutterbLoginConstants.shutterbLogin));
          localStorage.removeItem("dataToSend");
          localStorage.removeItem("activeTab");
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, shutterbLoginConstants.shutterbLogin));
        }
      )
      .finally(() => {
        dispatch(
          custom(
            shutterbLoginConstants.shutterbLogin,
            globalSystemConstants.SPINNER_DIACTIVATE
          )
        );
      });
  };
};

export const shutterbActions = {
  shutterbRegister,
  shutterbLogin,
};
