import React from "react";
// eslint-disable-next-line import/no-cycle
import { authService } from "../services";
import { GLOBAL_STATUS } from "./global-enums";

function allErrorMessages(errors) {
  let errorMessages = [];
  // eslint-disable-next-line
  Object.keys(errors).map((key) => {
    errorMessages.push(errors[key]);
  });
  return errorMessages;
}

function handleHTTPResponses(httpResponse) {
  let errorMessages = [];
  if (httpResponse) {
    const { message } = httpResponse.data;
    if (message === "Network Error") {
      errorMessages.push("unable_to_access_server");
      return Promise.reject(errorMessages);
    }
    const responseStatus = httpResponse.status;
    if (responseStatus !== 200) {
      if (responseStatus === 401) {
        alert("Session has expired");
        authService.logout().then(() => window.location.assign("/login"));
        return Promise.reject(errorMessages);
      }
      if (httpResponse.data.errors) {
        errorMessages = allErrorMessages(httpResponse.data.errors);
      }
      errorMessages.unshift(message);
      return Promise.reject(errorMessages);
    }
    return httpResponse;
  }
  errorMessages.push("unable_to_access_server");
  return Promise.reject(errorMessages);
}

function handleResponseByStatus(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (response.status === GLOBAL_STATUS.UNAUTHORIZED) {
      // auto logout if 401 response returned from api
      authService.logout().then(() => window.location.assign("/login"));
    }

    const error = (data && data.message) || response.statusText;
    return Promise.reject(error);
  });
}

function handleServerResponse(response) {
  const { status, message, errors = [], payload } = response.data;
  if (status === GLOBAL_STATUS.SUCCESS) {
    return payload;
  }
  const Msg = () => (
    <div>
      <h4 className="m-0">{message || "Something went wrong"}</h4>
      {Object.keys(errors).map((key) => (
        <div key={key}>{errors[key]}</div>
      ))}
    </div>
  );
  return Promise.reject(Msg);
}

function handleServerResponseMessage(response) {
  // const { status, message, errors = [], payload } = response.data;
  const { status, message, errors = [] } = response.data;
  if (status === GLOBAL_STATUS.SUCCESS) {
    return message;
  }
  const Msg = () => (
    <div>
      <h4 className="m-0">{message || "Something went wrong"}</h4>
      {Object.keys(errors).map((key) => (
        <div key={key}>{errors[key]}</div>
      ))}
    </div>
  );
  return Promise.reject(Msg);
}

export default {
  handleHTTPResponses,
  handleResponseByStatus,
  handleServerResponse,
  handleServerResponseMessage,
};
