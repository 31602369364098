import jwtDecode from "jwt-decode";

import { getDecryptPayload } from "../../system";
import { updateObject } from "../../shared/utility";
import { loginConstants, globalConstants, userType } from "../../constants";

// initializing the token from the browser
const payload = getDecryptPayload();
const token = payload.token || "";
const userId = (payload.user && payload.user.id) || 0;
const userName = (payload.user && payload.user.userName) || "";

// decoding JWT to get user type
const decodedToken = token && jwtDecode(token);

// default state
const initialState = {
  userId,
  userName,
  userType: decodedToken?.UserType,
  isAuthenticated:
    token.length > 0 &&
    (userType.USER === decodedToken.UserType ||
      decodedToken.UserType === userType.ADMIN),
  isEmailedVerified: true,
  passwordChanged: false,
};

// manupilating the state based on actions and returning the state
export const authentication = (state = initialState, action) => {
  switch (action.type) {
    case loginConstants.USER_LOGIN + globalConstants.SUCCESS:
      return updateObject(state, {
        isAuthenticated: true,
        userId: action.data.user.id,
        userName: action.data.user.userName,
        passwordChanged: true,
      });
    case loginConstants.USER_LOGIN + globalConstants.FAILURE:
      return updateObject(state, {
        userName: "",
        isAuthenticated: false,
      });
    case loginConstants.EMAIL_VERIFIED:
      return updateObject(state, { isEmailedVerified: action.data });
    default:
      return state;
  }
};
