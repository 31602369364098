import React, { useEffect, useRef } from "react";
import { messageBoardActions } from "../../../redux/actions";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import { connect } from "react-redux";
import { startConnection, sendMessage } from "../../../system/signalRHOC";
import { useDispatch } from "react-redux";
import { custom } from "../../../redux/actions/utilities";
import avatar from "../../img/avatar.jpg";
import "../../../scss/pages/_message-board.scss";
import {
  messageBoardConstants,
  customerConstants,
  shutterbDashboardConstants,
} from "../../../constants";

const MessageBoardView = (props) => {
  const messageBoardRef = useRef(null);
  const connection = useRef(null);
  const dispatch = useDispatch();
  useEffect(() => {
    const setupConnection = async () => {
      try {
        // Clean up previous connection and event listener
        if (connection.current) {
          connection.current.off("ReceiveMessage");
          await connection.current.stop();
        }
        // Establish the connection
        connection.current = await startConnection(
          props.userId,
          props.sbUserId,
          props.eventId
        );

        // Listen for incoming messages
        connection.current.on("ReceiveMessage", (message) => {
          // Dispatch an action to update your Redux state with the received message
          dispatch(custom(message, messageBoardConstants.RECEIVE_MESSAGES));
          if (
            (props.currentUser === shutterbDashboardConstants.SHUTTERB &&
              message.sender === customerConstants.HOST) ||
            (props.currentUser === customerConstants.HOST &&
              props.currentActiveId === message.sbuserId &&
              message.sender === shutterbDashboardConstants.SHUTTERB &&
              message.eventId === props.eventId)
          ) {
            connection.current.invoke("MessageSeenAcknowledgment", message.id);
            props.getUserNotificationCount(
              message.receiverId,
              message.userType
            );
          }
          // connection.current.invoke(
          //   "NotificationsCount",
          //   message.receiverId,
          //   message.userType
          // );
        });
      } catch (error) {
        console.error("Error setting up connection:", error);
      }
    };
    setupConnection();

    // Cleanup function
    return () => {
      if (connection.current) {
        connection.current.off("ReceiveMessage");
        connection.current.stop();
      }
    };
    // eslint-disable-next-line
  }, [dispatch, props.currentActiveId]);

  const handleSubmit = async () => {
    try {
      if (props.formState.message.trim() !== "") {
        // Send the message
        await sendMessage(connection.current, props.formState);
        // Clear the input field after submitting
        props.setFormState({
          ...props.formState,
          message: "",
        });
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
    }
  };
  const scrollToBottom = () => {
    if (messageBoardRef.current) {
      const scrollHeight = messageBoardRef.current.scrollHeight;
      messageBoardRef.current.scrollTop = scrollHeight;
    }
  };
  useEffect(() => {
    scrollToBottom();
  }, [props.messages]);

  return (
    <>
      <div className="h-100 chat">
        <div className="card msg-card">
          <div className="card-header msg-card-header msg_head">
            <div className="d-flex bd-highlight">
              <div className="img_cont">
                <img
                  src={
                    props.profileImageUrl
                      ? process.env.REACT_APP_S3_BASE_URL_PORTFOLIO +
                        "/" +
                        props.profileImageUrl
                      : avatar
                  }
                  alt="profile"
                  className="rounded-circle user_img w-75"
                />
              </div>
              <div className="user_info">
                <span>{props.formState.name}</span>
              </div>
            </div>
          </div>
          <div className="card-body msg_card_body" ref={messageBoardRef}>
            {props.messages?.map((data) => {
              return (
                <div>
                  {props.currentUserId === data.senderId &&
                  (data.sender === customerConstants.HOST ||
                    data.sender === shutterbDashboardConstants.SHUTTERB) ? (
                    <div className="d-flex justify-content-end mb-4">
                      <div className="msg_cotainer_send">
                        {data.message}
                        <span className="msg_time_send">
                          {data.createdAt
                            ? new Date(
                                data.createdAt * 1000
                              ).toLocaleTimeString([], {
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: false,
                              })
                            : data.sendedTime}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-start mb-4">
                      <div className="msg_cotainer">
                        {data.message}
                        <span className="msg_time">
                          {" "}
                          {data.createdAt
                            ? new Date(
                                data.createdAt * 1000
                              ).toLocaleTimeString([], {
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: false,
                              })
                            : data.sendedTime}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <div className="card-footer msg-card-footer">
            <div className="input-group">
              <textarea
                name=""
                className="form-control type_msg"
                placeholder="Type your message..."
                onChange={(e) =>
                  props.setFormState({
                    ...props.formState,
                    message: e.target.value,
                    sendedTime: new Date().toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: false,
                    }),
                  })
                }
                value={props.formState.message}
              ></textarea>
              <div className="input-group-append">
                <span
                  className="input-group-text send_btn"
                  onClick={() => handleSubmit()}
                >
                  <i className="fas fa-location-arrow"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  formState: state.messageBoard.formState,
  messages: state.messageBoard.messages,
  customerChatStarted: state.messageBoard.customerChatStarted,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  setFormState: (data) => dispatch(messageBoardActions.setFormState(data)),
  sendMessage: (data) => dispatch(messageBoardActions.sendMessage(data)),
  updateMessageStatus: (messageId) =>
    dispatch(messageBoardActions.updateMessageStatus(messageId)),
  getUserNotificationCount: (id, type) =>
    dispatch(messageBoardActions.getUserNotificationCount(id, type)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MessageBoardView));
