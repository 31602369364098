import React from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import MainWrapper from "../MainWrapper";
import PrivateRoute from "./PrivateRoute";
import { userRoutes, sbRoutes } from "../../../system/routes";
import { userType } from "../../../constants";
import Shutterb from "../../../views/Shutterb/Shutterb";
import Footer from "../../Layout/footer/Footer";
import Topbar from "../../Layout/topbar/Topbar";
import Spinner from "../../../shared/components/ErrorBoundary/Spinner";
import Overlay from "../../../shared/components/ErrorBoundary/Overlay";
import EmailConfirmation from "../../Views/EmailConfimation/index";
import ChangePassword from "../../../container/Views/ChangePassword";
import EventBookingForm from "../../../../src/views/Customer/EventBooking/EventBookingForm";
import SharedGallery from "../../../views/Customer/CustomerDashboard/CustomerDashboardTabs/MyEventsTab/MyEventsDashboard/Tabs/SharedGallery";
import ViewShutterBProfile from "../../../views/Customer/ShutterBProfile/ViewShutterBProfile";
import Modal from "../../Views/Login/Modal";

const RouterWrapper = (props) => {
  // eslint-disable-next-line
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  return (
    <>
      <>
        {/* topbar will not render for these two routes */}
        {window.location.href.includes("/email_confirmation/:queryparam") ||
        window.location.href.includes("/change_password") ? null : (
          <Topbar />
        )}
        {/* app */}
        <MainWrapper>
          <main>
            <Switch>
              <Route
                exact
                path="/"
                component={() => (
                  <Modal setIsModalOpen={setIsModalOpen} isPage />
                )}
              />
              <Route
                exact
                path="/email_confirmation/:queryparam"
                component={EmailConfirmation}
              />
              <Route exact path="/change_password" component={ChangePassword} />
              <Route
                exact
                path="/be-a-shutterb"
                component={() => <Shutterb />}
              />
              <Route
                exact
                path="/Login"
                component={() => (
                  <Modal setIsModalOpen={setIsModalOpen} isPage />
                )}
              />
              <Route
                exact
                path="/event_booking"
                component={() => <EventBookingForm />}
              />
              <Route
                exact
                path="/event_booking/:isDraftEvent/:eventId"
                component={() => <EventBookingForm />}
              />
              <Route
                exact
                path="/ShutterbProfile/:id"
                component={() => <ViewShutterBProfile />}
              />
              {/* <Route exact path="/SharedGallery" component={() => <SharedGallery />} /> */}
              {/* once encrypted link from backend is deployed */}
              <Route
                exact
                path="/SharedGallery"
                component={() => <SharedGallery />}
              />
              {props.userType === userType.SHUTTERB ? (
                sbRoutes.map((prop, key) => (
                  <PrivateRoute
                    key={key}
                    path={prop.path}
                    component={prop.component}
                    {...props}
                  />
                ))
              ) : props.userType === userType.USER ||
                props.userType === userType.ADMIN ? (
                userRoutes.map((prop, key) => (
                  <PrivateRoute
                    key={key}
                    path={prop.path}
                    component={prop.component}
                    {...props}
                  />
                ))
              ) : (
                <Redirect
                  to={{ pathname: "/", state: { from: props.location } }}
                />
              )}
              {props.isAuthenticated ? (
                <Redirect
                  to={{
                    pathname: "/CustomerDashboard",
                    state: { from: props.location },
                  }}
                />
              ) : props.isShutterbAuthenticated ? (
                <Redirect
                  to={{
                    pathname: "/shutterbDashboard",
                    state: { from: props.location },
                  }}
                />
              ) : (
                <Redirect
                  to={{ pathname: "/", state: { from: props.location } }}
                />
              )}
            </Switch>
          </main>
        </MainWrapper>
        {/* footer will not render for these two routes */}
        {window.location.href.includes("/email_confirmation/:queryparam") ||
        window.location.href.includes("/change_password") ? null : (
          <Footer />
        )}
      </>

      <Spinner />
      <Overlay />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isShutterbAuthenticated:
      state.shutterbAuthentication.isShutterbAuthenticated,
    isAuthenticated: state.authentication.isAuthenticated,
    userType: state.authentication.userType,
  };
};

export default connect(mapStateToProps)(React.memo(RouterWrapper));