// eslint-disable-next-line import/no-cycle
import responseHandler from "../system/server-response-handler";
import { getRequest, postRequest } from "../system/server-call";

/**
 * The Event Booking Service
 *
 * This is used for getting data from server and handling the business logic on that data
 */

// get shot list based on event type
const getShotList = (id) => {
  return getRequest(
    `/api/v1/User/EventSetup/GetEventTypeItems/${id}`,
    true
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

// get shutter Bs list
const getAvailableShutterBs = (data) => {
  return postRequest(
    "/api/v1/ShutterB/GeoLocation/GetSBByLocation",
    data,
    true
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

// get event Types
const getEventTypes = () => {
  return getRequest("/api/v1/User/EventSetup/GetEventTypes", true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const checkOut = (data) => {
  return postRequest(`/api/Stripe/CreateCheckoutSession`, data).then(
    (response) => response.data,
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const requestBooking = (data, orderItemGroupId) => {
  return postRequest(
    `/api/Stripe/MakePayment/${data}/${orderItemGroupId}`,
    true
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const getReelPrice = () => {
  return getRequest("/api/v1/user/reel/getprice", false).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const getMiscAmounts = () => {
  return getRequest("/api/v1/user/userinfo/getconfiguration", false).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const applyCoupon = (dataObj) => {
  return getRequest(
    `/api/v1/admin/PromoCodes/GetDiscount/${dataObj.code}/${dataObj.userId}`,
    dataObj,
    true
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const draftEventDetails = (data) => {
  return postRequest(`/api/v1/User/EventSetup/EventSaveAsDraft`, data).then(
    (response) => response.data,
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const getDraftEventSbList = (eventId) => {
  return getRequest(
    `/api/v1/User/EventSetup/GetDraftEvent/${eventId}`,
    false
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const getReelTypes = () => {
  return getRequest(
    `/api/v1/User/EventSetup/getReelTypes`,
    false
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const uploadMusicFile = (formData) => {
  return postRequest(
    "/api/v1/User/EventSetup/UploadMusicFile",
    formData,
    true
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};


// exporting functions for other files
export const eventBookingServices = {
  checkOut,
  getShotList,
  getEventTypes,
  requestBooking,
  getAvailableShutterBs,
  getReelPrice,
  getMiscAmounts,
  applyCoupon,
  draftEventDetails,
  getDraftEventSbList,
  getReelTypes,
  uploadMusicFile,
};
