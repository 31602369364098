import React, { useState, useEffect } from "react";

import * as modalConstants from "../../../constants/modal.constants.js";
import LoginForm from "./LoginForm";
import ForgotPassword from "./ForgotPassword/ForgotPassword.jsx";
import SignUpForm from "./SignUp/SignUpForm.jsx";
import ShutterbLogin from "./ShutterbLogin/ShutterbLogin.jsx";

const Modal = (props) => {
  // to manage diffrent forms in modal creating state of forms and render the one you want to show
  const [formSelect, setFormSelect] = useState(modalConstants.LOGIN_FORM);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "unset");
  }, []);
  return (
    <>
      <div className={`${props.isAllowModal ? "black-drop" : "no-black-drop"}`}>
        <div className={`px-3 py-5 modal-forms-con ${props.isPage ? "" : ""}`}>
          {formSelect === modalConstants.LOGIN_FORM && (
            <LoginForm
              isAllowModal={props.isAllowModal}
              isPage={props.isPage}
              setIsModalOpen={props.setIsModalOpen}
              setFormSelect={setFormSelect}
            />
          )}
          {formSelect === modalConstants.FORGOT_PASSWORD_FORM && (
            <ForgotPassword
              isAllowModal={props.isAllowModal}
              isPage={props.isPage}
              setIsModalOpen={props.setIsModalOpen}
              setFormSelect={setFormSelect}
            />
          )}
          {formSelect === modalConstants.SIGNUP_FORM && (
            <SignUpForm
              isAllowModal={props.isAllowModal}
              isPage={props.isPage}
              setIsModalOpen={props.setIsModalOpen}
              setFormSelect={setFormSelect}
            />
          )}
          {formSelect === modalConstants.SHUTTERB_LOGIN && (
            <ShutterbLogin
             isAllowModal={props.isAllowModal}
              isPage={props.isPage}
              setIsModalOpen={props.setIsModalOpen}
              setFormSelect={setFormSelect}
            />
          )}
          {/* shutterb signup will be redirected */}
        </div>
      </div>
    </>
  );
};

export default Modal;
