import i18next from "i18next";
import { customerDashboardServices } from "../../services";
import { globalSystemConstants, customerConstants, galleryConstants } from "../../constants";
import { customToast } from "../../shared/utility";
import { toast } from "react-toastify";
import { success, failure, custom } from "./utilities";
import { NavigateToPath } from "../../system";

// Event request tab actions
const myEvents = (id, status) => {
  return (dispatch) => {
    dispatch(custom(null, globalSystemConstants.SPINNER_ACTIVATE));
    customerDashboardServices
      .getMyEvents(id, status)
      .then(
        (response) => {
          dispatch(success(response, customerConstants.GET_MY_EVENTS));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, customerConstants.GET_MY_EVENTS));
        }
      )
      .finally(() => {
        dispatch(custom(null, globalSystemConstants.SPINNER_DIACTIVATE));
      });
  };
};

// same action for both components extend sb and tip sb
const myEventsExtendSb = (eventId, userId) => {
  return (dispatch) => {
    dispatch(custom(null, globalSystemConstants.SPINNER_ACTIVATE));
    customerDashboardServices
      .getExtendSb(eventId, userId)
      .then(
        (response) => {
          dispatch(success(response, customerConstants.EXTEND_SHUTTERB));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
        }
      )
      .finally(() => {
        dispatch(custom(null, globalSystemConstants.SPINNER_DIACTIVATE));
      });
  };
};

const extendSbCheckout = (eventId, dataObj, cartPreviousScreen, history) => {
  return (dispatch) => {
    dispatch(custom(null, globalSystemConstants.SPINNER_ACTIVATE));
    customerDashboardServices
      .getExtendSbCheckout(eventId, dataObj, cartPreviousScreen)
      .then(
        (response) => {
          NavigateToPath(history, "/event_booking");
          dispatch(success(response, customerConstants.EXTEND_SHUTTERB_CART));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
        }
      )
      .finally(() => {
        dispatch(custom(null, globalSystemConstants.SPINNER_DIACTIVATE));
      });
  };
};

// Event request dashboard's actions it's same for both components AccessGAllery and SharedGallery
const myEventsAccessGallery = (eventId, files, setFiles, componentName) => {
  return (dispatch) => {
    dispatch(custom(null, globalSystemConstants.SPINNER_ACTIVATE));
    customerDashboardServices
      .getAccessGallery(eventId, componentName)
      .then(
        (response) => {
          dispatch(success(response, customerConstants.MY_EVENTS_GALLERY));
          const data = response.items?.gallery;
          if (data) {
            const updatedFiles = data.map((file) => {
              return {
                ...file,
                name: file.imageUrl && file.imageUrl.slice(0, -4),
                imageUrl: `${process.env.REACT_APP_S3_BASE_URL_EVENTS_GALLERY}/${file.imageUrl}`,
                thumbnailUrl: `${process.env.REACT_APP_S3_BASE_URL_EVENTS_GALLERY}/${file.thumbnailUrl}`,
              };
            });
            setFiles(
              updatedFiles.map((file) => {
                let fileType;
                let galleryType;
                if (
                  file.imageUrl.endsWith(".mp4") ||
                  file.imageUrl.endsWith(".MP4")
                ) {
                  fileType = "video/mp4";
                  galleryType = galleryConstants.GALLERY_VIDEOS;
                } else if (
                  file.imageUrl.endsWith(".mov") ||
                  file.imageUrl.endsWith(".MOV")
                ) {
                  fileType = "video/mov";
                  galleryType = galleryConstants.GALLERY_VIDEOS;
                } else if (
                  file.imageUrl.endsWith(".m4v") ||
                  file.imageUrl.endsWith(".M4V")
                ) {
                  fileType = "video/m4v";
                  galleryType = galleryConstants.GALLERY_VIDEOS;
                } else {
                  fileType = "image/jpeg";
                  galleryType = galleryConstants.GALLERY_IMAGES;
                }
                return {
                  file: new File([file], file.name, {
                    type: fileType,
                    lastModified: file.lastModified,
                    lastModifiedDate: file.lastModifiedDate,
                  }),
                  url: file.imageUrl,
                  thumbnailUrl: file.thumbnailUrl,
                  selected: false,
                  id: file?.id,
                  isLocked: file?.isLocked,
                  contentType: file?.contentType,
                  galleryType:
                    file?.contentType === galleryConstants.CONTENT_TYPE_REELS
                      ? galleryConstants.GALLERY_REELS
                      : galleryType,
                };
              })
            );
          }
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, customerConstants.MY_EVENTS_GALLERY));
        }
      )
      .finally(() => {
        dispatch(custom(null, globalSystemConstants.SPINNER_DIACTIVATE));
      });
  };
};

// delete from gallery
const deleteFromGallery = (
  data,
  eventId,
  files,
  setFiles,
  selectedFiles,
  setSelectedFiles,
  componentName
) => {
  return (dispatch) => {
    dispatch(
      custom(
        customerConstants.DELETE_FROM_GALLERY,
        globalSystemConstants.SPINNER_ACTIVATE
      )
    );
    customerDashboardServices
      .deleteFromGallery(data)
      .then(
        (response) => {
          dispatch(
            myEventsAccessGallery(eventId, files, setFiles, componentName)
          );
          setSelectedFiles([]);
          customToast(
            i18next.t("file_successfully_deleted"),
            toast.TYPE.SUCCESS
          );
        },
        (error) => {
          customToast(
            i18next.t(error[0] === undefined ? "undefined" : error[0]),
            toast.TYPE.ERROR
          );
          dispatch(failure(error, customerConstants.DELETE_FROM_GALLERY));
        }
      )
      .finally(() => {
        dispatch(
          custom(
            customerConstants.DELETE_FROM_GALLERY,
            globalSystemConstants.SPINNER_DIACTIVATE
          )
        );
      });
  };
};

const lockFromGallery = (data, eventId, files, setFiles) => {
  return (dispatch) => {
    dispatch(
      custom(
        customerConstants.LOCK_FROM_GALLERY,
        globalSystemConstants.SPINNER_ACTIVATE
      )
    );
    customerDashboardServices
      .lockFromGallery(data)
      .then(
        (response) => {
          dispatch(success(response, customerConstants.LOCK_FROM_GALLERY));
          dispatch(
            myEventsAccessGallery(
              eventId,
              files,
              setFiles,
              "ACCESS_GALLERY_CALL"
            )
          );
        },
        (error) => {
          customToast(
            i18next.t(error[0] === undefined ? "undefined" : error[0]),
            toast.TYPE.ERROR
          );
          dispatch(failure(error, customerConstants.LOCK_FROM_GALLERY));
        }
      )
      .finally(() => {
        dispatch(
          custom(
            customerConstants.LOCK_FROM_GALLERY,
            globalSystemConstants.SPINNER_DIACTIVATE
          )
        );
      });
  };
};

// same action for both components extend sb and tip sb
const getReviewFormData = (eventId) => {
  return (dispatch) => {
    dispatch(
      custom(
        customerConstants.GET_REVIEW_FORM_DATA,
        globalSystemConstants.SPINNER_ACTIVATE
      )
    );
    customerDashboardServices
      .getReviewFormData(eventId)
      .then(
        (response) => {
          dispatch(success(response, customerConstants.GET_REVIEW_FORM_DATA));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
        }
      )
      .finally(() => {
        dispatch(
          custom(
            customerConstants.GET_REVIEW_FORM_DATA,
            globalSystemConstants.SPINNER_DIACTIVATE
          )
        );
      });
  };
};

const submitRatingAndFeedback = (data) => {
  return (dispatch) => {
    dispatch(
      custom(
        customerConstants.SUBMIT_RATING_AND_FEEDBACK,
        globalSystemConstants.SPINNER_ACTIVATE
      )
    );
    customerDashboardServices
      .submitRatingAndFeedback(data)
      .then(
        (response) => {
          dispatch(
            success(response, customerConstants.SUBMIT_RATING_AND_FEEDBACK)
          );
          dispatch(getReviewFormData(data.eventId));
          customToast(
            i18next.t("review_submit_successfully"),
            toast.TYPE.SUCCESS
          );
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
        }
      )
      .finally(() => {
        dispatch(
          custom(
            customerConstants.SUBMIT_RATING_AND_FEEDBACK,
            globalSystemConstants.SPINNER_DIACTIVATE
          )
        );
      });
  };
};

// my account tab actions
const updateCustomerPassword = (userId, dataObj) => {
  return (dispatch) => {
    dispatch(custom(null, globalSystemConstants.SPINNER_ACTIVATE));
    customerDashboardServices
      .updateCustomerPassword(userId, dataObj)
      .then(
        (response) => {
          customToast(
            i18next.t(response),
            response.includes("not") ? toast.TYPE.ERROR : toast.TYPE.SUCCESS
          );
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
        }
      )
      .finally(() => {
        dispatch(custom(null, globalSystemConstants.SPINNER_DIACTIVATE));
      });
  };
};

const myAccountInfo = (userId) => {
  return (dispatch) => {
    dispatch(custom(null, globalSystemConstants.SPINNER_ACTIVATE));
    customerDashboardServices
      .getMyAccountInfo(userId)
      .then(
        (response) => {
          dispatch(success(response, customerConstants.GET_MY_ACCOUNT_INFO));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
        }
      )
      .finally(() => {
        dispatch(custom(null, globalSystemConstants.SPINNER_DIACTIVATE));
      });
  };
};

const updateMyAccountInfo = (userId, dataObj) => {
  return (dispatch) => {
    dispatch(custom(null, globalSystemConstants.SPINNER_ACTIVATE));
    customerDashboardServices
      .updateMyAccountInfo(userId, dataObj)
      .then(
        (response) => {
          // dispatch(success(response, customerConstants.UPDATE_MY_ACCOUNT_INFO));
          customToast(i18next.t("updated_successfully"), toast.TYPE.SUCCESS);
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
        }
      )
      .finally(() => {
        dispatch(custom(null, globalSystemConstants.SPINNER_DIACTIVATE));
      });
  };
};

// Attended events tab actions
// Event request tab actions
const attendedEventSearchResult = (eventId, userId) => {
  return (dispatch) => {
    dispatch(custom(null, globalSystemConstants.SPINNER_ACTIVATE));
    customerDashboardServices
      .getAttendedEventSearchResult(eventId, userId)
      .then(
        (response) => {
          dispatch(success(response, customerConstants.GET_SEARCH_RESULT));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, customerConstants.GET_MY_EVENTS));
        }
      )
      .finally(() => {
        dispatch(custom(null, globalSystemConstants.SPINNER_DIACTIVATE));
      });
  };
};

const attendedEventRecentSearches = (userId) => {
  return (dispatch) => {
    dispatch(custom(null, globalSystemConstants.SPINNER_ACTIVATE));
    customerDashboardServices
      .getAttendedEventRecentSearches(userId)
      .then(
        (response) => {
          dispatch(success(response, customerConstants.GET_RECENT_SEARCHES));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, customerConstants.GET_MY_EVENTS));
        }
      )
      .finally(() => {
        dispatch(custom(null, globalSystemConstants.SPINNER_DIACTIVATE));
      });
  };
};

// refer a friend tab
const referAFriend = (dataObj) => {
  return (dispatch) => {
    dispatch(custom(null, globalSystemConstants.SPINNER_ACTIVATE));
    customerDashboardServices
      .referAFriend(dataObj)
      .then(
        (response) => {
          customToast(i18next.t(response), toast.TYPE.SUCCESS);
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
        }
      )
      .finally(() => {
        dispatch(custom(null, globalSystemConstants.SPINNER_DIACTIVATE));
      });
  };
};

const getInvoice = (eventId) => {
  return (dispatch) => {
    dispatch(
      custom(
        customerConstants.GET_INVOICE,
        globalSystemConstants.SPINNER_ACTIVATE
      )
    );
    customerDashboardServices
      .getInvoice(eventId)
      .then(
        (response) => {
          dispatch(success(response, customerConstants.GET_INVOICE));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
        }
      )
      .finally(() => {
        dispatch(
          custom(
            customerConstants.GET_INVOICE,
            globalSystemConstants.SPINNER_DIACTIVATE
          )
        );
      });
  };
};

const downloadSelectedFiles = (eventIdsArr) => {
  return (dispatch) => {
    dispatch(custom(null, globalSystemConstants.SPINNER_ACTIVATE));
    customerDashboardServices
      .downloadSelectedFiles(eventIdsArr)
      .then(
        (message) => {
          toast.success(i18next.t("download_files_email"), {
            autoClose: 5000,
            hideProgressBar: true,
          });
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
        }
      )
      .finally(() => {
        dispatch(custom(null, globalSystemConstants.SPINNER_DIACTIVATE));
      });
  };
};

const cancelEvent = (dataObj) => {
  return (dispatch) => {
    dispatch(custom(null, globalSystemConstants.SPINNER_ACTIVATE));
    customerDashboardServices
      .cancelEvent(dataObj)
      .then(
        (response) => {
          dispatch(myEvents(dataObj.userId));
          dispatch(success(response, customerConstants.CANCEL_EVENT));
          customToast(
            i18next.t("event_cancel_successfully"),
            toast.TYPE.SUCCESS
          );

        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, customerConstants.CANCEL_EVENT));
        }
      )
      .finally(() => {
        dispatch(custom(null, globalSystemConstants.SPINNER_DIACTIVATE));
      });
  };
};

export const customerDashboardActions = {
  myEvents,
  myEventsExtendSb,
  myEventsAccessGallery,
  deleteFromGallery,
  lockFromGallery,
  updateCustomerPassword,
  myAccountInfo,
  updateMyAccountInfo,
  attendedEventSearchResult,
  attendedEventRecentSearches,
  referAFriend,
  getReviewFormData,
  submitRatingAndFeedback,
  getInvoice,
  extendSbCheckout,
  downloadSelectedFiles,
  cancelEvent
};
