import { GLOBAL_STATUS } from "../system/global-enums";

export const loginConstants = {
  USER_LOGIN: "USER_LOGIN",
  USER_LOGOUT: "USER_LOGOUT",
  LOGIN_STATUS: {
    ...GLOBAL_STATUS,
  },
  EMAIL_VERIFIED: "EMAIL_VERIFIED",
};

export const signupConstants = {
  USER_SIGNUP: "USER_SIGNUP",
  SIGNUP_STATUS: {
    ...GLOBAL_STATUS,
  },
};
