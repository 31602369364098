import React, { useEffect, useRef } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { connect } from "react-redux";
import { convertDateToUTC } from "../../../../shared/utility";
import { customerDashboardActions } from "../../../../redux/actions/customerDashboard.actions";

const AttendedEventsTab = (props) => {
  const { searchResult, recentSearches } = { ...props };
  const galleryUrlRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const formProps = Object.fromEntries(formData);
    props.getSearchResult(formProps.eventId, props.userId);
  };

  const handleSearch = (prevEventId) => {
    props.getSearchResult(prevEventId, props.userId);
  };

  function copyToClipboard(text) {
    galleryUrlRef.current.value = text;
    galleryUrlRef.current.select();
    document.execCommand("copy");
    alert("Link Copied");
  }


  useEffect(() => {
    props.getRecentSearches(props.userId);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="container attended-events-tab">
      <div className="row">
        <div className="col px-0">
          <h5 className="p-0 m-0 mb-2">Attending Event</h5>
          <p className="p-0 m-0 mb-4">
            If you are a guest at an event with a shutterb, please enter the
            event ID below to access the event images.
          </p>
        </div>
      </div>

      {searchResult?.id && (
        <div className="row py-3 px-3 search-result">
          <div className="col px-0">
            <div
              className="shared-icon-con"
              onClick={() =>
                copyToClipboard(
                  `${window.location.protocol}//${window.location.host}/SharedGallery?eventid=${searchResult?.id}`
                )
              }
            >
              <i
                class="fa fa-share-alt"
                aria-hidden="true"
               
              ></i>
              <input
                ref={galleryUrlRef}
                type="text"
                style={{ position: "absolute", left: "-9999px" }}
                readOnly
              />
            </div>
            <h6>Event Name: {searchResult?.eventName}</h6>
            <h6>Event ID: {searchResult?.id}</h6>
            <h6>Uploaded on: {convertDateToUTC(searchResult?.eventDate)}</h6>
            <h6>Uploaded by: </h6>
            <div className="container-fluid d-flex justify-content-start align-items-center flex-wrap">
              {searchResult?.orders[0]?.orderItems?.map((sb) => (
                <div className="p-3 d-flex flex-column justify-content-center align-items-center sb-list">
                  <div className="profile-img-con">
                    <img
                      src={
                        process.env.REACT_APP_S3_BASE_URL_PORTFOLIO +
                        "/" +
                        sb?.sbUser?.sbPortfolios[0]?.profileImageUrl
                      }
                      className="mh-3"
                      alt="shutterb profile pic"
                    />
                  </div>
                  <p className="p-0 m-0">
                    {sb?.sbUser?.sbPortfolios[0]?.profileName}
                  </p>
                  <p className="p-0 m-0">
                    {sb?.sbUser?.address?.currentLocation}
                  </p>
                </div>
              ))}
            </div>
            {/* link opens on clicking */}
            <Link
              className="btn-simple view-event-btn d-flex align-items-center justify-content-center p-0 d-block w-100 col-md-5"
              to={`/SharedGallery?eventid=${searchResult?.id}`}
            >
              View Gallery
            </Link>
          </div>
        </div>
      )}

      <div className="row">
        <div className="col px-0">
          <div className="d-flex justify-content-start">
            <form onSubmit={handleSubmit}>
              <input
                type="search"
                name="eventId"
                placeholder="Enter Event ID"
                minLength={3}
                maxLength={30}
                pattern="^[a-zA-Z0-9]*$"
                title="Only alphanumeric characters are allowed."
                required
              />
              <button className="btn-simple search-btn mb-2" type="submit">
                SEARCH
              </button>
            </form>
          </div>
          <div>
            <h6>
              <strong>Recent search</strong>
            </h6>
            <table>
              <tbody>
                {recentSearches?.map((item) => (
                  <tr key={item.eventId}>
                    <td>{item.eventName}</td>
                    <td>
                      - Event ID:{" "}
                      <span
                        className="cursor-point text-primary"
                        onClick={() => {
                          handleSearch(item.eventId);
                        }}
                      >
                        <u>{item.eventId}</u>
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  userId: state.authentication.userId,
  searchResult: state.customerDashboard.attendedEvents.searchResult,
  recentSearches: state.customerDashboard.attendedEvents.recentSearches,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getRecentSearches: (userId) =>
    dispatch(customerDashboardActions.attendedEventRecentSearches(userId)),
  getSearchResult: (eventId, userId) =>
    dispatch(
      customerDashboardActions.attendedEventSearchResult(eventId, userId)
    ),
});

// mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AttendedEventsTab));
