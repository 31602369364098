import { HubConnectionBuilder } from "@microsoft/signalr";
import { getDecryptPayload } from "../system";

const startConnection = async (userId,sbUserId,eventId, currentUser = null) => {
  const connection = new HubConnectionBuilder()
    .withUrl(
      `${process.env.REACT_APP_SERVER_BASE_URL}messageBoardHub?userId=${userId}&sbUserId=${sbUserId}&eventId=${eventId}&currentUser=${currentUser}`,
      {
        accessTokenFactory: () => {
          const token = getDecryptPayload().token;
          return token;
        },
      }
    )
    .withAutomaticReconnect()
    .build();
  try {
    await connection.start();
  } catch (err) {
    console.error(err);
  }

  return connection;
};


const sendMessage = async (connection, data) => {
  try {
    await connection.invoke("SendMessage", data);
  } catch (err) {
    console.error(err);
  }
};

export { startConnection, sendMessage };
