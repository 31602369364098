import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import MySchedule from "./MySchedule";
import MyPortfolio from "./MyPortfolio";
import MyInfo from "./MyInfo";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { shutterbDashboardConstants } from "../../../../../constants";
import { NavigateToPath } from "../../../../../system";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const MyProfileTab = () => {
  const { nestedTab } = useParams();
  const [value, setValue] = React.useState();
  const location = useLocation();
  const history = useHistory();

  const handleUpdateParams = (tab) => {
    let newLocation =
      location.pathname.slice(0, location.pathname.lastIndexOf("/") + 1) + tab;
    return newLocation;
  };

  React.useEffect(() => {
    setValue(
      nestedTab === shutterbDashboardConstants.MY_SCHEDULE
        ? shutterbDashboardConstants.MY_SCHEDULE_TAB_NUMBER
        : nestedTab === shutterbDashboardConstants.MY_PORTFOLIO
        ? shutterbDashboardConstants.MY_PORTFOLIO_TAB_NUMBER
        : nestedTab === shutterbDashboardConstants.MY_INFO
        ? shutterbDashboardConstants.MY_INFO_TAB_NUMBER
        : shutterbDashboardConstants.DEFAULT_TAB_NUMBER
    );
  }, [nestedTab]);
  return (
    <Box sx={{ width: "100%" }} className="sb-my-profil-con">
      <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
        <Tabs
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          value={value}
          aria-label="basic tabs example"
          className="sb-my-profile-tabs"
        >
          <Tab
            label="MY SCHEDULE"
            onClick={() => {
              NavigateToPath(
                history,
                handleUpdateParams(shutterbDashboardConstants.MY_SCHEDULE)
              );
            }}
            {...a11yProps(0)}
          />
          <Tab
            label="MY PORTFOLIO"
            onClick={() => {
              NavigateToPath(
                history,
                handleUpdateParams(shutterbDashboardConstants.MY_PORTFOLIO)
              );
            }}
            {...a11yProps(1)}
          />
          <Tab
            label="MY INFO"
            onClick={() => {
              NavigateToPath(
                history,
                handleUpdateParams(shutterbDashboardConstants.MY_INFO)
              );
            }}
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>
      <TabPanel
        value={value}
        index={shutterbDashboardConstants.MY_SCHEDULE_TAB_NUMBER}
      >
        <MySchedule />
      </TabPanel>
      <TabPanel
        value={value}
        index={shutterbDashboardConstants.MY_PORTFOLIO_TAB_NUMBER}
      >
        <MyPortfolio />
      </TabPanel>
      <TabPanel
        value={value}
        index={shutterbDashboardConstants.MY_INFO_TAB_NUMBER}
      >
        <MyInfo />
      </TabPanel>
    </Box>
  );
};

export default MyProfileTab;
