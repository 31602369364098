import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import ViewGallery from "../../../../../../../shared/components/ViewGallery/ViewGallery";
import { customerDashboardActions } from "../../../../../../../redux/actions/customerDashboard.actions";
import { customerConstants } from "../../../../../../../constants";
import { convertDateToUTC } from "../../../../../../../shared/utility";
import { useTranslation } from "react-i18next";

const AccessGallery = (props) => {
  const { t } = useTranslation("common");
  const galleryUrlRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const { ...myEventsAccessGallery } = props.myEventsAccessGallery;
  const { eventId } = useParams();

  useEffect(() => {
    props.getMyEventsAccessGallery(eventId, files, setFiles);
    // eslint-disable-next-line
  }, []);

  const copyToClipboard = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const formProps = Object.fromEntries(formData);
     galleryUrlRef.current.value = formProps.galleryLink;
     galleryUrlRef.current.select();
     document.execCommand("copy");
    alert("Link Copied");
  };

  const handleDelete = async () => {
    const filesToDeleteFromServer = selectedFiles.filter((file) => file.id);
    const filesToRemoveLocally = selectedFiles.filter((file) => !file.id);

    if (filesToDeleteFromServer.length > 0) {
      const idArray = filesToDeleteFromServer.map((file) => file.id);
      props.deleteFromGallery(
        { ids: idArray },
        eventId,
        files,
        setFiles,
        selectedFiles,
        setSelectedFiles
      );
    }

    if (filesToRemoveLocally.length > 0) {
      setFiles((prevFiles) =>
        prevFiles.filter((file) => !filesToRemoveLocally.includes(file))
      );
    }

    if (
      filesToRemoveLocally.length > 0 &&
      filesToDeleteFromServer.length === 0
    ) {
      setSelectedFiles([]);
    }
  };

  const handleLock = () => {
    const filesToLock = selectedFiles.filter((file) => file.id);

    if (filesToLock.length > 0) {
      const galleryFiles = filesToLock.map((file) => ({
        Id: file.id,
        IsLocked: file.isLocked ? false : true,
      }));

      props.lockFromGallery(
        { GalleryFiles: galleryFiles },
        eventId,
        files,
        setFiles
      );
    }

    setSelectedFiles([]);
  };

  return (
    <div className="container access-gallery px-0">
      <div className="row">
        <div className="col d-flex justify-content-center align-items-center flex-column">
          <p className="p-0 m-0">
            {myEventsAccessGallery?.eventName +
              " - " +
              convertDateToUTC(myEventsAccessGallery?.eventDate)}
          </p>
          <p className="p-0 m-0">
            <strong> Event ID:</strong> {myEventsAccessGallery?.id}
          </p>
        </div>
      </div>
      <div className="row my-3">
        <div className="col px-0">
          <p className="p-0 m-0 mb-2">Share this gallery with your guests</p>
          <div className="d-flex col-12 justify-content-start w-100 px-0">
            <form className="col-12 px-0" onSubmit={copyToClipboard}>
              <input
                type="text"
                readonly="true"
                name="galleryLink"
                className="col-md-7 w-100"
                ref={galleryUrlRef}
                value={`${window.location.protocol}//${window.location.host}/SharedGallery?eventid=${myEventsAccessGallery?.id}`}
              />
              <button
                className="btn copy-btn col-md-5 px-0 mt-md-0 mt-2 min-h-45"
                type="submit"
              >
                COPY
              </button>
            </form>
          </div>
        </div>
      </div>
      {files?.length > 0 ? (
        <div className="row">
          <div className="col px-0">
            <ViewGallery
              files={files}
              setFiles={setFiles}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              handleDelete={handleDelete}
              handleLock={handleLock}
              isModalAllowed
              isAllowDownload
              isAllowLock
              isTabsDisplay
            />
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-start mt-5 access-gallery-fonts">
          <p>{t("no_image")}</p>
        </div>
      )}
    </div>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  myEventsAccessGallery: state.customerDashboard.myEventsAccessGallery,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getMyEventsAccessGallery: (eventId, files, setFiles) =>
    dispatch(
      customerDashboardActions.myEventsAccessGallery(
        eventId,
        files,
        setFiles,
        customerConstants.ACCESS_GALLERY_CALL
      )
    ),
  deleteFromGallery: (
    dataObj,
    eventId,
    files,
    setFiles,
    selectedFiles,
    setSelectedFiles
  ) =>
    dispatch(
      customerDashboardActions.deleteFromGallery(
        dataObj,
        eventId,
        files,
        setFiles,
        selectedFiles,
        setSelectedFiles,
        customerConstants.ACCESS_GALLERY_CALL
      )
    ),
  lockFromGallery: (data, eventId, files, setFiles) =>
    dispatch(
      customerDashboardActions.lockFromGallery(data, eventId, files, setFiles)
    ),
});

// mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AccessGallery));
