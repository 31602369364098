import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { customToast } from "../../../../shared";
import { eventBookingActions } from "../../../../redux/actions/eventBooking.actions";
import { shutterbProfileActions } from "../../../../redux/actions/shutterbProfile.actions";
import ViewShutterBProfile from "../../ShutterBProfile/ViewShutterBProfile";
import { eventBookingConstants } from "../../../../constants";
import DraftEvent from "./DraftEvent";

const SelectShutterB = (props) => {
  const { t } = useTranslation("common");
  const { TABS } = eventBookingConstants;
  const existingData = localStorage.getItem("dataToSend");
  const parsedData = JSON.parse(existingData);
  const prevCards = parsedData?.Event?.Order?.orderItems ?? [];

  const [selectedCards, setSelectedCards] = useState(
    prevCards ? prevCards : []
  );
  // eslint-disable-next-line
  const [currentImageIndices, setCurrentImageIndices] = useState(
    Array(props.availableShutterBs.length).fill(0)
  );
  const [selectedShutterBId, setSelectedShutterBId] = useState(null);

  const handlePrevImage = (index) => {
    setCurrentImageIndices((prevIndices) => {
      const newIndices = [...prevIndices];
      newIndices[index] =
        (newIndices[index] -
          1 +
          props.availableShutterBs[index].images.length) %
        props.availableShutterBs[index].images.length;
      return newIndices;
    });
  };

  const handleNextImage = (index) => {
    setCurrentImageIndices((prevIndices) => {
      const newIndices = [...prevIndices];
      newIndices[index] =
        (newIndices[index] + 1) % props.availableShutterBs[index].images.length;
      return newIndices;
    });
  };

  const handleCardClick = (index, itemId) => {
    if (
      selectedCards.some((card) =>
        typeof card === "object" ? card.id === itemId : card === itemId
      )
    ) {
      // Deselect the card
      setSelectedCards((prevSelectedCards) =>
        prevSelectedCards.filter((selectedItem) =>
          typeof selectedItem === "object"
            ? selectedItem.id !== itemId
            : selectedItem !== itemId
        )
      );
    } else {
      // Select the card
      setSelectedCards((prevSelectedCards) => {
        const isObject = typeof itemId === "object";
        const existingItem = prevSelectedCards.find((selectedItem) =>
          isObject ? selectedItem.id === itemId.id : selectedItem === itemId
        );

        if (existingItem) {
          // Remove the existing item before adding the new one
          return prevSelectedCards.filter((selectedItem) =>
            isObject ? selectedItem.id !== itemId.id : selectedItem !== itemId
          );
        } else {
          return [...prevSelectedCards, itemId];
        }
      });
    }
  };

  useEffect(() => {
    const selectedData = selectedCards?.map((id) => {
      const matchingItem =
        typeof id === "object"
          ? props.availableShutterBs.find((item) => item.id === id.id)
          : props.availableShutterBs.find((item) => item.id === id);
      return matchingItem;
    });

    const filteredData = selectedData?.filter((item) => item !== undefined);
    if (filteredData?.length > 0) {
      props.setSelectedShutterBz([...filteredData]);
    } else {
      props.setSelectedShutterBz([]);
    }
    // eslint-disable-next-line
  }, [selectedCards, props.setSelectedShutterBz, props.availableShutterBs]);

  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem("activeTab", TABS.SELECT_SHUTTERB);
    const dataToGetShutterBz = {
      eventDate: parsedData?.Event?.eventDate,
      userLongitude: props.model?.latLng
        ? props.model.latLng.lng
        : parsedData?.Event?.latLng?.lng,
      userLatitude: props.model?.latLng
        ? props.model.latLng.lat
        : parsedData?.Event?.latLng?.lat,
      sBStartTime: parsedData?.Event?.sbStartTime,
      duration: parseInt(parsedData?.Event?.duration),
      postalCode: props.model?.postalCode
        ? props.model.postalCode
        : parsedData?.Event?.postalCode,
    };
    props.getAvailableShutterBs(dataToGetShutterBz);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setCurrentImageIndices(Array(props.availableShutterBs.length).fill(0));
    // eslint-disable-next-line
  }, [props.availableShutterBs]);

  const handleButtonClick = () => {
    window.dataLayer.push({
      event: "site_action",
      action: "continue_booking",
    });
    customToast(t("added_to_cart"), toast.TYPE.SUCCESS);
    props.onNext();
  };

  const handleViewProfileClick = (id) => {
    setSelectedShutterBId(id);
    props.getShutterBProfile(id);
  };

  return (
    <>
      {selectedShutterBId ? (
        <ViewShutterBProfile
          allowBooking
          handleCardClick={handleCardClick}
          setSelectedShutterBId={setSelectedShutterBId}
        />
      ) : (
        <div>
          <div className="text-center">
            <p className="m-0 text-style">{props.model.eventname}</p>
            <p className="m-0 text-style">{props.model.eventlocation}</p>
            <p className="m-0 text-style">
              {props.model.duration} Hour Reservation{" "}
            </p>
            <p className="m-0 text-style">
              For{" "}
              {(() => {
                const parts = props.model?.eventdate?.split("-");
                const year = parts && parts[0];
                const monthIndex = parts && parseInt(parts[1], 10) - 1;
                const day = parts && parseInt(parts[2], 10);

                const monthNames = [
                  "January",
                  "February",
                  "March",
                  "April",
                  "May",
                  "June",
                  "July",
                  "August",
                  "September",
                  "October",
                  "November",
                  "December",
                ];

                return `${monthNames[monthIndex]} ${day}, ${year}`;
              })()}{" "}
              at{" "}
              {new Date(props.model.shutterb_start_time).toLocaleString(
                "en-US",
                {
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                }
              )}{" "}
              <a href={props.onBack} onClick={props.onBack}>
                <i className="fa fa-edit edit-icon"></i>
              </a>
            </p>
          </div>
          <div className="text-center">
            <p>{props.shutterBCount} shutterbz available for your event </p>
          </div>
          <div className="card-container col-12">
            <div className="row col-12 px-0 card-container mx-0">
              {props.availableShutterBs.length > 0 ? (
                props.availableShutterBs.map((item, index) => (
                  <div
                    className={`card col-12 col-md-6 px-0 flex-lg-row flex-md-row flex-column flex-basis-500 ${
                      selectedCards?.some((card) =>
                        typeof card === "object"
                          ? card.id === item.id
                          : card === item.id
                      )
                        ? "selected"
                        : ""
                    }`}
                    key={index}
                  >
                    {/* Content for small screens */}
                    <div className="image-container overflow-visible mr-0 col-12 col-md-6 p-0 px-0 min-max-height-360">
                      {item.images.length > 1 && (
                        <button
                          className="select-shutterB-prev-btn booking-prev-next-btns booking-prev-btn"
                          onClick={() => handlePrevImage(index)}
                        >
                          <img
                            src="/images/previousArrowButtonWhite.svg"
                            alt="previousArrowButton"
                            className=""
                          />
                        </button>
                      )}
                      <img
                        src={`${process.env.REACT_APP_S3_BASE_URL_PORTFOLIO}/${
                          item.images[currentImageIndices[index]]
                        }`}
                        className="w-100"
                        alt="..."
                        style={{
                          maxHeight: "360px",
                          minHeight: "100%",
                          objectFit: "cover",
                        }}
                      />
                      {item.images.length > 1 && (
                        <button
                          className="select-shutterB-next-btn booking-prev-next-btns booking-next-btn"
                          onClick={() => handleNextImage(index)}
                        >
                          <img
                            src="/images/nextArrowButtonWhite.svg"
                            alt="nextArrowButton"
                            className=""
                          />
                        </button>
                      )}
                    </div>

                    <div className="card-body card-border-left col-12 col-md-6 min-max-height-360">
                      <div className="row col-12 px-0 min-max-height-110 mx-0">
                        <div className="col-5 px-1">
                          <figure>
                            <img
                              className="rounded-circle hw-70 obj-fit-cover"
                              src={`${process.env.REACT_APP_S3_BASE_URL_PORTFOLIO}/${item.sbportfolioGalleries[0]?.profileImageUrl}`}
                              alt=""
                            />
                          </figure>
                        </div>
                        <div className="col-7 px-0">
                          <p className="font-weight-bold mb-0 fw-600 font-size-14">
                            {item?.sbportfolioGalleries &&
                            item.sbportfolioGalleries[0] &&
                            item.sbportfolioGalleries[0]?.profileName
                              ? `${item.sbportfolioGalleries[0]?.profileName}`
                                  .length > 23
                                ? `${item.sbportfolioGalleries[0]?.profileName}`.substring(
                                    0,
                                    23
                                  ) + "..."
                                : `${item.sbportfolioGalleries[0]?.profileName}`
                              : ""}
                          </p>
                          <p className="mb-0 font-size-14">
                            <i
                              className="fa fa-star color-custom mb-0"
                              aria-hidden="true"
                            ></i>{" "}
                            {item.averageReview ? item.averageReview : "N/A"}
                          </p>
                          <p className="mb-0 font-size-14">
                            <i
                              className="fa fa-mobile color-custom"
                              aria-hidden="true"
                            ></i>{" "}
                            {item?.device?.deviceName
                              ? item.device.deviceName.length > 22
                                ? item.device.deviceName.substring(0, 22) +
                                  "..."
                                : item.device.deviceName
                              : ""}
                          </p>
                        </div>
                      </div>
                      <div className="col-12 px-0 d-flex flex-column justify-content-between min-max-height-160">
                        <div className="d-flex justify-content-start pt-2 min-max-height-50">
                          <p className="font-size-14">
                            {item.sbportfolioGalleries[0]?.bio &&
                            item.sbportfolioGalleries[0]?.bio.length > 50
                              ? item.sbportfolioGalleries[0]?.bio.substring(
                                  0,
                                  50
                                ) + "..."
                              : item.sbportfolioGalleries[0]?.bio}
                          </p>
                        </div>
                        <div className="booking-btn-custom-height">
                          <div className="d-flex flex-column">
                            {/* eslint-disable-next-line*/}
                            <a
                              href="#"
                              className="btn mb-2 w-100 p-0 booking-a-custom-height"
                              onClick={() => handleViewProfileClick(item?.id)}
                            >
                              View Profile
                            </a>
                            {/* eslint-disable-next-line */}
                            <a
                              href="#"
                              className="btn w-100 mb-4 p-0 booking-a-custom-height"
                              onClick={() => handleCardClick(index, item.id)}
                            >
                              Reserve {item.firstName}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <DraftEvent onBack={props.onBack} />
              )}
            </div>
          </div>
          {props.selectedShutterBz?.length > 0 && (
            <div className="text-center show_continue_booking_shutterb  h-auto d-flex justify-content-center align-items-center">
              <button
                type="button"
                className="btn addToCart"
                onClick={handleButtonClick}
              >
                Continue Booking
              </button>
            </div>
          )}
          <div className="row pt-4 ml-1">
            <div className="event-booking-form-btn">
              <button type="submit" className="back-btn" onClick={props.onBack}>
                Back
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  shutterBCount: state.eventBooking.shutterBCount,
  availableShutterBs: state.eventBooking.availableShutterBs,
  selectedShutterBz: state.eventBooking.selectedShutterBz,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  setSelectedShutterBz: (data) =>
    dispatch(eventBookingActions.setSelectedShutterBz(data)),
  getAvailableShutterBs: (data) =>
    dispatch(eventBookingActions.getAvailableShutterBs(data)),
  getShutterBProfile: (id) =>
    dispatch(shutterbProfileActions.getShutterBProfile(id)),
});

// mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SelectShutterB));
