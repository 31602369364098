import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter, useParams } from "react-router-dom";
import Modal from "../../../../container/Views/Login/Modal";
import { eventBookingActions } from "../../../../redux/actions/eventBooking.actions";
import { customerConstants } from "../../../../constants";
import { useRef } from "react";
import { eventBookingConstants } from "../../../../constants";
import {
  convertEpochToFormattedDate,
  convertLongDateToShortDate,
} from "../../../../shared/utility";
import DraftEventCart from "./DraftEventCart";

const Cart = (props) => {
  /* eslint-disable */
  const { TABS } = eventBookingConstants;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [...customerDashboardCart] = props.customerDashboardCart;
  const { ...eventDetails } = props.eventDetails;
  const { ...miscAmounts } = props.miscAmounts;
  const notNewEvent = localStorage.getItem(customerConstants.NOT_NEW_EVENT);
  const cartPreviousScreen = localStorage.getItem(
    customerConstants.CART_PREVIOUS_SCREEN
  );
  const [promoCodeDiscount, setPromoCodeDiscount] = useState();
  const isOnlyReel = localStorage.getItem("isOnlyReel");
  const { isDraftEvent } = useParams();
  const remainingBalance = useRef(0);
  const fee = useRef(0);
  const serviceFee = useRef(0);
  const tax = useRef(0);
  const netTotal = useRef(0);
  const discount = useRef(0);

  const roundToTwoDecimal = (num) => {
    num = num.toString(); //If it's not already a String
    num = num.includes(".") ? num.slice(0, num.indexOf(".") + 3) : num; //With 3 exposing the hundredths place
    return Number(num);
  };
  const calculateSubtotal = () => {
    fee.current = 0;
    if (!notNewEvent || isOnlyReel) {
      let subtotal = 0;
      if (props.selectedShutterBz) {
        props.selectedShutterBz.forEach((shutterB) => {
          fee.current += (shutterB?.hourlyRate ?? 0) * props.model.duration;
        });
      }
      // adding reel total price if there's any in order items
      if (
        props.addReel?.isReelSubmitted &&
        props.addReel?.isReelWithLogo &&
        !props.addReel?.isReelWithoutLogo
      ) {
        fee.current +=
          props.addReel?.reelQtyWithLogo * props.addReel?.reelPriceWithLogo;
      } else if (
        props.addReel?.isReelSubmitted &&
        !props.addReel?.isReelWithLogo &&
        props.addReel?.isReelWithoutLogo
      ) {
        fee.current +=
          props.addReel?.reelQtyWithoutLogo *
          props.addReel?.reelPriceWithoutLogo;
      }
      if (promoCodeDiscount?.type === "$") {
        serviceFee.current = roundToTwoDecimal(
          (fee.current * miscAmounts?.serviceFee ?? 0) / 100
        );
        subtotal = roundToTwoDecimal(fee.current + serviceFee.current);
        if (promoCodeDiscount?.value > subtotal) {
          if (promoCodeDiscount.isGiftCard) {
            remainingBalance.current = promoCodeDiscount.value - fee.current;
          }
          discount.current = fee.current;
        } else {
          remainingBalance.current = 0;
          discount.current = Number(promoCodeDiscount?.value);
        }
      } else if (promoCodeDiscount?.type === "%") {
        discount.current =
          (fee.current * Number(promoCodeDiscount?.value)) / 100;
      } else {
        discount.current = 0;
      }
      fee.current -= discount.current;
      // Retrieve dataToSend from localStorage
      const existingData = localStorage.getItem("dataToSend");
      const parsedData = JSON.parse(existingData);
      serviceFee.current = roundToTwoDecimal(
        (fee.current * miscAmounts?.serviceFee ?? 0) / 100
      );
      subtotal = roundToTwoDecimal(fee.current + serviceFee.current);
      tax.current = roundToTwoDecimal((subtotal * miscAmounts?.tax ?? 0) / 100);
      // service fee is aleady added in subTotal
      netTotal.current = roundToTwoDecimal(subtotal + tax.current);
      // Update the Order property in the dataToSend object
      if (parsedData) {
        parsedData.Event = {
          ...parsedData.Event,
          additionalNotes:
            props.formState.additionalNotes != undefined
              ? props.formState.additionalNotes
              : parsedData.Event.notes,
        };
        parsedData.Event.Order = {
          ...parsedData?.Event?.Order,
          serviceFee: Number(String(serviceFee.current)),
          tax: Number(String(tax.current)),
          taxInPercentage: miscAmounts?.tax,
          discount: Number(String(discount.current)),
          totalAmount: Number(String(netTotal.current)),
          orderDiscountDetail: promoCodeDiscount
            ? {
                ...promoCodeDiscount,
                userId: props.userId,
                amount: promoCodeDiscount?.value,
                promoBalance: remainingBalance.current,
                id: 0,
              }
            : null,
        };
        // parsedData.Event.Order.totalAmount = parseFloat(subtotal);
        // Save the updated dataToSend object back to localStorage
        localStorage.setItem("dataToSend", JSON.stringify(parsedData));
      }
      return subtotal;
    } else {
      let subTotal = 0;
      if (cartPreviousScreen === customerConstants.TIP_TO_CART) {
        fee.current = customerDashboardCart.reduce((sum, sb) => {
          return sum + Number(sb.tipPrice_);
        }, 0);
      } else if (cartPreviousScreen === customerConstants.EXTEND_TO_CART) {
        fee.current = customerDashboardCart.reduce((sum, sb) => {
          return sum + Number(sb.extendedPrice_);
        }, 0);
      } else {
        fee.current = customerDashboardCart.reduce((sum, sb) => {
          if (sb?.extendedPrice_) {
            return sum + Number(sb.extendedPrice_);
          } else if (sb?.tipPrice_) {
            return sum + Number(sb.tipPrice_);
          } else {
            return sum;
          }
        }, 0);
      }
      if (promoCodeDiscount?.type === "$") {
        discount.current = Number(promoCodeDiscount?.value);
      } else if (promoCodeDiscount?.type === "%") {
        discount.current = roundToTwoDecimal(
          (subTotal * Number(promoCodeDiscount?.value)) / 100
        );
      } else {
        discount.current = 0;
      }
      fee.current -= discount.current;
      serviceFee.current = roundToTwoDecimal(
        (fee.current * miscAmounts?.serviceFee ?? 0) / 100
      );
      subTotal = fee.current + serviceFee.current;
      // as tax cannot be applied on total tip amount
      const tipTotalPrice = customerDashboardCart.reduce((accumulator, sb) => {
        if (sb.hasOwnProperty(eventBookingConstants.TIP_PRICE_)) {
          return accumulator + Number(sb.tipPrice_);
        } else {
          return accumulator;
        }
      }, 0);
      let serviceFeeOfTotalTip =
        (tipTotalPrice * miscAmounts?.serviceFee ?? 0) / 100;
      tax.current = roundToTwoDecimal(
        ((subTotal - (tipTotalPrice + serviceFeeOfTotalTip)) *
          miscAmounts?.tax ?? 0) / 100
      );
      // service fee is already added in subTotal
      netTotal.current = roundToTwoDecimal(subTotal + tax.current);
      if (customerDashboardCart.length === 0) {
        serviceFee.current = 0;
        tax.current = 0;
        discount.current = 0;
        netTotal.current = 0;
        fee.current = 0;
      }
      return subTotal;
    }
  };

  const handleRemoveShutterB = (index) => {
    if (!notNewEvent) {
      const updatedSelectedShutterBz = [...props.selectedShutterBz];
      // storing the deleted order item to set undoCart
      const deletedCartItem = updatedSelectedShutterBz.splice(index, 1);
      props.setUndoCartItem(...deletedCartItem);
      props.setSelectedShutterBz(updatedSelectedShutterBz);
    } else {
      const updatedSelectedShutterBz = [...customerDashboardCart];
      // storing the deleted order item to set undoCart
      const deletedCartItem = updatedSelectedShutterBz.splice(index, 1);
      props.setUndoCartItem(...deletedCartItem);
      props.updateCustomerDashboardCart(updatedSelectedShutterBz);
    }
  };

  const handleRemoveReel = () => {
    const defaultReelObj = {
      isReelWithoutLogo: false,
      reelPriceWithoutLogo: 75,
      reelQtyWithoutLogo: 0,
      isReelWithLogo: true,
      reelPriceWithLogo: 100,
      reelQtyWithLogo: 0,
      logo: {
        preview: "",
        raw: "",
      },
      musicFile: {
        preview: "",
        filePath: null,
      },
      reelQuestion1: "",
      music: "",
      orientation: "Portrait (Instagram, TikTok)",
      terms: false,
      reelTotal: 0,
      isReelSubmitted: false,
      reelType: "",
    };
    props.setReelValues({
      value: defaultReelObj,
    });
  };

  const handleCheckout = () => {
    if (!notNewEvent || isOnlyReel) {
      !notNewEvent &&
        window.dataLayer.push({
          event: "site_action",
          action: "checkout",
        });
      props.setCartTab();
      props.onCheckOut();
    } else {
      props.setCartTab();
      const dataObj = {
        Event: {
          id: eventDetails.id,
          userId: eventDetails.userId,
          Order: {
            serviceFee: serviceFee.current,
            tax: tax.current,
            taxInPercentage: miscAmounts.tax,
            discount: discount?.current,
            totalAmount: netTotal.current,
            id: isOnlyReel ? 0 : eventDetails?.orders[0]?.id,
            orderDiscountDetail: promoCodeDiscount
              ? {
                  ...promoCodeDiscount,
                  userId: props.userId,
                  amount: promoCodeDiscount?.value,
                  id: 0,
                }
              : null,
            orderItems: customerDashboardCart.map((sb) => {
              if (
                sb.hasOwnProperty(eventBookingConstants.TIP_PRICE_) &&
                !sb.hasOwnProperty(eventBookingConstants.EXTENDED_PRICE_)
              ) {
                return {
                  ...sb,
                  tipPrice: sb.tipPrice_,
                  price: 0,
                  quantity: 0,
                };
              } else if (
                !sb.hasOwnProperty(eventBookingConstants.TIP_PRICE_) &&
                sb.hasOwnProperty(eventBookingConstants.EXTENDED_PRICE_)
              ) {
                return {
                  ...sb,
                  tipPrice: 0,
                  price: sb.extendedPrice_,
                  quantity: sb.extendedQuantity_,
                };
              }
            }),
          },
        },
      };
      props.onCheckOut(false, dataObj);
    }
  };

  const handlePromoCodeSubmit = (e) => {
    e.preventDefault();
    if (props.isAuthenticated) {
      const formData = new FormData(e.target);
      const formProps = Object.fromEntries(formData);
      const dataObj = {
        code: formProps.promoCode,
        userId: props.userId,
      };
      const subtotalForPromoCode = calculateSubtotal().toFixed(2);
      props.applyCoupon(
        dataObj,
        promoCodeDiscount,
        setPromoCodeDiscount,
        subtotalForPromoCode,
        fee?.current,
        netTotal.current
      );
    }
  };

  useEffect(() => {
    calculateSubtotal();
    props.getMiscAmounts();

    calculateSubtotal();
    // eslint-disable-next-line
  }, [props.selectedShutterBz]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const parsedData = JSON.parse(localStorage.getItem("dataToSend"));
    console.log("parsedData", parsedData);
    localStorage.setItem("activeTab", TABS.CART);
    return () => {
      props.setUndoCartItem({});
    };
    // eslint-disable-next-line
  }, []);

  // fixes the null tax and service fee in new event book case
  calculateSubtotal();

  return (
    <>
      {!notNewEvent ? (
        Number(isDraftEvent) === TABS.CART ? (
          <DraftEventCart
            handleCheckout={handleCheckout}
            handlePromoCodeSubmit={handlePromoCodeSubmit}
            calculateSubtotal={calculateSubtotal}
            setIsModalOpen={setIsModalOpen}
            handleRemoveShutterB={handleRemoveShutterB}
            handleRemoveReel={handleRemoveReel}
            setUndoCartItem={props.setUndoCartItem}
            fee={fee}
            tax={tax}
            serviceFee={serviceFee}
            netTotal={netTotal}
            discount={discount}
            miscAmounts={miscAmounts}
            onBack={props.onBack}
            model={props.model}
            setmodel={props.setmodel}
            props={props}
          />
        ) : (
          <section className="h-100 h-custom">
            <div className="container h-100 py-5">
              <div className="row d-flex justify-content-center align-items-center h-100">
                <div className="p-0 col outer-border">
                  <div className="table-responsive border-0">
                    <table className="table my-custom-table">
                      <thead className="d-none d-md-none d-lg-block d-xl-block header-border brdr-btm-hidden">
                        <tr className="row col-12 px-0 mx-0">
                          {/* This <th> will be displayed on medium and small screens */}
                          <th
                            className="d-table-cell d-md-none col-1 d-flex align-items-center bordr-top-hidden"
                            scope="col"
                          ></th>
                          {/* This <td> will be displayed on larger screens */}
                          <th className="d-none d-md-table-cell col-1 d-flex align-items-center bordr-top-hidden bordr-btm-hidden">
                            {/* Add your content here */}
                          </th>
                          <th
                            className="d-table-cell d-md-none col-3 d-flex align-items-center bordr-top-hidden"
                            scope="col"
                          ></th>
                          <th
                            className="d-none d-md-table-cell col-3 d-flex align-items-center bordr-top-hidden  bordr-btm-hidden"
                            scope="col"
                          ></th>
                          <th
                            className="d-table-cell d-md-none col-5 d-flex align-items-center px-0 bordr-top-hidden"
                            scope="col"
                          >
                            Details
                          </th>
                          <th
                            className="d-none d-md-table-cell col-5 d-flex align-items-center px-0 bordr-top-hidden bordr-btm-hidden"
                            scope="col"
                          >
                            Details
                          </th>

                          <th
                            className="d-table-cell d-md-none  col-1 d-flex align-items-center px-0 bordr-top-hidden"
                            scope="col"
                          >
                            Hourly Rate
                          </th>
                          <th
                            className="d-none d-md-table-cell col-1 d-flex align-items-center px-0 bordr-top-hidden bordr-btm-hidden"
                            scope="col"
                          >
                            Hourly Rate
                          </th>

                          <th
                            className="d-table-cell d-md-none  col-1 d-flex align-items-center bordr-top-hidden"
                            scope="col"
                          >
                            Qty
                          </th>
                          <th
                            className="d-none d-md-table-cell col-1 d-flex align-items-center bordr-top-hidden bordr-btm-hidden"
                            scope="col"
                          >
                            Qty
                          </th>
                          <th
                            className="d-table-cell d-md-none col-1 d-flex align-items-center px-0 bordr-top-hidden"
                            scope="col"
                          >
                            Total
                          </th>
                          <th
                            className="d-none d-md-table-cell col-1 d-flex align-items-center px-0 bordr-top-hidden bordr-btm-hidden"
                            scope="col"
                          >
                            Total
                          </th>
                        </tr>
                      </thead>
                      <tbody className="brdr-btm">
                        {props.selectedShutterBz?.map(
                          (shutterB, index) =>
                            shutterB.orderItemTypeId !==
                              eventBookingConstants.ORDER_ITEM_TYPE_ID.REEL && (
                              <tr
                                className="row col-12 px-0 mx-xs-0 mx-sm-0 mx-md-0 mx-lg-0 mx-xl-0 mx-0"
                                key={index}
                              >
                                <td className="d-none d-lg-table-cell col-6 col-lg-1">
                                  {/* This will be displayed on larger and extra large screens */}
                                  <div className="flex-column mr-4 ms-4">
                                    <div
                                      onClick={() =>
                                        handleRemoveShutterB(index)
                                      }
                                    >
                                      <i
                                        className="fas fa-trash-alt mx-4 mt-4 mb-0 clr-grey cursor-point"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                  </div>
                                </td>
                                <td className="d-table-cell d-lg-none col-6 col-lg-1 back-dull">
                                  <div className="flex-column mr-4 ms-4">
                                    <div
                                      onClick={() =>
                                        handleRemoveShutterB(index)
                                      }
                                    >
                                      <i
                                        className="fas fa-trash-alt mx-4 mt-4 mb-0 clr-grey cursor-point"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                  </div>
                                </td>
                                <td className="d-none d-lg-table-cell col-6 col-lg-3">
                                  {/* This will be displayed on larger and extra large screens */}
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={`${
                                        process.env
                                          .REACT_APP_S3_BASE_URL_PORTFOLIO
                                      }/${
                                        shutterB?.sbportfolioGalleries &&
                                        shutterB?.sbportfolioGalleries[0]
                                          ?.profileImageUrl
                                      }`}
                                      className="img-fluid rounded-circle cart-image min-width-100"
                                      alt="Book"
                                    />
                                  </div>
                                </td>
                                {/* Second <td> for medium and smaller screens */}
                                <td className="d-table-cell d-lg-none col-6 col-lg-3 back-dull">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={`${
                                        process.env
                                          .REACT_APP_S3_BASE_URL_PORTFOLIO
                                      }/${
                                        shutterB?.sbportfolioGalleries &&
                                        shutterB?.sbportfolioGalleries[0]
                                          ?.profileImageUrl
                                      }`}
                                      className="img-fluid rounded-circle cart-image min-width-100"
                                      alt="Book"
                                    />
                                  </div>
                                </td>
                                <td className="align-middle col-12 col-lg-5 px-4 px-md-0">
                                  <div className="d-flex col-12 px-0 flex-column">
                                    <div className="d-flex col-12 px-0 flex-row">
                                      <p className="mb-0 col-6 col-lg-4 col-xl-4 col-md-6 col-sm-6-col-xs-6 px-0 font-weight-bold">
                                        shutterb:
                                      </p>
                                      <p className="mb-0 col-6 col-lg-8 col-xl-8 col-md-6 col-sm-6-col-xs-6 px-0 ">
                                        {shutterB.firstName} {shutterB.lastName}
                                      </p>
                                    </div>
                                    <div className="d-flex col-12 px-0 flex-row">
                                      <p className="mb-0 col-6 col-lg-4 col-xl-4 col-md-6 col-sm-6-col-xs-6 px-0 font-weight-bold">
                                        Event:
                                      </p>
                                      <p className="mb-0 col-6 col-lg-8 col-xl-8 col-md-6 col-sm-6-col-xs-6 px-0 ">
                                        {props.model.eventname}
                                      </p>
                                    </div>
                                    <div className="d-flex col-12 px-0 flex-row">
                                      <p className="mb-0 col-6 col-lg-4 col-xl-4 col-md-6 col-sm-6-col-xs-6 px-0 font-weight-bold">
                                        Date:
                                      </p>
                                      <p className="mb-0 col-6 col-lg-8 col-xl-8 col-md-6 col-sm-6-col-xs-6 px-0 ">
                                        {props.model?.eventdate
                                          ?.split("-")
                                          .reverse()
                                          .join("/")}
                                      </p>
                                    </div>
                                    <div className="d-flex col-12 px-0 flex-row">
                                      <p className="mb-0 font-weight-bold col-6 col-lg-4 col-xl-4 col-md-6 col-sm-6-col-xs-6 px-0">
                                        Start Time:
                                      </p>
                                      <p className="mb-0 col-6 col-lg-8 col-xl-8 col-md-6 col-sm-6-col-xs-6 px-0 ">
                                        {/*props.model.shutterb_start_time*/}
                                        {props.model.shutterb_start_time
                                          ? new Date(
                                              props.model.shutterb_start_time
                                            ).toLocaleString("en-US", {
                                              hour: "numeric",
                                              minute: "numeric",
                                              hour12: true,
                                            })
                                          : null}{" "}
                                      </p>
                                    </div>
                                    <div className="d-flex flex-row col-12 px-0">
                                      <p className="mb-0 font-weight-bold col-6 col-lg-4 col-xl-4 col-md-6 col-sm-6-col-xs-6 px-0">
                                        Duration:
                                      </p>
                                      <p className="mb-0 col-6 col-lg-8 col-xl-8 col-md-6 col-sm-6-col-xs-6 px-0 ">
                                        {props.model.duration} hours
                                      </p>
                                    </div>
                                  </div>
                                </td>

                                <td className="row align-middle mx-0 col-12 col-lg-1 px-4 px-md-0">
                                  <p className="mb-0 d-xs-block d-sm-block d-md-block d-xl-none d-lg-none font-weight-bold col-6 px-0">
                                    Price:
                                  </p>
                                  <p className="mb-0 px-0 col-6 col-xs-6 col-sm-6 col-md-6 col-lg-12 col-xl-12 ">
                                    ${(shutterB?.hourlyRate ?? 0).toFixed(2)}
                                  </p>
                                </td>
                                <td className="row align-middle mx-0 col-12 col-lg-1 px-4 px-md-0">
                                  <div className="d-flex flex-row col-12 col-lg-1 px-lg-3 px-md-0 px-sm-0 px-xs-0 px-xl-3 px-0">
                                    <p className="mb-0 d-xs-block d-sm-block d-md-block d-xl-none d-lg-none font-weight-bold col-6 px-0">
                                      Quantity:
                                    </p>
                                    <p className="mb-0 px-0 col-6 col-xs-6 col-sm-6 col-md-6 col-lg-12 col-xl-12 ">
                                      {props.model.duration}
                                    </p>
                                  </div>
                                </td>
                                <td className="align-middle col-12 col-lg-1 px-4 px-md-0">
                                  <div className="d-flex flex-row col-12 col-lg-1 px-lg-0 px-md-0 px-sm-0 px-xs-0 px-xl-0 px-0">
                                    <p className="mb-0 d-xs-block d-sm-block d-md-block d-xl-none d-lg-none font-weight-bold col-6 px-0">
                                      Total:
                                    </p>
                                    <p className="mb-0 px-0 col-6 col-xs-6 col-sm-6 col-md-6 col-lg-12 col-xl-12 ">
                                      $
                                      {(
                                        props.model.duration *
                                          shutterB?.hourlyRate ?? 0
                                      ).toFixed(2)}
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            )
                        )}

                        {/* addReel order item (reel without logo) */}
                        {props.addReel?.isReelSubmitted &&
                          props.addReel?.isReelWithoutLogo && (
                            <tr
                              key={1}
                              className="add-reel-row row col-12 px-0 mx-xs-0 mx-sm-0 mx-md-0 mx-lg-0 mx-xl-0 mx-0"
                            >
                              <td className="d-table-cell d-lg-none col-6 col-lg-1 back-dull">
                                <div className="flex-column mr-4 ms-4 pt-2">
                                  <div onClick={() => handleRemoveReel()}>
                                    <i
                                      className="fas fa-trash-alt mx-4 clr-grey cursor-point"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </div>
                              </td>
                              <td className="d-none d-lg-table-cell col-6 col-lg-1 ">
                                <div className="flex-column mr-4 ms-4">
                                  <div onClick={() => handleRemoveReel()}>
                                    <i
                                      className="fas fa-trash-alt mx-4 clr-grey cursor-point"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </div>
                              </td>
                              <td className="d-table-cell d-lg-none col-6 col-lg-3 px-0 back-dull">
                                <div className="d-flex  align-items-center px-0">
                                  <h5 className="p-0 pb-5 m-0">Reel</h5>
                                </div>
                              </td>
                              <td className="d-none d-lg-table-cell col-6 col-lg-3">
                                <div className="d-flex  align-items-center px-0">
                                  <h5 className="p-0 pb-5 m-0">Reel</h5>
                                </div>
                              </td>
                              <td className="row align-middle mx-0 col-12 col-lg-5 px-4 px-md-0">
                                <p className="mb-0 d-xs-block d-sm-block d-md-block d-xl-none d-lg-none font-weight-bold col-6 px-0">
                                  Details:
                                </p>
                                <p className="mb-0 px-0 col-6 col-xs-6 col-sm-6 col-md-6 col-lg-12 col-xl-12 ">
                                  {"-"}
                                </p>
                              </td>

                              <td className="row align-middle mx-0 col-12 col-lg-1 px-4 px-md-0">
                                <p className="mb-0 d-xs-block d-sm-block d-md-block d-xl-none d-lg-none font-weight-bold col-6 px-0">
                                  Hourly Rate:
                                </p>
                                <p className="mb-0 px-0 col-6 col-xs-6 col-sm-6 col-md-6 col-lg-12 col-xl-12 ">
                                  {"-"}
                                </p>
                              </td>
                              <td className="row align-middle mx-0 col-12 col-lg-1 px-4 px-md-0">
                                <div className="d-flex flex-row col-12 col-lg-1 px-lg-3 px-md-0 px-sm-0 px-xs-0 px-xl-3 px-0">
                                  <p className="mb-0 d-xs-block d-sm-block d-md-block d-xl-none d-lg-none font-weight-bold col-6 px-0">
                                    Quantity:
                                  </p>
                                  <p className="mb-0 px-0 col-6 col-xs-6 col-sm-6 col-md-6 col-lg-12 col-xl-12 ">
                                    {props.addReel.reelQtyWithoutLogo}
                                  </p>
                                </div>
                              </td>
                              <td className="row align-middle mx-0 col-12 col-lg-1 px-4 px-md-0">
                                <div className="d-flex flex-row col-12 col-lg-1 px-lg-0 px-md-0 px-sm-0 px-xs-0 px-xl-0 px-0">
                                  <p className="mb-0 d-xs-block d-sm-block d-md-block d-xl-none d-lg-none font-weight-bold col-6 px-0">
                                    Total:
                                  </p>
                                  <p className="mb-0 px-0 col-6 col-xs-6 col-sm-6 col-md-6 col-lg-12 col-xl-12 ">
                                    $
                                    {props.addReel.reelQtyWithoutLogo *
                                      props.addReel.reelPriceWithoutLogo}
                                  </p>
                                </div>
                              </td>
                            </tr>
                          )}
                        {/* addReel order item (reel with logo) */}
                        {props.addReel?.isReelSubmitted &&
                          props.addReel?.isReelWithLogo && (
                            <tr
                              key={props.selectedShutterBz.length + 1}
                              className="add-reel-row row col-12 px-0 mx-xs-0 mx-sm-0 mx-md-0 mx-lg-0 mx-xl-0 mx-0"
                            >
                              <td className="d-table-cell d-lg-none col-6 col-lg-1 back-dull">
                                <div className="flex-column mr-4 ms-4 pt-2">
                                  <div onClick={() => handleRemoveReel()}>
                                    <i
                                      className="fas fa-trash-alt mx-4 clr-grey cursor-point"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </div>
                              </td>
                              <td className="d-none d-lg-table-cell col-6 col-lg-1 ">
                                <div className="flex-column mr-4 ms-4">
                                  <div onClick={() => handleRemoveReel()}>
                                    <i
                                      className="fas fa-trash-alt mx-4 clr-grey cursor-point"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </div>
                              </td>
                              <td className="d-table-cell d-lg-none col-6 col-lg-3 px-0 back-dull">
                                <div className="d-flex  align-items-center px-0">
                                  <h5 className="p-0 pb-5 m-0">Recap</h5>
                                </div>
                              </td>
                              <td className="d-none d-lg-table-cell col-6 col-lg-3">
                                <div className="d-flex  align-items-center px-0">
                                  <h5 className="p-0 pb-5 m-0">Recap</h5>
                                </div>
                              </td>
                              <td className="row align-middle mx-0 col-12 col-lg-5 px-4 px-md-0">
                                <p className="mb-0 d-xs-block d-sm-block d-md-block d-xl-none d-lg-none font-weight-bold col-6 px-0">
                                  Details:
                                </p>
                                <p className="mb-0 px-0 col-6 col-xs-6 col-sm-6 col-md-6 col-lg-12 col-xl-12 ">
                                  {"-"}
                                </p>
                              </td>

                              <td className="row align-middle mx-0 col-12 col-lg-1 px-4 px-md-0">
                                <p className="mb-0 d-xs-block d-sm-block d-md-block d-xl-none d-lg-none font-weight-bold col-6 px-0">
                                  Hourly Rate:
                                </p>
                                <p className="mb-0 px-0 col-6 col-xs-6 col-sm-6 col-md-6 col-lg-12 col-xl-12 ">
                                  {"-"}
                                </p>
                              </td>
                              <td className="row align-middle mx-0 col-12 col-lg-1 px-4 px-md-0">
                                <div className="d-flex flex-row col-12 col-lg-1 px-lg-3 px-md-0 px-sm-0 px-xs-0 px-xl-3 px-0">
                                  <p className="mb-0 d-xs-block d-sm-block d-md-block d-xl-none d-lg-none font-weight-bold col-6 px-0">
                                    Quantity:
                                  </p>
                                  <p className="mb-0 px-0 col-6 col-xs-6 col-sm-6 col-md-6 col-lg-12 col-xl-12 ">
                                    {props.addReel.reelQtyWithLogo}
                                  </p>
                                </div>
                              </td>
                              <td className="row align-middle mx-0 col-12 col-lg-1 px-4 px-md-0">
                                <div className="d-flex flex-row col-12 col-lg-1 px-lg-0 px-md-0 px-sm-0 px-xs-0 px-xl-0 px-0">
                                  <p className="mb-0 d-xs-block d-sm-block d-md-block d-xl-none d-lg-none font-weight-bold col-6 px-0">
                                    Total:
                                  </p>
                                  <p className="mb-0 px-0 col-6 col-xs-6 col-sm-6 col-md-6 col-lg-12 col-xl-12 ">
                                    $
                                    {props.addReel.reelQtyWithLogo *
                                      props.addReel.reelPriceWithLogo}
                                  </p>
                                </div>
                              </td>
                            </tr>
                          )}
                        {/* apply coupon */}
                        <tr>
                          <td
                            className="align-middle border-bottom-0 col-12 px-4 px-md-0"
                            colSpan={6}
                          >
                            <form
                              onSubmit={(e) => {
                                setIsModalOpen(true);
                                handlePromoCodeSubmit(e);
                              }}
                            >
                              <div className="d-flex flex-row justify-content-start">
                                <input
                                  id="form1"
                                  name="promoCode"
                                  required
                                  placeholder="Promo Code"
                                  type="text"
                                  maxLength={35}
                                  className="form-control form-control-sm m-0 mx-md-4 w-50"
                                />
                                <button
                                  className="apply-coupon-btn px-2"
                                  disabled={
                                    props.selectedShutterBz?.length === 0
                                  }
                                >
                                  Coupon
                                </button>
                              </div>
                            </form>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {/* subtotal and total section */}
                  <div className="cartt shadow-2-strong outer-border mx-0 mx-md-4 px-4 px-md-1">
                    <div className="cartt-body p-0">
                      <table className="table">
                        <tbody className="brdr-btm-hidden">
                          {discount.current > 0 &&
                            props.selectedShutterBz?.length !== 0 && (
                              <tr className="brdr-top-hidden">
                                <th
                                  scope="row"
                                  className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50"
                                >
                                  <div className="d-flex flex-row">
                                    <p className="font-weight-bold m-0">
                                      Discount
                                    </p>
                                  </div>
                                </th>
                                <td className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50">
                                  <div className="d-flex flex-row justify-content-start justify-content-md-end">
                                    <p className="m-0">
                                      ${discount.current ?? 0}
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            )}

                          {promoCodeDiscount?.isGiftCard &&
                            props.selectedShutterBz?.length !== 0 && (
                              <tr className="brdr-top-hidden">
                                <th
                                  scope="row"
                                  className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50"
                                >
                                  <div className="d-flex flex-row">
                                    <p className="font-weight-bold m-0">
                                      Remaining Balance
                                    </p>
                                  </div>
                                </th>
                                <td className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50">
                                  <div className="d-flex flex-row justify-content-start justify-content-md-end">
                                    <p className="m-0">
                                      ${remainingBalance.current ?? 0}
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            )}

                          <tr
                            className={
                              (discount.current === 0 ||
                                props.selectedShutterBz?.length === 0) &&
                              "brdr-top-hidden"
                            }
                          >
                            <th
                              scope="row"
                              className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50"
                            >
                              <div className="d-flex flex-row">
                                <p className="font-weight-bold m-0">Fee</p>
                              </div>
                            </th>
                            <td className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50">
                              <div className="d-flex flex-row justify-content-start justify-content-md-end">
                                <p className="m-0">${fee.current.toFixed(2)}</p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th
                              scope="row"
                              className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50"
                            >
                              <div className="d-flex flex-row">
                                <p className="font-weight-bold m-0">
                                  Service Fee
                                  {` (${miscAmounts?.serviceFee}%)` || ""}
                                </p>
                              </div>
                            </th>
                            <td className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50">
                              <div className="d-flex flex-row justify-content-start justify-content-md-end">
                                <p className="m-0">
                                  ${serviceFee.current.toFixed(2)}
                                </p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th
                              scope="row"
                              className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50"
                            >
                              <div className="d-flex flex-row">
                                <p className="font-weight-bold m-0">Subtotal</p>
                              </div>
                            </th>
                            <td className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50">
                              <div className="d-flex flex-row justify-content-start justify-content-md-end">
                                <p className="m-0">
                                  ${calculateSubtotal().toFixed(2)}
                                </p>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <th
                              scope="row"
                              className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50"
                            >
                              <div className="d-flex flex-row">
                                <p className="font-weight-bold m-0">
                                  Tax{` (${miscAmounts?.tax}%)` || ""}
                                </p>
                              </div>
                            </th>
                            <td className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50">
                              <div className="d-flex flex-row justify-content-start justify-content-md-end">
                                <p className="m-0">${tax.current.toFixed(2)}</p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th
                              scope="row"
                              className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50"
                            >
                              <div className="d-flex flex-row">
                                <p className="font-weight-bold m-0">Total</p>
                              </div>
                            </th>
                            <td className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50">
                              <div className="d-flex flex-row justify-content-start justify-content-md-end">
                                <p className="m-0">
                                  ${(netTotal?.current ?? 0).toFixed(2)}
                                </p>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="container">
                    <div className="row py-3">
                      <div className="col-12 d-flex justify-content-between align-items-center">
                        <button
                          type="button"
                          className="back-btn"
                          onClick={() => {
                            props.onBack();
                            props.setUndoCartItem({});
                          }}
                        >
                          Back
                        </button>
                        <button
                          type="button"
                          className={`btn checkout-btn-style ${
                            isOnlyReel
                              ? ""
                              : !props.selectedShutterBz?.length
                              ? "disable-checkout"
                              : ""
                          }`}
                          disabled={
                            isOnlyReel
                              ? false
                              : !props.selectedShutterBz?.length
                          }
                          onClick={() => {
                            setIsModalOpen(true);
                            handleCheckout();
                          }}
                        >
                          Check out
                        </button>
                      </div>
                    </div>
                  </div>
                  <p className="text-center cart-footer">
                    Shutter Hive Inc. HST # 73876 3739 RT0001
                  </p>
                </div>
              </div>
            </div>
          </section>
        )
      ) : isOnlyReel ? (
        <section className="h-100 h-custom">
          <div className="container h-100 py-5">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="p-0 col outer-border">
                <div className="table-responsive border-0">
                  <table className="table my-custom-table">
                    <thead className="d-none d-md-none d-lg-block d-xl-block header-border brdr-btm-hidden">
                      <tr className="row col-12 px-0 mx-0">
                        {/* This <th> will be displayed on medium and small screens */}
                        <th
                          className="d-table-cell d-md-none col-1 d-flex align-items-center bordr-top-hidden"
                          scope="col"
                        ></th>
                        {/* This <td> will be displayed on larger screens */}
                        <th className="d-none d-md-table-cell col-1 d-flex align-items-center bordr-top-hidden bordr-btm-hidden">
                          {/* Add your content here */}
                        </th>
                        <th
                          className="d-table-cell d-md-none col-3 d-flex align-items-center bordr-top-hidden"
                          scope="col"
                        ></th>
                        <th
                          className="d-none d-md-table-cell col-3 d-flex align-items-center bordr-top-hidden  bordr-btm-hidden"
                          scope="col"
                        ></th>
                        <th
                          className="d-table-cell d-md-none col-5 d-flex align-items-center px-0 bordr-top-hidden"
                          scope="col"
                        >
                          Details
                        </th>
                        <th
                          className="d-none d-md-table-cell col-5 d-flex align-items-center px-0 bordr-top-hidden bordr-btm-hidden"
                          scope="col"
                        >
                          Details
                        </th>

                        <th
                          className="d-table-cell d-md-none  col-1 d-flex align-items-center px-0 bordr-top-hidden"
                          scope="col"
                        >
                          Hourly Rate
                        </th>
                        <th
                          className="d-none d-md-table-cell col-1 d-flex align-items-center px-0 bordr-top-hidden bordr-btm-hidden"
                          scope="col"
                        >
                          Hourly Rate
                        </th>

                        <th
                          className="d-table-cell d-md-none  col-1 d-flex align-items-center bordr-top-hidden"
                          scope="col"
                        >
                          Qty
                        </th>
                        <th
                          className="d-none d-md-table-cell col-1 d-flex align-items-center bordr-top-hidden bordr-btm-hidden"
                          scope="col"
                        >
                          Qty
                        </th>
                        <th
                          className="d-table-cell d-md-none col-1 d-flex align-items-center px-0 bordr-top-hidden"
                          scope="col"
                        >
                          Total
                        </th>
                        <th
                          className="d-none d-md-table-cell col-1 d-flex align-items-center px-0 bordr-top-hidden bordr-btm-hidden"
                          scope="col"
                        >
                          Total
                        </th>
                      </tr>
                    </thead>
                    <tbody className="brdr-btm">
                      {/* addReel order item (reel without logo) */}
                      {props.addReel?.isReelSubmitted &&
                        props.addReel?.isReelWithoutLogo && (
                          <tr
                            key={1}
                            className="add-reel-row row col-12 px-0 mx-xs-0 mx-sm-0 mx-md-0 mx-lg-0 mx-xl-0 mx-0"
                          >
                            <td className="d-table-cell d-lg-none col-6 col-lg-1 back-dull">
                              <div className="flex-column mr-4 ms-4 pt-2">
                                <div onClick={() => handleRemoveReel()}>
                                  <i
                                    className="fas fa-trash-alt mx-4 clr-grey cursor-point invisible"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </td>
                            <td className="d-none d-lg-table-cell col-6 col-lg-1 ">
                              <div className="flex-column mr-4 ms-4">
                                <div onClick={() => handleRemoveReel()}>
                                  <i
                                    className="fas fa-trash-alt mx-4 clr-grey cursor-point invisible"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </td>
                            <td className="d-table-cell d-lg-none col-6 col-lg-3 px-0 back-dull">
                              <div className="d-flex  align-items-center px-0">
                                <h5 className="p-0 pb-5 m-0">Reel</h5>
                              </div>
                            </td>
                            <td className="d-none d-lg-table-cell col-6 col-lg-3">
                              <div className="d-flex  align-items-center px-0">
                                <h5 className="p-0 pb-5 m-0">Reel</h5>
                              </div>
                            </td>
                            <td className="row align-middle mx-0 col-12 col-lg-5 px-4 px-md-0">
                              <p className="mb-0 d-xs-block d-sm-block d-md-block d-xl-none d-lg-none font-weight-bold col-6 px-0">
                                Details:
                              </p>
                              <p className="mb-0 px-0 col-6 col-xs-6 col-sm-6 col-md-6 col-lg-12 col-xl-12 ">
                                {"-"}
                              </p>
                            </td>

                            <td className="row align-middle mx-0 col-12 col-lg-1 px-4 px-md-0">
                              <p className="mb-0 d-xs-block d-sm-block d-md-block d-xl-none d-lg-none font-weight-bold col-6 px-0">
                                Hourly Rate:
                              </p>
                              <p className="mb-0 px-0 col-6 col-xs-6 col-sm-6 col-md-6 col-lg-12 col-xl-12 ">
                                {"-"}
                              </p>
                            </td>
                            <td className="row align-middle mx-0 col-12 col-lg-1 px-4 px-md-0">
                              <div className="d-flex flex-row col-12 col-lg-1 px-lg-3 px-md-0 px-sm-0 px-xs-0 px-xl-3 px-0">
                                <p className="mb-0 d-xs-block d-sm-block d-md-block d-xl-none d-lg-none font-weight-bold col-6 px-0">
                                  Quantity:
                                </p>
                                <p className="mb-0 px-0 col-6 col-xs-6 col-sm-6 col-md-6 col-lg-12 col-xl-12 ">
                                  {props.addReel.reelQtyWithoutLogo}
                                </p>
                              </div>
                            </td>
                            <td className="row align-middle mx-0 col-12 col-lg-1 px-4 px-md-0">
                              <div className="d-flex flex-row col-12 col-lg-1 px-lg-0 px-md-0 px-sm-0 px-xs-0 px-xl-0 px-0">
                                <p className="mb-0 d-xs-block d-sm-block d-md-block d-xl-none d-lg-none font-weight-bold col-6 px-0">
                                  Total:
                                </p>
                                <p className="mb-0 px-0 col-6 col-xs-6 col-sm-6 col-md-6 col-lg-12 col-xl-12 ">
                                  $
                                  {props.addReel.reelQtyWithoutLogo *
                                    props.addReel.reelPriceWithoutLogo}
                                </p>
                              </div>
                            </td>
                          </tr>
                        )}
                      {/* addReel order item (reel with logo) */}
                      {props.addReel?.isReelSubmitted &&
                        props.addReel?.isReelWithLogo && (
                          <tr
                            key={1}
                            className="add-reel-row row col-12 px-0 mx-xs-0 mx-sm-0 mx-md-0 mx-lg-0 mx-xl-0 mx-0"
                          >
                            <td className="d-table-cell d-lg-none col-6 col-lg-1 back-dull">
                              <div className="flex-column mr-4 ms-4 pt-2">
                                <div onClick={() => handleRemoveReel()}>
                                  <i
                                    className="fas fa-trash-alt mx-4 clr-grey cursor-point invisible"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </td>
                            <td className="d-none d-lg-table-cell col-6 col-lg-1 ">
                              <div className="flex-column mr-4 ms-4">
                                <div onClick={() => handleRemoveReel()}>
                                  <i
                                    className="fas fa-trash-alt mx-4 clr-grey cursor-point invisible"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </td>
                            <td className="d-table-cell d-lg-none col-6 col-lg-3 px-0 back-dull">
                              <div className="d-flex  align-items-center px-0">
                                <h5 className="p-0 pb-5 m-0">Recap</h5>
                              </div>
                            </td>
                            <td className="d-none d-lg-table-cell col-6 col-lg-3">
                              <div className="d-flex  align-items-center px-0">
                                <h5 className="p-0 pb-5 m-0">Recap</h5>
                              </div>
                            </td>
                            <td className="row align-middle mx-0 col-12 col-lg-5 px-4 px-md-0">
                              <p className="mb-0 d-xs-block d-sm-block d-md-block d-xl-none d-lg-none font-weight-bold col-6 px-0">
                                Details:
                              </p>
                              <p className="mb-0 px-0 col-6 col-xs-6 col-sm-6 col-md-6 col-lg-12 col-xl-12 ">
                                {"-"}
                              </p>
                            </td>

                            <td className="row align-middle mx-0 col-12 col-lg-1 px-4 px-md-0">
                              <p className="mb-0 d-xs-block d-sm-block d-md-block d-xl-none d-lg-none font-weight-bold col-6 px-0">
                                Hourly Rate:
                              </p>
                              <p className="mb-0 px-0 col-6 col-xs-6 col-sm-6 col-md-6 col-lg-12 col-xl-12 ">
                                {"-"}
                              </p>
                            </td>
                            <td className="row align-middle mx-0 col-12 col-lg-1 px-4 px-md-0">
                              <div className="d-flex flex-row col-12 col-lg-1 px-lg-3 px-md-0 px-sm-0 px-xs-0 px-xl-3 px-0">
                                <p className="mb-0 d-xs-block d-sm-block d-md-block d-xl-none d-lg-none font-weight-bold col-6 px-0">
                                  Quantity:
                                </p>
                                <p className="mb-0 px-0 col-6 col-xs-6 col-sm-6 col-md-6 col-lg-12 col-xl-12 ">
                                  {props.addReel.reelQtyWithLogo}
                                </p>
                              </div>
                            </td>
                            <td className="row align-middle mx-0 col-12 col-lg-1 px-4 px-md-0">
                              <div className="d-flex flex-row col-12 col-lg-1 px-lg-0 px-md-0 px-sm-0 px-xs-0 px-xl-0 px-0">
                                <p className="mb-0 d-xs-block d-sm-block d-md-block d-xl-none d-lg-none font-weight-bold col-6 px-0">
                                  Total:
                                </p>
                                <p className="mb-0 px-0 col-6 col-xs-6 col-sm-6 col-md-6 col-lg-12 col-xl-12 ">
                                  $
                                  {props.addReel.reelQtyWithLogo *
                                    props.addReel.reelPriceWithLogo}
                                </p>
                              </div>
                            </td>
                          </tr>
                        )}
                      {/* apply coupon */}
                      <tr>
                        <td
                          className="align-middle border-bottom-0 col-12 px-4 px-md-0"
                          colSpan={6}
                        >
                          <form
                            onSubmit={(e) => {
                              setIsModalOpen(true);
                              handlePromoCodeSubmit(e);
                            }}
                          >
                            <div className="d-flex flex-row justify-content-start">
                              <input
                                id="form1"
                                name="promoCode"
                                required
                                placeholder="Promo Code"
                                type="text"
                                maxLength={35}
                                className="form-control form-control-sm m-0 mx-md-4 w-50"
                              />
                              <button
                                className="apply-coupon-btn px-2"
                                disabled={
                                  isOnlyReel
                                    ? false
                                    : props.selectedShutterBz?.length === 0
                                }
                              >
                                Coupon
                              </button>
                            </div>
                          </form>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* subtotal and total section */}
                <div className="cartt shadow-2-strong outer-border mx-0 mx-md-4 px-4 px-md-1">
                  <div className="cartt-body p-0">
                    <table className="table">
                      <tbody className="brdr-btm-hidden">
                        {discount.current > 0 && (
                          <tr className="brdr-top-hidden">
                            <th
                              scope="row"
                              className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50"
                            >
                              <div className="d-flex flex-row">
                                <p className="font-weight-bold m-0">Discount</p>
                              </div>
                            </th>
                            <td className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50">
                              <div className="d-flex flex-row justify-content-start justify-content-md-end">
                                <p className="m-0">${discount.current ?? 0}</p>
                              </div>
                            </td>
                          </tr>
                        )}

                        {promoCodeDiscount?.isGiftCard && (
                          <tr className="brdr-top-hidden">
                            <th
                              scope="row"
                              className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50"
                            >
                              <div className="d-flex flex-row">
                                <p className="font-weight-bold m-0">
                                  Remaining Balance
                                </p>
                              </div>
                            </th>
                            <td className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50">
                              <div className="d-flex flex-row justify-content-start justify-content-md-end">
                                <p className="m-0">
                                  ${remainingBalance.current ?? 0}
                                </p>
                              </div>
                            </td>
                          </tr>
                        )}

                        <tr
                          className={
                            discount.current === 0 ||
                            props.selectedShutterBz?.length === 0
                            // &&
                            // "brdr-top-hidden"
                          }
                        >
                          <th
                            scope="row"
                            className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50"
                          >
                            <div className="d-flex flex-row">
                              <p className="font-weight-bold m-0">Fee</p>
                            </div>
                          </th>
                          <td className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50">
                            <div className="d-flex flex-row justify-content-start justify-content-md-end">
                              <p className="m-0">${fee.current.toFixed(2)}</p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th
                            scope="row"
                            className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50"
                          >
                            <div className="d-flex flex-row">
                              <p className="font-weight-bold m-0">
                                Service Fee
                                {` (${miscAmounts?.serviceFee}%)` || ""}
                              </p>
                            </div>
                          </th>
                          <td className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50">
                            <div className="d-flex flex-row justify-content-start justify-content-md-end">
                              <p className="m-0">
                                ${serviceFee.current.toFixed(2)}
                              </p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th
                            scope="row"
                            className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50"
                          >
                            <div className="d-flex flex-row">
                              <p className="font-weight-bold m-0">Subtotal</p>
                            </div>
                          </th>
                          <td className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50">
                            <div className="d-flex flex-row justify-content-start justify-content-md-end">
                              <p className="m-0">
                                ${calculateSubtotal().toFixed(2)}
                              </p>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <th
                            scope="row"
                            className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50"
                          >
                            <div className="d-flex flex-row">
                              <p className="font-weight-bold m-0">
                                Tax{` (${miscAmounts?.tax}%)` || ""}
                              </p>
                            </div>
                          </th>
                          <td className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50">
                            <div className="d-flex flex-row justify-content-start justify-content-md-end">
                              <p className="m-0">${tax.current.toFixed(2)}</p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th
                            scope="row"
                            className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50"
                          >
                            <div className="d-flex flex-row">
                              <p className="font-weight-bold m-0">Total</p>
                            </div>
                          </th>
                          <td className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50">
                            <div className="d-flex flex-row justify-content-start justify-content-md-end">
                              <p className="m-0">
                                ${(netTotal?.current ?? 0).toFixed(2)}
                              </p>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="container">
                  <div className="row py-3">
                    <div className="col-12 d-flex justify-content-between align-items-center">
                      <button
                        type="button"
                        className="back-btn"
                        onClick={() => {
                          props.onBack();
                          props.setUndoCartItem({});
                        }}
                      >
                        Back
                      </button>
                      <button
                        type="button"
                        className={`btn checkout-btn-style ${
                          isOnlyReel
                            ? ""
                            : !props.selectedShutterBz?.length
                            ? "disable-checkout"
                            : ""
                        }`}
                        disabled={
                          isOnlyReel ? false : !props.selectedShutterBz?.length
                        }
                        onClick={() => {
                          setIsModalOpen(true);
                          handleCheckout();
                        }}
                      >
                        Check out
                      </button>
                    </div>
                  </div>
                </div>
                <p className="text-center cart-footer">
                  Shutter Hive Inc. HST # 73876 3739 RT0001
                </p>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="h-100 h-custom">
          <div className="container h-100 py-5">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="p-0 col outer-border">
                <div className="table-responsive border-0">
                  <table className="table">
                    {/* table header */}
                    <thead className="d-none d-md-none d-lg-block d-xl-block header-border brdr-btm-hidden">
                      {cartPreviousScreen === customerConstants.TIP_TO_CART ? (
                        <tr className="row col-12 px-0 mx-0">
                          {/* This <th> will be displayed on medium and small screens */}
                          <th
                            className="d-table-cell d-md-none col-1 d-flex align-items-center bordr-top-hidden"
                            scope="col"
                          ></th>
                          <th
                            className="d-none d-md-table-cell col-1 d-flex align-items-center bordr-top-hidden bordr-btm-hidden"
                            scope="col"
                          ></th>
                          <th
                            className="d-table-cell d-md-none col-3 d-flex align-items-center bordr-top-hidden"
                            scope="col"
                          ></th>
                          <th
                            className="d-none d-md-table-cell col-3 d-flex align-items-center bordr-top-hidden bordr-btm-hidden"
                            scope="col"
                          ></th>

                          <th
                            className="d-table-cell d-md-none col-6 d-flex align-items-center px-0 bordr-top-hidden"
                            scope="col"
                          >
                            Details
                          </th>
                          <th
                            className="d-none d-md-table-cell col-6 d-flex align-items-center px-0 bordr-top-hidden bordr-btm-hidden"
                            scope="col"
                          >
                            Details
                          </th>

                          <th
                            className="d-table-cell d-md-none col-1 d-flex align-items-center px-0 bordr-top-hidden"
                            scope="col"
                          >
                            Tip
                          </th>
                          <th
                            className="d-none d-md-table-cell col-1 d-flex align-items-center px-0 bordr-top-hidden bordr-btm-hidden"
                            scope="col"
                          >
                            Tip
                          </th>
                          <th
                            className="d-table-cell d-md-none col-1 d-flex align-items-center px-0 bordr-top-hidden"
                            scope="col"
                          >
                            Total
                          </th>
                          <th
                            className="d-none d-md-table-cell col-1 d-flex align-items-center px-0 bordr-top-hidden bordr-btm-hidden"
                            scope="col"
                          >
                            Total
                          </th>
                        </tr>
                      ) : cartPreviousScreen ===
                        customerConstants.EXTEND_TO_CART ? (
                        <tr className="row col-12 px-0 mx-0">
                          {/* This <th> will be displayed on medium and small screens */}
                          <th
                            className="d-table-cell d-md-none col-1 d-flex align-items-center bordr-top-hidden"
                            scope="col"
                          ></th>
                          {/* This <th> will be displayed on larger screens */}
                          <th
                            className="d-none d-md-table-cell bordr-btm-hidden col-1 d-flex align-items-center bordr-top-hidden"
                            scope="col"
                          ></th>
                          <th
                            className="d-table-cell d-md-none col-3 d-flex align-items-center bordr-top-hidden"
                            scope="col"
                          ></th>
                          <th
                            className="d-none d-md-table-cell bordr-btm-hidden  col-3 d-flex align-items-center bordr-top-hidden"
                            scope="col"
                          ></th>
                          <th
                            className="d-table-cell d-md-none col-5 d-flex align-items-center px-0 bordr-top-hidden"
                            scope="col"
                          >
                            Details
                          </th>
                          <th
                            className="d-none d-md-table-cell bordr-btm-hidden col-5 d-flex align-items-center px-0 bordr-top-hidden"
                            scope="col"
                          >
                            Details
                          </th>
                          <th
                            className="d-table-cell d-md-none  col-1 d-flex align-items-center px-0 bordr-top-hidden"
                            scope="col"
                          >
                            Hourly Rate
                          </th>
                          <th
                            className="d-none d-md-table-cell bordr-btm-hidden col-1 d-flex align-items-center px-0 bordr-top-hidden"
                            scope="col"
                          >
                            Hourly Rate
                          </th>
                          <th
                            className="d-table-cell d-md-none col-1 d-flex align-items-center bordr-top-hidden"
                            scope="col"
                          >
                            Qty
                          </th>
                          <th
                            className="d-none d-md-table-cell bordr-btm-hidden col-1 d-flex align-items-center bordr-top-hidden"
                            scope="col"
                          >
                            Qty
                          </th>
                          <th
                            className="d-table-cell d-md-none col-1 d-flex align-items-center px-0 bordr-top-hidden"
                            scope="col"
                          >
                            Total
                          </th>
                          <th
                            className="d-none d-md-table-cell bordr-btm-hidden col-1 d-flex align-items-center px-0 bordr-top-hidden"
                            scope="col"
                          >
                            Total
                          </th>
                        </tr>
                      ) : (
                        // extend and tip
                        <tr className="row col-12 px-0 mx-0">
                          <th
                            className="d-table-cell d-md-none  col-1 d-flex align-items-center bordr-top-hidden"
                            scope="col"
                          ></th>
                          <th
                            className="d-none d-md-table-cell bordr-btm-hidden col-1 d-flex align-items-center bordr-top-hidden"
                            scope="col"
                          ></th>
                          <th
                            className="d-table-cell d-md-none col-3 d-flex align-items-center bordr-top-hidden"
                            scope="col"
                          ></th>
                          <th
                            className="d-none d-md-table-cell bordr-btm-hidden col-3 d-flex align-items-center bordr-top-hidden"
                            scope="col"
                          ></th>

                          <th
                            className="d-table-cell d-md-none col-4 d-flex align-items-center px-0 bordr-top-hidden"
                            scope="col"
                          >
                            Details
                          </th>
                          <th
                            className="d-none d-md-table-cell bordr-btm-hidden col-4 d-flex align-items-center px-0 bordr-top-hidden"
                            scope="col"
                          >
                            Details
                          </th>
                          <th
                            className="d-table-cell d-md-none col-1 d-flex align-items-center px-0 bordr-top-hidden"
                            scope="col"
                          >
                            Hourly Rate
                          </th>
                          <th
                            className="d-none d-md-table-cell bordr-btm-hidden col-1 d-flex align-items-center px-0 bordr-top-hidden"
                            scope="col"
                          >
                            Hourly Rate
                          </th>
                          <th
                            className="d-table-cell d-md-none col-1 d-flex align-items-center bordr-top-hidden"
                            scope="col"
                          >
                            Qty
                          </th>
                          <th
                            className="d-none d-md-table-cell bordr-btm-hidden col-1 d-flex align-items-center bordr-top-hidden"
                            scope="col"
                          >
                            Qty
                          </th>
                          <th
                            className="d-table-cell d-md-none col-1 d-flex align-items-center px-0 bordr-top-hidden"
                            scope="col"
                          >
                            Tip
                          </th>
                          <th
                            className="d-none d-md-table-cell bordr-btm-hidden col-1 d-flex align-items-center px-0 bordr-top-hidden"
                            scope="col"
                          >
                            Tip
                          </th>
                          <th
                            className="d-table-cell d-md-none col-1 col-1 d-flex align-items-center px-0 bordr-top-hidden"
                            scope="col"
                          >
                            Total
                          </th>
                          <th
                            className="d-none d-md-table-cell bordr-btm-hidden col-1 d-flex align-items-center px-0 bordr-top-hidden"
                            scope="col"
                          >
                            Total
                          </th>
                        </tr>
                      )}
                    </thead>
                    {/* table body */}
                    <tbody className="brdr-btm">
                      <>
                        {customerDashboardCart.map((shutterB, index) => {
                          return (
                            <tr
                              className="row col-12 px-0 mx-xs-0 mx-sm-0 mx-md-0 mx-lg-0 mx-xl-0 mx-0"
                              key={index}
                            >
                              <td className="d-table-cell d-lg-none col-6 col-lg-1 back-dull">
                                <div className="flex-column mr-4 ms-4">
                                  <div
                                    onClick={() => handleRemoveShutterB(index)}
                                  >
                                    <i
                                      className="fas fa-trash-alt mx-4 mt-4 mb-0 clr-grey cursor-point"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </div>
                              </td>
                              <td className="d-none d-lg-table-cell  col-6 col-lg-1">
                                <div className="flex-column mr-4 ms-4">
                                  <div
                                    onClick={() => handleRemoveShutterB(index)}
                                  >
                                    <i
                                      className="fas fa-trash-alt mx-4 mt-4 mb-0 clr-grey cursor-point"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </div>
                              </td>
                              <td className="d-table-cell d-lg-none col-6 col-lg-3 back-dull">
                                <div className="d-flex align-items-center">
                                  <img
                                    src={`${process.env.REACT_APP_S3_BASE_URL_PORTFOLIO}/${shutterB?.sbUser?.sbPortfolios[0]?.profileImageUrl}`}
                                    className="img-fluid rounded-circle cart-image min-width-100"
                                    alt="Book"
                                  />
                                </div>
                              </td>
                              <td className="d-none d-lg-table-cell col-6 col-lg-3">
                                <div className="d-flex align-items-center">
                                  <img
                                    src={`${process.env.REACT_APP_S3_BASE_URL_PORTFOLIO}/${shutterB?.sbUser?.sbPortfolios[0]?.profileImageUrl}`}
                                    className="img-fluid rounded-circle cart-image min-width-100"
                                    alt="Book"
                                  />
                                </div>
                              </td>
                              <td
                                className={`align-middle col-12 px-4 px-md-0 ${
                                  cartPreviousScreen ===
                                  customerConstants.TIP_TO_CART
                                    ? "col-lg-6"
                                    : cartPreviousScreen ===
                                      customerConstants.EXTEND_TO_CART
                                    ? "col-lg-5"
                                    : "col-lg-4"
                                }`}
                              >
                                <div className="d-flex col-12 px-0 flex-column">
                                  <div className="d-flex col-12 px-0 flex-row">
                                    <p className="mb-0 col-6 col-lg-4 col-xl-4 col-md-6 col-sm-6-col-xs-6 px-0 font-weight-bold">
                                      shutterb:
                                    </p>
                                    <p className="mb-0 col-6 col-lg-8 col-xl-8 col-md-6 col-sm-6-col-xs-6 px-0 ">
                                      {shutterB?.sbUser?.firstName}{" "}
                                      {shutterB?.sbUser?.lastName}
                                    </p>
                                  </div>
                                  <div className="d-flex col-12 px-0 flex-row">
                                    <p className="mb-0 col-6 col-lg-4 col-xl-4 col-md-6 col-sm-6-col-xs-6 px-0 font-weight-bold">
                                      Event:
                                    </p>
                                    <p className="mb-0 col-6 col-lg-8 col-xl-8 col-md-6 col-sm-6-col-xs-6 px-0 ">
                                      {eventDetails?.eventName}
                                    </p>
                                  </div>
                                  <div className="d-flex col-12 px-0 flex-row">
                                    <p className="mb-0 col-6 col-lg-4 col-xl-4 col-md-6 col-sm-6-col-xs-6 px-0 font-weight-bold">
                                      Date:
                                    </p>
                                    <p className="mb-0 col-6 px-0 col-lg-8 col-xl-8 col-md-6 col-sm-6-col-xs-6  ">
                                      {convertLongDateToShortDate(
                                        convertEpochToFormattedDate(
                                          eventDetails?.eventDate
                                        )
                                      )}
                                    </p>
                                  </div>
                                  <div className="d-flex col-12 px-0 flex-row">
                                    <p className="mb-0 font-weight-bold col-6 col-lg-4 col-xl-4 col-md-6 col-sm-6-col-xs-6 px-0">
                                      Start Time:
                                    </p>
                                    <p className="mb-0 col-6 px-0 col-lg-8 col-xl-8 col-md-6 col-sm-6-col-xs-6  ">
                                      {/*new Date(
                                        eventDetails?.sbstartTime
                                      ).toLocaleTimeString()*/}
                                      -
                                    </p>
                                  </div>
                                  <div className="d-flex flex-row col-12 px-0">
                                    <p className="mb-0 font-weight-bold col-6 col-lg-4 col-xl-4 col-md-6 col-sm-6-col-xs-6 px-0">
                                      Duration:
                                    </p>
                                    <p className="mb-0 col-6 px-0 col-lg-8 col-xl-8 col-md-6 col-sm-6-col-xs-6 ">
                                      {eventDetails?.duration} hours
                                    </p>
                                  </div>
                                </div>
                              </td>

                              {cartPreviousScreen ===
                              customerConstants.TIP_TO_CART ? (
                                <>
                                  <td className="row align-middle mx-0 col-12 col-lg-1 px-4 px-md-0">
                                    <p className="mb-0 d-xs-block d-sm-block d-md-block d-xl-none d-lg-none font-weight-bold col-6 px-0">
                                      Tip:
                                    </p>
                                    <p className="mb-0 px-0 col-6 col-xs-6 col-sm-6 col-md-6 col-lg-12 col-xl-12 ">
                                      ${shutterB?.tipPrice_}
                                    </p>
                                  </td>

                                  <td className="align-middle col-12 col-lg-1 px-4 px-md-0">
                                    <div className="d-flex flex-row col-12 col-lg-1 px-0">
                                      <p className="mb-0 d-xs-block d-sm-block d-md-block d-xl-none d-lg-none font-weight-bold col-6 px-0">
                                        Total:
                                      </p>
                                      <p className="mb-0 px-0 col-6 col-xs-6 col-sm-6 col-md-6 col-lg-12 col-xl-12 ">
                                        ${shutterB?.tipPrice_}
                                      </p>
                                    </div>
                                  </td>
                                </>
                              ) : cartPreviousScreen ===
                                customerConstants.EXTEND_TO_CART ? (
                                <>
                                  <td className="row align-middle mx-0 col-12 col-lg-1 px-4 px-md-0">
                                    <p className="mb-0 d-xs-block d-sm-block d-md-block d-xl-none d-lg-none font-weight-bold col-6 px-0">
                                      Hourly Rate:
                                    </p>
                                    <p className="mb-0 px-0 col-6 col-xs-6 col-sm-6 col-md-6 col-lg-12 col-xl-12 ">
                                      $
                                      {(
                                        shutterB?.sbUser?.hourlyRate ?? 0
                                      ).toFixed(2)}
                                    </p>
                                  </td>

                                  <td className="row align-middle mx-0 col-12 col-lg-1 px-4 px-md-0">
                                    <div className="d-flex flex-row col-12 col-lg-1 px-0">
                                      <p className="mb-0 d-xs-block d-sm-block d-md-block d-xl-none d-lg-none font-weight-bold col-6 px-0">
                                        Quantity:
                                      </p>
                                      <p className="mb-0 px-0 col-6 col-xs-6 col-sm-6 col-md-6 col-lg-12 col-xl-12 px-0 px-md-3">
                                        {shutterB?.extendedQuantity_}
                                      </p>
                                    </div>
                                  </td>

                                  <td className="align-middle col-12 col-lg-1 px-4 px-md-0">
                                    <div className="d-flex flex-row col-12 col-lg-1 px-lg-0 px-md-0 px-sm-0 px-xs-0 px-xl-0 px-0">
                                      <p className="mb-0 d-xs-block d-sm-block d-md-block d-xl-none d-lg-none font-weight-bold col-6 px-0">
                                        Total:
                                      </p>
                                      <p className="mb-0 px-0 col-6 col-xs-6 col-sm-6 col-md-6 col-lg-12 col-xl-12 ">
                                        $
                                        {(
                                          shutterB?.sbUser?.hourlyRate ?? 0
                                        ).toFixed(2)}
                                      </p>
                                    </div>
                                  </td>
                                </>
                              ) : (
                                // extend and tip
                                <>
                                  <td className="row align-middle mx-0 col-12 col-lg-1 px-4 px-md-0">
                                    <p className="mb-0 d-xs-block d-sm-block d-md-block d-xl-none d-lg-none font-weight-bold col-6 px-0">
                                      Hourly Rate:
                                    </p>
                                    <p className="mb-0 px-0 col-6 col-xs-6 col-sm-6 col-md-6 col-lg-12 col-xl-12 ">
                                      {shutterB?.tipPrice_
                                        ? " - "
                                        : "$" +
                                          (
                                            shutterB?.sbUser?.hourlyRate ?? 0
                                          ).toFixed(2)}
                                    </p>
                                  </td>

                                  <td className="row align-middle mx-0 col-12 col-lg-1 px-4 px-md-0">
                                    <div className="d-flex flex-row col-12 col-lg-1 px-0">
                                      <p className="mb-0 d-xs-block d-sm-block d-md-block d-xl-none d-lg-none font-weight-bold col-6 px-0">
                                        Quantity:
                                      </p>
                                      <p className="mb-0 px-0 col-6 col-xs-6 col-sm-6 col-md-6 col-lg-12 col-xl-12 px-0 px-md-3">
                                        {shutterB?.extendedQuantity_ ?? "-"}
                                      </p>
                                    </div>
                                  </td>

                                  <td className="row align-middle mx-0 col-12 col-lg-1 px-4 px-md-0">
                                    <div className="d-flex flex-row col-12 col-lg-1 px-lg-1 px-md-0 px-sm-0 px-xs-0 px-xl-1 px-0">
                                      <p className="mb-0 d-xs-block d-sm-block d-md-block d-xl-none d-lg-none font-weight-bold col-6 px-0">
                                        Tip:
                                      </p>
                                      <p className="mb-0 px-0 col-6 col-xs-6 col-sm-6 col-md-6 col-lg-12 col-xl-12 ">
                                        {shutterB?.tipPrice_
                                          ? "$" + shutterB?.tipPrice_
                                          : "-"}
                                      </p>
                                    </div>
                                  </td>

                                  <td className="row align-middle col-12 col-lg-1 px-4 px-md-0 mx-0">
                                    <div className="d-flex flex-row col-12 col-lg-1 px-lg-0 px-md-0 px-sm-0 px-xs-0 px-xl-0 px-0">
                                      <p className="mb-0 d-xs-block d-sm-block d-md-block d-xl-none d-lg-none font-weight-bold col-6 px-0">
                                        Total:
                                      </p>
                                      <p className="mb-0 px-0 col-6 col-xs-6 col-sm-6 col-md-6 col-lg-12 col-xl-12 ">
                                        $
                                        {shutterB?.tipPrice_
                                          ? shutterB?.tipPrice_
                                          : shutterB?.sbUser?.hourlyRate?.toFixed(
                                              2
                                            )}
                                      </p>
                                    </div>
                                  </td>
                                </>
                              )}
                            </tr>
                          );
                        })}
                      </>
                    </tbody>
                  </table>
                </div>
                {/* subtotal and total section */}
                <div className="cartt shadow-2-strong outer-border mx-0 mx-md-4 px-4 px-md-1">
                  <div className="cartt-body p-0">
                    <table className="table">
                      <tbody className="brdr-btm-hidden">
                        <tr className="brdr-top-hidden">
                          <th
                            scope="row"
                            className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50"
                          >
                            <div className="d-flex flex-row">
                              <p className="font-weight-bold m-0">Fee</p>
                            </div>
                          </th>
                          <td className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50">
                            <div className="d-flex flex-row justify-content-start justify-content-md-end">
                              <p className="m-0">
                                ${(fee?.current ?? 0).toFixed(2)}
                              </p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th
                            scope="row"
                            className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50"
                          >
                            <div className="d-flex flex-row">
                              <p className="font-weight-bold m-0">
                                Service Fee
                                {` (${miscAmounts?.serviceFee}%)` || ""}
                              </p>
                            </div>
                          </th>
                          <td className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50">
                            <div className="d-flex flex-row justify-content-start justify-content-md-end">
                              <p className="m-0">
                                ${(serviceFee?.current ?? 0).toFixed(2)}
                              </p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th
                            scope="row"
                            className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50"
                          >
                            <div className="d-flex flex-row">
                              <p className="font-weight-bold m-0">Subtotal</p>
                            </div>
                          </th>
                          <td className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50">
                            <div className="d-flex flex-row justify-content-start justify-content-md-end">
                              {/* <p>${calculateSubtotal(false)}</p> */}
                              <p className="m-0">
                                ${calculateSubtotal().toFixed(2)}
                              </p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th
                            scope="row"
                            className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50"
                          >
                            <div className="d-flex flex-row">
                              <p className="font-weight-bold m-0">
                                Tax {` (${miscAmounts?.tax}%)` || ""}
                              </p>
                            </div>
                          </th>
                          <td className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50">
                            <div className="d-flex flex-row justify-content-start justify-content-md-end">
                              <p className="m-0">
                                ${(tax?.current ?? 0).toFixed(2)}
                              </p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th
                            scope="row"
                            className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50"
                          >
                            <div className="d-flex flex-row">
                              <p className="font-weight-bold m-0">Total</p>
                            </div>
                          </th>
                          <td className="px-0 px-md-1 py-3 align-middle border-bottom-0 w-50">
                            <div className="d-flex flex-row justify-content-start justify-content-md-end">
                              <p className="m-0">
                                ${(netTotal?.current ?? 0).toFixed(2)}
                              </p>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="d-flex d-md-block justify-content-center align-items-center">
                  <button
                    type="button"
                    className={`btn m-4 ${
                      !customerDashboardCart.length ? "disable-checkout" : ""
                    }`}
                    disabled={!customerDashboardCart.length}
                    onClick={() => {
                      setIsModalOpen(true);
                      handleCheckout();
                    }}
                  >
                    Check out
                  </button>
                </div>
                <p className="text-center cart-footer">
                  Shutter Hive Inc. HST # 73876 3739 RT0001
                </p>
              </div>
            </div>
          </div>
        </section>
      )}
      {!props.isAuthenticated && isModalOpen && (
        <Modal setIsModalOpen={setIsModalOpen} isAllowModal />
      )}
    </>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  cartTab: state.eventBooking.cartTab,
  isAuthenticated: state.authentication.isAuthenticated,
  userId: state.authentication.userId,
  selectedShutterBz: state.eventBooking.selectedShutterBz,
  customerDashboardCart: state.customerDashboard.customerDashboardCart,
  eventDetails: state.customerDashboard.myEventsExtendSb,
  addReel: state.eventBooking.addReel,
  miscAmounts: state.eventBooking.miscAmounts,
  formState: state.eventBooking.formState,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  setCartTab: () => dispatch(eventBookingActions.setCartTab()),
  setSelectedShutterBz: (data) =>
    dispatch(eventBookingActions.setSelectedShutterBz(data)),
  updateCustomerDashboardCart: (dataObj) =>
    dispatch({
      type: customerConstants.UPDATE_CUSTOMER_DASHBOARD_CART,
      data: dataObj,
    }),
  getMiscAmounts: () => dispatch(eventBookingActions.getMiscAmounts()),
  applyCoupon: (
    dataObj,
    promoCodeDiscount,
    setPromoCodeDiscount,
    subTotal,
    fee,
    total
  ) =>
    dispatch(
      eventBookingActions.applyCoupon(
        dataObj,
        promoCodeDiscount,
        setPromoCodeDiscount,
        subTotal,
        fee,
        total
      )
    ),
  setReelValues: (dataObj) =>
    dispatch({
      type: eventBookingConstants.SET_REEL_VALUES_ON_DELETE,
      data: dataObj,
    }),
  setUndoCartItem: (deletedCartItem) =>
    dispatch({
      type: eventBookingConstants.SET_UNDO_CART_ITEM,
      data: deletedCartItem,
    }),
});

// mapping action and store the function via props
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Cart));
