import React from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";

import { loginActions } from "../../../../redux/actions";
import { configurationsConstants } from "../../../../constants";

const ForgotPassword = (props) => {
  // when user clicks on Login button
  const handleSubmit = (event, values) => {
    // event.preventDefault();
    props.forgotPassword({ userTypeId: 2, ...values }, props.setIsModalOpen);
  };

  // navigate to screen after login
  // useEffect(() => {
  //   if (isAuthenticated) NavigateToDefault(history);
  //   // eslint-disable-next-line
  // }, [props.authentication]);
  return (
    <div
      className={`login-form d-flex flex-column justify-content-center px-0 px-sm-0 px-md-1 ${
        props.isAllowModal ? "" : "border-color-grey"
      }`}
    >
      {!props.isPage && (
        <button id="cancel-icon" onClick={() => props.setIsModalOpen(false)}>
          <span>X</span>
        </button>
      )}
      <AvForm className="form form_Display_Flex" onValidSubmit={handleSubmit}>
        {/* cancel icon */}
        <div className="form-header-group pt-3">
          <h2 className="pt-0 pb-1">Forgot Password?</h2>
        </div>
        <div className="form__form-group">
          <div className="form__form-group-field">
            <div className="">
              <AvField
                required
                type="email"
                name="email"
                maxLength={configurationsConstants.EMAIL_INPUT_LENGTH}
                placeholder="Enter your email"
                className="input-without-border-radius"
              />
            </div>
          </div>
        </div>
        <div className="form__form-group mb-4">
          <div className="form__form-group-field">
            <div className="account__btns ml-0">
              <Button
                className="account__btn mb-0 mt-2 submit-btn"
                color="primary"
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </AvForm>
    </div>
  );
};

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  forgotPassword: (data, setIsModalOpen) =>
    dispatch(loginActions.forgotPassword(data, setIsModalOpen)),
});

// mapping action and store the function via props
export default connect(null, mapDispatchToProps)(withRouter(ForgotPassword));
