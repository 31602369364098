import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import { Calendar } from "react-multi-date-picker";
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { slotsInDay } from "../../../../../constants";
import { shutterbDashboardActions } from "../../../../../redux/actions";
import { minimumAllowedDate, customToast } from "../../../../../shared";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTable from "react-data-table-component";

const MySchedule = (props) => {
  const { t } = useTranslation("common");
  const columns = [
    {
      name: "Date",
      selector: "date",
      sortable: true,
      width: "170px",
      cell: (row) => {
        return new Date(row.date * 1000).toDateString();
      },
    },
    {
      name: "Start Time",
      selector: "startTime",
      sortable: true,
      width: "160px",
      cell: (row) => {
        return row.startTime === null || row.startTime === ""
          ? "--"
          : new Date(row.startTime * 1000).toLocaleTimeString();
      },
    },
    {
      name: "End Time",
      selector: "endTime",
      sortable: true,
      width: "150px",
      cell: (row) => {
        return row.endTime === null || row.endTime === ""
          ? "--"
          : new Date(row.endTime * 1000).toLocaleTimeString();
      },
    },
  ];
  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        position: "sticky",
        top: "0",
      },
    },
  };

  const initialBlackoutDays = {
    displayDates: [],
    holidayDetails: [{ date: 0, startTime: 0, endTime: 0 }],
  };
  const [distanceValue, setDistanceValue] = React.useState(6);
  const [distanceValueForLongEvent, setDistanceValueForLongEvent] = useState(6);
  const [blackoutDays, setBlackoutDays] = useState(initialBlackoutDays);
  const [workingSchedules, setWorkingSchedules] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFullHolidayEnabled, setIsFullHolidayEnabled] = useState(true);
  const [holidayStartTime, setHolidayStartTime] = useState("");
  const [holidayEndTime, setHolidayEndTime] = useState("");
  const [isErrorFound, setIsErrorFound] = useState(false);

  const customInputStyle = {
    display: "block",
    height: "calc(1.5em + 0.75rem + 2px)",
    padding: "0.375rem 0.75rem",
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: 1.5,
    color: "#495057",
    backgroundColor: "#fff",
    backgroundClip: "padding-box",
    border: "1px solid #ced4da",
    borderRight: "none",
  };

  // on reload getting updated info from server
  useEffect(() => {
    props.getMySchedule(
      props.sbUserId,
      setDistanceValue,
      setBlackoutDays,
      setWorkingSchedules,
      setDistanceValueForLongEvent
    );
    // eslint-disable-next-line
  }, []);

  // slider handle change func
  const handleChange = (event, newValue) => {
    setDistanceValue(newValue);
  };

  // slider handle change func for 2 hour+ event
  const handleChange2 = (event, newValue) => {
    setDistanceValueForLongEvent(newValue);
  };

  // schedule handle change func
  const handleScheChange = (e, dayIndex, slotIndex) => {
    const updatedWorkingSche = JSON.parse(JSON.stringify(workingSchedules));
    updatedWorkingSche[dayIndex].timeSlots[slotIndex].isEnable =
      !updatedWorkingSche[dayIndex].timeSlots[slotIndex].isEnable;
    setWorkingSchedules(updatedWorkingSche);
  };

  // on submission put call to update info in backend
  const submission = (e, values) => {
    if (values.distance2 < values.distance) {
      customToast(
        t("max_distance_cannot_be_less_than_min_distance"),
        toast.TYPE.ERROR
      );
      return;
    }
    const dataObj = {
      SBUserId: props.sbUserId,
      WorkingSchedules: values.workingSchedules,
      HolidayProfile: blackoutDays.holidayDetails,
      TravelDistance: {
        minDistanceToTravel: values.distance,
        unit: "KM",
        maxDistanceToTravel: values.distance2,
      },
    };

    props.updateMySchedule(props.sbUserId, dataObj);
  };

  const selectDeselectAll = (e) => {
    const updatedWorkingSche = JSON.parse(JSON.stringify(workingSchedules));
    setWorkingSchedules(
      updatedWorkingSche.map((day, key) => {
        return {
          ...day,
          timeSlots: day.timeSlots.map((timeSlot) => {
            return {
              ...timeSlot,
              isEnable: e.target.checked,
            };
          }),
        };
      })
    );
  };

  const manageBlackoutDays = (e) => {
    for (let index = 0; index < e.length; index++) {
      const element = e[index];
      if (e.length > blackoutDays.holidayDetails.length) {
        var findBlackoutDayIfExist = blackoutDays.holidayDetails.find(
          (y) => y.date === element.unix
        );
        if (findBlackoutDayIfExist === undefined) {
          setBlackoutDays((prevBlackoutDays) => ({
            displayDates: [
              ...prevBlackoutDays.displayDates,
              element.unix * 1000,
            ],
            holidayDetails: [
              ...prevBlackoutDays.holidayDetails,
              { date: element.unix, startTime: "", endTime: "" },
            ],
          }));
          setIsModalOpen(true);
          setIsFullHolidayEnabled(true);
        }
      }
    }

    if (e.length < blackoutDays.holidayDetails.length)
      var updatedListOfHolidayDetails = [...blackoutDays.holidayDetails];
    var updatedListOfDisplatDates = [...blackoutDays.displayDates];
    setBlackoutDays({
      displayDates: updatedListOfDisplatDates.filter((item) =>
        e.some((y) => y.unix * 1000 === item)
      ),
      holidayDetails: updatedListOfHolidayDetails.filter((item) =>
        e.some((y) => y.unix === item.date)
      ),
    });
  };

  const handleBlackoutDays = () => {
    const startTime =
      holidayStartTime !== ""
        ? Math.floor(new Date(holidayStartTime).getTime() / 1000)
        : "";

    const endTime =
      holidayEndTime !== ""
        ? Math.floor(new Date(holidayEndTime).getTime() / 1000)
        : "";

    if (!isFullHolidayEnabled && (startTime === "" || endTime === "")) {
      setIsErrorFound(true);
      // customToast(t("Invalid Time"), toast.TYPE.ERROR);
      return;
    } else if (!isFullHolidayEnabled && startTime >= endTime) {
      customToast(
        t("End time must be greater than start time"),
        toast.TYPE.ERROR
      );
      return;
    } else {
      const lastIndex = blackoutDays.holidayDetails.length - 1;
      const updatedBlackoutDays = [...blackoutDays.holidayDetails];
      const lastBlackoutDay = updatedBlackoutDays[lastIndex];
      // Update the specified date's start and end times
      lastBlackoutDay.startTime = startTime;
      lastBlackoutDay.endTime = endTime;
      setHolidayStartTime("");
      setHolidayEndTime("");
      setIsErrorFound(false);
      setIsModalOpen(false);
      // console.log("test", blackoutDays);
    }
  };

  const handleCloseModal = () => {
    var updatedListOfHolidayDetails = [...blackoutDays.holidayDetails];
    var updatedListOfDisplatDates = [...blackoutDays.displayDates];
    setBlackoutDays({
      displayDates: updatedListOfDisplatDates.slice(0, -1),
      holidayDetails: updatedListOfHolidayDetails.slice(0, -1),
    });
    setHolidayStartTime("");
    setHolidayEndTime("");
    setIsModalOpen((previousState) => !previousState);
  };

  return (
    <div className="shutterb-dashboard-my-schedule">
      <AvForm id="sb-schedule-form" onValidSubmit={submission} noValidate>
        {/* Working schedule */}
        <div className="py-3">
          <h6 className="sch-sub-heading">Times</h6>
          <p>Please set-up when would you like to be available for bookings.</p>
          <div class="check-table-cont">
            <table class="check-table">
              <thead>
                {/* <th></th> */}

                <th>Time Slots</th>

                <th>Sun</th>

                <th>Mon</th>

                <th>Tue</th>

                <th>Wed</th>

                <th>Thu</th>

                <th>Fri</th>

                <th>Sat</th>
              </thead>
              {slotsInDay.map((e, key) => (
                <tr>
                  {/* <td>{timesOfTheDay[key]}</td> */}
                  <td>{e}</td>

                  {workingSchedules.map((day, key2) => {
                    return (
                      <td>
                        <div class="form-group">
                          <div class="checkbox check_custom chck-set">
                            <label>
                              <input
                                type="checkbox"
                                id={key + " " + key2}
                                name={key + " " + key2}
                                key={key + " " + key2}
                                onChange={(e) =>
                                  handleScheChange(
                                    e,
                                    day.dayInWeek,
                                    day?.timeSlots?.[key].slotId
                                  )
                                }
                                checked={day?.timeSlots?.[key].isEnable}
                              />
                              <span class="check_box"></span>
                            </label>
                          </div>
                        </div>
                      </td>
                    );
                  })}
                </tr>
              ))}
            </table>
          </div>
          <div class="table-btm-content">
            <ul className="d-flex justify-content-center flex-wrap">
              <li className="p-0 m-0 d-flex justify-content-between align-items-center">
                <div className="avalaible-sign"></div>
                <p className="p-0 m-0"> Available </p>
              </li>

              <li className="p-0 m-0 ml-3 d-flex justify-content-between align-items-center">
                <div className="not-avalaible-sign"></div>
                <p className="p-0 m-0"> Not Available </p>
              </li>

              <li className="p-0 m-0 ml-3 d-flex justify-content-between align-items-center">
                <input
                  type="checkbox"
                  name="selectDeselectAll"
                  id="selectDeselectAll"
                  className="select-deselect-all"
                  defaultChecked={false}
                  onChange={(e) => selectDeselectAll(e)}
                />
                <label htmlFor="selectDeselectAll" className="p-0 m-0">
                  Select/Deselect All
                </label>
              </li>
            </ul>
          </div>
        </div>
        <AvField
          className="d-none"
          name="workingSchedules"
          value={workingSchedules}
        />

        {/* Blackout days */}
        <div className="blackout-days-con py-3">
          <h6 className="sch-sub-heading">Blackout Days</h6>
          <p>
            If there are any dates you know you aren’t available to work you can
            block them off here.
          </p>
          <div className="">
            <div className="row">
              <div className="col-md-5 my-2 calendar-con d-flex d-md-block justify-content-center align-items-center p-1">
                <Calendar
                  value={blackoutDays.displayDates}
                  minDate={minimumAllowedDate()}
                  // onChange={setBlackoutDays}
                  style={{ zIndex: "10" }}
                  onChange={(e) =>
                    e.length === 0
                      ? setBlackoutDays({
                          displayDates: [],
                          holidayDetails: [],
                        })
                      : manageBlackoutDays(e)
                  }
                  multiple
                ></Calendar>
              </div>
              <div className="col-md-7 my-2">
                <DataTable
                  columns={columns}
                  data={blackoutDays.holidayDetails}
                  highlightOnHover
                  responsive
                  customStyles={{
                    ...customStyles,
                    table: "tbl-holiday", // Add your custom class here
                  }}
                  className="sticky-header-class my-1 tbl-holiday"
                />
                {isModalOpen ? (
                  <Modal
                    isOpen={isModalOpen}
                    onRequestClose={(e) => manageBlackoutDays(e)}
                    contentLabel="Holiday Profile Modal"
                    className="holiday-profile-modal"
                  >
                    <div className="modal-content">
                      <AvCheckboxGroup
                        inline
                        name="HolidayProfileCheckbox"
                        label=""
                        className="my-3"
                      >
                        <AvCheckbox
                          label="Black out entire day"
                          checked={isFullHolidayEnabled}
                          onClick={(e) => {
                            if (e.target.checked === true) {
                              setHolidayStartTime("");
                              setHolidayEndTime("");
                              setIsErrorFound(false);
                            }
                            setIsFullHolidayEnabled(e.target.checked);
                          }}
                        />
                      </AvCheckboxGroup>{" "}
                      {!isFullHolidayEnabled ? (
                        <div className="row w-100">
                          <div className="col-md-6">
                            <label>Start Time</label>
                            <DatePicker
                              selected={holidayStartTime}
                              onChange={(time) => {
                                setHolidayStartTime(time);
                              }}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              timeCaption="Time"
                              timeFormat="h:mm aa" // Use "h:mm aa" for 12-hour format
                              dateFormat="h:mm aa" // Use "h:mm aa" for 12-hour format
                              className={`input-height-time`}
                              popperClassName="custom-datepicker-popper"
                              minTime={new Date().setHours(8, 0, 0, 0)} // 8 AM
                              maxTime={new Date().setHours(23, 59, 59, 999)} // 11:59 PM
                              customInput={
                                <div className="input-group">
                                  <input
                                    style={customInputStyle}
                                    name="holidayStartTime"
                                    required
                                    className={`form-control `}
                                    value={
                                      holidayStartTime // Add this line to set the value
                                        ? new Date(
                                            holidayStartTime
                                          ).toLocaleTimeString("en-US", {
                                            hour: "numeric",
                                            minute: "numeric",
                                            hour12: true,
                                          })
                                        : ""
                                    }
                                  />
                                  <div className="input-group-append">
                                    <span
                                      className={`input-group-text clock-icon`}
                                    >
                                      <i className="fas fa-clock"></i>
                                    </span>
                                  </div>
                                  {holidayStartTime === "" && isErrorFound && (
                                    <div className="is-time-field-empty">
                                      Start Time is required
                                    </div>
                                  )}
                                </div>
                              }
                              // Additional props can be added as per your requirement
                            />
                          </div>
                          <div className="col-md-6">
                            <label>End Time</label>
                            <DatePicker
                              selected={holidayEndTime}
                              onChange={(time) => {
                                setHolidayEndTime(time);
                              }}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              timeCaption="Time"
                              timeFormat="h:mm aa" // Use "h:mm aa" for 12-hour format
                              dateFormat="h:mm aa" // Use "h:mm aa" for 12-hour format
                              className={`input-height-time`}
                              popperClassName="custom-datepicker-popper"
                              minTime={new Date().setHours(8, 0, 0, 0)} // 8 AM
                              maxTime={new Date().setHours(23, 59, 59, 999)} // 11:59 PM
                              customInput={
                                <div className="input-group">
                                  <input
                                    style={customInputStyle}
                                    name="holidayEndTime"
                                    // required
                                    className={`form-control`}
                                    value={
                                      holidayEndTime // Add this line to set the value
                                        ? new Date(
                                            holidayEndTime
                                          ).toLocaleTimeString("en-US", {
                                            hour: "numeric",
                                            minute: "numeric",
                                            hour12: true,
                                          })
                                        : ""
                                    }
                                  />
                                  <div className="input-group-append">
                                    <span
                                      className={`input-group-text clock-icon`}
                                    >
                                      <i className="fas fa-clock"></i>
                                    </span>
                                  </div>
                                  {holidayEndTime === "" && isErrorFound && (
                                    <div className="is-time-field-empty">
                                      End Time is required
                                    </div>
                                  )}
                                </div>
                              }
                              // Additional props can be added as per your requirement
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="con">
                        <div class="set-save-btn">
                          <button
                            type="submit"
                            class="btn-simple my-3"
                            onClick={() => {
                              handleBlackoutDays();
                            }}
                          >
                            DONE
                          </button>
                        </div>
                        <button
                          className="modal-close"
                          onClick={handleCloseModal}
                        >
                          <img
                            src="/images/simpleCrossWhite.svg"
                            alt="crossIcon"
                            className="modal-close-img"
                          />
                        </button>
                      </div>
                    </div>
                  </Modal>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <AvField className="d-none" name="blackoutDays" value={blackoutDays} />

        {/* Travel distance for 1 hour*/}
        <div className="py-3">
          <h6 className="sch-sub-heading">Travel Distance (1 hour event)</h6>
          <p>
            Please specify your desired maximum travel distance you’d be willing
            to travel for a 1 hour event from your home address
          </p>
          <Stack
            spacing={2}
            direction="row"
            sx={{ mb: 1 }}
            alignItems="center"
            className="p-0 m-0"
          >
            <Slider
              className="p-0 m-0"
              sx={{ color: "#3e8993" }}
              aria-label="Distance"
              defaultValue={1}
              value={distanceValue}
              onChange={handleChange}
              valueLabelDisplay="auto"
              valueLabelFormat={distanceValue + " km"}
              step={1}
              marks
              min={1}
              max={100}
            />
          </Stack>
          <div>
            <ul className="d-flex justify-content-between">
              <li className="p-0 m-0">
                <p className="p-0 m-0"> Min </p>
              </li>

              <li className="p-0 m-0">
                <p className="p-0 m-0"> Max </p>
              </li>
            </ul>
          </div>
        </div>
        <AvField className="d-none" name="distance" value={distanceValue} />

        {/* Travel distance for 2 hour+ event */}
        <div className="py-3">
          <h6 className="sch-sub-heading">Travel Distance (2 hour+ event)</h6>
          <p>
            Please specify your desired maximum travel distance you’d be willing
            to travel for a 2 hour+ event from your home address
          </p>
          <Stack
            spacing={2}
            direction="row"
            sx={{ mb: 1 }}
            alignItems="center"
            className="p-0 m-0"
          >
            <Slider
              className="p-0 m-0"
              sx={{ color: "#3e8993" }}
              aria-label="Distance"
              defaultValue={1}
              value={distanceValueForLongEvent}
              onChange={handleChange2} // Make sure you have a separate handleChange function for this
              valueLabelDisplay="auto"
              valueLabelFormat={distanceValueForLongEvent + " km"}
              step={1}
              marks
              min={1}
              max={100}
            />
          </Stack>
          <div>
            <ul className="d-flex justify-content-between">
              <li className="p-0 m-0">
                <p className="p-0 m-0"> Min </p>
              </li>
              <li className="p-0 m-0">
                <p className="p-0 m-0"> Max </p>
              </li>
            </ul>
          </div>
        </div>
        <AvField
          className="d-none"
          name="distance2"
          value={distanceValueForLongEvent}
        />

        <div className="con">
          <div class="set-save-btn">
            <button type="submit" class="btn-simple">
              SAVE
            </button>
          </div>
        </div>
      </AvForm>
    </div>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  sbUserId: state.shutterbAuthentication.sbUserId,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getMySchedule: (
    id,
    setDistanceValue,
    setBlackoutDays,
    setWorkingSchedules,
    setDistanceValueForLongEvent
  ) =>
    dispatch(
      shutterbDashboardActions.mySchedule(
        id,
        setDistanceValue,
        setBlackoutDays,
        setWorkingSchedules,
        setDistanceValueForLongEvent
      )
    ),
  updateMySchedule: (id, dataObj) =>
    dispatch(shutterbDashboardActions.updateMySchedule(id, dataObj)),
});

// mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MySchedule));
