import React, { useEffect, useMemo } from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import LoadingIcon from "mdi-react/LoadingIcon";
import { useTranslation } from "react-i18next";
import { AvForm, AvField } from "availity-reactstrap-validation";

import { NavigateToPathWithGo, NavigateToPath } from "../../../../system";
import { shutterbActions } from "../../../../redux/actions";
import { loginConstants } from "../../../../constants/login.constants";
import * as modalConstants from "../../../../constants/modal.constants";
import { configurationsConstants } from "../../../../constants";

const ShutterbLogin = (props) => {
  const { t } = useTranslation("common");
  const history = useHistory();
  const loginSpinner = useMemo(
    () =>
      props.spinnerActions.findIndex((f) => f === loginConstants.USER_LOGIN),
    [props.spinnerActions]
  );

  // when user clicks on Login button
  const handleSubmit = (event, values) => {
    props.onShutterbLoginClick({ ...values }, props.setIsModalOpen);
  };

  // navigate to Shutterb Dasboard if authenticated
  useEffect(() => {
    if (props.isShutterbAuthenticated) {
      NavigateToPathWithGo(
        history,
        "/Shutterbdashboard",
        "/Shutterbdashboard",
        2
      );
    }
    //eslint-disable-next-line
  }, [props.isShutterbAuthenticated]);

  return (
    <div
      className={`login-form d-flex flex-column justify-content-center px-0 px-sm-1 px-md-4 ${
        props.isAllowModal ? "" : "border-color-grey"
      }`}
    >
      {!props.isPage && (
        <button id="cancel-icon" onClick={() => props.setIsModalOpen(false)}>
          <span>X</span>
        </button>
      )}
      <AvForm className="form form_Display_Flex" onValidSubmit={handleSubmit}>
        {/* cancel icon */}
        <div className="form-header-group pt-3">
          <h2 className="pt-0 pb-1">shutterb Login</h2>
          <p className="p-0 pt-3 m-0">
            Welcome back! Please fill in your email and password below to sign
            into your account
          </p>
        </div>
        <div className="form__form-group">
          {/* <span className="form__form-group-label">{t("user_name")}</span> */}
          <div className="form__form-group-field">
            <div>
              <AvField
                type="email"
                name="userName"
                placeholder="Email"
                className="input-without-border-radius"
                maxLength={configurationsConstants.EMAIL_INPUT_LENGTH}
                pattern="^[a-z,A-Z,0-9,!@#$%^&*_=+-].{3,}$"
                validate={{
                  required: {
                    value: true,
                    errorMessage: t("this_field_is_required"),
                  },
                }}
              />
            </div>
          </div>
        </div>
        <div className="form__form-group">
          {/* <span className="form__form-group-label">{t("user_name")}</span> */}
          <div className="form__form-group-field">
            <div>
              <AvField
                customInput
                name="password"
                type="password"
                placeholder="Password"
                className="input-without-border-radius"
                maxLength={configurationsConstants.PASSWORD_INPUT_LENGTH}
                pattern="^[a-z,A-Z,0-9,!@#$%^&*_=+-].{3,}$"
                validate={{
                  required: {
                    value: true,
                    errorMessage: t("this_field_is_required"),
                  },
                }}
              />
            </div>
          </div>
        </div>
        <div className="form__form-group align-self-end d-flex justify-content-end">
          <p className="p-0 py-sm-1 py-md-2 m-0">
            {/* eslint-disable-next-line */}
            <a
              href="#"
              onClick={() =>
                props.setFormSelect(modalConstants.FORGOT_PASSWORD_FORM)
              }
            >
              Forgot your password?
            </a>
          </p>
        </div>
        <div className="form__form-group mb-4">
          <div className="form__form-group-field">
            <div className="account__btns ml-0">
              {loginSpinner > -1 ? (
                <div className=" account__btn text-center login_spinner">
                  <LoadingIcon fill="#70bbfd" style={{ width: "50px" }} />
                </div>
              ) : (
                <Button
                  className="account__btn mb-0 mt-2 submit-btn"
                  color="primary"
                >
                  {/* {t("login")} */}
                  LOGIN
                </Button>
              )}
            </div>
          </div>
        </div>
      </AvForm>
      <div className="form__form-group align-self-center">
        <p className="p-0 m-0">
          To become a shutterb {/* eslint-disable-next-line */}
          <a
            href="#"
            // shutterb signup will be redirected
            onClick={() => {
              props.setIsModalOpen(false);
              NavigateToPath(history, "/be-a-shutterb");
            }}
          >
            sign up here
          </a>
        </p>
      </div>
    </div>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  isShutterbAuthenticated: state.shutterbAuthentication.isShutterbAuthenticated,
  spinnerActions: state.system.spinnerActions,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  onShutterbLoginClick: (data, setIsModalOpen) =>
    dispatch(shutterbActions.shutterbLogin(data, setIsModalOpen)),
});

// mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ShutterbLogin));
