export * from "./login.constants";
export * from "./eventBooking.constants";
export * from "./shutterb.constants";
export * from "./customer.constants";
export * from "./shutterbProfile.constants";
export * from "./configurations.constants";
export * from "./modal.constants";
export * from "./shared.constants";
export * from "./messageBoard.constants";
export * from "./gallery.constants";
export * from "./regex.constant";

export const globalConstants = {
  REQUEST: "_REQUEST",
  SUCCESS: "_SUCCESS",
  FAILURE: "_FAILURE",
};

export const globalSystemConstants = {
  SPINNER_ACTIVATE: "SPINNER_ACTIVATE",
  SPINNER_DIACTIVATE: "SPINNER_DIACTIVATE",
  OVERLAY_ACTIVATE: "OVERLAY_ACTIVATE",
  OVERLAY_DIACTIVATE: "OVERLAY_DIACTIVATE",
  DOWNLOAD_SPINNER_ACTIVATE: "DOWNLOAD_SPINNER_ACTIVATE",
  DOWNLOAD_SPINNER_DIACTIVATE: "DOWNLOAD_SPINNER_DIACTIVATE",
};

export const userType = {
  ADMIN: "1",
  SHUTTERB: "2",
  USER: "3",
};
