// eslint-disable-next-line import/no-cycle
import responseHandler from "../system/server-response-handler";
import { getRequest } from "../system/server-call";

/**
 * The ShutterB Profile Service
 *
 * This is used for getting data from server and handling the business logic on that data
 */

// get shot list based on event type
const getShutterBProfile = (id) => {
  return getRequest(`/api/v1/ShutterB/Profile/ViewProfile/${id}`, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};


// exporting functions for other files
export const shutterbProfileServices = {
    getShutterBProfile,
};
