import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Tabs, Tab, Box } from "@mui/material";
import ReelGallery from "./ReelGallery";
import ReelDetailsModal from "../ReelDetailsModal";
import { customerConstants } from "../../../../../constants";
import { shutterbDashboardActions } from "../../../../../redux/actions";

const ReelEvents = (props) => {
  const [...reelEvents] = props.reelEvents;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reelModalEventId, setReelModalEventId] = useState(null);
  const [activeTab, setActiveTab] = useState(customerConstants.PENDING_EVENTS);

  useEffect(() => {
    // call action to getThe evenRequests from server and update everything
    props.getReelEvents(props.sbUserId, customerConstants.PENDING_EVENTS);

    // call action that will call serice to get the user data
    //  eslint-disable-next-line
  }, []);

  const convertDateToUTC = (date) => {
    const epochTimestamp = date;
    const getDate = new Date(epochTimestamp * 1000);
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = months[getDate.getUTCMonth()];
    const day = getDate.getUTCDate();
    const year = getDate.getUTCFullYear();

    return `${month} ${day}, ${year}`;
  };

  const handleEvents = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      props.getReelEvents(props.sbUserId, tab);
    }
  };

  const closeAllAccordians = () => {
    // Remove class 'close-accordian' from all accordions
    const accordions = document.querySelectorAll(".close-accordian");
    if (accordions) {
      accordions.forEach((accordion) => {
        accordion.classList.remove("show");
      });
    }
  };

  return (
    <>
      <Box sx={{ width: "100%" }} className="mb-4">
        <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
          <Tabs
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            value={activeTab}
            aria-label="basic tabs example"
            className="member-my-events-tabs"
          >
            <Tab
              label="Pending"
              onClick={() => {
                handleEvents(customerConstants.PENDING_EVENTS);
              }}
              aria-selected={
                activeTab === customerConstants.PENDING_EVENTS ? true : false
              }
            />
            <Tab
              label="Completed"
              onClick={() => {
                handleEvents(customerConstants.COMPLETED_EVENTS);
              }}
              aria-selected={
                activeTab === customerConstants.COMPLETED_EVENTS ? true : false
              }
            />
          </Tabs>
        </Box>
      </Box>
      <div id="accordion" className="container p-0">
        {reelEvents.length ? (
          [...reelEvents].reverse().map((eventDetails) => {
            return (
              <>
                <div className="reel-events-con px-0 py-0">
                  {/* accordian header */}
                  <div id="headingOne" className="event-request-header">
                    <div
                      className="row"
                      data-toggle="collapse"
                      data-target={"#collapseOne" + eventDetails?.id}
                      aria-expanded="true"
                      aria-controls={"collapseOne" + eventDetails?.id}
                    >
                      <div className="col-md-8">
                        <h3>
                          {eventDetails?.eventName +
                            " - " +
                            convertDateToUTC(eventDetails?.eventDate) +
                            " - " +
                            eventDetails?.user?.firstName}
                        </h3>
                      </div>
                      <div className="col-md-4">
                        <h3 className="event-header-text text-md-right">
                          Event ID: {eventDetails?.id}
                        </h3>
                      </div>
                    </div>
                  </div>

                  {/* accordian body */}
                  <div
                    id={"collapseOne" + eventDetails?.id}
                    className="collapse event-request-body close-accordian"
                    aria-labelledby="headingOne"
                    data-parent="#accordion"
                  >
                    <div>
                      <div className="container">
                        <div className="row">
                          <div className="col-12">
                            {!eventDetails?.reelStatus ? (
                              <ReelGallery
                                eventId={eventDetails?.id}
                                eventName={eventDetails?.eventName}
                                reelQty={
                                  eventDetails?.order?.orderItems[0]
                                    ?.quantity ?? 0
                                }
                                isAllowDownload={eventDetails?.isDownloadable}
                                setReelModalEventId={setReelModalEventId}
                                setIsModalOpen={setIsModalOpen}
                                isModalOpen={isModalOpen}
                                closeAllAccordians={closeAllAccordians}
                              />
                            ) : (
                              <p> Recap have been uploaded successfully.</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })
        ) : (
          <p>You have no events at the moment.</p>
        )}
      </div>
      <ReelDetailsModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        reelModalEventId={reelModalEventId}
        className="reel-detail-con"
      />
    </>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  sbUserId: state.shutterbAuthentication.sbUserId,
  reelEvents: state.shutterbDashboard.reelEvents,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getReelEvents: (sbUserId, status) =>
    dispatch(shutterbDashboardActions.getReelEvents(sbUserId, status)),
  updateEventRequest: (id, dataObj) =>
    dispatch(shutterbDashboardActions.updateEventRequest(id, dataObj)),
});

// mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ReelEvents));
