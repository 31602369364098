import React, { Component, Fragment } from "react";
import * as Sentry from "@sentry/react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  restart = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.replace("/");
  };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
    // logging error into sentry
    Sentry.captureException(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Fragment>
          <div className="not-found d-flex justify-content-center align-items-center">
            <div className="not-found__content d-flex justify-content-center align-items-center flex-column px-3">
              <img
                // src="https://img.icons8.com/quill/200/broken-robot.png"
                src="/images/logo.png"
                alt="broken-robot"
              />
              <h4 className="not-found__info w-100 text-center py-3 m-0">
                Something went wrong, Kindly report the problem
              </h4>
              <div className="d-flex justify-content-center align-items-between flex-row flex-wrap">
                <a
                  className="btn mr-0 mr-md-2 mb-2 mb-md-0"
                  onClick={this.restart}
                  href={`${process.env.REACT_APP_MARKETING_SITE_BASE_URL}/contact-us/`}
                >
                  Contact Us
                </a>
                <button className="btn" onClick={this.restart}>
                  Go Back to Login
                </button>
              </div>
            </div>
          </div>
        </Fragment>
      );
    }

    return this.props.children;
  }
}
export default ErrorBoundary;
