import { globalConstants } from "../../constants";

function request(data, type, component = "") {
  return {
    type: (component ? `${component}_` : "") + type + globalConstants.REQUEST,
    data,
  };
}

function success(data, type, component = "") {
  return {
    type: (component ? `${component}_` : "") + type + globalConstants.SUCCESS,
    data,
  };
}

function failure(data, type, component = "") {
  return {
    type: (component ? `${component}_` : "") + type + globalConstants.FAILURE,
    data,
  };
}

function custom(data, type, component = "") {
  return {
    type: (component ? `${component}_` : "") + type,
    data,
  };
}

export { request, success, failure, custom };
