const common_en = {
  user_name: "User Name",
  password: "Password",
  login: "Login",
  user_email: "Email",
  confirm_password: "Confirm Password",
  logged_in_successfully: "Logged In sucessfully",
  registered_successfully: "Registered sucessfully",
  saved_successfully: "Saved sucessfully",

  // shutterB registration----------------------------------------------------------
  first_name: "First Name",
  last_name: "Last Name",
  current_address: "Current Address",
  please_enter_first_name: "Please Enter First Name",
  please_enter_last_name: "Please Enter Last Name",
  please_enter_email: "Please Enter Your Email",
  please_enter_instagram: "Please Enter Your Instagram Handle",
  please_enter_distance: "Please Enter Distance",
  please_enter_phone_number: "Please Enter Your Phone Number",
  invalid_registration_image_count: "Invalid Registration Image Count",
  max_3_photos_can_be_selected: "Max 3 photos can be selected",
  please_select_image: "Please select image",
  invalid_file_type: "Invalid File Type, Allowed Files are JPG/PNG",
  form_submitted_successfully: "Form Submitted Successfully",

  // enter_a_location: from event form,
  distance_willing_to_travel: "Distance Willing to Travel",
  email_address: "Email Address",
  instagram_handle: "Instagram Handle",
  phone_number: "Phone Number",
  current_smartphone: "Current Smartphone",
  please_select_smart_phone: "Please select smart phone",
  shutterb_question_1: "Why do you want to work for shutterb?",
  shutterb_question_1_description:
    "Tell us why you’d fit into our community of go-getting, creative and talented individuals. We’re not only looking for good smartphone content creators, we are also looking for people who are personable, professional and passionate about doing what they love!",
  shutterb_question_2:
    "Why do you think you’ll be a great smartphone content creator?",
  shutterb_question_2_description:
    "Tell us what you like about smartphone content creation.  What’s your style and how do you approach it?  Do you have any smartphone accessories that enhance your capabilities as a smartphone content creator?",
  shutterb_question_3:
    "Have you ever worked independently as a gig worker / freelancer before?",
  shutterb_question_3_description:
    "What did you think about it? What were the benefits? What were the drawbacks?",
  shutterb_question_4:
    "How many hours do you plan to devote to shutterb a week?",
  shutterb_question_4_description:
    "When you’re a shutterb you need to commit to a minimum 1 x 3 hour window of availability per week, but we encourage you to make yourself as available as you can be!",
  upload_image_msg:
    "Upload max 3 photos you’ve taken from your smartphone. These should represent your ability to capture people, food, decor or shared moments.",
  select_multiple_files: "Select Multiple Files",
  submit_application: "Submit Application",
  already_booked: "Event Already Booked",
  event_cancellation_failed: "Event Cancellation Failed",
  event_cancel_successfully: "Event Cancel Successfully",

  // Shutterb dashboard-----------------------------------------
  profile_name: "Profile Name",
  this_field_is_required: "This Field is Required",
  bio: "Bio",
  portfolio_images: "Portfolio Images",
  portfolio_images_description:
    "Please select a minimum of 6, maximum of 12 images for your portfolio page. Hosts will get to review available shutterbz in their area so make sure you’re choosing images that will help you stand out and really show your style! These images must be taken from your current smartphone.",
  device_type: "Device Type",
  language_spoken: "Language Spoken",
  additional_equipment: "Additional equipment (optional)",
  additional_equipment_description:
    "If you have smartphone camera accessories that you feel will up your game and offer hosts an elevated experience feel free to add it here!",
  save: "Save",
  save_and_preview: "Save and Preview",
  preview: "Preview",
  my_info_heading_msg:
    "This is your personal info for our records. This will not be shared with event hosts.",
  home_address: "Home Address",
  primary_phone_number: "Primary Phone Number",
  emergency_contact_name: "Emergency Contact Name",
  emergency_contact_number: "Emergency Contact Number ",
  stripe_btn_msg:
    "Click below to update your banking info with our payment fulfillment partners Stripe Connect.",
  connected: "Connected",
  img_successfully_deleted: "Files Successfully Deleted",
  max_img_error: "Maximum 12 images are allowed",
  successfully_checked_out: "Successfully Checked Out",
  successfully_checked_in: "Successfully Checked In",
  file_successfully_deleted: "File Successfully Deleted",
  someone_not_checkout_from_event: "Someone not checkout from event.",
  invalid_image_type: "Unsupported image format.",
  max_distance_cannot_be_less_than_min_distance:
    "Travel distance for 2hrs+ event cannot be less than 1 hour event",

  //customer dashboard------------------------------------------------------------------
  please_select_tip: "Please select tip amount.",
  please_select_shutterb: "Please select atleast 1 shutterb.",
  already_exists: "Action already perfomred on this shutterb.",
  address: "Address",
  city: "City",
  postal_code: "Postal Code",
  province: "Province",
  country: "Country",
  update: "Update",
  current_password: "Current Password",
  new_password: "New Password",
  confirm_new_password: "Confirm New Password",
  password_instruction_error:
    "Must contain a Number, a Special character, a Upper case letter, a Lower case letter and Minimum 6 characters",
  updated_successfully: "Updated Successfully",
  password_updated_successfully: "Password Updated Successfully",
  password_not_matched: "Current Password is incorrect.",
  refer_to_friend_successfully: "Invitation send successfully.",
  review_submit_successfully: "Review submitted successfully.",
  invalid_eventid: "Invalid Event ID",

  //event booking form------------------------------------------------------------------
  event_booking_form: "EVENT BOOKING FORM",
  book_your_event: "BOOK YOUR EVENT",
  event_setup: "EVENT setup",
  select_shutterb: "SELECT SHUTTERB",
  add_reel: "ADD RECAP",
  cart: "Cart",
  payment: "payment",
  promo_code_has_expired: "Promo code has expired.",
  invalid_promo_code: "Invalid Promo Code",
  event_detail: "Event Details",

  //event setup---------------------------------------------------------------------------
  event_name: "Event Name",
  please_enter_an_event_name: "Please enter an event name",
  event_date: "Event Date",
  please_select_an_event_date: "Please select an event date",
  event_start_time: "Event Start Time",
  event_location: "Event Location",
  enter_a_location: "Enter a Location",
  loading: "Loading...",
  location_description:
    "We are only servicing select cities in the GTA, Ontario Canada for now. If you are not in the GTA ",
  location_description_next: " for updates on when we’ll be in your area.",
  additional_notes_description:
    "Let us know anything else we should know about your event to ensure we capture everything!",
  appartment_unit_or_suite_no: "Apartment, Unit or Suite Number",
  please_select_an_event_type: "Please select an event type",
  additional_notes: "Additional Notes",
  duration: "Duration (Hours)",
  please_enter_a_duration: "Please enter a duration",
  you_have_read_and_accepted: "You have read & accepted",
  terms_and_condition: "Terms and Conditions",
  you_must_accept_terms_and_conditions:
    "You must accept the terms and conditions",
  privacy_policy: "Privacy Policy",
  you_must_accept_privacy_policy: "You must accept the privacy policy",
  next: "Next",
  please_add_additional_notes: "Please add additional notes",
  added_to_cart: "shutterb(z) have been added to your cart",
  cart_is_empty: "Cart is empty",
  please_enter_a_value_greater_than_or_equal_to_1:
    "Please enter a value greater than or equal to 1",
  please_enter_a_value_less_than_or_equal_to_10:
    "Please enter a value less than or equal to 10",
  contact_name: "On-Site Contact Name",
  contact_number: "On-Site Contact Phone Number",

  //create password----------------------------------------
  password_has_been_set_successfully: "Password has been set successfully",
  change_password: "Change Password",
  create_or_change_password: "Create or Change password",

  // Toast error handeling
  undefined: "Something went wrong.",
  invalid_login: "Invalid Login.",
  invalid_input: "Invalid Input.",
  unable_to_access_server: "Unable to access server.",
  internal_server_error: "Internal server error.",
  email_sent_success: "Email Sent Successfully",
  email_sent_failiure: "Email Sent Failure",
  user_already_taken: "User name already exists.",
  user_already_exists: "User already exists.",
  email_is_taken: "Sorry, this user email has already applied.",
  invalid_question_object: "Invalid questions submission.",
  duplicate_value_exist: "One or more fields exists already.",
  object_not_exist: "Object does not exist.",
  something_went_wrong: "Something went wrong.",
  user_registration_failed: "User registration failed",
  not_active: "User is Not Active",
  user_not_active: "User is Not Active",
  username_incorrect: "Username or password is incorrect",
  password_incorrect: "Username or password is incorrect",
  email_not_verified: "Email is not verified",
  password_reset_sent: "Please check your email to set a new password.",
  email_not_found: "Email not found",

  //ShutterB gallery------------------------
  files_already_uploaded: "Files already uploaded",
  no_id_exist: "No Id exist",
  only_12_images_are_allowed: "Only 12 images are allowed",
  no_shutterB_available_in_this_location:
    "No shutterB available in this location",
  please_unselect_videos: "Please Unselect Videos",
  already_refered: "Sorry, this email has already been sent a referral code.",
  reason_required: "Reason is required",
  event_request_accepted: "Event request accepted",
  event_request_declined: "Event request declined",
  atleast_six_pic_required: "At least 6 images are required for Portfolio",
  minimum_order_should_be: "Minimum amount should be 2$, Kindly contact Admin.",
  additional_language: "Additional language",
  you_will_receive_an_email_when_its_ready:
    "You will receive an email when download is ready",
  no_image:
    "Sorry the event content is not ready yet, please check back within 24 hours of the event ending",
  no_files_uploaded: "No Files Uploaded Yet!",
  download_files_email:
    "Your request is being processed.  Please allow for up to 10 minutes for this email to be sent to your inbox.",
};

export default common_en;
