import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";

const Dashboard = (props) => {
  return (
    <div className="row">
      <div className="col-md-12">
        {props.authentication.isAuthenticated ? (
          <div className="mt-4 mb-4 pt-4 pb-4">
            <br />
            <br />
            <h1 className="d-flex justify-content-center">Home Page</h1>
            <br />
            <br />
          </div>
        ) : (
          <div className="mt-4 mb-4 pt-4 pb-4">
            <br />
            <br />
            <h1 className="d-flex justify-content-center">Dashboard</h1>
            <br />
            <br />
          </div>
        )}
      </div>
    </div>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  spinnerActions: state.system.spinnerActions,
  authentication: state.authentication,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({});

// mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Dashboard));
