// eslint-disable-next-line import/no-cycle
import responseHandler from "../system/server-response-handler";
import {
  getRequest,
  putRequest,
  putRequestFormData,
  deleteRequestWithData,
  deleteRequestWithPayload,
  postRequestWithProgress,
} from "../system/server-call";

/**
 * The Shutterb Dashboard Services
 *
 * This is used for getting data from server and handling the business logic on that data
 */

// get the update Event Requests from server
const getEventRequests = (id) => {
  return getRequest(`/api/v1/ShutterB/Event/${id}`, true).then(
    (response) => {
      return responseHandler.handleServerResponse(response);
    },
    (error) => {
      return responseHandler.handleHTTPResponses(error.response);
    }
  );
};

const putEventRequests = (id, dataObj) => {
  return putRequest(`/api/v1/ShutterB/Event/${id}`, dataObj, true).then(
    (response) => {
      return responseHandler.handleServerResponse(response);
    },
    (error) => {
      return responseHandler.handleHTTPResponses(error.response);
    }
  );
};

// get the updated Sb My Events from server
const getSbMyEvents = (id, status) => {
  return getRequest(
    `/api/v1/ShutterB/Event/SBMyEvents/${id}/${status}`,
    true
  ).then(
    (response) => {
      return responseHandler.handleServerResponse(response);
    },
    (error) => {
      return responseHandler.handleHTTPResponses(error.response);
    }
  );
};

// update the event status checkIn or checkOut
const putSbMyEventsStatus = (eventId, dataObj) => {
  return putRequest(
    `/api/v1/ShutterB/Event/UpdateCheckStatus/${eventId}`,
    dataObj,
    true
  ).then(
    (response) => {
      return responseHandler.handleServerResponse(response);
    },
    (error) => {
      return responseHandler.handleHTTPResponses(error.response);
    }
  );
};

// get the update Portfolio Info from server
const getMySchedule = (id) => {
  return getRequest(
    `/api/v1/ShutterB/Profile/GetScheduleBySBId/${id}`,
    true
  ).then(
    (response) => {
      return responseHandler.handleServerResponse(response);
    },
    (error) => {
      return responseHandler.handleHTTPResponses(error.response);
    }
  );
};

const putMySchedule = (id, dataObj) => {
  return putRequest(
    `/api/v1/ShutterB/Profile/UpdateWorkingSchedule/${id}`,
    dataObj,
    true
  ).then(
    (response) => {
      return responseHandler.handleServerResponse(response);
    },
    (error) => {
      return responseHandler.handleHTTPResponses(error.response);
    }
  );
};

const getPortfolioRequests = (id) => {
  return getRequest(`/api/v1/ShutterB/Profile/Portfolio/${id}`, true).then(
    (response) => {
      return responseHandler.handleServerResponse(response);
    },
    (error) => {
      return responseHandler.handleHTTPResponses(error.response);
    }
  );
};

const putPortfolioRequests = (id, dataObj) => {
  return putRequestFormData(
    `/api/v1/ShutterB/Profile/Portfolio/${id}`,
    dataObj,
    true
  ).then(
    (response) => {
      return responseHandler.handleServerResponse(response);
    },
    (error) => {
      return responseHandler.handleHTTPResponses(error.response);
    }
  );
};

const deletePortfolioImgRequests = (dataObj) => {
  return deleteRequestWithData(
    `/api/v1/ShutterB/Profile/${dataObj.id}`,
    dataObj,
    true
  ).then(
    (response) => {
      return responseHandler.handleServerResponse(response);
    },
    (error) => {
      return responseHandler.handleHTTPResponses(error.response);
    }
  );
};

const getMyInfoRequests = (id) => {
  return getRequest(`/api/v1/ShutterB/Profile/Info/${id}`, true).then(
    (response) => {
      return responseHandler.handleServerResponse(response);
    },
    (error) => {
      return responseHandler.handleHTTPResponses(error.response);
    }
  );
};

const putMyInfoRequests = (id, dataObj) => {
  return putRequest(`/api/v1/ShutterB/Profile/Info/${id}`, dataObj, true).then(
    (response) => {
      return responseHandler.handleServerResponse(response);
    },
    (error) => {
      return responseHandler.handleHTTPResponses(error.response);
    }
  );
};

const getStripeConnectionRequest = (id) => {
  return getRequest(`/api/Stripe/StartOnBoarding/${id}`, true).then(
    (response) => {
      return response.data.message;
    },
    (error) => {
      return responseHandler.handleHTTPResponses(error.response);
    }
  );
};

// check the stripe connection status either pending or connected
const getStripeConnectedStatusRequest = (id) => {
  return getRequest(`/api/Stripe/ExitOnBoarding/${id}`, true).then(
    (response) => {
      return response.data.payload;
    },
    (error) => {
      return responseHandler.handleHTTPResponses(error.response);
    }
  );
};

const deleteFromGallery = (dataObj) => {
  //change url later
  return deleteRequestWithPayload(
    `/api/v1/User/EventGallery`,
    dataObj,
    true
  ).then(
    (response) => {
      return responseHandler.handleServerResponse(response);
    },
    (error) => {
      return responseHandler.handleHTTPResponses(error.response);
    }
  );
};

const getGalleryData = (data) => {
  //change url later
  return getRequest(
    `/api/v1/ShutterB/Event/SBEventGallery/${data.eventId}/${data.sbUserId}`,
    true
  ).then(
    (response) => {
      return responseHandler.handleServerResponse(response);
    },
    (error) => {
      return responseHandler.handleHTTPResponses(error.response);
    }
  );
};

const addToGallery = (data, options) => {
  return postRequestWithProgress(
    `/api/v1/ShutterB/Event/SBEventGallery`,
    data,
    true,
    options
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const addToProfile = (data, options) => {
  return postRequestWithProgress(
    `/api/v1/ShutterB/Profile/AddToProfile`,
    data,
    true,
    options
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

// get the update Event Requests from server
const getReelEvents = (id, status) => {
  return getRequest(
    `/api/v1/Shutterb/reel/GetSBReels/${id}/${status}`,
    true
  ).then(
    (response) => {
      return responseHandler.handleServerResponse(response);
    },
    (error) => {
      return responseHandler.handleHTTPResponses(error.response);
    }
  );
};

const downloadAssets = (eventId, sbUserName) => {
  return getRequest(
    `/api/v1/Shutterb/reel/${eventId}/${sbUserName}`,
    true
  ).then(
    (response) => {
      return responseHandler.handleServerResponseMessage(response);
    },
    (error) => {
      return responseHandler.handleHTTPResponses(error.response);
    }
  );
};
const getReelDetails = (eventId) => {
  return getRequest(`/api/v1/shutterb/reel/GetReelBrief/${eventId}`, true).then(
    (response) => {
      return responseHandler.handleServerResponse(response);
    },
    (error) => {
      return responseHandler.handleHTTPResponses(error.response);
    }
  );
};

// get the update Portfolio Info from server
const getEventDetailByEventId = (eventId) => {
  return getRequest(
    `/api/v1/ShutterB/Event/GetEventDetailByEventId/${eventId}`,
    true
  ).then(
    (response) => {
      return responseHandler.handleServerResponse(response);
    },
    (error) => {
      return responseHandler.handleHTTPResponses(error.response);
    }
  );
};

// exporting functions for other files
export const shutterbDashboardServices = {
  getEventRequests,
  putEventRequests,
  getSbMyEvents,
  putSbMyEventsStatus,
  getMySchedule,
  putMySchedule,
  getPortfolioRequests,
  putPortfolioRequests,
  deletePortfolioImgRequests,
  getMyInfoRequests,
  putMyInfoRequests,
  getStripeConnectionRequest,
  getStripeConnectedStatusRequest,
  deleteFromGallery,
  getGalleryData,
  addToGallery,
  addToProfile,
  getReelEvents,
  downloadAssets,
  getReelDetails,
  getEventDetailByEventId,
};
