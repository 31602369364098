import React, { useEffect, useMemo } from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import LoadingIcon from "mdi-react/LoadingIcon";
import { useHistory, withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";

import LoginWithFacebook from "./OAuth/LoginWithFacebook";
import LoginWithGoogle from "./OAuth/LoginWithGoogle";
import * as modalConstants from "../../../constants/modal.constants.js";
import { NavigateToPath } from "../../../system";
import { configurationsConstants, loginConstants } from "../../../constants";
import { loginActions } from "../../../redux/actions";
import googleIcon from "../../../shared/img/google.svg";

const LogInForm = (props) => {
  /* eslint-disable */
  const history = useHistory();
  const { t } = useTranslation("common");

  const loginSpinner = useMemo(
    () =>
      props.spinnerActions.findIndex((f) => f === loginConstants.USER_LOGIN),
    [props.spinnerActions]
  );

  // when user clicks on Login button
  const handleSubmit = (event, values) => {
    props.onLoginClick({ ...values });
  };

  // navigate to screen after login
  useEffect(() => {
    if (props?.authentication?.isAuthenticated) {
      window.location.replace(
        `${process.env.REACT_APP_HOST_BASE_URL}/CustomerDashboard`
      );
    } else if (props.isShutterbAuthenticated) {
      window.location.replace(
        `${process.env.REACT_APP_HOST_BASE_URL}/Shutterbdashboard`
      );
    }
    // eslint-disable-next-line
  }, [props.authentication.isAuthenticated, props.isShutterbAuthenticated]);

  return (
    <div
      className={`login-form d-flex flex-column justify-content-center px-0 px-sm-1 px-md-4 ${
        props.isAllowModal ? "" : "border-color-grey"
      }`}
    >
      {!props.isPage && (
        <button id="cancel-icon" onClick={() => props.setIsModalOpen(false)}>
          <span>X</span>
        </button>
      )}
      <AvForm className="form form_Display_Flex" onValidSubmit={handleSubmit}>
        {/* cancel icon */}
        <div className="form-header-group">
          <h2 className="p-0 pt-sm-2 pt-md-0">Login</h2>
          <p className="p-0 pt-sm-1 pt-md-2">
            Welcome! Please fill in your email and password below to sign into
            your account.
          </p>
        </div>
        <div className="form__form-group">
          {/* <span className="form__form-group-label">{t("user_name")}</span> */}
          <div className="form__form-group-field">
            <div className="">
              <AvField
                type="email"
                name="userName"
                placeholder="Email"
                className="input-without-border-radius"
                maxLength={configurationsConstants.EMAIL_INPUT_LENGTH}
                pattern="^[a-z,A-Z,0-9,!@#$%^&*_=+-].{3,}$"
                validate={{
                  required: {
                    value: true,
                    errorMessage: t("this_field_is_required"),
                  },
                }}
              />
            </div>
          </div>
        </div>
        <div className="form__form-group">
          <div className="form__form-group-field">
            <div>
              <AvField
                type="password"
                name="password"
                placeholder="Password"
                className="input-without-border-radius"
                maxLength={configurationsConstants.PASSWORD_INPUT_LENGTH}
                pattern="^[a-z,A-Z,0-9,!@#$%^&*_=+-].{3,}$"
                validate={{
                  required: {
                    value: true,
                    errorMessage: t("this_field_is_required"),
                  },
                }}
              />
            </div>
          </div>
        </div>
        <div className="form__form-group align-self-end d-flex justify-content-end">
          <p className="p-0 py-sm-1 py-md-2 m-0">
            {/* eslint-disable-next-line */}
            <a
              href="#"
              onClick={() =>
                props.setFormSelect(modalConstants.FORGOT_PASSWORD_FORM)
              }
            >
              Forgot your password?
            </a>
          </p>
        </div>
        <div className="form__form-group mb-2">
          <div className="form__form-group-field">
            <div className="account__btns ml-0">
              {loginSpinner > -1 ? (
                <div className=" account__btn text-center login_spinner">
                  <LoadingIcon fill="#70bbfd" style={{ width: "50px" }} />
                </div>
              ) : (
                <Button className="account__btn mb-0 submit-btn d-flex justify-content-center align-items-center">
                  {t("login")}
                </Button>
              )}
            </div>
          </div>
        </div>
      </AvForm>
      <div>
        <div className="form__form-group d-flex justify-content-center">
          <p className="p-0 m-0">
            Don't have an account?{" "}
            <a
              href="#"
              onClick={() => props.setFormSelect(modalConstants.SIGNUP_FORM)}
            >
              Sign Up Now
            </a>
          </p>
        </div>

        <div className="form__form-group d-flex justify-content-between p-0 px-3 py-sm-1 py-md-2 m-0">
          <hr className="login-form-divider" />
          Or
          <hr className="login-form-divider" />
        </div>

        <div className="form__form-group mb-2">
          <div className="form__form-group-field">
            <div className="account__btns ml-0">
              <div className="account__btn oaut-fb-btn mb-0 p-0">
                <div className="f-icon-con">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1365.3 1365.3"
                  >
                    <path
                      d="M1365.3 682.7A682.7 682.7 0 10576 1357V880H402.7V682.7H576V532.3c0-171.1 102-265.6 257.9-265.6 74.6 0 152.8 13.3 152.8 13.3v168h-86.1c-84.8 0-111.3 52.6-111.3 106.6v128h189.4L948.4 880h-159v477a682.8 682.8 0 00576-674.3"
                      fill="#fff"
                    ></path>
                  </svg>
                </div>
                <LoginWithFacebook
                  onLoginWithFacebookClick={props.onLoginWithFacebookClick}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="form__form-group mb-2">
          <div className="form__form-group-field">
            <div className="account__btns ml-0">
              <div className="mb-0 p-0 account__btn oaut-g-btn oauth-btn-container">
                <img src={googleIcon} alt="google icon" />
                <LoginWithGoogle
                  onLoginWithGoogleClick={props.onLoginWithGoogleClick}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="form__form-group d-flex justify-content-center align-items-center flex-column">
          <p className="p-0 m-0">
            If you are a shutterb{" "}
            <a
              href="#"
              onClick={() => props.setFormSelect(modalConstants.SHUTTERB_LOGIN)}
            >
              click here to login
            </a>
          </p>
          <p className="p-0 m-0">
            To become a shutterb{" "}
            <a
              className="cursor-point teal-color"
              onClick={() => {
                props.setIsModalOpen(false);
                NavigateToPath(history, "/be-a-shutterb");
              }}
            >
              sign up here
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  authentication: state.authentication,
  isShutterbAuthenticated: state.shutterbAuthentication.isShutterbAuthenticated,
  spinnerActions: state.system.spinnerActions,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  onLoginClick: (data) => dispatch(loginActions.login(data)),

  onLoginWithFacebookClick: (data) =>
    dispatch(loginActions.loginWithFacebook(data)),

  onLoginWithGoogleClick: (data) =>
    dispatch(loginActions.loginWithGoogle(data)),
});

// mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LogInForm));
