import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import EventRequestTab from "./ShutterbDashboardTabs/EventRequestTab";
import MyEventsTab from "./ShutterbDashboardTabs/MyEventsTab";
import ReelEvents from "./ShutterbDashboardTabs/ReelEventsTab/ReelEvents";
import MyProfileTab from "./ShutterbDashboardTabs/MyProfileTab/MyProfileTab";
import { NavigateToPath } from "../../../system";
import { useHistory, useParams } from "react-router-dom";
import { shutterbDashboardConstants } from "../../../constants";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const ShutterbDashboard = () => {
  const { tab } = useParams();
  const [value, setValue] = React.useState();
  const history = useHistory();

  React.useEffect(() => {
    setValue(
      tab === shutterbDashboardConstants.EVENT_REQUESTS
        ? shutterbDashboardConstants.EVENT_REQUESTS_TAB_NUMBER
        : tab === shutterbDashboardConstants.MY_EVENTS
        ? shutterbDashboardConstants.MY_EVENTS_TAB_NUMBER
        : tab === shutterbDashboardConstants.REEL_EVENTS
        ? shutterbDashboardConstants.REEL_EVENTS_TAB_NUMBER
        : tab === shutterbDashboardConstants.MY_PROFILE
        ? shutterbDashboardConstants.MY_PROFILE_TAB_NUMBER
        : shutterbDashboardConstants.DEFAULT_TAB_NUMBER
    );
  }, [tab]);

  return (
    <div className="container-fluid">
      <Box className="row mb-5 mt-1 mt-md-5 px-2 px-md-5 shutterb-dashboard-con custom-min-height">
        <Box className="col-12 col-md-6 col-lg-3 col-xl-3 mx-auto">
          <Tabs
            value={value}
            orientation="vertical"
            centered="true"
            aria-label="basic evnet tabs"
          >
            <Tab
              className="sb-dashboard-tab-btn m-auto m-md-0 align-items-center mw-160"
              label="EVENT REQUESTS"
              onClick={() => {
                NavigateToPath(
                  history,
                  `/ShutterbDashboard_/${shutterbDashboardConstants.EVENT_REQUESTS}`
                );
              }}
              {...a11yProps(0)}
            />
            <Tab
              className="sb-dashboard-tab-btn m-auto m-md-0 align-items-center mw-160"
              label="MY EVENTS"
              onClick={() => {
                NavigateToPath(
                  history,
                  `/ShutterbDashboard_/${shutterbDashboardConstants.MY_EVENTS}`
                );
              }}
              {...a11yProps(1)}
            />
            <Tab
              className="sb-dashboard-tab-btn m-auto m-md-0 align-items-center mw-160"
              label="RECAP EVENTS"
              onClick={() => {
                NavigateToPath(
                  history,
                  `/ShutterbDashboard_/${shutterbDashboardConstants.REEL_EVENTS}`
                );
              }}
              {...a11yProps(2)}
            />
            <Tab
              className="sb-dashboard-tab-btn m-auto m-md-0 align-items-center mw-160"
              label="MY PROFILE"
              onClick={() => {
                NavigateToPath(
                  history,
                  `/ShutterbDashboardTab/${shutterbDashboardConstants.MY_PROFILE}/${shutterbDashboardConstants.MY_SCHEDULE}`
                );
              }}
              {...a11yProps(3)}
            />
          </Tabs>
        </Box>
        <Box className="col-12 col-md-12 col-lg-9 col-xl-9 mx-auto mr-md-auto p-1 p-md-3 tab-pannel">
          <TabPanel
            value={value}
            index={shutterbDashboardConstants.EVENT_REQUESTS_TAB_NUMBER}
          >
            <EventRequestTab />
          </TabPanel>
          <TabPanel
            value={value}
            index={shutterbDashboardConstants.MY_EVENTS_TAB_NUMBER}
          >
            <MyEventsTab />
          </TabPanel>
          <TabPanel
            value={value}
            index={shutterbDashboardConstants.REEL_EVENTS_TAB_NUMBER}
          >
            <ReelEvents />
          </TabPanel>
          <TabPanel
            value={value}
            index={shutterbDashboardConstants.MY_PROFILE_TAB_NUMBER}
          >
            <MyProfileTab />
          </TabPanel>
        </Box>
      </Box>
    </div>
  );
};

export default ShutterbDashboard;
