export const customerConstants = {
  MY_EVENTS: "MY_EVENTS",
  GET_MY_EVENTS: "GET_MY_EVENTS",
  MY_EVENTS_GALLERY: "MY_EVENTS_GALLERY",
  EXTEND_SHUTTERB: "EXTEND_SHUTTERB",
  EXTEND_SHUTTERB_CART: "EXTEND_SHUTTERB_CART",
  EXTEND_SHUTTERB_CART_RESET: "EXTEND_SHUTTERB_CART_RESET",
  ADD_TO_CUSTOMER_DASHBOARD_CART: "ADD_TO_CUSTOMER_DASHBOARD_CART",
  UPDATE_CUSTOMER_DASHBOARD_CART: "UPDATE_CUSTOMER_DASHBOARD_CART",
  STORED_CUSTOMER_DASHBOARD_CART: "STORED_CUSTOMER_DASHBOARD_CART",
  STORED_EVENT_DETAILS: "STORED_EVENT_DETAILS",
  SHARED_GALLERY_IMAGES: "SHARED_GALLERY_IMAGES",
  DELETE_FROM_GALLERY: "DELETE_FROM_GALLERY",
  GET_MY_ACCOUNT_INFO: "GET_MY_ACCOUNT_INFO",
  UPDATE_MY_ACCOUNT_INFO: "UPDATE_MY_ACCOUNT_INFO",
  GET_SEARCH_RESULT: "GET_SEARCH_RESULT",
  GET_RECENT_SEARCHES: "GET_RECENT_SEARCHES",
  SHARED_GALLERY_CALL: "SHARED_GALLERY_CALL",
  ACCESS_GALLERY_CALL: "ACCESS_GALLERY_CALL",
  LOCK_FROM_GALLERY: "LOCK_FROM_GALLERY",
  NOT_NEW_EVENT: "NOT_NEW_EVENT",
  DATA_TO_SEND_EXTEND_SB: "DATA_TO_SEND_EXTEND_SB",
  CART_PREVIOUS_SCREEN: "CART_PREVIOUS_SCREEN",
  TIP_TO_CART: "TIP_TO_CART",
  EXTEND_TO_CART: "EXTEND_TO_CART",
  EXTEND_AND_TIP_TO_CART: "EXTEND_AND_TIP_TO_CART",
  GET_REVIEW_FORM_DATA: "GET_REVIEW_FORM_DATA",
  SUBMIT_RATING_AND_FEEDBACK: "SUBMIT_RATING_AND_FEEDBACK",
  GET_INVOICE: "GET_INVOICE",
  MY_EVENT: "MyEvents",
  MY_ACCOUNT: "MyAccount",
  ATTENDED_EVENTS: "AttendedEvents",
  REFER_A_FRIEND: "ReferAFriend",
  SUPPORT: "Support",
  DEFAULT_TAB_NUMBER: 1,
  MY_EVENTS_TAB_NUMBER: 0,
  MY_ACCOUNT_TAB_NUMBER: 1,
  ATTENDED_EVENTS_TAB_NUMBER: 2,
  REFER_A_FRIEND_TAB_NUMBER: 3,
  SUPPORT_TAB_NUMBER: 4,
  EXTEND_YOUR_SHUTTERB: "ExtendYourShutterb",
  TIP_YOUR_SHUTTERB: "TipYourShutterb",
  ACCESS_GALLERY: "AccessGallery",
  DOWNLOAD_INVOICE: "DownloadInvoice",
  REVIEW_YOUR_SHUTTERB: "ReviewYourShutterb",
  EXTEND_YOUR_SHUTTERB_TAB_NUMBER: 0,
  TIP_YOUR_SHUTTERB_TAB_NUMBER: 1,
  ACCESS_GALLERY_TAB_NUMBER: 2,
  DOWNLOAD_INVOICE_TAB_NUMBER: 3,
  REVIEW_YOUR_SHUTTERB_TAB_NUMBER: 4,
  MESSAGE_BOARD_TAB_NUMBER: 5,
  DRAFT_EVENT_TOP_BAR_CHECK: "/event_booking/4",
  ADD_REEL: "AddReel",
  COMPLETED_EVENTS: "completed",
  UPCOMING_EVENTS: "upcoming",
  PENDING_EVENTS: "pending",
  CANCELLED_EVENTS: "cancel",
  CANCEL_EVENT: "CANCEL_EVENT",
  MESSAGE_BOARD: "MessageBoard",
  HOST: "host",
};
