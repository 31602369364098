import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import ShutterbForm from "./ShutterbForm";

const Shutterb = (props) => {
  return (
    <>
      <div className="container">
        {!props.isFormSubmitted && (
          <div className="row">
            <div className="col-12 col-sm-11 col-md-10 col-lg-9 m-auto">
              <div className="d-flex justify-content-center">
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <h1 className="mt-5 text-center">BECOME A SHUTTERB</h1>
                  <p className="pt-4 text-center">
                    Do you love taking pictures or short videos on your
                    smartphone? Are you the friend who’s always making sure
                    everything is being captured? If you’re nodding your head
                    yes then we want to hear from you! shutterb is a new kind of
                    photography service that connects event planners with local
                    smartphone photographers. We’re looking for enthusiastic,
                    personable, and driven individuals, who want to get paid for
                    doing what they love using their smartphone!
                  </p>
                  <p className="text-center">
                    shutterb offers an entrepreneurial environment where you are
                    your own boss. We allow you to set your own schedule and
                    encourage you to not only represent shutterb when on jobs,
                    but also yourself because the higher your ratings the better
                    the perks!
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="row my-5">
          <div className="col-12 col-sm-11 col-md-10 col-lg-9 m-auto px-1">
            <ShutterbForm spinnerActions={props.spinnerActions} />
          </div>
        </div>
      </div>
    </>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  isFormSubmitted: state.shutterbAuthentication.isFormSubmitted,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({});

// mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Shutterb));
