export const messageBoardConstants = {
  GET_SHUTTERB_BY_EVENT_ID: "GET_SHUTTERB_BY_EVENT_ID",
  FORM_STATE_OF_MESSAGE_BOARD: "FORM_STATE_OF_MESSAGE_BOARD",
  SEND_MESSAGE: "SEND_MESSAGE",
  GET_MESSAGES: "GET_MESSAGES",
  RECEIVE_MESSAGES: "RECEIVE_MESSAGES",
  GET_SB_BY_ID: "GET_SB_BY_ID",
  GET_NOTIFICATIONS: "GET_NOTIFICATIONS",
  UPDATE_MESSAGE_STATUS: "UPDATE_MESSAGE_STATUS",
  RECEIVE_NOTIFICATION: "RECEIVE_NOTIFICATION",
  GET_NOTIFICATION_COUNT: "GET_NOTIFICATION_COUNT",
  BULK_UPDATE_MESSAGE_STATUS: "BULK_UPDATE_MESSAGE_STATUS",
  CUSTOMER_CHAT_STARTED: "CUSTOMER_CHAT_STARTED",
};