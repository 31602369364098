import React from "react";

const DraftEventThankYou = () => {
  return (
    <div className="py-5 my-5 d-flex justify-content-center align-items-center flex-column">
      <h1 className="mb-1 mt-5 pt-5">Thank You</h1>
      <h3 className="pb-5 mb-5">
        We will email you within 24 hours with a potential shutterb(z) for your
        event.
      </h3>
    </div>
  );
};

export default DraftEventThankYou;
