import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { withRouter, useHistory } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  shutterbDashboardConstants,
  languageOptions,
  configurationsConstants,
  globalSystemConstants,
} from "../../../../../constants";
import {
  shutterbDashboardActions,
  configurationsActions,
} from "../../../../../redux/actions";
import MyPortfolioGallery from "./MyPortfolioGallery";
import { customToast, convertHeicToImage } from "../../../../../shared";
import dummypic from "../../../../../shared/img/dummyPic.jpg";

const MyPortfolio = (props) => {
  // eslint-disable-next-line
  const history = useHistory();

  const { ...portfolioInfo } = props?.portfolioInfo;
  const { t } = useTranslation("common");
  const [profileImgToShow, setProfileImgToShow] = useState({});
  const [images, setImages] = React.useState([]);
  const [devicesData, setDevicesData] = useState([]);
  const [deleteImageIds, setDeleteImageIds] = useState([]);
  const allowedFormats = [
    ".jpg",
    ".jpeg",
    ".png",
    ".heic",
    ".heif",
    ".HEIC",
    ".HEIF",
  ];

  const handleSubmit = (e, values) => {
    if (
      !portfolioInfo?.profileImageUrl &&
      !profileImgToShow.hasOwnProperty(shutterbDashboardConstants.PREVIEW)
    ) {
      customToast("Please Upload Profile Picture", toast.TYPE.ERROR);
      return;
    } else if (profileImgToShow?.raw) {
      const fileName = profileImgToShow.raw.name;
      const fileExtension = fileName
        .slice(fileName.lastIndexOf("."))
        .toLowerCase();
      if (!allowedFormats.includes(fileExtension)) {
        customToast("Profile image format not supported", toast.TYPE.ERROR);
        return;
      }
    }
    if (images && images.length < 6) {
      customToast(t("atleast_six_pic_required"), toast.TYPE.ERROR);
      return;
    }
    let portfolioFormData = new FormData();
    images
      ? images.forEach((file) => {
          !file.hasOwnProperty("imageUrl") &&
            portfolioFormData.append("GalleryImages", file.file);
        })
      : portfolioFormData.append("GalleryImages", null);
    portfolioFormData.append("ProfileImage", profileImgToShow?.raw ?? null);
    portfolioFormData.append("sbUserId", props.sbUserId);
    portfolioFormData.append("profileName", values.profile_name);
    portfolioFormData.append("Bio", values.Bio);
    portfolioFormData.append("deviceId", values.device_type);
    portfolioFormData.append("Language", values.language_spoken);
    portfolioFormData.append(
      "additionalEquipment",
      values.additional_equipment ? values.additional_equipment : ""
    );
    portfolioFormData.append("deleteImageIds", deleteImageIds);
    portfolioFormData.append("isActive", true);
    const updatedImages = images.map((image, index) => ({
      ...image,
      displayOrder: index + 1,
    }));    
    portfolioFormData.append("displayOrderList", JSON.stringify(updatedImages));

    props.updateMyPortfolio(
      props.sbUserId,
      portfolioFormData,
      setProfileImgToShow,
      setImages
    );
    // setProfileImgToShow({});
  };

  useEffect(() => {
    props.getPortfolioRequests(props.sbUserId, setImages);

    // you can portfolioInfo down to populate the response once backend response is confirmed
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    props.getSmartPhoneDevices(setDevicesData);

    // eslint-disable-next-line
  }, []);

  const handlePreview = (id) => {
    if (id) {
      window.location.href = `${process.env.REACT_APP_HOST_BASE_URL}/ShutterbProfile/${id}`;
    } else {
      customToast("Profile doesnot exist", toast.TYPE.ERROR);
    }
  };

  const handleHeicFile = async (file) => {
    const result = await convertHeicToImage(file);

    setProfileImgToShow({
      preview: URL.createObjectURL(result),
      raw: result,
    });
    props.diActivateSpinner();
  };
console.log(images)
  return (
    <div className="shutterb-dashboard-my-portfolio mt-3">
      <div className="shutterb-form-header">
        <div className="shutterb-form-profile">
          <img
            src={
              !profileImgToShow.hasOwnProperty(
                shutterbDashboardConstants.PREVIEW
              )
                ? portfolioInfo?.profileImageUrl
                  ? `${process.env.REACT_APP_S3_BASE_URL_PORTFOLIO}/${portfolioInfo?.profileImageUrl}`
                  : dummypic
                : profileImgToShow.preview
            }
            alt=""
            className="avatar obj-fit-cover"
          />
          <label className="camera-icon-con" htmlFor="profilePic">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                fill-rule="evenodd"
                d="M9.778 21h4.444c3.121 0 4.682 0 5.803-.735a4.408 4.408 0 0 0 1.226-1.204c.749-1.1.749-2.633.749-5.697c0-3.065 0-4.597-.749-5.697a4.407 4.407 0 0 0-1.226-1.204c-.72-.473-1.622-.642-3.003-.702c-.659 0-1.226-.49-1.355-1.125A2.064 2.064 0 0 0 13.634 3h-3.268c-.988 0-1.839.685-2.033 1.636c-.129.635-.696 1.125-1.355 1.125c-1.38.06-2.282.23-3.003.702A4.405 4.405 0 0 0 2.75 7.667C2 8.767 2 10.299 2 13.364c0 3.064 0 4.596.749 5.697c.324.476.74.885 1.226 1.204C5.096 21 6.657 21 9.778 21ZM12 9.273c-2.301 0-4.167 1.831-4.167 4.09c0 2.26 1.866 4.091 4.167 4.091c2.301 0 4.167-1.831 4.167-4.09c0-2.26-1.866-4.091-4.167-4.091Zm0 1.636c-1.38 0-2.5 1.099-2.5 2.455c0 1.355 1.12 2.454 2.5 2.454s2.5-1.099 2.5-2.454c0-1.356-1.12-2.455-2.5-2.455Zm4.722-.818c0-.452.373-.818.834-.818h1.11c.46 0 .834.366.834.818a.826.826 0 0 1-.833.818h-1.111a.826.826 0 0 1-.834-.818Z"
                clip-rule="evenodd"
              />
            </svg>
          </label>
        </div>
      </div>
      <hr className="my-4" />

      <AvForm id="event-form" onValidSubmit={handleSubmit}>
        <div id="form-content">
          <input type="hidden" name="is_offline_process" value="no" />
          <input type="hidden" value="add_event" id="event_section" />
          {/* profile pic input */}

          <AvField
            className="d-none"
            type="file"
            accept=".png,.jpg,.jpeg,image/heic,image/heif,.heic,.heif,.HEIC,.HEIF"
            name="profilePic"
            onChange={(e) => {
              const file = e.target.files[0];
              const fileName = file.name;
              const fileExtension = fileName
                .slice(fileName.lastIndexOf("."))
                .toLowerCase();
              if (!allowedFormats.includes(fileExtension)) {
                customToast("Format not supported", toast.TYPE.ERROR);
                return;
              }

              if (
                fileExtension === ".heic" ||
                fileExtension === ".heif" ||
                fileExtension === ".HEIC" ||
                fileExtension === ".HEIF"
              ) {
                props.activateSpinner();
                handleHeicFile(file);
              } else {
                setProfileImgToShow({
                  preview: URL.createObjectURL(file),
                  raw: file,
                });
              }
            }}
          />

          <div className="row">
            <div className="col-lg-12">
              <AvField
                name="profile_name"
                value={portfolioInfo?.profileName}
                label={t("profile_name")}
                type="text"
                validate={{
                  required: {
                    value: true,
                    errorMessage: t("this_field_is_required"),
                  },
                }}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <label htmlFor="Bio">{t("bio")}</label>
              <AvField
                name="Bio"
                type="textarea"
                value={portfolioInfo?.bio}
                maxLength={configurationsConstants.TEXTAREA_INPUT_LENGTH}
                validate={{
                  required: {
                    value: true,
                    errorMessage: t("this_field_is_required"),
                  },
                }}
              />
            </div>
          </div>

          {/* galley */}
          <div className="row py-3">
            <div className="col-12 my-port-gallary-con">
              <AvField
                name="Image Gallery"
                label="Portfolio Images"
                type="hidden"
                value={images}
              />
              <MyPortfolioGallery
                images={images}
                setImages={setImages}
                setDeleteImageIds={setDeleteImageIds}
              />
              <p>{t("portfolio_images_description")}</p>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 icon-field-con">
              <label htmlFor="device_type">{t("device_type")}</label>
              <AvField
                type="select"
                name="device_type"
                value={
                  portfolioInfo?.device?.id ??
                  (devicesData && devicesData.length > 0
                    ? devicesData[0].id
                    : null)
                }
              >
                {devicesData &&
                  devicesData.map((device) => (
                    <option key={device.id} value={device.id}>
                      {device.deviceName}
                    </option>
                  ))}
              </AvField>
              <div className="drop-arrow-con">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="m12 15l-4.243-4.242l1.415-1.414L12 12.172l2.828-2.828l1.415 1.414L12 15.001Z"
                  />
                </svg>
              </div>
            </div>
            <div className="col-lg-6 icon-field-con">
              <label htmlFor="language_spoken">
                {t("additional_language")}
              </label>
              <AvField
                type="select"
                name="language_spoken"
                value={portfolioInfo?.language ?? "null"}
              >
                <option value="null" selected disabled>
                  Select Language
                </option>
                {languageOptions.map((language, index) => (
                  <option key={index}>{language}</option>
                ))}
              </AvField>
              <div className="drop-arrow-con">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="m12 15l-4.243-4.242l1.415-1.414L12 12.172l2.828-2.828l1.415 1.414L12 15.001Z"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <AvField
                name="language"
                value="English"
                label={t("language_spoken")}
                type="text"
                readOnly
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <label htmlFor="additional_equipment">
                {t("additional_equipment")}
              </label>
              <p className="p-0 m-0">{t("additional_equipment_description")}</p>
              <AvField
                name="additional_equipment"
                type="textarea"
                value={portfolioInfo?.additionalEquipment}
                maxLength={configurationsConstants.TEXTAREA_INPUT_LENGTH}
              />
            </div>
          </div>

          <div className="container">
            <div className="row pt-4 con">
              <div className="set-save-btn">
                <button type="submit" className="btn-simple">
                  {t("save")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </AvForm>
      <div className="set-save-btn pt-3">
        <button
          className="btn-simple"
          onClick={() => handlePreview(portfolioInfo?.sbuserId)}
        >
          {t("preview")}
        </button>
      </div>
    </div>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  sbUserId: state.shutterbAuthentication.sbUserId,
  portfolioInfo: state.shutterbDashboard.sbMyPortfolio,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getPortfolioRequests: (id, setImages) =>
    dispatch(shutterbDashboardActions.myPortfolio(id, setImages)),
  updateMyPortfolio: (id, dataObj, setProfileImgToShow, setImages) =>
    dispatch(
      shutterbDashboardActions.updateMyPortfolio(
        id,
        dataObj,
        setProfileImgToShow,
        setImages
      )
    ),
  getSmartPhoneDevices: (setData) =>
    dispatch(configurationsActions.getSmartPhoneDevices(setData)),
  activateSpinner: () =>
    dispatch({ type: globalSystemConstants.SPINNER_ACTIVATE, data: null }),
  diActivateSpinner: () =>
    dispatch({ type: globalSystemConstants.SPINNER_DIACTIVATE, data: null }),
});

// mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MyPortfolio));
