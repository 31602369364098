import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";

import useGetQueryParams from "../../../../../../shared/components/CustomHooks/GetQueryParams";
import ExtendYourSb from "./Tabs/ExtendYourSb";
import TipYouSb from "./Tabs/TipYourSb/TipYourSb";
import AccessGallery from "./Tabs/AccessGallery";
import DownloadInvoice from "./Tabs/DownloadInvoice";
import MessageBoard from "./Tabs/MessageBoard";
import ReviewYourSb from "./Tabs/ReviewYourSb";
import { NavigateToPath } from "../../../../../../system";
import { customerConstants } from "../../../../../../constants";
// A custom hook that builds on useLocation to parse
// the query string for you.

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const MyEventsDashboard = () => {
  let query = useGetQueryParams();
  const history = useHistory();
  const location = useLocation();
  const [value, setValue] = React.useState(Number(query.get("tab")));
  const { tab } = useParams();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const updateTabInParams = (tab) => {
    let newLocation =
      location.pathname.slice(0, location.pathname.lastIndexOf("/") + 1) + tab;
    return newLocation;
  };
  //   Tabs MUI Functionality Ends

  React.useEffect(() => {
    setValue(
      Number(
        tab === customerConstants.EXTEND_YOUR_SHUTTERB
          ? customerConstants.EXTEND_YOUR_SHUTTERB_TAB_NUMBER
          : tab === customerConstants.TIP_YOUR_SHUTTERB
          ? customerConstants.TIP_YOUR_SHUTTERB_TAB_NUMBER
          : tab === customerConstants.ACCESS_GALLERY
          ? customerConstants.ACCESS_GALLERY_TAB_NUMBER
          : tab === customerConstants.DOWNLOAD_INVOICE
          ? customerConstants.DOWNLOAD_INVOICE_TAB_NUMBER
          : tab === customerConstants.REVIEW_YOUR_SHUTTERB
          ? customerConstants.REVIEW_YOUR_SHUTTERB_TAB_NUMBER
          : customerConstants.MESSAGE_BOARD
          ? customerConstants.MESSAGE_BOARD_TAB_NUMBER
          : customerConstants.DEFAULT_TAB_NUMBER
      )
    );
  }, [tab]);
  return (
    <div className="container-fluid">
      <Box className="row my-5 px-2 px-md-5 customer-dashboard-con customer-event-dashboard custom-min-height">
        <Box className="col-12 col-md-6 col-lg-3 col-xl-3 mx-auto">
          <h5 className="ed-sidebar-heading text-center">MY EVENTS</h5>
          <Tabs
            value={value}
            onChange={handleChange}
            orientation="vertical"
            centered="true"
            aria-label="basic evnet tabs"
          >
            <Tab
              className="customer-dashboard-tab-btn m-auto m-md-0 align-items-center mw-160"
              label="EXTEND YOUR SHUTTERB"
              onClick={() => {
                NavigateToPath(
                  history,
                  updateTabInParams(customerConstants.EXTEND_YOUR_SHUTTERB)
                );
              }}
              {...a11yProps(customerConstants.EXTEND_YOUR_SHUTTERB_TAB_NUMBER)}
            />
            <Tab
              className="customer-dashboard-tab-btn m-auto m-md-0 align-items-center mw-160"
              label="TIP YOUR SHUTTERB"
              onClick={() => {
                NavigateToPath(
                  history,
                  updateTabInParams(customerConstants.TIP_YOUR_SHUTTERB)
                );
              }}
              {...a11yProps(customerConstants.TIP_YOUR_SHUTTERB_TAB_NUMBER)}
            />
            <Tab
              className="customer-dashboard-tab-btn m-auto m-md-0 align-items-center mw-160"
              label="ACCESS GALLERY"
              onClick={() => {
                NavigateToPath(
                  history,
                  updateTabInParams(customerConstants.ACCESS_GALLERY)
                );
              }}
              {...a11yProps(customerConstants.ACCESS_GALLERY_TAB_NUMBER)}
            />
            <Tab
              className="customer-dashboard-tab-btn m-auto m-md-0 align-items-center mw-160"
              label="DOWNLOAD INVOICE"
              onClick={() => {
                NavigateToPath(
                  history,
                  updateTabInParams(customerConstants.DOWNLOAD_INVOICE)
                );
              }}
              {...a11yProps(customerConstants.DOWNLOAD_INVOICE_TAB_NUMBER)}
            />
            <Tab
              className="customer-dashboard-tab-btn m-auto m-md-0 align-items-center mw-160"
              label="REVIEW YOUR SHUTTERB"
              onClick={() => {
                NavigateToPath(
                  history,
                  updateTabInParams(customerConstants.REVIEW_YOUR_SHUTTERB)
                );
              }}
              {...a11yProps(customerConstants.REVIEW_YOUR_SHUTTERB_TAB_NUMBER)}
            />
            <Tab
              className="customer-dashboard-tab-btn m-auto m-md-0 align-items-center mw-160"
              label="MESSAGE BOARD"
              onClick={() => {
                NavigateToPath(
                  history,
                  updateTabInParams(customerConstants.MESSAGE_BOARD)
                );
              }}
              {...a11yProps(customerConstants.MESSAGE_BOARD)}
            />
            <Link
              to="/CustomerDashboard"
              className="customer-dashboard-tab-btn m-auto m-md-0 text-center align-items-center mw-160"
            >
              <button className="account-btn-padding customer-dashboard-tab-btn m-auto m-md-0 events-dashboard-back-btn align-items-center mw-160">
                BACK TO MY ACCOUNT
              </button>
            </Link>
          </Tabs>
        </Box>
        <Box className="col-12 col-md-12 col-lg-9 col-xl-9 mx-auto mr-md-auto py-0 tab-pannel">
          <TabPanel value={value} index={0} className="position-relative pt-3">
            <ExtendYourSb />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <TipYouSb />
          </TabPanel>
          <TabPanel value={value} index={2} className="">
            <AccessGallery />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <DownloadInvoice />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <ReviewYourSb />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <MessageBoard />
          </TabPanel>
        </Box>
      </Box>
    </div>
  );
};

export default MyEventsDashboard;
