import { toast } from "react-toastify";
import { customToast } from "../../shared";
import { custom, failure } from "./utilities";
import i18next from "i18next";
import { shutterbProfileServices } from "../../services";
import {
  globalSystemConstants,
  shutterbProfileConstants,
} from "../../constants";
const { GET_SHUTTERB_PROFILE_DATA } = shutterbProfileConstants;

const getShutterBProfile = (id) => {
  return (dispatch) => {
    dispatch(
      custom(GET_SHUTTERB_PROFILE_DATA, globalSystemConstants.SPINNER_ACTIVATE)
    );
    shutterbProfileServices
      .getShutterBProfile(id)
      .then(
        (response) => {
          dispatch(custom(response, GET_SHUTTERB_PROFILE_DATA));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(failure(error, GET_SHUTTERB_PROFILE_DATA));
        }
      )
      .finally(() => {
        dispatch(
          custom(
            GET_SHUTTERB_PROFILE_DATA,
            globalSystemConstants.SPINNER_DIACTIVATE
          )
        );
      });
  };
};

export const shutterbProfileActions = {
  getShutterBProfile,
};
