import React, { Component } from "react";

const withHttpsRedirect = (WrappedComponent) => {
  return class HttpsRedirectComponent extends Component {
    componentDidMount() {
      // Check if the current protocol is HTTP and not on localhost
      if (
        window.location.protocol === "http:" &&
        window.location.hostname !== "localhost"
      ) {
        // Redirect to HTTPS
        window.location.href = window.location.href.replace(
          /^http(?!s)/,
          "https"
        );
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
};

export default withHttpsRedirect;
