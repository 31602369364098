import jwtDecode from "jwt-decode";

import { getDecryptPayload } from "../../system";
import { updateObject } from "../../shared/utility";
import {
  shutterbRegisterConstants,
  shutterbLoginConstants,
  globalConstants,
  userType,
} from "../../constants";

// initializing the token from the browser
const payload = getDecryptPayload();
const sbUserId = (payload.user && payload.user.id) || 0;
const token = payload.token || "";
const sbUserName = (payload.user && payload.user.userName) || "";

// decoding JWT to get user type
const decodedToken = (token && jwtDecode(token)) || "";
// default state
const initialState = {
  sbUserId,
  sbUserName,
  isShutterbAuthenticated:
    token.length > 0 && userType.SHUTTERB === decodedToken.UserType,
  isFormSubmitted: false,
};

// manupilating the state based on actions and returning the state
export const shutterbAuthentication = (state = initialState, action) => {
  switch (action.type) {
    // needs to match the actionName + type
    case shutterbRegisterConstants.shutterbRegister + globalConstants.SUCCESS:
      return updateObject(state, {
        isFormSubmitted: true,
      });
    case shutterbLoginConstants.shutterbLogin + globalConstants.SUCCESS:
      return updateObject(state, {
        isFormSubmitted: true,
        isShutterbAuthenticated: true,
      });
    default:
      return state;
  }
};
