import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MapMarker from "mdi-react/MapMarkerIcon";
import { AvForm, AvField } from "availity-reactstrap-validation";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { eventBookingActions } from "../../../../redux/actions/eventBooking.actions";
import { configurationsActions } from "../../../../redux/actions";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { toast } from "react-toastify";
import { customToast } from "../../../../shared";
import {
  configurationsConstants,
  eventBookingConstants,
} from "../../../../constants";
import { minimumAllowedDate } from "../../../../shared";
import BootStrapToolTip from "../../../../shared/components/ToolTip/BootStrapToolTip";

const EventSetup = (props) => {
  const { t } = useTranslation("common");
  const { TABS } = eventBookingConstants;
  const [address, setAddress] = useState("");

  // eslint-disable-next-line
  const [selectedEventType, setSelectedEventType] = useState(0);
  const [isTermsSelected, setIsTermsSelected] = useState([true, true]);
  const [autocompleteSuggestions, setAutocompleteSuggestions] = useState([]);
  const [isEventStartTimeEmpty, setIsEventStartTimeEmpty] = useState(false);
  const [isShutterBStartTimeEmpty, setIsShutterBStartTimeEmpty] =
    useState(false);
  const focusErrorFieldRef = useRef([]);

  const handleEventTypeChange = (event) => {
    setSelectedEventType(event.target.value);
    props.setmodel({
      ...props.model,
      eventtype: event.target.value,
    });
    props.getShotList(event.target.value ? event.target.value : 0);
  };

  const getPostalCode = (place) => {
    // Check if the place has address components
    if (place && place.address_components) {
      // Iterate through address components to find the postal code
      for (const component of place.address_components) {
        if (component.types.includes("postal_code")) {
          return component.long_name; // This is the postal code
        }
      }
    }
    return null; // Return null if no postal code found
  };

  const handleSelect = async (selectedAddress) => {
    setAddress(selectedAddress);
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      let postalCode = getPostalCode(results[0]);
      props.setmodel({
        ...props.model,
        eventlocation: selectedAddress,
        latLng: latLng,
        postalCode: postalCode,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = (event, values, errors) => {
    // checking terms are accepted
    if (!props.model.event_start_time && !props.model.shutterb_start_time) {
      setIsEventStartTimeEmpty(true);
      setIsShutterBStartTimeEmpty(true);
      return;
    }
    if (!props.model.event_start_time) {
      setIsEventStartTimeEmpty(true);
      return;
    }
    if (!props.model.shutterb_start_time) {
      setIsShutterBStartTimeEmpty(true);
      return;
    }
    !values.termsAndConditions &&
      setIsTermsSelected([false, isTermsSelected[1]]);
    !values.privacyPolicy && setIsTermsSelected([isTermsSelected[0], false]);
    const dataToSend = {
      eventname: values.eventname,
      eventdate: values.eventdate,
      event_start_time: props.model.event_start_time,
      eventlocation: values.eventlocation,
      apartment: values.apartment,
      eventtype: values.eventtype,
      additionalNotes: values.additionalNotes,
      notes: values.additionalNotes,
      shutterb_start_time: props.model.shutterb_start_time,
      duration: values.duration,
      termsAndConditions: values.termsAndConditions,
      privacyPolicy: values.privacyPolicy,
      latLng: props.model?.latLng ? props.model?.latLng : null,
      postalCode: props.model?.postalCode ? props.model?.postalCode : null,
      contactname: values.contactname,
      phoneNumber: values.phoneNumber,
      // affiliateCode: 123
    };
    props.setFormState(dataToSend);
    if (props.model.latLng) {
      window.dataLayer.push({
        event: "site_action",
        action: "next_button",
      });
      props.onNext();
    } else {
      customToast("Please select location from dropdown", toast.TYPE.ERROR);
    }
  };

  const handleInvalidSubmit = (event, errors, values) => {
    for (let toFocusElm of focusErrorFieldRef.current) {
      if (toFocusElm.htmlFor === errors[0]) {
        toFocusElm.focus();
        break;
      }
    }
    !props.model.termsAndConditions &&
      setIsTermsSelected([false, props.model.privacyPolicy]);
    !props.model.privacyPolicy &&
      setIsTermsSelected([props.model.termsAndConditions, false]);
    if (!props.model.event_start_time) {
      setIsEventStartTimeEmpty(true);
    }
    if (!props.model.shutterb_start_time) {
      setIsShutterBStartTimeEmpty(true);
    }
  };

  const customInputStyle = {
    display: "block",
    height: "calc(1.5em + 0.75rem + 2px)",
    padding: "0.375rem 0.75rem",
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: 1.5,
    color: "#495057",
    backgroundColor: "#fff",
    backgroundClip: "padding-box",
    border: "1px solid #ced4da",
    borderRight: "none",
  };

  useEffect(() => {
    if (address) {
      if (
        address &&
        autocompleteSuggestions &&
        autocompleteSuggestions.some(
          (suggestion) => suggestion.description === address
        )
      ) {
        handleSelect(address);
      } else {
        props.setmodel({
          ...props.model,
          eventlocation: address,
          latLng: null,
        });
      }
    }

    // eslint-disable-next-line
  }, [props.model.eventlocation]);

  useEffect(() => {
    if (address) {
      props.setmodel({
        ...props.model,
        eventlocation: address,
      });
    }

    // eslint-disable-next-line
  }, [address]);

  useEffect(() => {
    window.scrollTo(0, 0);
    props.getPostalCodes();
    props.getEventTypes(props.isAuthenticated);
    localStorage.setItem("activeTab", TABS.EVENT_SETUP);
    // eslint-disable-next-line
  }, []);

  const validatePostalCodes = () => {
    if (!props.model.postalCode) {
      return "Enter precise location";
    }
    if (props.model.postalCode && props.model.postalCode !== "") {
      const first3Chars = props.model.postalCode.slice(0, 3).toLowerCase();
      //Check if the first 3 characters of postalCode
      // match any item in the postalCodesList
      if (
        props.postalCodesList &&
        props.postalCodesList.some(
          (code) => code.slice(0, 3).toLowerCase() === first3Chars
        )
      ) {
        return true;
      }

      // If not, check if the first 2 characters of postalCode
      // match any item in postalCodesList where the third character is "*"
      const first2Chars = props.model.postalCode.slice(0, 2).toLowerCase();
      if (
        props.postalCodesList &&
        props.postalCodesList.some(
          (code) =>
            code.slice(0, 2).toLowerCase() === first2Chars &&
            code.charAt(2) === "*"
        )
      ) {
        return true;
      }
    }
    // If neither condition is met, return an error message
    return "We are not servicing this area, please visit our contact us page to join our mailing list";
  };

  return (
    <div className="event-setup-form-box">
      <AvForm
        id="event-form"
        onValidSubmit={handleSubmit}
        onInvalidSubmit={handleInvalidSubmit}
        noValidate
      >
        <div id="form-content">
          <div className="row">
            <div className="col-lg-12">
              <label
                ref={(element) => focusErrorFieldRef.current.push(element)}
                htmlFor="eventname"
              >
                {t("event_name")}
              </label>
              <AvField
                name="eventname"
                type="text"
                maxLength="70"
                value={props.model.eventname}
                validate={{
                  required: {
                    value: true,
                    errorMessage: t("please_enter_an_event_name"),
                  },
                }}
                onChange={(event, value) => {
                  props.setmodel({
                    ...props.model,
                    eventname: value,
                  });
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <label
                ref={(element) => focusErrorFieldRef.current.push(element)}
                htmlFor="eventdate"
              >
                {t("event_date")}
              </label>
              <AvField
                name="eventdate"
                value={props.model.eventdate}
                type="date"
                validate={{
                  required: {
                    value: true,
                    errorMessage: t("please_select_an_event_date"),
                  },
                }}
                min={minimumAllowedDate(1)}
                onChange={(event, value) => {
                  props.setmodel({
                    ...props.model,
                    eventdate: value,
                  });
                }}
              />
            </div>
            <div className="col-lg-6 icon-label-con mb-3 custom-mt">
              <label
                ref={(element) => focusErrorFieldRef.current.push(element)}
                htmlFor="event_start_time"
                className="d-flex justify-content-start align-items-start"
              >
                <span>{t("event_start_time")}</span>

                <div className="label-icon-con">
                  <BootStrapToolTip
                    id="tooltip-1"
                    tooltip={"This is the time your event will start"}
                    position="top"
                  >
                    <i className="fas fa-info-circle"></i>
                  </BootStrapToolTip>
                </div>
              </label>
              <div className="input-group">
                <DatePicker
                  selected={
                    props.model.event_start_time
                      ? new Date(props.model.event_start_time)
                      : null
                  }
                  onChange={(time) => {
                    props.setmodel({
                      ...props.model,
                      event_start_time: time,
                      shutterb_start_time: time,
                    });
                    setIsEventStartTimeEmpty(false);
                    setIsShutterBStartTimeEmpty(false);
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  timeFormat="h:mm aa" // Use "h:mm aa" for 12-hour format
                  dateFormat="h:mm aa" // Use "h:mm aa" for 12-hour format
                  className={`input-height-time`}
                  popperClassName="custom-datepicker-popper"
                  customInput={
                    <div className="input-group">
                      <input
                        style={customInputStyle}
                        name="event_start_time"
                        required
                        className={`form-control ${
                          isEventStartTimeEmpty ? "is-invalid-time" : ""
                        }`}
                        value={
                          props.model.event_start_time // Add this line to set the value
                            ? new Date(
                                props.model.event_start_time
                              ).toLocaleTimeString("en-US", {
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                              })
                            : ""
                        }
                      />
                      <div className="input-group-append">
                        <span
                          className={`input-group-text clock-icon ${
                            isEventStartTimeEmpty ? "is-invalid-time" : ""
                          }`}
                        >
                          <i className="fas fa-clock"></i>
                        </span>
                      </div>
                    </div>
                  }
                  // Additional props can be added as per your requirement
                />
              </div>
              {isEventStartTimeEmpty && (
                <div className="is-time-field-empty">
                  This field is required
                </div>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <label
                ref={(element) => focusErrorFieldRef.current.push(element)}
                htmlFor="eventlocation"
              >
                {t("event_location")}
              </label>
              <div className="places-autocomplete-container">
                <PlacesAutocomplete
                  value={props.model.eventlocation}
                  onChange={setAddress}
                  onSelect={handleSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => {
                    if (suggestions.length > 0) {
                      setAutocompleteSuggestions(suggestions);
                    }

                    return (
                      <div className="icon-field-con">
                        <AvField
                          name="eventlocation"
                          type="address"
                          className={"pr-5 pr-md-2 icon-error-issue"}
                          value={props.model.eventlocation}
                          validate={{
                            required: {
                              value: true,
                              errorMessage: t("enter_a_location"),
                            },
                            custom: validatePostalCodes,
                          }}
                          //errorMessage={t("enter_a_location")}
                          placeholder={t("enter_a_location")}
                          {...getInputProps()}
                        />
                        <div className="drop-location-con mr-n2">
                          <i className="fas fa-crosshairs"></i>
                        </div>
                        <div className="address-suggestions-con px-2 suggestion-zIndex">
                          {loading ? <div>{t("loading")}</div> : null}
                          {suggestions.map((suggestion, index) => {
                            const style = {
                              backgroundColor: suggestion.active
                                ? "#eaeaea"
                                : "#fff",
                            };
                            return (
                              <>
                                <div
                                  className="address-suggestions"
                                  key={index}
                                  {...getSuggestionItemProps(suggestion, {
                                    style,
                                  })}
                                >
                                  <MapMarker className="map-icon" />
                                  {suggestion.description}
                                </div>
                                <hr className="p-0 m-0" />
                              </>
                            );
                          })}
                          {suggestions.length > 0 && (
                            <div className="google-trademark p-0 m-0">
                              powered by
                              <div className="google-svg-con">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  height="250"
                                  width="400"
                                  viewBox="-40.446 -22.19 350.532 133.14"
                                >
                                  <path
                                    d="M115.39 46.71c0 12.77-9.99 22.18-22.25 22.18s-22.25-9.41-22.25-22.18c0-12.86 9.99-22.18 22.25-22.18s22.25 9.32 22.25 22.18zm-9.74 0c0-7.98-5.79-13.44-12.51-13.44s-12.51 5.46-12.51 13.44c0 7.9 5.79 13.44 12.51 13.44s12.51-5.55 12.51-13.44z"
                                    fill="#EA4335"
                                  />
                                  <path
                                    d="M163.39 46.71c0 12.77-9.99 22.18-22.25 22.18s-22.25-9.41-22.25-22.18c0-12.85 9.99-22.18 22.25-22.18s22.25 9.32 22.25 22.18zm-9.74 0c0-7.98-5.79-13.44-12.51-13.44s-12.51 5.46-12.51 13.44c0 7.9 5.79 13.44 12.51 13.44s12.51-5.55 12.51-13.44z"
                                    fill="#FBBC05"
                                  />
                                  <path
                                    d="M209.39 25.87v39.82c0 16.38-9.66 23.07-21.08 23.07-10.75 0-17.22-7.19-19.66-13.07l8.48-3.53c1.51 3.61 5.21 7.87 11.17 7.87 7.31 0 11.84-4.51 11.84-13v-3.19h-.34c-2.18 2.69-6.38 5.04-11.68 5.04-11.09 0-21.25-9.66-21.25-22.09 0-12.52 10.16-22.26 21.25-22.26 5.29 0 9.49 2.35 11.68 4.96h.34v-3.61h9.25zm-8.56 20.92c0-7.81-5.21-13.52-11.84-13.52-6.72 0-12.35 5.71-12.35 13.52 0 7.73 5.63 13.36 12.35 13.36 6.63 0 11.84-5.63 11.84-13.36z"
                                    fill="#4285F4"
                                  />
                                  <path
                                    d="M224.64 2.53v65h-9.5v-65z"
                                    fill="#34A853"
                                  />
                                  <path
                                    d="M261.66 54.01l7.56 5.04c-2.44 3.61-8.32 9.83-18.48 9.83-12.6 0-22.01-9.74-22.01-22.18 0-13.19 9.49-22.18 20.92-22.18 11.51 0 17.14 9.16 18.98 14.11l1.01 2.52-29.65 12.28c2.27 4.45 5.8 6.72 10.75 6.72 4.96 0 8.4-2.44 10.92-6.14zm-23.27-7.98l19.82-8.23c-1.09-2.77-4.37-4.7-8.23-4.7-4.95 0-11.84 4.37-11.59 12.93z"
                                    fill="#EA4335"
                                  />
                                  <path
                                    d="M34.93 40.94v-9.41h31.71c.31 1.64.47 3.58.47 5.68 0 7.06-1.93 15.79-8.15 22.01-6.05 6.3-13.78 9.66-24.02 9.66C15.96 68.88 0 53.42 0 34.44 0 15.46 15.96 0 34.94 0c10.5 0 17.98 4.12 23.6 9.49l-6.64 6.64c-4.03-3.78-9.49-6.72-16.97-6.72-13.86 0-24.7 11.17-24.7 25.03 0 13.86 10.84 25.03 24.7 25.03 8.99 0 14.11-3.61 17.39-6.89 2.66-2.66 4.41-6.46 5.1-11.65z"
                                    fill="#4285F4"
                                  />
                                </svg>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  }}
                </PlacesAutocomplete>
              </div>
              <p>
                {t("location_description")}{" "}
                <a
                  href={`${process.env.REACT_APP_MARKETING_SITE_BASE_URL}/contact-us/`}
                  className="underline-on-hover"
                >
                  {t("join our mailing list")}
                </a>
                {t("location_description_next")}
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <label
                ref={(element) => focusErrorFieldRef.current.push(element)}
                htmlFor="apartment"
              >
                {t("appartment_unit_or_suite_no")}
              </label>
              <AvField
                name="apartment"
                type="text"
                maxLength="90"
                value={props.model.apartment}
                onChange={(event, value) => {
                  props.setmodel({
                    ...props.model,
                    apartment: value,
                  });
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 icon-field-con">
              <label
                ref={(element) => focusErrorFieldRef.current.push(element)}
                htmlFor="eventtype"
              >
                Event Type
              </label>
              <AvField
                type="select"
                name="eventtype"
                className={"pr-5 pr-md-2"}
                value={props.model.eventtype}
                onChange={handleEventTypeChange}
                validate={{
                  required: {
                    value: true,
                    errorMessage: t("please_select_an_event_type"),
                  },
                }}
              >
                <option selected disabled value="">
                  Select Event Type You are Hosting
                </option>
                {props.eventTypes.map((eventType) => (
                  <option key={eventType.id} value={eventType.id}>
                    {eventType.name}
                  </option>
                ))}
              </AvField>
              <div className="drop-arrow-con">
                <i className="fas fa-caret-down"></i>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="form-group">
                <label
                  ref={(element) => focusErrorFieldRef.current.push(element)}
                  htmlFor="shotList"
                >
                  Shot List
                </label>
                <div>
                  {props.shotList.map((item, index) => (
                    <div key={index}>{item.itemName}</div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <label
                ref={(element) => focusErrorFieldRef.current.push(element)}
                htmlFor="additionalNotes"
              >
                {t("additional_notes")}
              </label>
              <AvField
                name="additionalNotes"
                type="textarea"
                value={props.model.additionalNotes}
                maxLength={configurationsConstants.TEXTAREA_INPUT_LENGTH}
                validate={{
                  required: {
                    value:
                      parseInt(props.model.eventtype) ===
                      props.eventTypes[11]?.id,
                    errorMessage: t("please_add_additional_notes"),
                  },
                }}
                onChange={(event, value) => {
                  props.setmodel({
                    ...props.model,
                    additionalNotes: value,
                    notes: value,
                  });
                }}
              />
              <p>{t("additional_notes_description")}</p>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 icon-label-con">
              <label
                ref={(element) => focusErrorFieldRef.current.push(element)}
                htmlFor="shutterb_start_time"
                className="d-flex justify-content-start align-items-start"
              >
                <span>{"shutterb Start Time"}</span>
                <div className="label-icon-con">
                  <BootStrapToolTip
                    id="tooltip-2"
                    tooltip={
                      "This is the time you would like your shutterb to arrive and begin shooting at your event"
                    }
                    position="top"
                  >
                    <i className="fas fa-info-circle"></i>
                  </BootStrapToolTip>
                </div>
              </label>
              <div className="input-group">
                <DatePicker
                  selected={
                    props.model.shutterb_start_time
                      ? new Date(props.model.shutterb_start_time)
                      : null
                  }
                  onChange={(time) => {
                    setIsShutterBStartTimeEmpty(false);
                    props.setmodel({
                      ...props.model,
                      shutterb_start_time: time,
                    });
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  timeFormat="h:mm aa" // Use "h:mm aa" for 12-hour format
                  dateFormat="h:mm aa" // Use "h:mm aa" for 12-hour format
                  className={`input-height-time`}
                  popperClassName="custom-datepicker-popper"
                  customInput={
                    <div className="input-group">
                      <input
                        style={customInputStyle}
                        required
                        className={`form-control ${
                          isShutterBStartTimeEmpty ? "is-invalid-time" : ""
                        }`}
                        value={
                          props.model.shutterb_start_time // Add this line to set the value
                            ? new Date(
                                props.model.shutterb_start_time
                              ).toLocaleTimeString("en-US", {
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                              })
                            : ""
                        }
                      />
                      <div className="input-group-append">
                        <span
                          className={`input-group-text clock-icon ${
                            isShutterBStartTimeEmpty ? "is-invalid-time" : ""
                          }`}
                        >
                          <i className="fas fa-clock"></i>
                        </span>
                      </div>
                    </div>
                  }
                  // Additional props can be added as per your requirement
                />
              </div>
              {isShutterBStartTimeEmpty && (
                <div className="is-time-field-empty">
                  This field is required
                </div>
              )}
              <span id="timeError" className="error d-inline-block"></span>
            </div>
            <div className="col-lg-6">
              <label
                ref={(element) => focusErrorFieldRef.current.push(element)}
                htmlFor="duration"
              >
                {t("duration")}
              </label>
              <AvField
                name="duration"
                type="number"
                min="1"
                max="10"
                step="1"
                value={props.model.duration}
                onChange={(event, value) => {
                  props.setmodel({
                    ...props.model,
                    duration: value,
                  });
                }}
                validate={{
                  required: {
                    value: true,
                    errorMessage: t("please_enter_a_duration"),
                  },
                  min: {
                    value: 1,
                    errorMessage: t(
                      "please_enter_a_value_greater_than_or_equal_to_1"
                    ),
                  },
                  max: {
                    value: 10,
                    errorMessage: t(
                      "please_enter_a_value_less_than_or_equal_to_10"
                    ),
                  },
                }}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <label
                ref={(element) => focusErrorFieldRef.current.push(element)}
                htmlFor="contactname"
              >
                {t("contact_name")}
              </label>
              <AvField
                name="contactname"
                type="text"
                maxLength="70"
                value={props.model.contactname}
                onChange={(event, value) => {
                  props.setmodel({
                    ...props.model,
                    contactname: value,
                  });
                }}
              />
            </div>
            <div className="col-lg-6">
              <label
                ref={(element) => focusErrorFieldRef.current.push(element)}
                htmlFor="phoneNumber"
              >
                {t("contact_number")}
              </label>
              <AvField
                name="phoneNumber"
                type="number"
                pattern="^[0-9].{8,9}$"
                value={props.model.phoneNumber}
                onChange={(event, value) => {
                  props.setmodel({
                    ...props.model,
                    phoneNumber: value,
                  });
                }}
              />
            </div>
          </div>

          <div className="row pl-3 mt-3 checkbox-custom-err">
            <AvField
              type="checkbox"
              name="termsAndConditions"
              value={props.model.termsAndConditions}
              className="pt-2"
              required
              onChange={(event, value) => {
                props.setmodel({
                  ...props.model,
                  termsAndConditions: value,
                });
                setIsTermsSelected([value, isTermsSelected[1]]);
              }}
            />
            <label
              ref={(element) => focusErrorFieldRef.current.push(element)}
              htmlFor="termsAndConditions"
              className=" ml-3 pl-1 ml-md-0 ml-lg-0 font-14"
            >
              You have read &amp; accepted{" "}
              <a
                href={`${process.env.REACT_APP_MARKETING_SITE_BASE_URL}/terms-and-conditions/`}
                target="_blank"
                without
                rel="noopener noreferrer"
              >
                {t("terms_and_condition")}
              </a>
            </label>
            <p className={isTermsSelected[0] ? "d-none" : ""}>
              {t("this_field_is_required")}
            </p>
          </div>

          <div className="row pl-3 checkbox-custom-err">
            <AvField
              type="checkbox"
              name="privacyPolicy"
              value={props.model.privacyPolicy}
              required
              onChange={(event, value) => {
                props.setmodel({
                  ...props.model,
                  privacyPolicy: value,
                });
                setIsTermsSelected([isTermsSelected[0], value]);
              }}
            />
            <label
              ref={(element) => focusErrorFieldRef.current.push(element)}
              htmlFor="privacyPolicy"
              className="pl-1 ml-3 ml-md-0 ml-lg-0 font-14"
              // style={{fontSize: "13px"}}
            >
              You have read &amp; accepted our{" "}
              <a
                className="pl-1"
                href={`${process.env.REACT_APP_MARKETING_SITE_BASE_URL}/privacy-policy/`}
                target="_blank"
                without
                rel="noopener noreferrer"
              >
                {t("privacy_policy")}
              </a>
            </label>
            <p className={isTermsSelected[1] ? "d-none" : ""}>
              {t("this_field_is_required")}
            </p>
          </div>
          {/* <div className="row pl-3 checkbox-custom-err">
            <div>
              <AvField
                type="checkbox"
                name="isConsentForMarketing"
                value={props.model.isConsentForMarketing}
                className="pt-2"
                onChange={(event, value) => {
                  props.setmodel({
                    ...props.model,
                    isConsentForMarketing: value,
                  });
                }}
              />
              <label
                // ref={(element) => focusErrorFieldRef.current.push(element)}
                htmlFor="isConsentForMarketing"
                className=" ml-3 pl-1 ml-md-0 ml-lg-0 font-weight-bold font-13"
              ></label>
            </div>
          </div> */}
          <div className="row pl-3 icon-label-con">
            <AvField
              type="checkbox"
              name="isConsentForMarketing"
              value={
                props.model.isConsentForMarketing !== undefined
                  ? props.model.isConsentForMarketing
                  : true
              }
              className="pt-2"
              onChange={(event, value) => {
                props.setmodel({
                  ...props.model,
                  isConsentForMarketing: value,
                });
              }}
            />
            <label
              // ref={(element) => focusErrorFieldRef.current.push(element)}
              htmlFor="isConsentForMarketing"
              className="ml-3 pl-1 ml-md-0 ml-lg-0 font-14 d-flex justify-content-start align-items-start"
            >
              <p className="font-14 m-0" style={{ color: "#000" }}>
                You grant shutterb permission to feature your content on our{" "}
                <br />
                social channels, website, and in marketing materials.
                <BootStrapToolTip
                  id="tooltip-3"
                  tooltip={
                    "Rest assured, we won't use your content for 7 days after the event, allowing you ample time to lock or delete any images or videos you wouldn't want us sharing"
                  }
                  position="right"
                >
                  <i className="fas fa-info-circle"></i>
                </BootStrapToolTip>
              </p>
            </label>
          </div>

          <div className="row pl-3">
            <AvField
              type="checkbox"
              name="isGallerySharedPermission"
              value={
                props.model.isGallerySharedPermission !== undefined
                  ? props.model.isGallerySharedPermission
                  : true
              }
              className="pt-2"
              onChange={(event, value) => {
                props.setmodel({
                  ...props.model,
                  isGallerySharedPermission: value,
                });
              }}
            />
            <label
              // ref={(element) => focusErrorFieldRef.current.push(element)}
              htmlFor="isGallerySharedPermission"
              className="ml-3 pl-1 ml-md-0 ml-lg-0 font-14"
            >
              shutterb can give access to the event gallery directly to my
              guests <br /> so that they can enjoy and share the content as
              well!
            </label>
          </div>

          <div className="row justify-content-center pt-4">
            <div className="event-booking-form-btn">
              <button type="submit" className="btn">
                {t("next")}
              </button>
            </div>
          </div>
        </div>
      </AvForm>
    </div>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  eventTypes: state.eventBooking.eventTypes,
  shotList: state.eventBooking.shotList,
  isAuthenticated: state.authentication.isAuthenticated,
  postalCodesList: state.configurations.postalCodesList,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getEventTypes: (isAuthenticated) =>
    dispatch(eventBookingActions.getEventTypes(isAuthenticated)),
  getShotList: (id) => dispatch(eventBookingActions.getShotList(id)),
  getAvailableShutterBs: (data) =>
    dispatch(eventBookingActions.getAvailableShutterBs(data)),
  setFormState: (data) => dispatch(eventBookingActions.setFormState(data)),
  getPostalCodes: () => dispatch(configurationsActions.getPostalCodes()),
});

// mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EventSetup));
