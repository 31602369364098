import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import { customerDashboardActions } from "../../../../redux/actions";
import { configurationsConstants } from "../../../../constants";

// import refer from "../../../../shared/img/refer.png";

const ReferAFriend = (props) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const formProps = Object.fromEntries(formData);
    const dataObj = {
      userId: props.userId,
      email: formProps.referEmail,
    };
    props.referAFriend(dataObj);
  };
  return (
    <>
      <div className="container attended-events-tab">
        <div className="row">
          <div className="col px-0">
            <h5 className="p-0 m-0 mb-2">Get 10$ Off</h5>
            <p className="p-0 m-0 mb-4">
              Refer a friend and when they book a shutterb you will each get $10
              off your next event.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col px-0">
            <div className="d-flex justify-content-start">
              {/* <form onSubmit={handleSubmit}> */}
              <form onSubmit={handleSubmit}>
                <input
                  type="email"
                  name="referEmail"
                  placeholder="friend@gmail.com"
                  pattern="^[a-z,A-Z,0-9,!@#$%^&*_=+-].{3,}$"
                  minLength={3}
                  maxLength={configurationsConstants.EMAIL_INPUT_LENGTH}
                  required
                />
                <button className="btn-simple search-btn mb-2" type="submit">
                  SEND
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="row">
        <div className="col-12 col-sm-6 col-md-4 m-auto d-flex justify-content-center align-items-center">
          <img className="m-auto" src={refer} alt="Refer friend" />
        </div>
      </div> */}
    </>
  );
};

const mapStateToProps = (state) => ({
  userId: state.authentication.userId,
  userName: state.authentication.userName,
});
const mapDispatchToProps = (dispatch) => ({
  referAFriend: (dataObj) => {
    dispatch(customerDashboardActions.referAFriend(dataObj));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ReferAFriend));
