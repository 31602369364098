import React, { useMemo } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LoadingIcon from "mdi-react/LoadingIcon";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { loginActions } from "../../../redux/actions";
import {
  configurationsConstants,
  loginConstants,
  passwordRegexConstant,
} from "../../../constants";
import { getDecryptPayload } from "../../../system";

const ChangePasswordForm = (props) => {
  const { t } = useTranslation("common");

  const loginSpinner = useMemo(
    () => props.spinnerActions.findIndex((f) => f === loginConstants.LOGIN),
    [props.spinnerActions]
  );

  const payload = getDecryptPayload();

  const userName = (payload.user && payload.user.userName) || "";

  const passwordValidation = (event) => {
    const password = event.target.value;

    const letter = document.getElementById("letter");
    const capital = document.getElementById("capital");
    const number = document.getElementById("number");
    const special = document.getElementById("special");
    const length = document.getElementById("length");
    const lowerCaseLetters = /[a-z]/g;
    if (password.match(lowerCaseLetters)) {
      letter.classList.remove("text-danger");
      letter.classList.add("text-success");
    } else {
      letter.classList.remove("text-success");
      letter.classList.add("text-danger");
    }
    const upperCaseLetters = /[A-Z]/g;
    if (password.match(upperCaseLetters)) {
      capital.classList.remove("text-danger");
      capital.classList.add("text-success");
    } else {
      capital.classList.remove("text-success");
      capital.classList.add("text-danger");
    }
    const numbers = /[0-9]/g;
    if (password.match(numbers)) {
      number.classList.remove("text-danger");
      number.classList.add("text-success");
    } else {
      number.classList.remove("text-success");
      number.classList.add("text-danger");
    }
    const specialCharacter = /[!@#$%^&*)(+=._-]/g;
    if (password.match(specialCharacter)) {
      special.classList.remove("text-danger");
      special.classList.add("text-success");
    } else {
      special.classList.remove("text-success");
      special.classList.add("text-danger");
    }
    if (password.length >= 6) {
      length.classList.remove("text-danger");
      length.classList.add("text-success");
    } else {
      length.classList.remove("text-success");
      length.classList.add("text-danger");
    }
  };

  // when user clicks on change password button.
  const handleSubmit = (event, values) => {
    const id = props.authentication.userId;
    props.changePassword({ id, ...values });
  };

  return (
    <div className="event-setup-form-box mb-2 m-6">
      <h4 className="text-center shutterb-form-header">
        {t("create_or_change_password")}
      </h4>
      <AvForm id="change-password-form" onValidSubmit={handleSubmit} noValidate>
        <div id="form-content">
          <div className="row">
            <div className="col-lg-12">
              <AvField
                name="userName"
                label={t("user_email")}
                value={userName}
                type="text"
                disabled
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <AvField
                type="password"
                name="password"
                label={t("password")}
                errorMessage=" "
                maxLength={configurationsConstants.PASSWORD_INPUT_LENGTH}
                validate={{
                  required: { value: true, errorMessage: "" },
                  pattern: {
                    value: `${passwordRegexConstant.PASSWORD_REGEX}`,
                    errorMessage: "",
                  },
                  minLength: { value: 6, errorMessage: "" },
                }}
                onChange={passwordValidation}
              />
              <div id="message">
                <p id="number" className="text-danger m-0">
                  A number
                </p>
                <p id="letter" className="text-danger m-0">
                  A lowercase letter
                </p>
                <p id="capital" className="text-danger m-0">
                  A uppercase letter
                </p>
                <p id="special" className="text-danger m-0">
                  A Special Character
                </p>
                <p id="length" className="text-danger m-0">
                  Minimum 6 characters
                </p>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-lg-12">
              <AvField
                type="password"
                name="confirmPassword"
                label={t("confirm_password")}
                maxLength={configurationsConstants.PASSWORD_INPUT_LENGTH}
                validate={{ match: { value: "password" } }}
                required
              />
            </div>
          </div>
          <div className="row justify-content-center pt-4">
            <div className="event-booking-form-btn">
              {loginSpinner > -1 ? (
                <div className=" account__btn text-center login_spinner">
                  <LoadingIcon fill="#70bbfd" />
                </div>
              ) : (
                <button type="submit" className="btn">
                  {t("submit")}
                </button>
              )}
            </div>
          </div>
        </div>
      </AvForm>
    </div>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  authentication: state.authentication,
  spinnerActions: state.system.spinnerActions,
  shutterbAuthentication: state.shutterbAuthentication,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(loginActions.logout()),
  changePassword: (data) => dispatch(loginActions.changePassword(data)),
});

// mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ChangePasswordForm));
