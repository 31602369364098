import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import heic2any from "heic2any";

import { customToast } from "../../../../../shared/utility";
import ViewGallery from "../../../../../shared/components/ViewGallery/ViewGallery";
import { shutterbDashboardActions } from "../../../../../redux/actions";
import {
  customerConstants,
  sharedConstants,
  shutterbDashboardConstants,
} from "../../../../../constants";
import { convertBytesToMbs } from "../../../../../shared/utility";

const ReelGallery = (props) => {
  const fileInputRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [filesAdded, setFilesAdded] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);

  const handleDelete = async () => {
    const filesToDeleteFromServer = selectedFiles.filter((file) => file.id);
    const filesToRemoveLocally = selectedFiles.filter((file) => !file.id);
    props.isFileDeleted();
    if (filesToRemoveLocally.length > 0) {
      setFiles((prevFiles) =>
        prevFiles.filter((file) => !filesToRemoveLocally.includes(file))
      );
    }

    if (
      filesToRemoveLocally.length > 0 &&
      filesToDeleteFromServer.length === 0
    ) {
      setSelectedFiles([]);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*, video/*",
    onDrop: (acceptedFiles) => {
      setFiles((prevFiles) =>
        prevFiles.concat(
          acceptedFiles.map((file) => ({
            file,
            url: URL.createObjectURL(file),
            selected: false,
          }))
        )
      );
    },
  });

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = async (event) => {
    const selectedFiles = event.target.files;
    // throwing error in case of image selection
    let isImg = Array.from(selectedFiles).filter((file) =>
      file.type.includes("image")
    );
    if (isImg.length > 0) {
      customToast("Only videos are acceptable for Reel.", toast.TYPE.ERROR);
      return null;
    }
    const convertedFiles = await Promise.all(
      Array.from(selectedFiles).map(async (file) => {
        const fileExtension = file.name.split(".").pop().toLowerCase();

        if (!file.type.includes("image") && !file.type.includes("video")) {
          customToast("File format not supported", toast.TYPE.ERROR);
          return null;
        }

        if (
          file.type.includes("image") &&
          (fileExtension === "heic" || fileExtension === "heif")
        ) {
          // Convert HEIC image to JPEG
          const convertedFile = await convertHeicToImage(file);
          return {
            file: convertedFile,
            url: URL.createObjectURL(convertedFile),
            selected: false,
          };
        } else {
          return {
            file,
            url: URL.createObjectURL(file),
            selected: false,
          };
        }
      })
    );

    // Filter out any null entries from unsupported formats
    const validFiles = convertedFiles.filter((file) => file !== null);

    setFiles((prevFiles) => {
      setFilesAdded(true); // Set the flag to true when files are added
      return [...prevFiles, ...validFiles];
    });
  };

  const convertHeicToImage = async (file) => {
    try {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      const toType = fileExtension === "heif" ? "image/jpeg" : "image/jpeg";

      const imageBlob = await heic2any({ blob: file, toType });
      const convertedFileName = file.name.replace(/\.(heif|heic)$/, ".jpeg");

      return new File([imageBlob], convertedFileName, {
        type: "image/jpeg",
      });
    } catch (error) {
      console.error("HEIC/HEIF to image conversion error:", error);
      return null; // Return null or handle the error in an appropriate way
    }
  };

  const handleUploadReels = () => {
    let formdata = new FormData();
    const filesToSend = files.filter((file) => !file.id);
    // size limit: total payload size limit
    if (
      convertBytesToMbs(
        filesToSend.reduce((accomulator, currentValue) => {
          return accomulator + currentValue?.file?.size ?? 0;
        }, 0)
      ) > process.env.REACT_APP_GALLERY_UPLOAD_PAYLOAD_LIMIT
    ) {
      customToast(
        `Upto ${process.env.REACT_APP_GALLERY_UPLOAD_PAYLOAD_LIMIT} MBs are allowed in an attempt`,
        toast.TYPE.ERROR
      );
      return null;
    }
    // size limit: each file size limit
    let fileWithInvalidSizeArr = filesToSend.filter((file) => {
      return (
        file.file.size / sharedConstants.KB_TO_MB_BYTES >=
        process.env.REACT_APP_GALLERY_UPLOAD_FILE_SIZE_LIMIT
      );
    });
    if (fileWithInvalidSizeArr.length > 0) {
      customToast(
        `Each File size should be under ${process.env.REACT_APP_GALLERY_UPLOAD_FILE_SIZE_LIMIT}MB.`,
        toast.TYPE.ERROR
      );
      props.diActivateOverlay();
      return null;
    }
    if (filesToSend.length !== props.reelQty) {
      customToast(
        `${props.reelQty} ${
          props.reelQty > 1 ? "recap are required" : "recap is required"
        }`,
        toast.TYPE.ERROR
      );
      return;
    }
    filesToSend.forEach((file) => {
      formdata.append("file", file.file);
    });
    formdata.append("eventId", props.eventId);
    formdata.append("SbUserId", props.shutterBId);
    formdata.append(
      "ContentType",
      shutterbDashboardConstants.CONTENT_TYPE_REEL
    );
    if (filesToSend.length > 0) {
      const options = (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = ((loaded * 100) / total).toFixed(2);
        if (percent <= 100) {
          setUploadPercentage(percent);
        }
      };
      props.addReelsToGallery(
        formdata,
        options,
        props.shutterBId,
        customerConstants.PENDING_EVENTS
      );
    } else {
      //customToast(t("files_already_uploaded"), toast.TYPE.ERROR);
    }
    setSelectedFiles([]);
  };

  useEffect(() => {
    if (filesAdded) {
      setFilesAdded(false);
      // Call handleUploadReels only if clicked on inform customer
      // handleUploadReels();
    }
    // eslint-disable-next-line
  }, [files]);

  useEffect(() => {
    setUploadPercentage(0);
    // eslint-disable-next-line
  }, [props.galleryData]);

  useEffect(() => {
    if (props.isUpdated) {
      setSelectedFiles([]);
      setFiles([]);
      setFilesAdded(false);
      setUploadPercentage(0);
      props.closeAllAccordians();
      props.setIsUpdated(false);
    }
    // eslint-disable-next-line
  }, [props.isUpdated]);

  return (
    <div className=" col-lg-12 px-0">
      <div className="d-flex flex-column align-items-center text-center">
        {/*props.galleryData.eventName ? (
          <p>
            {props.galleryData.eventName} -{" "}
            {props.galleryData.eventDate
              ? new Date(props.galleryData.eventDate * 1000).toLocaleDateString(
                  "en-US",
                  {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  }
                )
              : ""}
          </p>
        ) : (
          ""
        )*/}
      </div>

      <div className="row">
        <div className="col-md-12  px-0 d-flex justify-content-center flex-wrap">
          <div className="py-1 py-md-0">
            <button
              className="gallery-btn"
              disabled={!props.isAllowDownload}
              onClick={() =>
                props.downloadAssets(props.eventId, props.sbUserName)
              }
            >
              Download Assets
            </button>
          </div>
          <div {...getRootProps()} className="py-1 py-md-0 dropzone-content">
            <input
              {...getInputProps()}
              ref={fileInputRef}
              disabled={uploadPercentage > 0}
              onChange={handleFileInputChange}
            />
            <button
              className="gallery-btn"
              disabled={uploadPercentage > 0}
              onClick={handleButtonClick}
            >
              Upload Recaps
            </button>
          </div>
          <div className="py-1 py-md-0">
            <button
              className="gallery-btn"
              onClick={() => {
                props.setReelModalEventId(props.eventId);
                props.setIsModalOpen(!props.isModalOpen);
              }}
            >
              View Recap Details
            </button>
          </div>
        </div>
      </div>
      {
        <ViewGallery
          files={files}
          isUploadAllowed
          setFiles={setFiles}
          handleDelete={handleDelete}
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
          progress={uploadPercentage}
          isReelUpload
        />
      }
      <div className="col-12 d-flex justify-content-center pt-4 pl-0">
        <button
          className="gallery-btn"
          disabled={uploadPercentage > 0}
          onClick={handleUploadReels}
        >
          Finish
        </button>
      </div>
    </div>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  isUpdated: state.shutterbDashboard.isUpdated,
  galleryData: state.shutterbDashboard.galleryData,
  shutterBId: state.shutterbAuthentication.sbUserId,
  isImageDeleted: state.shutterbDashboard.isImageDeleted,
  sbUserName: state.shutterbAuthentication.sbUserName,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  addReelsToGallery: (data, options, sbUserId, status) =>
    dispatch(
      shutterbDashboardActions.addReelsToGallery(
        data,
        options,
        sbUserId,
        status
      )
    ),
  setIsUpdated: (data) => dispatch(shutterbDashboardActions.setIsUpdated(data)),
  isFileDeleted: () => dispatch(shutterbDashboardActions.isFileDeleted()),
  downloadAssets: (eventId, sbUserName) =>
    dispatch(shutterbDashboardActions.downloadAssets(eventId, sbUserName)),
  activateSpinner: () => dispatch({ type: "SPINNER_ACTIVATE", data: null }),
  diActivateSpinner: () => dispatch({ type: "SPINNER_DIACTIVATE", data: null }),
});

// mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ReelGallery));
