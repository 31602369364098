import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { eventBookingActions } from "../../../../redux/actions/eventBooking.actions";
import { customerConstants } from "../../../../constants";
import { eventBookingConstants } from "../../../../constants";

const UndoCart = (props) => {
  /* eslint-disable */
  const notNewEvent = localStorage.getItem(customerConstants.NOT_NEW_EVENT);
  const handleUndoCartItem = () => {
    if (!notNewEvent) {
      const updatedCartItems = [...props.selectedShutterBz, props.undoCartItem];
      props.setSelectedShutterBz(updatedCartItems);
      props.setUndoCartItem({});
    } else {
      const updatedCartItems = [
        ...props.customerDashboardCart,
        props.undoCartItem,
      ];
      props.updateCustomerDashboardCart(updatedCartItems);
      props.setUndoCartItem({});
    }
  };
  return props.undoCartItem?.firstName || props.undoCartItem?.id ? (
    <div className="container px-5 mx-auto undo-cart">
      {!notNewEvent ? (
        <>
          "
          {(props.undoCartItem?.firstName ?? "") +
            " " +
            (props.undoCartItem?.lastName ?? "")}
          " removed.{" "}
          <a href="#" onClick={handleUndoCartItem}>
            Undo?
          </a>
        </>
      ) : (
        <>
          "
          {(props.undoCartItem?.sbUser?.firstName ?? "") +
            " " +
            (props.undoCartItem?.sbUser?.lastName ?? "")}
          " removed.{" "}
          <a href="#" onClick={handleUndoCartItem}>
            Undo?
          </a>
        </>
      )}
    </div>
  ) : (
    <></>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  selectedShutterBz: state.eventBooking.selectedShutterBz,
  customerDashboardCart: state.customerDashboard.customerDashboardCart,
  undoCartItem: state.eventBooking.undoCartItem,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  setSelectedShutterBz: (data) =>
    dispatch(eventBookingActions.setSelectedShutterBz(data)),
  updateCustomerDashboardCart: (dataObj) =>
    dispatch({
      type: customerConstants.UPDATE_CUSTOMER_DASHBOARD_CART,
      data: dataObj,
    }),
  setUndoCartItem: (deletedCartItem) =>
    dispatch({
      type: eventBookingConstants.SET_UNDO_CART_ITEM,
      data: deletedCartItem,
    }),
});

// mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UndoCart));
