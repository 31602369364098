import React, { useEffect, useState } from "react";
import MessageBoardView from "../../../../../../../shared/components/MessageBoard/MessageBoardView";
import { messageBoardActions } from "../../../../../../../redux/actions";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import { connect } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { convertDateToUTC } from "../../../../../../../shared/utility";
import { customerConstants } from "../../../../../../../constants";
import "../../../../../../../scss/pages/_message-board.scss";
import MessageBoardModal from "../../../../../../../shared/components/MessageBoard/Modal/MessageBoardModal";

const MessageBoard = (props) => {
  const smallScreenWidth = 768;
  const [isChatStarted, setIsChatStarted] = useState(false);
  const [currentActiveId, setCurrentActiveId] = useState(0);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const eventId = useParams().eventId;

  // useEffect(()=>{
  //    if (props.customerChatStarted.currentTabId > 0) {
  //      props.setCustomerChatStart({ currentTabId: 0 });
  //           setCurrentActiveId(0);
  //    }
  // },[])

  useEffect(() => {
    props.getshutterbzByEventId(eventId);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, []);

  const handleResize = () => {
    if (window.innerWidth && window.innerWidth >= 768) {
      setIsChatStarted(false);
      setIsMessageModalOpen(false);
      setCurrentActiveId(0);
    } else {
      setIsChatStarted(false);
      setCurrentActiveId(0);
    }
  };

  const handleCloseModal = () => {
    setIsMessageModalOpen(false);
    setCurrentActiveId(0);
  };

  // useEffect(() => {
  //   if (props.data?.sbInfo) {
  //     let info;
  //     // if (props.customerChatStarted.currentTabId > 0) {
  //     //   info = props.data.sbInfo.find(
  //     //     (data) => data.id === props.customerChatStarted.currentTabId
  //     //   );
  //     // } else {
  //       info = props.data.sbInfo[0];
  //     // }
  //     if (info) {
  //       handleSubmit(
  //         info.id,
  //         info.profileImageUrl,
  //         info.firstName + " " + info.lastName
  //       );
  //     }
  //   }
  // }, [props.data]);
  // props.customerChatStarted.currentTabId]);

  const handleSubmit = (sbId, imageUrl, name) => {
    if (window.innerWidth < smallScreenWidth) {
      setIsMessageModalOpen(true);
      setIsChatStarted(false);
    } else {
      setIsChatStarted(true);
      setIsMessageModalOpen(false);
    }
    setCurrentActiveId(sbId);
    props.bulkUpdateMessageStatus(
      eventId,
      sbId,
      props.authentication.userId,
      props.authentication.userType
    );
    props.getMessages(eventId, sbId);
    props.setFormState({
      ...props.formState,
      userId: props.userId,
      senderId: props.userId,
      receiverId: sbId,
      profileImageUrl: imageUrl,
      sbUserId: sbId,
      name: name,
      email: props.data.email,
      eventId: eventId,
      sender: customerConstants.HOST,
    });
  };

  return (
    <>
      <div className="row w-100">
        {props.data.sbInfo?.length > 0 ? (
          <>
            <div class="col-md-4 chat">
              <div class="card msg-card mb-sm-3 mb-md-0 contacts_card">
                <div class="card-header msg-card-header">
                  <p className="p-0 m-0 font-12 text-light">
                    {props.data?.id}
                    <br />

                    {props.data.eventName}
                    <br />

                    {convertDateToUTC(props.data?.eventDate)}
                  </p>
                </div>
                <div class="card-body contacts_body">
                  <ui class="contacts">
                    {props.data.sbInfo?.map((item) => {
                      return (
                        <li
                          className={
                            currentActiveId === item.id ? "active" : ""
                          }
                          onClick={() => {
                            handleSubmit(
                              item.id,
                              item.profileImageUrl,
                              item.firstName + " " + item.lastName
                            );
                          }}
                        >
                          <div class="d-flex bd-highlight">
                            <div class="img_cont_msg">
                              <img
                                src={
                                  process.env.REACT_APP_S3_BASE_URL_PORTFOLIO +
                                  "/" +
                                  item.profileImageUrl
                                }
                                alt="User Profile"
                                class="rounded-circle user_img w-50 h-50"
                              />
                            </div>
                            <div class="user-info-text">
                              <span>
                                {item.firstName + " " + item.lastName}
                              </span>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ui>
                </div>
                <div class="card-footer msg-card-footer">
                  <p className="d-flex align-items-center text-light  justify-content-center mt-2 mb-2">
                    shutterb(z)
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              {isChatStarted ? (
                <MessageBoardView
                  currentUser={customerConstants.HOST}
                  currentActiveId={currentActiveId}
                  userId={props.formState.userId}
                  sbUserId={props.formState.sbUserId}
                  eventId={props.formState.eventId}
                  profileImageUrl={props.formState.profileImageUrl}
                  currentUserId={props.userId}
                  // currentActiveTab={
                  //   props.formState.userId +
                  //   props.formState.sbUserId +
                  //   props.formState.eventId
                  // }
                />
              ) : !isMessageModalOpen && !isChatStarted ? (
                <p className="pt-3 d-flex justify-content-center">
                  Initialize Chat with shutterb(z)
                </p>
              ) : (
                ""
              )}
            </div>
          </>
        ) : (
          <div className="col-md-12">
            <p className="pt-3 d-flex justify-content-center">
              Your event has not been accepted by Shutterb(z) yet
            </p>
          </div>
        )}
      </div>
      {isMessageModalOpen ? (
        <MessageBoardModal
          isMessageModalOpen={isMessageModalOpen}
          handleCloseModal={handleCloseModal}
          currentUser={customerConstants.HOST}
          currentUserId={props.userId}
          className="message-board-modal"
        />
      ) : (
        ""
      )}
      {/* <div className="container-fluid p-0 container-vh">
        <div className="row w-100 margin-auto" style={{ margin: "0" }}>
          <div className="col-md-12 p-0 my-2">
            <div className="text-center m-3">
              <p className="p-0 m-0 font-12">
                {props.data.eventName}
                <br />
                {convertDateToUTC(props.data?.eventDate)}
                <br />
                <b> Event ID:</b> {props.data?.id}
              </p>
            </div>
            {props.customerChatStarted.isStarted ? (
              <MessageBoardView
                currentUser={customerConstants.HOST}
                currentActiveId={currentActiveId}
                userId={props.formState.userId}
                sbUserId={props.formState.sbUserId}
                eventId={props.formState.eventId}
              />
            ) : (
              <h6 className="d-flex justify-content-center pt-5">
                Initiate a Conversation With shutterb(z)
              </h6>
            )}
          </div>
        </div>
      </div> */}
    </>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  userId: state.authentication.userId,
  data: state.messageBoard.data,
  formState: state.messageBoard.formState,
  customerChatStarted: state.messageBoard.customerChatStarted,
  authentication: state.authentication,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getshutterbzByEventId: (eventId) =>
    dispatch(messageBoardActions.getshutterbByEventId(eventId)),
  setFormState: (data) => dispatch(messageBoardActions.setFormState(data)),
  getMessages: (eventId, sbUserId) =>
    dispatch(messageBoardActions.getMessages(eventId, sbUserId)),
  bulkUpdateMessageStatus: (eventId, sbUserId, userId, userType) =>
    dispatch(
      messageBoardActions.bulkUpdateMessageStatus(
        eventId,
        sbUserId,
        userId,
        userType
      )
    ),
  setCustomerChatStart: (data) =>
    dispatch(messageBoardActions.setCustomerChatStart(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MessageBoard));
