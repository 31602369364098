// eslint-disable-next-line import/no-cycle
import responseHandler from "../system/server-response-handler";
import { getRequest, putRequest } from "../system/server-call";
import { sendMessage as sendMessages } from "../system/signalRHOC";

// const dispatch = useDispatch();
const getshutterbByEventId = (eventId) => {
  return getRequest(
    `/api/v1/MessageBoard/GetSBzByEventId/${eventId}`,
    true
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const sendMessage = (connection,data) => {
  sendMessages(connection,data);
};

const getMessages = (eventId, sbUserId) => {
  return getRequest(
    `/api/v1/MessageBoard/GetMessages/${eventId}/${sbUserId}`,
    true
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const getSBById = (eventId, sbUserId) => {
  return getRequest(
    `/api/v1/MessageBoard/GetSBById/${eventId}/${sbUserId}`,
    true
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const getNotifications = (id, type) => {
  return getRequest(
    `/api/v1/MessageBoard/GetNotification/${id}/${type}`,
    true
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const getUserNotificationCount = (id, type) => {
  return getRequest(
    `/api/v1/MessageBoard/GetUserNotificationCount/${id}/${type}`,
    true
  ).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
};

const bulkUpdateMessageStatus = (eventId, sbUserId) => {
  return putRequest(
    `/api/v1/MessageBoard/BulkUpdateMessageStatus/${eventId}/${sbUserId}`,
    true
  ).then(
    (response) => {
      return responseHandler.handleServerResponse(response);
    },
    (error) => {
      return responseHandler.handleHTTPResponses(error.response);
    }
  );
};

const updateMessageStatus = (messageId) => {
  return putRequest(
    `/api/v1/MessageBoard/updateMessageStatus/${messageId}`,
    true
  ).then(
    (response) => {
      return responseHandler.handleServerResponse(response);
    },
    (error) => {
      return responseHandler.handleHTTPResponses(error.response);
    }
  );
};

// exporting functions for other files
export const messageBoardService = {
  getshutterbByEventId,
  sendMessage,
  getMessages,
  getSBById,
  getNotifications,
  bulkUpdateMessageStatus,
  getUserNotificationCount,
  updateMessageStatus,
};