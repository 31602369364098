import { system } from "./system.reducer";
import { authentication } from "./authentication.reducer";
import { shutterbAuthentication } from "./shutterb.reducer";
import { eventBooking } from "./eventBooking.reducer";
import { shutterbDashboard } from "./shutterbDashboard.reducer";
import { customerDashboard } from "./customerDashboard.reducer";
import { shutterbProfile } from "./shutterbProfile.reducer";
import { configurations } from "./configurations.reducer";
import { messageBoard } from "./messageBoard.reducer";

export default {
  system,
  authentication,
  shutterbAuthentication,
  eventBooking,
  shutterbDashboard,
  customerDashboard,
  shutterbProfile,
  configurations,
  messageBoard,
};
