import { updateObject } from "../../shared/utility";
import { shutterbDashboardConstants, globalConstants } from "../../constants";

// getting the default actions name from defined constants via decomposition
const {
  IS_UPDATED,
  ADD_TO_PROFILE,
  IS_FILE_DELETED,
  GET_GALLERY_DATA,
  DELETE_FROM_GALLERY,
  GET_EVENT_DETAIL_BY_ID,
} = shutterbDashboardConstants;

// default state
const initialState = {
  // item is an object of my portfolio details and events are event requests list
  events: [],
  sbMyEvents: [],
  sbMyPortfolio: {},
  sbMyInfo: {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    secondaryPhoneNumber: "",
    location: [{ currentLocation: "" }],
  },
  isStripeConnectSubmitted: true,
  isStripeConnectStatus: true,
  isImageDeleted: false,
  isAddedToProfile: false,
  galleryData: {},
  reelEvents: [],
  reelDetails: {
    reelDetails: [],
    reelQuestionAnswers: [],
  },
  eventDetailById: [],
  isUpdated: false,
};

// manupilating the state based on actions and returning the state
export const shutterbDashboard = (state = initialState, action) => {
  switch (action.type) {
    case shutterbDashboardConstants.GET_EVENT_REQUEST + globalConstants.SUCCESS:
      return updateObject(state, {
        events: action.data?.items?.event,
      });
    case shutterbDashboardConstants.PUT_EVENT_REQUEST + globalConstants.SUCCESS:
      const updatedEventList = {
        events: state.events.filter((e) => {
          return e.id !== action.data?.items?.eventId;
        }),
      };
      return updateObject(state, updatedEventList);
    case shutterbDashboardConstants.GET_SB_MY_EVENTS + globalConstants.SUCCESS:
      return updateObject(state, {
        sbMyEvents: action.data?.items?.event,
      });
    case shutterbDashboardConstants.GET_PORTFOLIO_REQUEST +
      globalConstants.SUCCESS:
      return updateObject(state, {
        sbMyPortfolio: action.data?.items,
      });
    case shutterbDashboardConstants.DELETE_IMG_REQUEST +
      globalConstants.SUCCESS:
      return updateObject(state, {
        ...state,
      });
    case shutterbDashboardConstants.GET_MY_INFO_REQUEST +
      globalConstants.SUCCESS:
      return updateObject(state, {
        sbMyInfo: action.data.items,
      });
    case shutterbDashboardConstants.GET_STRIPE_CONNECTED_STATUS_REQUEST +
      globalConstants.SUCCESS:
      return updateObject(state, {
        isStripeConnectSubmitted: action.data.detailsSubmitted,
        isStripeConnectStatus: action.data.chargesEnable,
      });
    case DELETE_FROM_GALLERY + globalConstants.SUCCESS:
      return updateObject(state, {
        isImageDeleted: true,
      });
    case DELETE_FROM_GALLERY + globalConstants.FAILURE:
      return updateObject(state, {
        isImageDeleted: false,
      });
    case IS_FILE_DELETED:
      return updateObject(state, {
        isImageDeleted: false,
      });
    case GET_GALLERY_DATA + globalConstants.SUCCESS:
      return updateObject(state, {
        galleryData: action.data.items,
      });
    case ADD_TO_PROFILE + globalConstants.SUCCESS:
      return updateObject(state, {
        isAddedToProfile: !state.isAddedToProfile,
      });
    case ADD_TO_PROFILE + globalConstants.FAILURE:
      return updateObject(state, {
        isAddedToProfile: !state.isAddedToProfile,
      });
    case shutterbDashboardConstants.GET_REEL_EVENTS + globalConstants.SUCCESS:
      return updateObject(state, {
        reelEvents: action.data?.items,
      });
    case shutterbDashboardConstants.GET_REEL_DETAILS + globalConstants.SUCCESS:
      return updateObject(state, {
        reelDetails: action.data,
      });
    case GET_EVENT_DETAIL_BY_ID:
      return updateObject(state, {
        eventDetailById: action.data?.items,
      });

    case IS_UPDATED:
      return updateObject(state, {
        isUpdated: action.data,
      });

    default:
      return state;
  }
};
