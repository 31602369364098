import i18next from "i18next";
import { toast } from "react-toastify";
import { shutterbDashboardServices } from "../../services";
import {
  globalSystemConstants,
  shutterbDashboardConstants,
  initialWorkingSchedules,
  customerConstants,
} from "../../constants";
import {
  customToast,
  calculateTotalFileSizeInMbs,
  convertBytesToMbs,
} from "../../shared/utility";
import { success, failure, custom } from "./utilities";

const {
  IS_UPDATED,
  ADD_TO_PROFILE,
  ADD_TO_GALLERY,
  GET_REEL_DETAILS,
  GET_GALLERY_DATA,
  DELETE_FROM_GALLERY,
  GET_EVENT_DETAIL_BY_ID,
} = shutterbDashboardConstants;

// Event request tab actions------------------------------
const eventRequests = (id) => {
  return (dispatch) => {
    dispatch(custom(null, globalSystemConstants.SPINNER_ACTIVATE));
    shutterbDashboardServices
      .getEventRequests(id)
      .then(
        (response) => {
          dispatch(
            success(response, shutterbDashboardConstants.GET_EVENT_REQUEST)
          );
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(
            failure(error, shutterbDashboardConstants.GET_EVENT_REQUEST)
          );
        }
      )
      .finally(() => {
        dispatch(custom(null, globalSystemConstants.SPINNER_DIACTIVATE));
      });
  };
};

const updateEventRequest = (id, dataObj) => {
  return (dispatch) => {
    dispatch(custom(null, globalSystemConstants.SPINNER_ACTIVATE));
    shutterbDashboardServices
      .putEventRequests(id, dataObj)
      .then(
        (response) => {
          if (dataObj.isActive) {
            customToast(
              i18next.t("event_request_accepted"),
              toast.TYPE.SUCCESS
            );
          } else {
            customToast(
              i18next.t("event_request_declined"),
              toast.TYPE.SUCCESS
            );
          }
          dispatch(
            success(response, shutterbDashboardConstants.PUT_EVENT_REQUEST)
          );
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(
            failure(error, shutterbDashboardConstants.GET_EVENT_REQUEST)
          );
        }
      )
      .finally(() => {
        dispatch(custom(null, globalSystemConstants.SPINNER_DIACTIVATE));
      });
  };
};

// My Events tab actions----------------------------------
const sbMyEvents = (id, status) => {
  return (dispatch) => {
    dispatch(custom(null, globalSystemConstants.SPINNER_ACTIVATE));
    shutterbDashboardServices
      .getSbMyEvents(id, status)
      .then(
        (response) => {
          dispatch(
            success(response, shutterbDashboardConstants.GET_SB_MY_EVENTS)
          );
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
        }
      )
      .finally(() => {
        dispatch(custom(null, globalSystemConstants.SPINNER_DIACTIVATE));
      });
  };
};

const updateSbMyEventsStatus = (
  eventId,
  dataObj,
  sbUserId,
  goToPreviousPage
) => {
  return (dispatch) => {
    dispatch(custom(null, globalSystemConstants.SPINNER_ACTIVATE));
    shutterbDashboardServices
      .putSbMyEventsStatus(eventId, dataObj)
      .then(
        (response) => {
          dataObj.checkedStatus === shutterbDashboardConstants.CHECK_OUT
            ? customToast(
                i18next.t("successfully_checked_out"),
                toast.TYPE.SUCCESS
              )
            : customToast(
                i18next.t("successfully_checked_in"),
                toast.TYPE.SUCCESS
              );
          if (typeof goToPreviousPage === "boolean") {
            window.location.reload();
          }
          dispatch(sbMyEvents(sbUserId, customerConstants.UPCOMING_EVENTS));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
        }
      )
      .finally(() => {
        dispatch(custom(null, globalSystemConstants.SPINNER_DIACTIVATE));
      });
  };
};

const getEventDetailByEventId = (eventId) => {
  return (dispatch) => {
    dispatch(
      custom(GET_EVENT_DETAIL_BY_ID, globalSystemConstants.SPINNER_ACTIVATE)
    );
    shutterbDashboardServices
      .getEventDetailByEventId(eventId)
      .then(
        (response) => {
          dispatch(custom(response, GET_EVENT_DETAIL_BY_ID));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
        }
      )
      .finally(() => {
        dispatch(
          custom(
            GET_EVENT_DETAIL_BY_ID,
            globalSystemConstants.SPINNER_DIACTIVATE
          )
        );
      });
  };
};

// My profile tab actions---------------------------------
const mySchedule = (
  id,
  setDistanceValue,
  setBlackoutDays,
  setWorkingSchedules,
  setDistanceValueForLongEvent
) => {
  // action does not use reducer, it updates the component's local state
  return (dispatch) => {
    dispatch(custom(null, globalSystemConstants.SPINNER_ACTIVATE));
    shutterbDashboardServices
      .getMySchedule(id)
      .then(
        (response) => {
          setDistanceValue(response.travelDistance.minDistanceToTravel);
          setDistanceValueForLongEvent(
            response.travelDistance.maxDistanceToTravel
          );
          setBlackoutDays({
            displayDates: response.holidayProfile.map((e) => {
              return e.date * 1000;
            }),
            holidayDetails: response.holidayProfile.map((e) => {
              return {
                date: e.date,
                dateTo: e.dateTo,
                startTime: e.startTime,
                endTime: e.endTime,
              };
            }),
          });
          // for new shutterb, in null case
          // response.workingSchedules.length !== 0
          //   ? setWorkingSchedules([...response.workingSchedules])
          //   : setWorkingSchedules([...initialWorkingSchedules]);
          if (response.workingSchedules.length !== 0) {
            setWorkingSchedules(
              JSON.parse(JSON.stringify([...response.workingSchedules]))
            );
          } else {
            setWorkingSchedules(
              JSON.parse(JSON.stringify([...initialWorkingSchedules]))
            );
          }
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(
            failure(error, shutterbDashboardConstants.GET_PORTFOLIO_REQUEST)
          );
        }
      )
      .finally(() => {
        // turn off the spinner
        dispatch(custom(null, globalSystemConstants.SPINNER_DIACTIVATE));
      });
  };
};

const updateMySchedule = (id, dataObj) => {
  return (dispatch) => {
    dispatch(custom(null, globalSystemConstants.SPINNER_ACTIVATE));
    shutterbDashboardServices
      .putMySchedule(id, dataObj)
      .then(
        (response) => {
          customToast(i18next.t("saved_successfully"), toast.TYPE.SUCCESS);
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(
            failure(error, shutterbDashboardConstants.GET_PORTFOLIO_REQUEST)
          );
        }
      )
      .finally(() => {
        // turn off the spinner
        dispatch(custom(null, globalSystemConstants.SPINNER_DIACTIVATE));
      });
  };
};

const myPortfolio = (id, setImages, isPortfolioUpdated) => {
  return (dispatch) => {
    dispatch(custom(null, globalSystemConstants.SPINNER_ACTIVATE));
    shutterbDashboardServices
      .getPortfolioRequests(id)
      .then(
        (response) => {
          if (response.items.gallery && response.items.gallery.length > 0) {
            if (isPortfolioUpdated) {
              window.location.reload(true);
            }
            setImages(
              response.items.gallery.map((e) => {
                return {
                  data_url: `${process.env.REACT_APP_S3_BASE_URL_PORTFOLIO}/${e.imageUrl}`,
                  imageUrl: e.imageUrl,
                  id: e.id,
                  sbportfolioId: e.sbportfolioId,
                };
              })
            );
          }
          dispatch(
            success(response, shutterbDashboardConstants.GET_PORTFOLIO_REQUEST)
          );
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(
            failure(error, shutterbDashboardConstants.GET_PORTFOLIO_REQUEST)
          );
        }
      )
      .finally(() => {
        // turn off the spinner
        dispatch(custom(null, globalSystemConstants.SPINNER_DIACTIVATE));
      });
  };
};

const deleteImg = (dataObj) => {
  return (dispatch) => {
    dispatch(custom(null, globalSystemConstants.SPINNER_ACTIVATE));
    shutterbDashboardServices
      .deletePortfolioImgRequests(dataObj)
      .then(
        (response) => {
          customToast(
            i18next.t("img_successfully_deleted"),
            toast.TYPE.SUCCESS
          );
          dispatch(
            success(response, shutterbDashboardConstants.DELETE_IMG_REQUEST)
          );
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
        }
      )
      .finally(() => {
        // turn off the spinner
        dispatch(custom(null, globalSystemConstants.SPINNER_DIACTIVATE));
      });
  };
};

const updateMyPortfolio = (id, dataObj, setProfileImgToShow, setImages) => {
  return (dispatch) => {
    dispatch(custom(null, globalSystemConstants.SPINNER_ACTIVATE));
    shutterbDashboardServices
      .putPortfolioRequests(id, dataObj)
      .then(
        (response) => {
          let isPortfolioUpdated = true;
          customToast(i18next.t("saved_successfully"), toast.TYPE.SUCCESS);
          setProfileImgToShow({});
          dispatch(myPortfolio(id, setImages, isPortfolioUpdated));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          dispatch(
            failure(error, shutterbDashboardConstants.GET_PORTFOLIO_REQUEST)
          );
        }
      )
      .finally(() => {
        // turn off the spinner
        dispatch(custom(null, globalSystemConstants.SPINNER_DIACTIVATE));
      });
  };
};

const myInfo = (id) => {
  return (dispatch) => {
    dispatch(custom(null, globalSystemConstants.SPINNER_ACTIVATE));
    shutterbDashboardServices
      .getMyInfoRequests(id)
      .then(
        (response) => {
          dispatch(
            success(response, shutterbDashboardConstants.GET_MY_INFO_REQUEST)
          );
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
          // can dispatch actions to handle error ie logout
        }
      )
      .finally(() => {
        // Turn off the spinner
        dispatch(custom(null, globalSystemConstants.SPINNER_DIACTIVATE));
      });
  };
};

const updateMyInfo = (id, dataObj) => {
  return (dispatch) => {
    dispatch(custom(null, globalSystemConstants.SPINNER_ACTIVATE));
    shutterbDashboardServices
      .putMyInfoRequests(id, dataObj)
      .then(
        (response) => {
          customToast(i18next.t("saved_successfully"), toast.TYPE.SUCCESS);
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
        }
      )
      .finally(() => {
        // turn off the spinner
        dispatch(custom(null, globalSystemConstants.SPINNER_DIACTIVATE));
      });
  };
};

const stripeConnection = (id) => {
  return (dispatch) => {
    shutterbDashboardServices
      .getStripeConnectionRequest(id)
      .then(
        (response) => {
          // dispatch(
          //   success(response, shutterbDashboardConstants.GET_PORTFOLIO_REQUEST)
          // );
          window.location.replace(response);
        },
        (error) => {
          customToast(i18next.t("unable_to_access_server"), toast.TYPE.ERROR);
          dispatch(
            failure(error, shutterbDashboardConstants.GET_PORTFOLIO_REQUEST)
          );
        }
      )
      .finally(() => {
        // turn off the spinner
        dispatch(custom(null, globalSystemConstants.SPINNER_DIACTIVATE));
      });
  };
};

const stripeConnectedStatus = (id) => {
  return (dispatch) => {
    dispatch(custom(null, globalSystemConstants.SPINNER_ACTIVATE));
    shutterbDashboardServices
      .getStripeConnectedStatusRequest(id)
      .then(
        (response) => {
          dispatch(
            success(
              response,
              shutterbDashboardConstants.GET_STRIPE_CONNECTED_STATUS_REQUEST
            )
          );
        },
        (error) => {
          customToast(i18next.t("unable_to_access_server"), toast.TYPE.ERROR);
          dispatch(
            failure(error, shutterbDashboardConstants.GET_PORTFOLIO_REQUEST)
          );
        }
      )
      .finally(() => {
        // turn off the spinner
        dispatch(custom(null, globalSystemConstants.SPINNER_DIACTIVATE));
      });
  };
};

const deleteFromGallery = (data) => {
  return (dispatch) => {
    dispatch(
      custom(DELETE_FROM_GALLERY, globalSystemConstants.SPINNER_ACTIVATE)
    );
    shutterbDashboardServices
      .deleteFromGallery(data)
      .then(
        (response) => {
          dispatch(success(response, DELETE_FROM_GALLERY));
          customToast(
            i18next.t("img_successfully_deleted"),
            toast.TYPE.SUCCESS
          );
        },
        (error) => {
          customToast(
            i18next.t(error[0] === undefined ? "undefined" : error[0]),
            toast.TYPE.ERROR
          );
          dispatch(failure(error, DELETE_FROM_GALLERY));
        }
      )
      .finally(() => {
        dispatch(
          custom(DELETE_FROM_GALLERY, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};

const getGalleryData = (data) => {
  return (dispatch) => {
    dispatch(custom(GET_GALLERY_DATA, globalSystemConstants.SPINNER_ACTIVATE));
    shutterbDashboardServices
      .getGalleryData(data)
      .then(
        (response) => {
          dispatch(success(response, GET_GALLERY_DATA));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(i18next.t(error[0]), toast.TYPE.ERROR);
          dispatch(failure(error, GET_GALLERY_DATA));
        }
      )
      .finally(() => {
        dispatch(
          custom(GET_GALLERY_DATA, globalSystemConstants.SPINNER_DIACTIVATE)
        );
        dispatch(
          custom(GET_GALLERY_DATA, globalSystemConstants.OVERLAY_DIACTIVATE)
        );
      });
  };
};

const addToGallery = (
  data,
  options,
  sbUserId = 0,
  counterFilesUploaded,
  setCounterFilesUploaded = null,
  totalUploadedFileSize = null,
  setTotalFileSizeToUpload = null,
  currentFilePrevUploadedSizeRef = null,
  setCurrentFileName = "",
  setCurrentFileSize = ""
) => {
  const files = Array.from(data.entries()).filter(
    (entry) => entry[0] === "file" && entry[1] instanceof File
  );
  const extractedEventId = data.get("eventId");
  const extractedSbUserId = data.get("SbUserId");

  const images = [];
  const videos = [];

  files.forEach((file) => {
    const fileObject = file[1];
    if (fileObject.type && fileObject.type.startsWith("video/")) {
      videos.push(fileObject);
    } else {
      images.push(fileObject);
    }
  });

  setTotalFileSizeToUpload(
    calculateTotalFileSizeInMbs(videos) + calculateTotalFileSizeInMbs(images)
  );

  return async (dispatch) => {
    try {
      // Make API calls for videos sequentially
      for (const video of videos) {
        currentFilePrevUploadedSizeRef.current = 0;
        setCurrentFileName(video.name);
        setCurrentFileSize(convertBytesToMbs(video.size).toFixed(2));
        const videoFormData = new FormData();
        videoFormData.append(`file`, video);
        videoFormData.append("eventId", extractedEventId);
        videoFormData.append("SbUserId", extractedSbUserId);
        videoFormData.append("contentType", "file");

        const videoResponse = await shutterbDashboardServices.addToGallery(
          videoFormData,
          options
        );
        if (counterFilesUploaded) {
          setCounterFilesUploaded((prevCount) => prevCount + 1);
        }
        console.log("Video response:", videoResponse);
      }

      // Make API calls for images sequentially
      for (const image of images) {
        currentFilePrevUploadedSizeRef.current = 0;
        setCurrentFileName(image.name);
        setCurrentFileSize(convertBytesToMbs(image.size).toFixed(2));
        const imageFormData = new FormData();
        imageFormData.append(`file`, image);
        imageFormData.append("eventId", extractedEventId);
        imageFormData.append("SbUserId", extractedSbUserId);
        imageFormData.append("contentType", "file");

        const imageResponse = await shutterbDashboardServices.addToGallery(
          imageFormData,
          options
        );
        if (counterFilesUploaded) {
          setCounterFilesUploaded((prevCount) => prevCount + 1);
          totalUploadedFileSize += convertBytesToMbs(image.size);
        }
        console.log("Image response:", imageResponse);
      }

      // Handle success for images and videos
      sbUserId && dispatch(getReelEvents(sbUserId));
      customToast("Added to gallery successfully", toast.TYPE.SUCCESS);
      // dispatch(success(imagesResponse, ADD_TO_GALLERY));

      const object = {};
      for (let [key, value] of data.entries()) {
        if (key === "SbUserId") {
          key = "sbUserId";
          value = parseInt(value);
        }
        if (key === "eventId") {
          // value = parseInt(value);
        }
        object[key] = value;
      }
      dispatch(getGalleryData(object));
    } catch (error) {
      console.error("Error in addToGallery:", error);
      Array.isArray(error) &&
        customToast(i18next.t(error[0]), toast.TYPE.ERROR);
      dispatch(failure(error, ADD_TO_GALLERY));

      const object = {};
      for (let [key, value] of data.entries()) {
        if (key === "SbUserId") {
          key = "sbUserId";
          value = parseInt(value);
        }
        if (key === "eventId") {
        }
        object[key] = value;
      }
      dispatch(getGalleryData(object));

      throw error;
    }
  };
};

const addToProfile = (data, options) => {
  return (dispatch) => {
    dispatch(custom(ADD_TO_PROFILE, globalSystemConstants.SPINNER_DIACTIVATE));
    dispatch(custom(ADD_TO_PROFILE, globalSystemConstants.OVERLAY_ACTIVATE));
    shutterbDashboardServices
      .addToProfile(data, options)
      .then(
        (response) => {
          customToast("Added to profile successfully", toast.TYPE.SUCCESS);
          dispatch(success(response, ADD_TO_PROFILE));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(i18next.t(error[0]), toast.TYPE.ERROR);
          dispatch(failure(error, ADD_TO_PROFILE));
        }
      )
      .finally(() => {
        dispatch(
          custom(ADD_TO_PROFILE, globalSystemConstants.SPINNER_DIACTIVATE)
        );
        dispatch(
          custom(ADD_TO_PROFILE, globalSystemConstants.OVERLAY_DIACTIVATE)
        );
      });
  };
};

const isFileDeleted = () => {
  return (dispatch) => {
    dispatch(custom(null, shutterbDashboardConstants.IS_FILE_DELETED));
  };
};

const getReelEvents = (sbUserId, status) => {
  return (dispatch) => {
    dispatch(custom(null, globalSystemConstants.SPINNER_ACTIVATE));
    shutterbDashboardServices
      .getReelEvents(sbUserId, status)
      .then(
        (response) => {
          dispatch(
            success(response, shutterbDashboardConstants.GET_REEL_EVENTS)
          );
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
        }
      )
      .finally(() => {
        dispatch(custom(null, globalSystemConstants.SPINNER_DIACTIVATE));
      });
  };
};

const downloadAssets = (eventId, sbUserName) => {
  return (dispatch) => {
    dispatch(custom(null, globalSystemConstants.SPINNER_ACTIVATE));
    shutterbDashboardServices
      .downloadAssets(eventId, sbUserName)
      .then(
        (message) => {
          toast.success(i18next.t("download_files_email"), {
            autoClose: 5000,
            hideProgressBar: true,
          });
        },
        (error) => {
          Array.isArray(error) &&
            customToast(
              i18next.t(error[0] === undefined ? "undefined" : error[0]),
              toast.TYPE.ERROR
            );
        }
      )
      .finally(() => {
        dispatch(custom(null, globalSystemConstants.SPINNER_DIACTIVATE));
      });
  };
};

const addReelsToGallery = (data, options, sbUserId = 0, status) => {
  return (dispatch) => {
    dispatch(custom(ADD_TO_GALLERY, globalSystemConstants.OVERLAY_ACTIVATE));
    shutterbDashboardServices
      .addToGallery(data, options)
      .then(
        (response) => {
          // in case of reel
          dispatch(getReelEvents(sbUserId, status));
          customToast("Recap added successfully", toast.TYPE.SUCCESS);
          dispatch(setIsUpdated(true));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(i18next.t(error[0]), toast.TYPE.ERROR);
          dispatch(failure(error, ADD_TO_GALLERY));
        }
      )
      .finally(() => {
        dispatch(
          custom(ADD_TO_GALLERY, globalSystemConstants.OVERLAY_DIACTIVATE)
        );
      });
  };
};

const getReelDetails = (eventId) => {
  return (dispatch) => {
    dispatch(custom(GET_REEL_DETAILS, globalSystemConstants.SPINNER_ACTIVATE));
    shutterbDashboardServices
      .getReelDetails(eventId)
      .then(
        (response) => {
          // in case of reel
          dispatch(success(response, GET_REEL_DETAILS));
        },
        (error) => {
          Array.isArray(error) &&
            customToast(i18next.t(error[0]), toast.TYPE.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(GET_REEL_DETAILS, globalSystemConstants.SPINNER_DIACTIVATE)
        );
      });
  };
};

const setIsUpdated = (data) => {
  return (dispatch) => {
    dispatch(custom(data, IS_UPDATED));
  };
};

// exporting all shutterb dashboard actions
export const shutterbDashboardActions = {
  eventRequests,
  updateEventRequest,
  sbMyEvents,
  updateSbMyEventsStatus,
  mySchedule,
  updateMySchedule,
  myPortfolio,
  deleteImg,
  updateMyPortfolio,
  myInfo,
  updateMyInfo,
  stripeConnection,
  stripeConnectedStatus,
  deleteFromGallery,
  getGalleryData,
  addToGallery,
  addToProfile,
  isFileDeleted,
  getReelEvents,
  downloadAssets,
  addReelsToGallery,
  getReelDetails,
  getEventDetailByEventId,
  setIsUpdated,
};
