import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import App from "./container/App/App";
import withHttpsRedirect from "./container/App/withHttpsRedirect";
import store from "./system/store";
import ErrorBoundary from "./shared/components/ErrorBoundary/ErrorBoundary";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN_KEY,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.3,
  replaysOnErrorSampleRate: 1.0,
});

const HttpsRedirectedApp = withHttpsRedirect(App);

ReactDOM.render(
  <React.Fragment>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <ToastContainer />
      {/* Main Redux Store */}
      <Provider store={store}>
        <ErrorBoundary>
          <HttpsRedirectedApp />
        </ErrorBoundary>
      </Provider>
    </GoogleOAuthProvider>
  </React.Fragment>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
