import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter} from "react-router-dom";
import Balancer from "react-wrap-balancer";

import {
  configurationsConstants,
  eventBookingConstants,
} from "../../../../constants";

const EventDetail = ({ onNext, onBack, ...props }) => {
  const { TABS, ADD_EVENT_DETAIL } = eventBookingConstants;
  const {
    eventQuestion1,
    eventQuestion2,
    eventQuestion3,
    eventQuestion4,
  } = props.addEventDetail;

  const handleSubmit = (e) => {
    e.preventDefault();
    // window.dataLayer.push({
    //   event: "site_action",
    //   action: "recap_reel_radio",
    // });
    onNext();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem("activeTab", TABS.EVENT_DETAIL);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="container mt-5 event-booking-add-reel">
      <div className="row">
        <div className="col-sm-10 m-auto">
          <form onSubmit={handleSubmit}>
            <div>
              <h1 className="text-center sub-heading">
                <Balancer>event details</Balancer>
              </h1>
              <p className="text-center">
                <Balancer>
                  help us get a few more details to ensure we know what you want
                  to have captured.
                </Balancer>
              </p>
            </div>
            {/* questions */}
            <div>
              <div>
                <h5>What are you using this event content for?</h5>
                <p>
                  Is it for personal use, a business, a brand? How will it be
                  shared ie. Instagram, Facebook, YouTube, Digital Album, etc.
                </p>
                <textarea
                  name="question1"
                  id="question1"
                  maxLength={configurationsConstants.TEXTAREA_INPUT_LENGTH}
                  required
                  cols="30"
                  rows="3"
                  value={eventQuestion1}
                  onChange={(e) => {
                    props.setEventDetailValues({
                      name: ADD_EVENT_DETAIL.EVENT_QUESTION_ONE,
                      value: e.target.value,
                    });
                  }}
                ></textarea>
              </div>
              <div>
                <h5>What special things are happening you want captured?</h5>
                <p>
                  What are all of the show moments you have planned for this
                  event ie. entertainment, activations, food, decor, etc.
                </p>
                <textarea
                  name="question2"
                  id="question2"
                  maxLength={configurationsConstants.TEXTAREA_INPUT_LENGTH}
                  required
                  cols="30"
                  rows="3"
                  value={eventQuestion2}
                  onChange={(e) => {
                    props.setEventDetailValues({
                      name: ADD_EVENT_DETAIL.EVENT_QUESTION_TWO,
                      value: e.target.value,
                    });
                  }}
                ></textarea>
              </div>
              <div>
                <h5>Guests we should, and should not, focus on</h5>
                <p>
                  Let us know here, but also be sure to point them out to your
                  shutterb when they arrive!
                </p>
                <textarea
                  name="question3"
                  id="question3"
                  maxLength={configurationsConstants.TEXTAREA_INPUT_LENGTH}
                  cols="30"
                  rows="3"
                  value={eventQuestion3}
                  onChange={(e) => {
                    props.setEventDetailValues({
                      name: ADD_EVENT_DETAIL.EVENT_QUESTION_THREE,
                      value: e.target.value,
                    });
                  }}
                ></textarea>
              </div>
              <div>
                <h5>Brand Guidelines and Legal Requirements (optional)</h5>
                <p>
                  If you are a company and there are specific rules around your
                  brand for example how the brand shows up, legal watch outs,
                  etc.
                </p>
                <textarea
                  name="question4"
                  id="question4"
                  maxLength={configurationsConstants.TEXTAREA_INPUT_LENGTH}
                  cols="30"
                  rows="3"
                  value={eventQuestion4}
                  onChange={(e) => {
                    props.setEventDetailValues({
                      name: ADD_EVENT_DETAIL.EVENT_QUESTION_FOUR,
                      value: e.target.value,
                    });
                  }}
                ></textarea>
              </div>
            </div>

            {/* continue booking btn */}
            <div className="text-center show_continue_booking_shutterb h-auto d-flex justify-content-center align-items-center">
              <button type="submit" className="btn addToCart">
                Continue Booking
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* back btn */}

      <div className="row pt-4 ml-2">
        <div className="event-booking-form-btn col-sm-10 m-auto px-0">
          <button type="submit" className="back-btn" onClick={onBack}>
            Back
          </button>
        </div>
      </div>
    </div>
  );
};
// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  addEventDetail: state.eventBooking.addEventDetail,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  setEventDetailValues: (dataObj) =>
    dispatch({ type: eventBookingConstants.SET_EVENT_DETAIL_VALUES, data: dataObj }),
});

// mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EventDetail));
