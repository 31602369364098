import React, { useState } from "react";
import { useEffect } from "react";

const SbList = (props) => {
  /* eslint-disable */
  const { selectedSbIds, setSelectedSbIds } = props;
  let { sb } = props;
  const [isCustomSelected, setIsCustomSelected] = useState(false);
  const [selectedPrice, setSelectedPrice] = useState(5);

  useEffect(() => {
    if (sb.tipPrice_) {
      sb = { ...sb };
    }
  }, []);
  useEffect(() => {
    if (sb.hasOwnProperty("tipPrice_")) {
      sb.tipPrice_ = selectedPrice ? selectedPrice : 0;
    }
  }, [selectedPrice]);

  const handlePriceChange = (e, sbId) => {
    e.target.name.includes("customPrice") && e.target.value.length > 0
      ? setIsCustomSelected(() => true)
      : setIsCustomSelected(() => false);
    setSelectedPrice(e.target.value);
  };
  const selectSb = (e) => {
    if (selectedSbIds.includes(sb?.sbUser?.id)) {
      setSelectedSbIds(selectedSbIds.filter((id) => id !== sb?.sbUser?.id));
    } else {
      setSelectedSbIds([...selectedSbIds, sb?.sbUser?.id]);
      sb.tipPrice_ = Number(selectedPrice);
    }
  };
  return (
    <div className="py-md-4 sb-list-item d-flex justify-content-start mb-3 row">
      <div className="col-md-4 col-xl-2 col-lg-3 p-0 m-0">
        <div className="profile-img-con">
          <label
            className="profile-img"
            htmlFor={sb?.sbUser?.firstName + sb?.sbUser?.id}
          >
            <img
              src={
                process.env.REACT_APP_S3_BASE_URL_PORTFOLIO +
                "/" +
                sb?.sbUser?.sbPortfolios[0]?.profileImageUrl
              }
              alt=""
            />
          </label>
          <input
            type="checkbox"
            name={sb?.sbUser?.firstName + sb?.sbUser?.id}
            id={sb?.sbUser?.firstName + sb?.sbUser?.id}
            onChange={selectSb}
          />
        </div>
      </div>
      <div className="col-md-8 col-xl-10 col-lg-9 p-0 m-0">
        <h6>{sb?.sbUser?.firstName + " " + sb?.sbUser?.lastName}</h6>
        <div className="col-md-11 col-lg-7 pl-0">
          <div className="d-block d-xs-flex sb-price-group">
            <input
              type="radio"
              id={"defaultPrice5" + sb?.sbUser?.id}
              name={"defaultPrice" + sb?.sbUser?.id}
              value={"5"}
              defaultChecked={!isCustomSelected}
              disabled={isCustomSelected}
              onChange={(e) => handlePriceChange(e, sb?.sbUser?.id)}
            />
            <label
              disabled={isCustomSelected}
              className={
                isCustomSelected ? "disabled-radio bg-transparent" : ""
              }
              htmlFor={"defaultPrice5" + sb?.sbUser?.id}
            >
              $5
            </label>
            <input
              type="radio"
              id={"defaultPrice10" + sb?.sbUser?.id}
              name={"defaultPrice" + sb?.sbUser?.id}
              value="10"
              disabled={isCustomSelected}
              onChange={(e) => handlePriceChange(e, sb?.sbUser?.id)}
            />
            <label
              disabled={isCustomSelected}
              htmlFor={"defaultPrice10" + sb?.sbUser?.id}
              className={
                isCustomSelected ? "disabled-radio bg-transparent" : ""
              }
            >
              $10
            </label>
            <input
              type="radio"
              id={"defaultPrice15" + sb?.sbUser?.id}
              name={"defaultPrice" + sb?.sbUser?.id}
              value="15"
              disabled={isCustomSelected}
              onChange={(e) => handlePriceChange(e, sb?.sbUser?.id)}
            />
            <label
              disabled={isCustomSelected}
              htmlFor={"defaultPrice15" + sb?.sbUser?.id}
              className={
                isCustomSelected ? "disabled-radio bg-transparent" : ""
              }
            >
              $15
            </label>
            <input
              type="radio"
              id={"defaultPrice20" + sb?.sbUser?.id}
              name={"defaultPrice" + sb?.sbUser?.id}
              value="20"
              disabled={isCustomSelected}
              onChange={(e) => handlePriceChange(e, sb?.sbUser?.id)}
            />
            <label
              disabled={isCustomSelected}
              htmlFor={"defaultPrice20" + sb?.sbUser?.id}
              className={
                isCustomSelected ? "disabled-radio bg-transparent" : ""
              }
            >
              $20
            </label>
          </div>
          <input
            type="number"
            name={"customPrice" + sb?.sbUser?.id}
            id={"customPrice" + sb?.sbUser?.id}
            placeholder="Enter Custom Amount"
            className="w-100"
            min="3"
            max="1000"
            onChange={(e) => handlePriceChange(e, sb?.sbUser?.id)}
          />
        </div>
      </div>
    </div>
  );
};

export default SbList;
