export const GLOBAL_STATUS = {
  SUCCESS: 0,
  ERROR: 1,
  UNAUTHORIZED: 2,
};

export const SERVER_TRANSLATION_KEYS = [
  "Invalid Login",
  "unable_to_access_server",
  "internal_server_error",
  "invalid_registration_image_count",
  "something_went_wrong",
  "email_sent_success",
  "email_sent_failiure",
  "user_already_taken",
  "email_is_taken",
  "invalid_question_object",
  "duplicate_value_exist",
  "object_not_exist",
  "something_went_wrong",
  "user_already_exists",
];
