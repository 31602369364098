import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import MyEventsTab from "./CustomerDashboardTabs/MyEventsTab/MyEventsTab";
import MyAccountTab from "./CustomerDashboardTabs/MyAccountTab/MyAccountTab";
import AttendedEventsTab from "./CustomerDashboardTabs/AttendedEventsTab";
import ReferAFriend from "./CustomerDashboardTabs/ReferAFriend";
import Support from "./CustomerDashboardTabs/Support";
import { useParams, useHistory } from "react-router-dom";
import { NavigateToPath } from "../../../system";
import { customerConstants } from "../../../constants";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const CustomerDashboard = () => {
  let { tab } = useParams();
  let history = useHistory();
  const [value, setValue] = React.useState(
    tab ? tab : customerConstants.DEFAULT_TAB_NUMBER
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    setValue(
      tab
        ? tab === customerConstants.MY_EVENT
          ? customerConstants.MY_EVENTS_TAB_NUMBER
          : tab === customerConstants.MY_ACCOUNT
          ? customerConstants.MY_ACCOUNT_TAB_NUMBER
          : tab === customerConstants.ATTENDED_EVENTS
          ? customerConstants.ATTENDED_EVENTS_TAB_NUMBER
          : tab === customerConstants.REFER_A_FRIEND
          ? customerConstants.REFER_A_FRIEND_TAB_NUMBER
          : tab === customerConstants.SUPPORT
          ? customerConstants.SUPPORT_TAB_NUMBER
          : customerConstants.DEFAULT_TAB_NUMBER
        : customerConstants.DEFAULT_TAB_NUMBER
    );
  }, [tab]);
  //   Tabs MUI Functionality Ends

  return (
    <div className="container-fluid">
      <Box className="row my-5 px-2 px-md-5 customer-dashboard-con custom-min-height">
        <Box className="col-12 col-md-6 col-lg-3 col-xl-3 mx-auto">
          <Tabs
            value={value}
            onChange={handleChange}
            orientation="vertical"
            centered="true"
            aria-label="basic evnet tabs"
          >
            <Tab
              className="customer-dashboard-tab-btn m-auto m-md-0 align-items-center mw-160"
              label="MY EVENTS"
              {...a11yProps(customerConstants.MY_EVENTS_TAB_NUMBER)}
              onClick={() => {
                NavigateToPath(
                  history,
                  `/CustomerDashboard_/${customerConstants.MY_EVENT}`
                );
              }}
            />

            <Tab
              className="customer-dashboard-tab-btn m-auto m-md-0 align-items-center mw-160"
              label="MY ACCOUNT"
              {...a11yProps(customerConstants.MY_ACCOUNT_TAB_NUMBER)}
              onClick={() => {
                NavigateToPath(
                  history,
                  `/CustomerDashboard_/${customerConstants.MY_ACCOUNT}`
                );
              }}
            />

            <Tab
              className="customer-dashboard-tab-btn m-auto m-md-0 align-items-center mw-160"
              label="ATTENDED EVENTS"
              {...a11yProps(customerConstants.ATTENDED_EVENTS_TAB_NUMBER)}
              onClick={() => {
                NavigateToPath(
                  history,
                  `/CustomerDashboard_/${customerConstants.ATTENDED_EVENTS}`
                );
              }}
            />

            <Tab
              className="customer-dashboard-tab-btn m-auto m-md-0 align-items-center mw-160"
              label="REFER A FRIEND"
              {...a11yProps(customerConstants.REFER_A_FRIEND_TAB_NUMBER)}
              onClick={() => {
                NavigateToPath(
                  history,
                  `/CustomerDashboard_/${customerConstants.REFER_A_FRIEND}`
                );
              }}
            />

            <Tab
              className="customer-dashboard-tab-btn m-auto m-md-0 align-items-center mw-160"
              label="SUPPORT"
              {...a11yProps(customerConstants.SUPPORT_TAB_NUMBER)}
              onClick={() => {
                NavigateToPath(
                  history,
                  `/CustomerDashboard_/${customerConstants.SUPPORT}`
                );
              }}
            />
          </Tabs>
        </Box>

        <Box className="col-12 col-md-12 col-lg-9 col-xl-9 mx-auto mr-md-auto py-0 tab-pannel ">
          <TabPanel value={value} index={0} className="position-relative pt-3">
            <MyEventsTab />
          </TabPanel>

          <TabPanel value={value} index={1}>
            <MyAccountTab />
          </TabPanel>

          <TabPanel value={value} index={2}>
            <AttendedEventsTab />
          </TabPanel>

          <TabPanel value={value} index={3}>
            <ReferAFriend />
          </TabPanel>

          <TabPanel value={value} index={4}>
            <Support />
          </TabPanel>
        </Box>
      </Box>
    </div>
  );
};

export default CustomerDashboard;
