import { GLOBAL_STATUS } from "../system/global-enums";

export const shutterbRegisterConstants = {
  shutterbRegister: "Shutterb Register",
  SHUTTERB_REGISTER_STATUS: {
    ...GLOBAL_STATUS,
  },
};

export const shutterbLoginConstants = {
  shutterbLogin: "Shutterb Login",
  SHUTTERB_LOGIN_STATUS: {
    ...GLOBAL_STATUS,
  },
};

export const shutterbDashboardConstants = {
  GET_EVENT_REQUEST: "Get Event Request",
  PUT_EVENT_REQUEST: "PUT Event Request",
  GET_SB_MY_EVENTS: "Get Shutterb My Events",
  PUT_SB_MY_EVENTS_STATUS: "Put Shutterb My Events Status",
  GET_PORTFOLIO_REQUEST: "Get Portfolio Request",
  DELETE_IMG_REQUEST: "Delete Img Request",
  GET_MY_INFO_REQUEST: "Get My Info",
  GET_STRIPE_CONNECTED_STATUS_REQUEST: "Get Stripe Connected Status",
  GET_GALLERY_DATA: "GET_GALLERY_DATA",
  DELETE_FROM_GALLERY: "DELETE_FROM_GALLERY",
  ADD_TO_GALLERY: "ADD_TO_GALLERY",
  IS_UPDATED: "IS_UPDATED",
  ADD_TO_PROFILE: "ADD_TO_PROFILE",
  IS_FILE_DELETED: "IS_FILE_DELETED",
  GET_REEL_EVENTS: "GET_REEL_EVENTS",
  CONTENT_TYPE_REEL: "reel",
  CHECK_IN: "checkIn",
  CHECK_OUT: "checkOut",
  CREATED: "created",
  PREVIEW: "preview",
  KB_TO_MB_BYTES: 1048576, //1024 * 1024 = 1048576
  SB_PORTFOLIO_MAX_IMAGE_COUNT: 12,
  EVENT_REQUESTS: "EventRequests",
  MY_EVENTS: "MyEvents",
  REEL_EVENTS: "ReelEvents",
  MY_PROFILE: "MyProfile",
  EVENT_REQUESTS_TAB_NUMBER: 0,
  MY_EVENTS_TAB_NUMBER: 1,
  REEL_EVENTS_TAB_NUMBER: 2,
  MY_PROFILE_TAB_NUMBER: 3,
  MY_SCHEDULE: "MySchedule",
  MY_PORTFOLIO: "MyPortfolio",
  MY_INFO: "MyInfo",
  MY_SCHEDULE_TAB_NUMBER: 0,
  MY_PORTFOLIO_TAB_NUMBER: 1,
  MY_INFO_TAB_NUMBER: 2,
  DEFAULT_TAB_NUMBER: 0,
  GET_REEL_DETAILS: "GET_REEL_DETAILS",
  GET_EVENT_DETAIL_BY_ID: "GET_EVENT_DETAIL_BY_ID",
  SHUTTERB: "shutterb",
  UPLOAD_IMAGE_URL:
    "https://shutter-bee-dev.customerdevsites.com/wp-content/uploads/2021/12/uploadd.jpg",
};

export const daysInWeek = {
  Sunday: "0",
  Monday: "1",
  Tuesday: "2",
  Wednesday: "3",
  Thursday: "4",
  Friday: "5",
  Saturday: "6",
};

export const slotsInDay = [
  "08:00 AM - 10:59 AM",
  "11:00 AM - 01:59 PM",
  "02:00 PM - 04:59 PM",
  "05:00 PM - 06:59 PM",
  "07:00 PM - 09:59 PM",
  "10:00 PM - 11:59 PM",
  "12:00 AM - 02:00 AM",
];

export const timesOfTheDay = [
  "Morning:",
  "Afternoon:",
  "Evening:",
  "Night:",
  "Late Night:",
];

const timeSlots = [
  {
    slotId: 0,
    startTime: "08:00",
    endTime: "12:00",
    isEnable: true,
  },
  {
    slotId: 1,
    startTime: "12:00",
    endTime: "16:00",
    isEnable: true,
  },
  {
    slotId: 2,
    startTime: "16:00",
    endTime: "20:00",
    isEnable: true,
  },
  {
    slotId: 3,
    startTime: "20:00",
    endTime: "23:59",
    isEnable: true,
  },
  {
    slotId: 4,
    startTime: "00:00",
    endTime: "02:00",
    isEnable: true,
  },
];

export const initialWorkingSchedules = [
  {
    dayInWeek: 0,
    timeSlots,
    isActive: true,
  },
  {
    dayInWeek: 1,
    timeSlots,
    isActive: true,
  },
  {
    dayInWeek: 2,
    timeSlots,
    isActive: true,
  },
  {
    dayInWeek: 3,
    timeSlots,
    isActive: true,
  },
  {
    dayInWeek: 4,
    timeSlots,
    isActive: true,
  },
  {
    dayInWeek: 5,
    timeSlots,
    isActive: true,
  },
  {
    dayInWeek: 6,
    timeSlots,
    isActive: true,
  },
];

export const ALLOWED_PORTFOLIO_FORMATS = [
  "jpg",
  "png",
  "jpeg",
  "jfif",
  "bmp",
  "webp",
  "svg",
  "heic",
  "HEIC",
  ".heic",
  "image/heic",
  "heif",
  "HEIF",
  ".heif",
  "image/heif",
];
