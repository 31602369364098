import { updateObject } from "../../shared/utility";
import { configurationsConstants } from "../../constants";

// getting the default actions name from defined constants via decomposition
const { GET_POSTAL_CODES } = configurationsConstants;

// default state
const initialState = {
  postalCodesList: [],
};

// manupilating the state based on actions and returning the state
export const configurations = (state = initialState, action) => {
  switch (action.type) {
    case GET_POSTAL_CODES:
      const codesList = action.data?.items?.map((item) => item.code);
      return updateObject(state, {
        postalCodesList: codesList,
      });
    default:
      return state;
  }
};
