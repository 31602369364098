import { postRequest } from "../system/server-call";
import responseHandler from "../system/server-response-handler";
import {
  shutterbRegisterConstants,
  shutterbLoginConstants,
} from "../constants";
import {
  clearStorage,
  encryptObject,
  setItemToStorage,
  removeItemFromStorage,
} from "../system";

/**
 * The Auth Service
 *
 * This is used for getting data from server and handling the business logic on that data
 */

// authenticating the user from server

// Shutterb login and logout
function shutterbLogout() {
  removeItemFromStorage(process.env.REACT_APP_PAYLOAD_SB);
  clearStorage();
  return Promise.resolve();
}

function handleShutterbLoginResponse(response) {
  const { status } = response.data;

  if (status !== shutterbLoginConstants.SHUTTERB_LOGIN_STATUS.SUCCESS) {
    shutterbLogout();
    return responseHandler.handleServerResponse(response);
  }

  const ciphertext = encryptObject({
    ...response.data.payload,
  });

  setItemToStorage(process.env.REACT_APP_PAYLOAD, ciphertext);
  return response.data.payload;
}

function shutterbLogin(userData) {
  return postRequest("/api/v1/login/SBUser", userData, false).then(
    (response) => handleShutterbLoginResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

// Shutterb Registration

const handleShutterbRegisterResponse = (response) => {
  const { status } = response.data;
  if (status !== shutterbRegisterConstants.SHUTTERB_REGISTER_STATUS.SUCCESS) {
    return responseHandler.handleServerResponse(response);
  }
  return response.data.payload;
};

function shutterbRegister(userData) {
  return postRequest(
    "/api/v1/register/SBUserRegistration",
    userData,
    false
  ).then(
    (response) => handleShutterbRegisterResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}
// exporting functions for other files
export const shutterbService = {
  shutterbRegister,
  shutterbLogin,
};
