import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MapMarker from "mdi-react/MapMarkerIcon";
import { customToast } from "../../../../../shared";
import { AvForm, AvField } from "availity-reactstrap-validation";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

import { shutterbDashboardActions } from "../../../../../redux/actions";
import { configurationsConstants } from "../../../../../constants";

const MyInfo = (props) => {
  const { ...sbMyInfo } = props.sbMyInfo;
  const { t } = useTranslation("common");
  //let latLng = "";

  const [address, setAddress] = useState(null);
  const [latLng, setLatLng] = useState(null);
  const [autocompleteSuggestions, setAutocompleteSuggestions] = useState([]);

  const handleSelect = async (selectedAddress) => {
    setAddress(selectedAddress);
    try {
      const results = await geocodeByAddress(selectedAddress);
      // eslint-disable-next-line
      const coordinates = await getLatLng(results[0]);
      setLatLng(coordinates);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (
      address &&
      autocompleteSuggestions &&
      autocompleteSuggestions.some(
        (suggestion) => suggestion.description === address
      )
    ) {
      handleSelect(address);
    } else {
      setLatLng(null);
    }

    // eslint-disable-next-line
  }, [address]);

  useEffect(() => {
    setLatLng({
      lat: sbMyInfo.location[0]?.latitude,
      lng: sbMyInfo.location[0]?.longitude,
    });
    // eslint-disable-next-line
  }, [sbMyInfo.location[0]?.latitude]);

  const handleSubmit = (event, values) => {
    if (!latLng) {
      customToast("Invalid Location", toast.TYPE.ERROR);
      return;
    }
    // Structuring request payload
    const dataObj = {
      id: props.sbUserId,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phoneNumber: values.phoneNumber,
      emergencyContactName: values.emergencyContactName,
      emergencyContactNumber: values.emergencyContactNumber,
      hstNumber: values.hstNumber,
      Location: [
        {
          id: sbMyInfo.location[0].id,
          sbUserId: props.sbUserId,
          currentLocation: values.sbLocation,
          latitude: latLng.lat,
          longitude: latLng.lng,
        },
      ],
    };
    props.updateMyInfo(props.sbUserId, dataObj);
  };

  // Stripe Backend Call
  const stripeConnect = () => {
    props.getStripeConnected(props.sbUserId);
  };
  useEffect(() => {
    props.getStripeConnectedStatus(props.sbUserId);
    props.getMyInfo(props.sbUserId);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="shutterb-dashboard-my-portfolio">
      <div className="container">
        <p>{t("my_info_heading_msg")}</p>
      </div>

      <AvForm
        id="my-info-form"
        className="my-info"
        onValidSubmit={handleSubmit}
        noValidate
      >
        <div id="form-content">
          <div className="row">
            <div className="col-lg-12">
              <label htmlFor="firstName">{t("first_name")}</label>
              <AvField
                name="firstName"
                value={sbMyInfo?.firstName}
                type="text"
                maxLength={35}
                pattern="^[a-z,A-Z].{0,35}$"
                validate={{
                  required: {
                    value: true,
                    errorMessage: t("this_field_is_required"),
                  },
                }}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <label htmlFor="lastName">{t("last_name")}</label>
              <AvField
                name="lastName"
                value={sbMyInfo?.lastName}
                type="text"
                maxLength={35}
                pattern="^[a-z,A-Z].{0,35}$"
                validate={{
                  required: {
                    value: true,
                    errorMessage: t("this_field_is_required"),
                  },
                }}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <label htmlFor="email">{t("email_address")}</label>
              <AvField
                name="email"
                value={sbMyInfo?.email}
                type="email"
                disabled
                maxLength={configurationsConstants.EMAIL_INPUT_LENGTH}
                validate={{
                  required: {
                    value: true,
                    errorMessage: t("this_field_is_required"),
                  },
                }}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <label htmlFor="sbLocation">{t("home_address")}</label>
              <div className="places-autocomplete-container">
                <PlacesAutocomplete
                  value={
                    address === null
                      ? sbMyInfo.location[0]?.currentLocation
                      : address
                  }
                  onChange={setAddress}
                  onSelect={handleSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => {
                    if (suggestions.length > 0) {
                      setAutocompleteSuggestions(suggestions);
                    }
                    return (
                      <div>
                        <AvField
                          placeholder={t("enter_a_location")}
                          name="sbLocation"
                          type="address"
                          value={address}
                          validate={{
                            required: {
                              value: true,
                              errorMessage: t("this_field_is_required"),
                            },
                          }}
                          {...getInputProps()}
                        />
                        <div className="address-suggestions-con px-2">
                          {loading ? <div>{t("loading")}</div> : null}
                          {suggestions.map((suggestion, index) => {
                            const style = {
                              backgroundColor: suggestion.active
                                ? "#eaeaea"
                                : "#fff",
                            };
                            return (
                              <>
                                <div
                                  className="address-suggestions"
                                  key={index}
                                  {...getSuggestionItemProps(suggestion, {
                                    style,
                                  })}
                                >
                                  <MapMarker className="map-icon" />
                                  {suggestion.description}
                                </div>
                                <hr className="p-0 m-0" />
                              </>
                            );
                          })}
                          {suggestions.length > 0 && (
                            <div className="google-trademark p-0 m-0">
                              powered by
                              <div className="google-svg-con">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  height="250"
                                  width="400"
                                  viewBox="-40.446 -22.19 350.532 133.14"
                                >
                                  <path
                                    d="M115.39 46.71c0 12.77-9.99 22.18-22.25 22.18s-22.25-9.41-22.25-22.18c0-12.86 9.99-22.18 22.25-22.18s22.25 9.32 22.25 22.18zm-9.74 0c0-7.98-5.79-13.44-12.51-13.44s-12.51 5.46-12.51 13.44c0 7.9 5.79 13.44 12.51 13.44s12.51-5.55 12.51-13.44z"
                                    fill="#EA4335"
                                  />
                                  <path
                                    d="M163.39 46.71c0 12.77-9.99 22.18-22.25 22.18s-22.25-9.41-22.25-22.18c0-12.85 9.99-22.18 22.25-22.18s22.25 9.32 22.25 22.18zm-9.74 0c0-7.98-5.79-13.44-12.51-13.44s-12.51 5.46-12.51 13.44c0 7.9 5.79 13.44 12.51 13.44s12.51-5.55 12.51-13.44z"
                                    fill="#FBBC05"
                                  />
                                  <path
                                    d="M209.39 25.87v39.82c0 16.38-9.66 23.07-21.08 23.07-10.75 0-17.22-7.19-19.66-13.07l8.48-3.53c1.51 3.61 5.21 7.87 11.17 7.87 7.31 0 11.84-4.51 11.84-13v-3.19h-.34c-2.18 2.69-6.38 5.04-11.68 5.04-11.09 0-21.25-9.66-21.25-22.09 0-12.52 10.16-22.26 21.25-22.26 5.29 0 9.49 2.35 11.68 4.96h.34v-3.61h9.25zm-8.56 20.92c0-7.81-5.21-13.52-11.84-13.52-6.72 0-12.35 5.71-12.35 13.52 0 7.73 5.63 13.36 12.35 13.36 6.63 0 11.84-5.63 11.84-13.36z"
                                    fill="#4285F4"
                                  />
                                  <path
                                    d="M224.64 2.53v65h-9.5v-65z"
                                    fill="#34A853"
                                  />
                                  <path
                                    d="M261.66 54.01l7.56 5.04c-2.44 3.61-8.32 9.83-18.48 9.83-12.6 0-22.01-9.74-22.01-22.18 0-13.19 9.49-22.18 20.92-22.18 11.51 0 17.14 9.16 18.98 14.11l1.01 2.52-29.65 12.28c2.27 4.45 5.8 6.72 10.75 6.72 4.96 0 8.4-2.44 10.92-6.14zm-23.27-7.98l19.82-8.23c-1.09-2.77-4.37-4.7-8.23-4.7-4.95 0-11.84 4.37-11.59 12.93z"
                                    fill="#EA4335"
                                  />
                                  <path
                                    d="M34.93 40.94v-9.41h31.71c.31 1.64.47 3.58.47 5.68 0 7.06-1.93 15.79-8.15 22.01-6.05 6.3-13.78 9.66-24.02 9.66C15.96 68.88 0 53.42 0 34.44 0 15.46 15.96 0 34.94 0c10.5 0 17.98 4.12 23.6 9.49l-6.64 6.64c-4.03-3.78-9.49-6.72-16.97-6.72-13.86 0-24.7 11.17-24.7 25.03 0 13.86 10.84 25.03 24.7 25.03 8.99 0 14.11-3.61 17.39-6.89 2.66-2.66 4.41-6.46 5.1-11.65z"
                                    fill="#4285F4"
                                  />
                                </svg>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  }}
                </PlacesAutocomplete>
              </div>
            </div>
          </div>

          <div className="row pt-2">
            <div className="col-lg-12">
              <label htmlFor="phoneNumber">{t("primary_phone_number")}</label>
              <AvField
                name="phoneNumber"
                type="number"
                value={props.sbMyInfo.phoneNumber}
                pattern="^[0-9].{8,9}$"
                validate={{
                  required: {
                    value: true,
                    errorMessage: t("this_field_is_required"),
                  },
                }}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <label htmlFor="emergencyContactName">
                {t("emergency_contact_name")}
              </label>
              <AvField
                name="emergencyContactName"
                value={props?.sbMyInfo?.emergencyContactName ?? ""}
                type="text"
                maxLength={35}
                pattern="^[a-z,A-Z].{0,35}$"
                validate={{
                  required: {
                    value: true,
                    errorMessage: t("this_field_is_required"),
                  },
                }}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <label htmlFor="emergencyContactNumber">
                {t("emergency_contact_number")}
              </label>
              <AvField
                name="emergencyContactNumber"
                value={props?.sbMyInfo?.emergencyContactNumber ?? null}
                type="number"
                pattern="^[0-9].{8,9}$"
                validate={{
                  required: {
                    value: true,
                    errorMessage: t("this_field_is_required"),
                  },
                }}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <label htmlFor="hstNumber">
                {t("If registered for HST, please enter your HST number here")}
              </label>
              <AvField
                type="text"
                maxLength={40}
                name="hstNumber"
                pattern="^[a-zA-Z0-9]+$"
                errorMessage="This field is invalid"
                value={props?.sbMyInfo?.hstNumber ?? null}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <strong>{t("stripe_btn_msg")}</strong>
            </div>
          </div>

          <div className="container">
            <div className="row pt-4">
              <div className="event-booking-form-btn shutterb-portfolio-btn">
                {props.isStripeConnectSubmitted ? (
                  props.isStripeConnectStatus ? (
                    <button
                      type="button"
                      className="btn btn-not-allowed"
                      disabled="true"
                    >
                      {t("connected")}
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-not-allowed"
                      disabled="true"
                    >
                      Pending
                    </button>
                  )
                ) : (
                  <button
                    type="button"
                    className="btn"
                    onClick={(event) => {
                      stripeConnect();
                      event.target.disabled = "true";
                    }}
                  >
                    Connect
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row pt-4">
              <div className="event-booking-form-btn shutterb-portfolio-btn">
                <button type="submit" className="btn-simple">
                  {t("save")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </AvForm>
    </div>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  sbMyInfo: state.shutterbDashboard.sbMyInfo,
  sbUserId: state.shutterbAuthentication.sbUserId,
  isStripeConnectSubmitted: state.shutterbDashboard.isStripeConnectSubmitted,
  isStripeConnectStatus: state.shutterbDashboard.isStripeConnectStatus,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getMyInfo: (id) => dispatch(shutterbDashboardActions.myInfo(id)),
  updateMyInfo: (id, dataObj) =>
    dispatch(shutterbDashboardActions.updateMyInfo(id, dataObj)),
  getStripeConnected: (id) =>
    dispatch(shutterbDashboardActions.stripeConnection(id)),
  getStripeConnectedStatus: (id) =>
    dispatch(shutterbDashboardActions.stripeConnectedStatus(id)),
});

// mapping action and store the function via props
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MyInfo));
