import React, { useEffect, useState } from "react";
import { withRouter, useHistory } from "react-router-dom/cjs/react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

import { customerDashboardActions } from "../../../../../../../../redux/actions";
import SbList from "./SbList";
import {
  customToast,
  convertDateToUTC,
  ClearReelStorage,
} from "../../../../../../../../shared/utility";
import {
  customerConstants,
  eventBookingConstants,
} from "../../../../../../../../constants";
import { NavigateToPath } from "../../../../../../../../system";

const TipYourSb = (props) => {
  const { ...myEventsTipSb } = props?.myEventsTipSb ?? {};
  const { eventId } = useParams();
  const [selectedSbIds, setSelectedSbIds] = useState([]);
  const history = useHistory();
  const { t } = useTranslation("common");

  const handleFormSub = (e) => {
    e.preventDefault();
    ClearReelStorage();
    const selectedSb = myEventsTipSb?.orders[0].orderItems.filter((sb) => {
      return selectedSbIds.includes(sb.sbuserId);
    });

    // handling submission
    if (selectedSb.length === 0) {
      customToast(t("please_select_shutterb"), toast.TYPE.ERROR);
      return;
    }
    props.addToCustomerDashboardCart([...selectedSb]);
    localStorage.setItem("activeTab", eventBookingConstants.TABS.CART);
    if (
      localStorage.getItem(customerConstants.CART_PREVIOUS_SCREEN) ===
      customerConstants.EXTEND_TO_CART
    ) {
      localStorage.setItem(
        customerConstants.CART_PREVIOUS_SCREEN,
        customerConstants.EXTEND_AND_TIP_TO_CART
      );
    } else if (
      localStorage.getItem(customerConstants.CART_PREVIOUS_SCREEN) !==
      customerConstants.EXTEND_AND_TIP_TO_CART
    ) {
      localStorage.setItem(
        customerConstants.CART_PREVIOUS_SCREEN,
        customerConstants.TIP_TO_CART
      );
    }
    localStorage.setItem(customerConstants.NOT_NEW_EVENT, JSON.stringify(true));
    NavigateToPath(history, "/event_booking");
    // props.extendSbCheckout(
    //   myEventsTipSb?.id,
    //   dataObj,
    //   customerConstants.TIP_TO_CART,
    //   history
    // );
  };
  // const handlePriceChange = (e) => {};

  useEffect(() => {
    props.getMyEventsTipSb(eventId, props.userId);
    // eslint-disable-next-line
  }, []);

  // disable the sb that is added in cart
  let sbInCart;
  // props.customerDashboardCart.event &&
  // localStorage.getItem(customerConstants.CART_PREVIOUS_SCREEN) ===
  //   customerConstants.TIP_TO_CART
  if (props.customerDashboardCart.length > 0) {
    sbInCart = props.customerDashboardCart
      .filter((sb) => {
        return sb.tipPrice_;
      })
      .map((sb) => {
        return sb.sbuserId;
      });
  }

  return (
    <div className="container extend-sb tip-sb">
      <div className="row">
        <div className="col d-flex justify-content-center align-items-center flex-column">
          <p className="p-0 m-0">
            {myEventsTipSb?.eventName +
              " - " +
              convertDateToUTC(myEventsTipSb?.eventDate)}
          </p>
          <p className="p-0 m-0">
            <strong> Event ID:</strong> {myEventsTipSb?.id}
          </p>
        </div>
      </div>
      <div className="row my-3">
        <div className="col px-0">
          <h6 className="p-0 m-0 mb-2">Tip you shutterb for a job well done</h6>
        </div>
      </div>
      {/* list of all shutterbs of event */}
      <div className="row px-0">
        {myEventsTipSb?.orders &&
        myEventsTipSb?.orders[0]?.orderItems.length ? (
          <form className="col px-0" onSubmit={handleFormSub}>
            {myEventsTipSb?.orders[0]?.orderItems.map((sb, index) => (
              <div
                className={
                  "py-4 " +
                  (((sbInCart && sbInCart.includes(sb.sbuserId)) ||
                    sb?.sbUser?.isTip) &&
                    "disable-div")
                  // "py-4 disable-div"
                }
                key={index}
              >
                <SbList
                  sb={sb}
                  selectedSbIds={selectedSbIds}
                  setSelectedSbIds={setSelectedSbIds}
                  myEventsTipSb={myEventsTipSb}
                  sbInCart={sbInCart}
                />
              </div>
            ))}
            <div className="checkout-btn ">
              <button type="submit" className="btn-simple">
                Checkout
              </button>
            </div>
          </form>
        ) : (
          <p>The event request has not been accepted by any shutterb yet.</p>
        )}
      </div>
    </div>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  userId: state.authentication.userId,
  myEventsTipSb: state.customerDashboard.myEventsExtendSb,
  customerDashboardCart: state.customerDashboard.customerDashboardCart,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getMyEventsTipSb: (eventId, userId) =>
    dispatch(customerDashboardActions.myEventsExtendSb(eventId, userId)),
  addToCustomerDashboardCart: (dataObj) =>
    dispatch({
      type: customerConstants.ADD_TO_CUSTOMER_DASHBOARD_CART,
      data: dataObj,
    }),
});

// mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TipYourSb));
