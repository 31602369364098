import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

export const NavigateToPath = (history, path, name, id) => {
  // but you can use a location instead
  const location = {
    pathname: path,
    state: {
      name: name ? name : history.location.state?.name,
    },
    id: id,
  };

  if (history.location.pathname !== path) {
    history.push(location);
  }
};

export const NavigateToPathWithGo = (history, path, name, id) => {
  // but you can use a location instead
  const location = {
    pathname: path,
    state: {
      name: name ? name : history.location.state?.name,
    },
    id: id,
  };

  if (history.location.pathname !== path) {
    history.push(location);
    history.go(-history);
  }
};

export const NavigateToPathWitoutHistory = (history, path, name, id) => {
  // cannot go back using browser's back navigation button
  const location = {
    pathname: path,
    state: {
      name: name ? name : history.location.state?.name,
    },
    id: id,
  };

  if (history.location.pathname !== path) {
    history.replace(location);
    history.go(-history);
  }
};

export const NavigateToDefault = (history) => {
  history.push("/Dashboard");
  history.replace("/Dashboard");
};

export const navigateToLogin = () => {
  history.replace("/Login", { name: "LogIn" });
  history.go(-history);
  window.location.reload();
};
