import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { ButtonToolbar } from "reactstrap";
import { loginActions } from "../../../../redux/actions";

import { NavigateToPathWitoutHistory } from "../../../../system";
import { useHistory } from "react-router-dom";

let serverCall = true;
const useQuery = () => new URLSearchParams(useLocation().search);

const EmailConfirmationCard = (props) => {
  const query = useQuery();
  const queryParam = query.toString();
  const history = useHistory();

  useEffect(() => {
    if (serverCall) {
      props.emailVerification(queryParam);
      serverCall = false;
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="col-12 m-auto d-flex justify-content-center align-items-center email-confirmation-con">
      {props.isEmailedVerified ? (
        <div className="email-confirmation py-5">
          <div className="d-flex justify-content-center mb-3">
            <img src="/images/logo.png" alt="shutteb company logo" />
          </div>
          <div className="d-flex justify-content-center text-center">
          <h3 className="email-confirmation__title d-flex justify-content-center">
            {props.isAuthenticated
              ? "Your e-mail address has been successfully verified"
              : "Verifing your Email...."}
          </h3>
          </div>
          {/*<p className="email-confirmation__sub d-flex justify-content-center">
            {props.isAuthenticated ? "Thank you for choosing shutterB" : ""}
            </p>*/}
          <ButtonToolbar className="email-confirmation__buttons d-flex justify-content-center">
            {props.isAuthenticated && (
              // eslint-disable-next-line
              <a
                className="btn-simple"
                onClick={() =>
                  NavigateToPathWitoutHistory(history, "/change_password")
                }
              >
                Create Password
              </a>
            )}
          </ButtonToolbar>
        </div>
      ) : (
        <div className="email-confirmation">
          <div className="d-flex justify-content-center mb-3">
            <img src="/images/logo.png" alt="shutteb company logo" />
          </div>
          <div className="d-flex justify-content-center text-center">
          <h3 className="email-confirmation__title d-flex justify-content-center">
            {" "}
            Your Verification Email Link is Expired.
          </h3>
          </div>
          <p className="email-confirmation__sub  d-flex justify-content-center">
            {" "}
            Contact Admin
          </p>
        </div>
      )}
    </div>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  isAuthenticated: state.shutterbAuthentication.isShutterbAuthenticated,
  isEmailedVerified: state.authentication.isEmailedVerified,
});

const mapDispatchToProps = (dispatch) => ({
  emailVerification: (queryParam) =>
    dispatch(loginActions.emailVerification(queryParam)),
});

// mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailConfirmationCard);
