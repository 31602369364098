import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { eventBookingActions } from "../../../../redux/actions/eventBooking.actions";
import Modal from "../../../../container/Views/Login/Modal";

const DraftEvent = ({ isAuthenticated, ...props }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const submitDraftEvent = (e) => {
    e.preventDefault();
    if (!isAuthenticated) {
      setIsModalOpen(true);
      return;
    }
    const { Event } = JSON.parse(localStorage.getItem("dataToSend"));
    const  eventDetail  = JSON.parse(localStorage.getItem("EVENT_DETAIL_STORED"));
    const dataObj = {
      event: {
        id: "",
        userId: props.userId,
        eventName: Event.eventName,
        eventDate: Event.eventDate,
        eventStartTime: Event.EventStartTime,
        apartment: Event.apartment,
        additionalNotes: Event.additionalNotes,
        sbstartTime: Event.sbStartTime,
        duration: Event.duration,
        isActive: true,
        eventTypeId: Event.EventTypeId,
        eventLocation: {
          id: 0,
          eventId: "",
          currentLocation: Event.eventLocation,
          longitude: Event.latLng.lng,
          latitude: Event.latLng.lat,
          isActive: true,
        },
        contactname: Event.contactname,
        phoneNumber: Event.phoneNumber,
        EventQuestionAnswers : [
        {
          questionTypeId: 5,
          answer: eventDetail.eventQuestion1,
        },
        {
          questionTypeId: 7,
          answer: eventDetail.eventQuestion2,
        },
        {
          questionTypeId: 8,
          answer: eventDetail.eventQuestion3,
        },
        {
          questionTypeId: 9,
          answer: eventDetail.eventQuestion4,
        },
      ]
      },
    };
    props.draftEventDetails(dataObj);
  };

  return props.isDraftEventAllowed === true ? (
    <>
      <p>
        Unfortunately we could not match your event details to a shutterb.{" "}
        <span className="draft-event-submit" onClick={submitDraftEvent}>
          {/* <Link to="/draft-event-thank-you" className="draft-event-submit"> */}
          Click here
          {/* </Link> */}
        </span>{" "}
        to submit your details to one of our hive leads that will work to help
        secure a shutterb for your event.
      </p>
      {isModalOpen && <Modal isAllowModal setIsModalOpen={setIsModalOpen} />}
    </>
  ) : (
    <p>
      {
        "We are not servicing that area and please visit our contact us page to join our mailing list."
      }
    </p>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  isDraftEventAllowed: state.eventBooking.isDraftEventAllowed,
  formState: state.eventBooking.formState,
  isAuthenticated: state.authentication.isAuthenticated,
  userId: state.authentication.userId,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  draftEventDetails: (data) =>
    dispatch(eventBookingActions.draftEventDetails(data)),
});

// mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DraftEvent));
