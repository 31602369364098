import Dashboard from "../views/Dashboard/Dashboard";
import CustomerDashboard from "../views/Customer/CustomerDashboard/CustomerDashboard";
import MyEventsDashboard from "../views/Customer/CustomerDashboard/CustomerDashboardTabs/MyEventsTab/MyEventsDashboard/MyEventsDashboard";
import ShutterbDashboard from "../views/Shutterb/ShutterbDashboard/ShutterbDashboard";
import ThankYou from "../views/Customer/EventBooking/Tabs/ThankYou";
import DraftEventThankYou from "../views/Customer/EventBooking/Tabs/DraftEventThankYou";

export const userRoutes = [
  {
    screenId: 1,
    path: `/Dashboard`,
    component: Dashboard,
  },
  {
    screenId: 2,
    path: `/CustomerDashboard`,
    component: CustomerDashboard,
  },
  {
    screenId: 3,
    path: "/EventBookRequested/:id/:orderItemGroupId/:isNewBooking",
    component: ThankYou,
  },
  {
    screenId: 4,
    path: `/CustomerDashboardTab/MyEvents/:eventId/:tab`,
    component: MyEventsDashboard,
  },
  {
    screenId: 5,
    path: `/CustomerDashboard_/:tab`,
    component: CustomerDashboard,
  },
  {
    screenId: 6,
    path: `/draft-event-thank-you`,
    component: DraftEventThankYou,
  },
  {
    screenId: 7,
    path: "/EventBookRequested",
    component: ThankYou,
  },
];

export const sbRoutes = [
  {
    screenId: 1,
    path: `/Dashboard`,
    component: Dashboard,
  },
  {
    screenId: 2,
    path: `/ShutterbDashboard`,
    component: ShutterbDashboard,
  },
  {
    screenId: 3,
    path: `/ShutterbDashboard_/:tab`,
    component: ShutterbDashboard,
  },
  {
    screenId: 4,
    path: `/ShutterbDashboardTab/:tab/:nestedTab`,
    component: ShutterbDashboard,
  },
];
