import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const BootStrapToolTip = ({ id, children, tooltip, position }) => {
  return (
    <OverlayTrigger
      overlay={<Tooltip id={id}>{tooltip}</Tooltip>}
      placement={position}
      delayShow={300}
      delayHide={150}
    >
      <div className="d-inline cursor-pointer">{children}</div>
    </OverlayTrigger>
  );
};

export default BootStrapToolTip;
