import React, { useMemo, useState } from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import LoadingIcon from "mdi-react/LoadingIcon";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { loginConstants } from "../../../../constants/login.constants";
import * as modalConstants from "../../../../constants/modal.constants";
import { loginActions } from "../../../../redux/actions";
import { configurationsConstants } from "../../../../constants";

const SignUpForm = (props) => {
  const { t } = useTranslation("common");
  const [isPasswordSame, setIsPasswordSame] = useState(true);
  /* eslint-disable */
  const loginSpinner = useMemo(
    () =>
      props.spinnerActions.findIndex((f) => f === loginConstants.USER_LOGIN),
    [props.spinnerActions]
  );

  // when user clicks on Login button
  const handleSubmit = (event, values) => {
    // event.preventDefault();

    if (values.password !== values.confirmPassword) {
      setIsPasswordSame(false);
      return;
    } else {
      setIsPasswordSame(true);
    }
    props.onSignupClick(
      {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
      },
      props.setIsModalOpen,
      props.setFormSelect
    );
  };

  const handleConfirmPassowrd = (e) => {
    e.target.value == password;
  };

  return (
    <div
      className={`login-form d-flex flex-column justify-content-center px-0 px-sm-1 px-md-4 ${
        props.isAllowModal ? "" : "border-color-grey"
      }`}
    >
      {!props.isPage && (
        <button id="cancel-icon" onClick={() => props.setIsModalOpen(false)}>
          <span>X</span>
        </button>
      )}
      <AvForm className="form form_Display_Flex" onValidSubmit={handleSubmit}>
        {/* cancel icon */}
        <div className="form-header-group pt-3">
          <h2 className="pt-0 pb-1">Sign Up</h2>
          {/*} <p className="p-0 pt-3 m-0">
            "Hello ! Please fill your details and be a part of shutterb family"
      </p>*/}
        </div>
        <div className="p-0 m-0">
          <div className="form__form-group">
            <div className="form__form-group-field">
              <div className="">
                <AvField
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  className="input-without-border-radius"
                  maxLength={35}
                  pattern="^[a-z,A-Z,0-9].{0,35}$"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: t("this_field_is_required"),
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <div className="form__form-group">
            <div className="form__form-group-field">
              <div className="">
                <AvField
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  className="input-without-border-radius"
                  maxLength={35}
                  pattern="^[a-z,A-Z,0-9].{0,35}$"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: t("this_field_is_required"),
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <div className="form__form-group">
            <div className="form__form-group-field">
              <div className="">
                <AvField
                  type="email"
                  name="email"
                  placeholder="Email"
                  className="input-without-border-radius"
                  maxLength={configurationsConstants.EMAIL_INPUT_LENGTH}
                  pattern="^[a-z,A-Z,0-9,!@#$%^&*_=+-].{3,}$"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: t("this_field_is_required"),
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <div className="form__form-group">
            <div className="form__form-group-field">
              <div className="">
                <AvField
                  className="input-without-border-radius"
                  required
                  type="password"
                  name="password"
                  placeholder="Password"
                  maxLength={configurationsConstants.PASSWORD_INPUT_LENGTH}
                  pattern="^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).{6,}$"
                  errorMessage="Minimum 6 characters, including Special, Lower & UpperCase Characters are required"
                />
              </div>
            </div>
          </div>
          <div className="form__form-group">
            <div className="form__form-group-field">
              <div className="">
                <AvField
                  className="input-without-border-radius"
                  required
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  maxLength={configurationsConstants.PASSWORD_INPUT_LENGTH}
                  pattern="^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{1,}$"
                  errorMessage="Must contain Numbers, Special Characters & Alphabets"
                  onChange={() => {
                    !isPasswordSame && setIsPasswordSame(true);
                  }}
                />
                {!isPasswordSame && (
                  <p className="custom-error p-0 m-0">
                    Password & Confirm Password does not match.
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="form__form-group align-self-center">
            <div class="form-group sign-check-row my-2">
              <AvField
                type="checkbox"
                name="termsAndConditions"
                className="pt-2 mt-2 signup-form-terms"
                label="Sign me up for shutterb news, updates and promotions."
              />
            </div>
          </div>
        </div>
        <div className="form__form-group mb-4">
          <div className="form__form-group-field">
            <div className="account__btns m-0">
              {loginSpinner > -1 ? (
                <div className=" account__btn text-center login_spinner">
                  <LoadingIcon fill="#70bbfd" style={{ width: "50px" }} />
                </div>
              ) : (
                <Button
                  className="account__btn mb-0 mt-2 submit-btn"
                  color="primary"
                >
                  SignUp
                </Button>
              )}
            </div>
          </div>
        </div>
      </AvForm>
      <div className="form__form-group align-self-center">
        <p className="p-0 m-0">
          Already have an account{" "}
          <a
            href="#"
            onClick={() => props.setFormSelect(modalConstants.LOGIN_FORM)}
          >
            login Here
          </a>
        </p>
      </div>
    </div>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  spinnerActions: state.system.spinnerActions,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  onSignupClick: (data, setIsModalOpen, setFormSelect) =>
    dispatch(loginActions.signup(data, setIsModalOpen, setFormSelect)),
});

// mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SignUpForm));
