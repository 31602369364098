import { connect } from "react-redux";
import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";

import {
  customToast,
  convertBytesToMbs,
  convertDateToUTC,
} from "../../../../../shared/utility";
import { shutterbDashboardActions } from "../../../../../redux/actions";
import {
  shutterbDashboardConstants,
  sharedConstants,
  globalSystemConstants,
  galleryConstants,
} from "../../../../../constants";
import ViewGallery from "../../../../../shared/components/ViewGallery/ViewGallery";
import { calculateTotalFileSizeInMbs } from "../../../../../shared/utility";

const ShutterBGallery = (props) => {
  const { t } = useTranslation("common");
  const fileInputRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isDeletionInProgress, setIsDeletionInProgress] = useState(false);
  const [filesAdded, setFilesAdded] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [eventDetailsById, setEventDetailsById] = useState(null);
  const [counterTotalFilesToUpload, setCounterTotalFilesToUpload] = useState(0);
  const [counterFilesUploaded, setCounterFilesUploaded] = useState(1);
  const [totalFileSizeToUpload, setTotalFileSizeToUpload] = useState(0);
  const [totalUploadedFileSize, setTotalUploadedFileSize] = useState(0);
  const [currentFileUploadedSize, setCurrentFileUploadedSize] = useState(0);
  const [currentFileName, setCurrentFileName] = useState("");
  const [currentFileSize, setCurrentFileSize] = useState("");
  const currentFilePrevUploadedSizeRef = useRef(0);

  const handleDelete = async () => {
    const filesToDeleteFromServer = selectedFiles.filter((file) => file.id);
    const filesToRemoveLocally = selectedFiles.filter((file) => !file.id);

    props.isFileDeleted();
    if (filesToDeleteFromServer.length > 0) {
      const idArray = filesToDeleteFromServer.map((file) => file.id);
      props.deleteFromGallery({ ids: idArray });
      setIsDeletionInProgress(true);
    }

    if (filesToRemoveLocally.length > 0) {
      setFiles((prevFiles) =>
        prevFiles.filter((file) => !filesToRemoveLocally.includes(file))
      );
    }

    if (
      filesToRemoveLocally.length > 0 &&
      filesToDeleteFromServer.length === 0
    ) {
      setSelectedFiles([]);
    }
  };

  const handleAddToProfile = async () => {
    props.activateSpinner();
    // returns if video is selected
    for (let i = 0; i < selectedFiles.length; i++) {
      if (selectedFiles[i]?.file?.type?.includes("vid")) {
        customToast(t("please_unselect_videos"), toast.TYPE.ERROR);
        props.diActivateSpinner();
        return false;
      }
    }
    if (
      selectedFiles.length >
      shutterbDashboardConstants.SB_PORTFOLIO_MAX_IMAGE_COUNT
    ) {
      customToast(t("max_img_error"), toast.TYPE.ERROR);
      props.diActivateSpinner();
      return false;
    }

    // backend call with form data
    let formdata = new FormData();
    formdata.append("sbUserId", props.shutterBId);
    for (let i = 0; i < selectedFiles.length; i++) {
      if (selectedFiles[i].file.name === "undefined") {
        const fileNameFromUrl = extractFileNameFromUrl(selectedFiles[i].url);
        const response = await fetch(selectedFiles[i].url);
        const blob = await response.blob();
        const fileToSent = new File([blob], fileNameFromUrl, {
          type: blob.type,
        });
        formdata.append("file", fileToSent);
      } else {
        formdata.append("file", selectedFiles.file);
      }

      // calling the action on last index
      if (i + 1 === selectedFiles.length) {
        const options = (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          if (percent <= 100) {
            setUploadPercentage(percent);
          }
        };
        props.addToProfile(formdata, options);
        setSelectedFiles([]);
      }
    }
  };

  // Helper function to extract the file name from the URL
  const extractFileNameFromUrl = (url) => {
    const parts = url.split("/");
    const fileName = parts.slice(-3).join("/");
    return fileName;
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*, video/*, .heic, .heif",
    onDrop: (acceptedFiles) => {
      setFiles((prevFiles) =>
        prevFiles.concat(
          acceptedFiles.map((file) => ({
            file,
            url: URL.createObjectURL(file),
            selected: false,
          }))
        )
      );
    },
  });

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = async (event) => {
    const selectedFiles = event.target.files;
    if (selectedFiles.length > 0) {
      props.activateOverlay();
    }
    // size limit: total payload size limit
    let totalSelectedFilesArr = Array.from(selectedFiles);
    let totalPayloadSize = calculateTotalFileSizeInMbs(totalSelectedFilesArr);
    if (totalPayloadSize > process.env.REACT_APP_GALLERY_UPLOAD_PAYLOAD_LIMIT) {
      customToast(
        `Upto ${process.env.REACT_APP_GALLERY_UPLOAD_PAYLOAD_LIMIT} MBs are allowed in an attempt`,
        toast.TYPE.ERROR
      );
      props.diActivateOverlay();
      return null;
    }
    // size limit: each file size limit
    let fileWithInvalidSizeArr = totalSelectedFilesArr.filter((file) => {
      return (
        file.size / sharedConstants.KB_TO_MB_BYTES >=
        process.env.REACT_APP_GALLERY_UPLOAD_FILE_SIZE_LIMIT
      );
    });
    if (fileWithInvalidSizeArr.length > 0) {
      customToast(
        `Each File size should be under ${process.env.REACT_APP_GALLERY_UPLOAD_FILE_SIZE_LIMIT}MB.`,
        toast.TYPE.ERROR
      );
      props.diActivateOverlay();
      return null;
    }
    const convertedFiles = Array.from(selectedFiles).map((file) => {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (
        !file.type.includes("image") &&
        !file.type.includes("video") &&
        fileExtension !== "heic" &&
        fileExtension !== "heif"
      ) {
        customToast("File format not supported", toast.TYPE.ERROR);
        props.diActivateOverlay();
        return null;
      }

      return {
        file,
        url: sharedConstants.IS_GALLERY_UPLOAD_PREVIEW,
        selected: false,
      };
    });

    // Filter out any null entries from unsupported formats
    const validFiles = convertedFiles.filter((file) => file !== null);

    setFiles((prevFiles) => {
      setFilesAdded(true); // Set the flag to true when files are added
      return [...prevFiles, ...validFiles];
    });
  };

  const handleAddToGallery = () => {
    let formdata = new FormData();
    const filesToSend = files.filter((file) => !file.id);
    filesToSend.forEach((file) => {
      formdata.append("file", file.file);
    });
    formdata.append("eventId", props.eventId);
    formdata.append("SbUserId", props.shutterBId);
    formdata.append("contentType", "file");
    if (filesToSend.length > 0) {
      const options = (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        if (percent <= 100) {
          setCurrentFileUploadedSize(loaded);
        }
      };

      props.addToGallery(
        formdata,
        options,
        null,
        counterFilesUploaded,
        setCounterFilesUploaded,
        totalUploadedFileSize,
        setTotalFileSizeToUpload,
        currentFilePrevUploadedSizeRef,
        setCurrentFileName,
        setCurrentFileSize
      );
      setCounterFilesUploaded(1);
    } else {
      //customToast(t("files_already_uploaded"), toast.TYPE.ERROR);
    }
    setSelectedFiles([]);
  };

  useEffect(() => {
    setTotalUploadedFileSize(
      totalUploadedFileSize +
        convertBytesToMbs(currentFileUploadedSize) -
        convertBytesToMbs(currentFilePrevUploadedSizeRef.current)
    );
    // keeping percentage in float to pass this condition progress > 0 and to show it as soon as uploading starts
    let updatedPercentage = (
      ((totalUploadedFileSize +
        convertBytesToMbs(currentFileUploadedSize) -
        convertBytesToMbs(currentFilePrevUploadedSizeRef.current)) /
        totalFileSizeToUpload) *
      100
    ).toFixed(2);
    updatedPercentage <= 100 && updatedPercentage > 0
      ? setUploadPercentage(updatedPercentage)
      : setUploadPercentage(100);

    currentFilePrevUploadedSizeRef.current = currentFileUploadedSize;
    // eslint-disable-next-line
  }, [currentFileUploadedSize]);

  useEffect(() => {
    if (isDeletionInProgress && props.isImageDeleted) {
      const filesToDeleteFromServer = selectedFiles.filter((file) => file.id);
      if (filesToDeleteFromServer.length > 0) {
        setFiles((prevFiles) =>
          prevFiles.filter((file) => !filesToDeleteFromServer.includes(file))
        );
        setSelectedFiles([]);
      }
      setIsDeletionInProgress(false);
    }
    // eslint-disable-next-line
  }, [isDeletionInProgress, props.isImageDeleted]);

  useEffect(() => {
    // if (props.isAddedToProfile) {
    //   setUploadPercentage(0);
    // }
    setUploadPercentage(0);
    // eslint-disable-next-line
  }, [props.isAddedToProfile]);

  useEffect(() => {
    if (filesAdded) {
      setFilesAdded(false);
      // Call handleAddToGallery only if files have been added
      handleAddToGallery();
    }
    // eslint-disable-next-line
  }, [files]);

  useEffect(() => {
    if (props.eventDetails) {
      setEventDetailsById(
        props.eventDetails.find((event) => event.id === props.eventId)
      );
    }
    // eslint-disable-next-line
  }, [props.eventDetails]);

  useEffect(() => {
    setUploadPercentage(0);
    setTotalFileSizeToUpload(0);
    setTotalUploadedFileSize(0);
    currentFilePrevUploadedSizeRef.current = 0;
    const data = props.galleryData?.gallery;
    if (data) {
      const updatedFiles = data.map((file) => ({
        ...file,
        imageUrl: `${process.env.REACT_APP_S3_BASE_URL_EVENTS_GALLERY}/${file.imageUrl}`,
        thumbnailUrl: `${process.env.REACT_APP_S3_BASE_URL_EVENTS_GALLERY}/${file.thumbnailUrl}`,
      }));
      setFiles(
        updatedFiles.map((file) => {
          let fileType;
          let galleryType;
          if (
            file.imageUrl.endsWith(".mp4") ||
            file.imageUrl.endsWith(".MP4")
          ) {
            fileType = "video/mp4";
            galleryType = galleryConstants.GALLERY_VIDEOS;
          } else if (
            file.imageUrl.endsWith(".mov") ||
            file.imageUrl.endsWith(".MOV")
          ) {
            fileType = "video/mov";
            galleryType = galleryConstants.GALLERY_VIDEOS;
          } else if (
            file.imageUrl.endsWith(".m4v") ||
            file.imageUrl.endsWith(".M4V")
          ) {
            fileType = "video/m4v";
            galleryType = galleryConstants.GALLERY_VIDEOS;
          } else {
            fileType = "image/jpeg";
            galleryType = galleryConstants.GALLERY_IMAGES;
          }

          return {
            file: new File([file], file.name, {
              type: fileType,
              lastModified: file.lastModified,
              lastModifiedDate: file.lastModifiedDate,
            }),
            url: file.imageUrl,
            thumbnailUrl: file.thumbnailUrl,
            selected: false,
            id: file?.id,
            contentType: file?.contentType,
            galleryType:
              file?.contentType === galleryConstants.CONTENT_TYPE_REELS
                ? galleryConstants.GALLERY_REELS
                : galleryType,
          };
        })
      );
    }
  }, [props.galleryData]);

  return (
    <>
      <div className="row d-flex justify-content-between pl-3 pr-3">
        {/* <div className="d-flex justify-content-start col-md-6"> */}
        {props.galleryData.eventName ? (
          <p>
            {props.galleryData.eventName} -{" "}
            {props.galleryData.eventDate
              ? convertDateToUTC(props.galleryData.eventDate)
              : ""}
          </p>
        ) : (
          ""
        )}
        {/* </div> */}
        {/* <div className="d-flex justify-content-end"> */}
        <p>
          <span className="bold-text">Event ID:</span> {props.galleryData.id}
        </p>
        {/* </div> */}
      </div>
      <div>
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12 col-xl-12">
            <button
              disabled={selectedFiles.length === 0 || uploadPercentage > 0}
              className="gallery-btn mr-md-4 mb-3 mw-175"
              onClick={() => handleAddToProfile()}
            >
              Add to profile
            </button>
            <span {...getRootProps()} className="dropzone-content">
              <input
                {...getInputProps()}
                ref={fileInputRef}
                disabled={uploadPercentage > 0}
                onChange={handleFileInputChange}
              />
              <button
                className="gallery-btn mr-md-4 mb-3 mw-175"
                disabled={uploadPercentage > 0}
                onClick={handleButtonClick}
              >
                Upload files
              </button>
            </span>
          </div>
          {/* <div className="col-12 col-md-6 col-lg-6 col-xl-6 d-flex justify-content-center">
          <div {...getRootProps()} className="dropzone-content">
            <input
              {...getInputProps()}
              ref={fileInputRef}
              disabled={uploadPercentage > 0}
              onChange={handleFileInputChange}
            />
            <button
              className="gallery-btn mr-md-4 mb-3 mw-175"
              disabled={uploadPercentage > 0}
              onClick={handleButtonClick}
            >
              Upload files
            </button>
          </div>
        </div> */}
          {/* <div className="col-12 col-xl-4  d-flex justify-content-center">
          <button
            className="gallery-btn mb-3 mw-175"
            disabled={uploadPercentage > 0}
          >
            Thumbnail
          </button>
        </div> */}
        </div>
        {files.length >= 0 &&
          typeof props.galleryData?.gallery !== "undefined" && (
            <ViewGallery
              files={files}
              isUploadAllowed
              isModalAllowed
              isAsyncUploadCall
              setFiles={setFiles}
              handleDelete={handleDelete}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              progress={uploadPercentage}
              counterFilesUploaded={counterFilesUploaded}
              counterTotalFilesToUpload={counterTotalFilesToUpload}
              setCounterTotalFilesToUpload={setCounterTotalFilesToUpload}
              setCounterFilesUploaded={setCounterFilesUploaded}
              currentFileName={currentFileName}
              setCurrentFileName={setCurrentFileName}
              currentFileSize={currentFileSize}
              setCurrentFileSize={setCurrentFileSize}
              isTabsDisplay
            />
          )}
        <div className="col-12 d-flex justify-content-center pt-4 px-0">
          <button
            className="gallery-btn mb-3 ml-0 mw-175"
            disabled={
              eventDetailsById?.order.orderItems[0].checkedStatus !==
              shutterbDashboardConstants.CHECK_IN
            }
            onClick={(e) =>
              props.onCheckStatus(
                e,
                eventDetailsById?.id,
                shutterbDashboardConstants.CHECK_OUT,
                props.setSelectedEventId
              )
            }
          >
            Checkout
          </button>
        </div>
      </div>
    </>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  galleryData: state.shutterbDashboard.galleryData,
  shutterBId: state.shutterbAuthentication.sbUserId,
  isImageDeleted: state.shutterbDashboard.isImageDeleted,
  isAddedToProfile: state.shutterbDashboard.isAddedToProfile,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  deleteFromGallery: (data) =>
    dispatch(shutterbDashboardActions.deleteFromGallery(data)),
  addToGallery: (
    data,
    options,
    dummy,
    counterFilesUploaded,
    setCounterFilesUploaded,
    totalUploadedFileSize,
    setTotalFileSizeToUpload,
    currentFilePrevUploadedSizeRef,
    setCurrentFileName,
    setCurrentFileSize
  ) =>
    dispatch(
      shutterbDashboardActions.addToGallery(
        data,
        options,
        dummy,
        counterFilesUploaded,
        setCounterFilesUploaded,
        totalUploadedFileSize,
        setTotalFileSizeToUpload,
        currentFilePrevUploadedSizeRef,
        setCurrentFileName,
        setCurrentFileSize
      )
    ),
  addToProfile: (data, options) =>
    dispatch(shutterbDashboardActions.addToProfile(data, options)),
  isFileDeleted: () => dispatch(shutterbDashboardActions.isFileDeleted()),
  activateOverlay: () =>
    dispatch({ type: globalSystemConstants.OVERLAY_ACTIVATE, data: null }),
  diActivateOverlay: () =>
    dispatch({ type: globalSystemConstants.OVERLAY_DIACTIVATE, data: null }),
  activateSpinner: () =>
    dispatch({ type: globalSystemConstants.SPINNER_ACTIVATE, data: null }),
  diActivateSpinner: () =>
    dispatch({ type: globalSystemConstants.SPINNER_DIACTIVATE, data: null }),
});

// mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ShutterBGallery));
