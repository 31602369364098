import { postRequest, getRequest, putRequest } from "../system/server-call";
import responseHandler from "../system/server-response-handler";
import { loginConstants, signupConstants } from "../constants";
import {
  clearStorage,
  encryptObject,
  setItemToStorage,
  removeItemFromStorage,
} from "../system";

/**
 * The Auth Service
 *
 * This is used for getting data from server and handling the business logic on that data
 */
// logging out the user from the app and removing its information from browser
function logout() {
  const affiliateCode = localStorage.getItem("affiliateCode");
  removeItemFromStorage(process.env.REACT_APP_PAYLOAD);
  clearStorage();
  if(affiliateCode !== null)
  {
    localStorage.setItem("affiliateCode", affiliateCode)
  };
  return Promise.resolve();
}

// handling the Login response, if user is authenticated then saving
// the jwt token in the local storage
function handleLoginResponse(response) {
  const { status } = response.data;

  if (status !== loginConstants.LOGIN_STATUS.SUCCESS) {
    logout();
    return responseHandler.handleServerResponse(response);
  }

  const ciphertext = encryptObject({
    ...response.data.payload,
  });

  setItemToStorage(process.env.REACT_APP_PAYLOAD, ciphertext);

  return response.data.payload;
}

// authenticating the user from server
function login(userData) {
  return postRequest("/api/v1/login/User", userData, false).then(
    (response) => handleLoginResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

// function handleLoginWithFacebookResponse(response) { because of the similar response from server }
// authenticating the user from server ussing Facebook Token
function loginWithFacebook(accessTokenObj) {
  return postRequest("/api/v1/OAuth/FBLogin", accessTokenObj, false).then(
    (response) => handleLoginResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

// authenticating the user from server ussing Google Token
function loginWithGoogle(accessTokenObj) {
  return postRequest("/api/v1/OAuth/GoogleLogin", accessTokenObj, false).then(
    (response) => handleLoginResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

// handle signup response
function handleSignupResponse(response) {
  const { status } = response.data;
  if (status !== signupConstants.SIGNUP_STATUS.SUCCESS) {
    return responseHandler.handleServerResponse(response);
  }

  return (
    response.data.message || "Account registered successfully, please login."
  );
}

// signup form for user
function signup(userData) {
  return postRequest("/api/v1/register/UserRegistration", userData, false).then(
    (response) => {
      return handleSignupResponse(response);
    },
    (error) => {
      return responseHandler.handleHTTPResponses(error.response);
    }
  );
}

// authenticating the email from server
function emailVerification(queryParam) {
  return getRequest(`/api/v1/login/verifyemail?${queryParam}`, false).then(
    (response) => handleLoginResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

// updating the password
function changePassword(userData) {
  return putRequest(`/api/v1/shutterb/updatepassword`, userData, true).then(
    (response) => responseHandler.handleServerResponse(response),
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

// forgot password service
function forgotPassword(userData) {
  return postRequest("/api/v1/login/ForgotPassword", userData, false).then(
    (response) => response?.data,
    (error) => responseHandler.handleHTTPResponses(error.response)
  );
}

// exporting functions for other files
export const authService = {
  login,
  logout,
  loginWithFacebook,
  loginWithGoogle,
  signup,
  emailVerification,
  changePassword,
  forgotPassword,
};
