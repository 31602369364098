export function setItemToStorage(keyname, value) {
  return localStorage.setItem(keyname, value);
}

export function getItemFromStorage(keyname) {
  return localStorage.getItem(keyname);
}

export function removeItemFromStorage(keyname) {
  return localStorage.removeItem(keyname);
}

export function clearStorage() {
  return localStorage.clear();
}
