export const eventBookingConstants = {
  CHECK_OUT: "CHECK_OUT",
  SET_CART_TAB: "SET_CART_TAB",
  GET_SHOT_LIST: "GET_SHOT_LIST",
  SET_FORM_STATE: "SET_FORM_STATE",
  REQUEST_BOOKING: "REQUEST_BOOKING",
  GET_EVENT_TYPES: "GET_EVENT_TYPES",
  SELECTED_SHUTTER_BS: "SELECTED_SHUTTER_BS",
  GET_AVAILABLE_SHUTTER_BS: "GET_AVAILABLE_SHUTTER_BS",
  GET_REEL_PRICE: "GET_REEL_PRICE",
  SET_REEL_VALUES: "SET_REEL_VALUES",
  SET_REEL_VALUES_ON_DELETE: "SET_REEL_VALUES_ON_DELETE",
  GET_MISC_AMOUNTS: "GET_MISC_AMOUNTS",
  REEL_STORED: "REEL_STORED",
  SET_UNDO_CART_ITEM: "SET_UNDO_CART_ITEM",
  UPLOAD_MUSIC_FILE: "UPLOAD_MUSIC_FILE",
  TABS: {
    EVENT_SETUP: 1,
    EVENT_DETAIL: 2,
    SELECT_SHUTTERB: 3,
    ADD_REEL: 4,
    CART: 5,
  },
  // add reel
  REEL_QTY_OPTIONS: [0, 1, 2, 3, 4, 5],
  REEL_ORIENTATION_OPTIONS: [
    "Portrait (Instagram, TikTok)",
    "Landscape (YouTube)",
  ],
  ADD_REEL_STATES: {
    IS_REEL_WITHOUT_LOGO: "isReelWithoutLogo",
    REEL_PRICE_WITHOUT_LOGO: "reelPriceWithoutLogo",
    REEL_QTY_WITHOUT_LOGO: "reelQtyWithoutLogo",
    IS_REEL_WITH_LOGO: "isReelWithLogo",
    REEL_PRICE_WITH_LOGO: "reelPriceWithLogo",
    REEL_QTY_WITH_LOGO: "reelQtyWithLogo",
    LOGO: "logo",
    REEL_QUESTION_ONE: "reelQuestion1",
    MUSIC: "music",
    ORIENTATION: "orientation",
    TERMS: "terms",
    REEL_TOTAL: "reelTotal",
    IS_REEL_SUBMITTED: "isReelSubmitted",
    MUSIC_FILE: "musicFile",
    REEL_TYPE: "reelType",
  },
  ORDER_ITEM_TYPE_ID: {
    NEW_EVENT_SB: 2,
    REEL: 3,
  },
  ADD_EVENT_DETAIL: {
    EVENT_QUESTION_ONE: "eventQuestion1",
    EVENT_QUESTION_TWO: "eventQuestion2",
    EVENT_QUESTION_THREE: "eventQuestion3",
    EVENT_QUESTION_FOUR: "eventQuestion4",
  },
  TIP_PRICE_: "tipPrice_",
  EXTENDED_PRICE_: "extendedPrice_",
  SET_IS_DRAFT_EVENT_ALLOWED: "SET_IS_DRAFT_EVENT_ALLOWED",
  SET_BULK_REEL_VALUES: "SET_BULK_REEL_VALUES",
  SET_EVENT_DETAIL_VALUES: "SET_EVENT_DETAIL_VALUES",
  EVENT_DETAIL_STORED: "EVENT_DETAIL_STORED",
  GET_REEL_TYPES: "GET_REEL_TYPES",
  SET_BULK_EVENT_DETAIL_VALUES: "SET_BULK_EVENT_DETAIL_VALUES",
};
